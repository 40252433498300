import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

// Constants
import { MAP } from '../../../../../constants'

// Redux Actions
import * as ACTIONS from '../../../../../services/redux/actions'

export const MapInitialiserWithVehicleAndGeofence = (vehicles, geofences, mapControl, mapRef) => {

    const dispatch = useDispatch()

    // Initial Mount Component
    useEffect(() => {
        
        dispatch(ACTIONS.v3_geofences.clear_selected_geofence_request())
        dispatch(ACTIONS.v3_vehicles.clear_selected_vehicle_request())
        dispatch(ACTIONS.v3_mapControl.clear_enabled_geofence_pop_ups())
        dispatch(ACTIONS.v3_mapControl.clear_enabled_vehicle_pop_ups())

    }, [dispatch])

    /**
     * If map did not set location to geofence/vehicles within 5 seconds, (a sign of a new account)
     * default location & zoom would be set instead
     */
     useEffect(() => {
        if (!mapRef) return

        setTimeout(() => 
            {
                if (!mapRef.center) {
                    mapRef.panTo(MAP.PREFERENCE_MAP_LOCATION)
                    mapRef.setZoom(MAP.REFERENCE_MAP_ZOOM_SIZE)
                }
            }, 
            5 * 1000
        )
    })

    /**
     * If map hasn't set a location yet,
     * Pan to all map item's circumference location
     * Else if map already has a location, do nothing
     */
     useEffect(() => {
        if (!mapRef) return
        if (mapRef.center) return

        const vehicleLocations = 
            Object.values(vehicles.stateByID).map(vehicle => {
                const location = vehicle.location
                return location && {
                    lat: parseFloat(location.lat),
                    lng: parseFloat(location.lon)
                }
            })
            .filter(l => l)

        const geofenceLocations = 
            Object.values(geofences.byID).map(geofence => {
                return geofence.coordinates
            })
            .reduce((a, b) => [...a, ...b], [])
            .filter(l => l)

        const allLocations = [...vehicleLocations, ...geofenceLocations]

        if (allLocations.length) {
            const bounds = new window.google.maps.LatLngBounds();

            allLocations.map(c => {
                bounds.extend(c)
                return null;
            })

            // console.log(`[GoogleMapsComponent] Setting map location by vehicles & geofences`);

            mapRef.fitBounds(bounds, [10]);
        }

    }, 
        [vehicles, geofences, mapRef]
    )

    /**
     * Render geofence, vehicles, etc
     */
     useEffect(() => {
        let zoom, location;

        /**
         * Pan to selected transit status
         */
        if (geofences.selectedID
            && geofences.byID[geofences.selectedID]
            && geofences.byID[geofences.selectedID].coordinates.length > 0
            && mapControl.mapControl === 1
        ) {
            const selectedGeofence = geofences.byID[geofences.selectedID];

            if (selectedGeofence.coordinates.length
                && selectedGeofence.coordinates[0]
                && selectedGeofence.coordinates[0].lat && selectedGeofence.coordinates[0].lng
            ) {
                zoom = MAP.REFERENCE_MAP_ZOOM_IN_COMPONENT_SIZE;

                location = {
                    lat: parseFloat(selectedGeofence.coordinates[0].lat),
                    lng: parseFloat(selectedGeofence.coordinates[0].lng),
                }

                if (mapRef) {
                    mapRef.setZoom(zoom);
                    mapRef.panTo(location);

                   dispatch(ACTIONS.v3_mapControl.set_map_control(0));
                }
            } else {
                dispatch(ACTIONS.v3_mapControl.set_map_control(0));
            }
        }
    })
}