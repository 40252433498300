import 
    React, 
    { 
        useState,
 } from 'react';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import {
    GoogleMap,
    TrafficLayer,
} from '@react-google-maps/api';

// Components
import MapSearchBar from '../../components/MapSearchBar';
import MapControlPanel from '../../components/MapControlPanel';
import GoogleMapsLoadScriptEnabler from "../components/GoogleMapsLoadScript";
import POIGeofences from "../../components/Geofences_POI";
import ZoneGeofences from "../../components/Geofences_Zone";
import TransitRecordsDrawer from '../components/TransitRecordsDrawer'
import RoutePlaybackTrace from '../components/RoutePlaybackTrace'
import EventTrace from '../components/EventTrace'

// Constants

// Handler
import { MapInitialiserWithVehicleAndGeofence } from '../handlers/MapInitialiserWithVehicleAndGeofence'
import { UnfocusMap } from '../handlers/UnfocusMap'

// Service
import { REDUX as REDUX_UTIL } from '../../../../services/util'

// Redux Action 

// Styles
import "../gmaps.css";

const { 
    reduxStoreWrapper
} = REDUX_UTIL

const GoogleMapsRoutePlayback = () => {

    const dispatch = useDispatch()

    // State
    const [mapRef, setMapRef] = useState(null);

    // Redux Store
    const vehicles = useSelector(reduxStoreWrapper('vehicles'))
    const geofences = useSelector(reduxStoreWrapper('geofences'))
    const mapControl = useSelector(reduxStoreWrapper('mapControl'))

    MapInitialiserWithVehicleAndGeofence(vehicles, geofences, mapControl, mapRef)

    return (
        <GoogleMap
            id = 'googles-map'
            mapContainerStyle = {{
                height: '100vh'
            }}
            onLoad = {ref => setMapRef(ref)}
            onDragStart = {() => {
                UnfocusMap(dispatch)
            }}
        >
            <POIGeofences mapRef = {mapRef} />
            <ZoneGeofences mapRef = {mapRef} /> 

            {mapControl.UIControl.showLiveTraffic && <TrafficLayer />}

            <div className = "searchBar">
                <MapSearchBar />
            </div>

            <div className = "controlPanel">
                <MapControlPanel 
                    geofenceMarkerToggle = {true}
                    geofenceInfoToggle = {true}
                    liveTrafficTroggle = {true}
                    vehicleInfoToggle = {false}
                />
            </div>

            <RoutePlaybackTrace mapRef = {mapRef} />
            <EventTrace />

            {
                mapControl
                &&
                mapControl.mapEvent.transitRecordDrawerState
                &&
                <TransitRecordsDrawer />
            }

        </GoogleMap>
    )
}

export default GoogleMapsLoadScriptEnabler(GoogleMapsRoutePlayback);