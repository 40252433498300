import 
    React, 
    { 
        useEffect, 
        useState
 } from 'react';
import { 
    useDispatch, 
    useSelector 
} from 'react-redux'
import {
    MarkerF,
    Circle,
    OverlayView,
    MarkerClustererF,
} from '@react-google-maps/api';

// Constans
import { 
    TEMPLATE,
    MAP
 } from "../../../../constants"

// Handler
import {
    geofenceTemplates_handler
} from '../../../../handlers'

// Redux Actions
import {
    v3_geofences,
    v3_mapControl
} from '../../../../services/redux/actions'

// Styles
import "../../GoogleMaps/gmaps.css";

const {
    DEFAULT_GEOFENCE_COLOR,
    GEOFENCE_TYPE_OBJ
} = TEMPLATE.GEOFENCE

const {
    POI_MARKER_BY_COLOR
} = MAP

const {
    returnLatestGeofenceTemplateColorForGeoID
} = geofenceTemplates_handler

const {
    set_selected_geofence_request,
    clear_selected_geofence_request
} = v3_geofences

const {
    clear_enabled_geofence_pop_ups,
    set_map_control
} = v3_mapControl

const POIGeofences = (props) => {

    const { 
        mapRef 
    } = props;

    // State
    const [circleRefs, setCircleRefs] = useState([]);

    // Redux Stores
    const vehicles = useSelector(state => state.containers.v3.vehicles);
    const geofences = useSelector(state => state.containers.v3.geofences);
    const mapControl = useSelector(state => state.containers.v3.mapControl);
    const geofenceTemplates = useSelector(state => state.containers.v3.geofenceTemplates);

    const dispatch = useDispatch();

    const getMarker = (colorHexCode) => {
        return POI_MARKER_BY_COLOR[(colorHexCode || DEFAULT_GEOFENCE_COLOR).replace("#", "").toUpperCase()]
    }

    // Initial mount of component
    useEffect(() => {
        dispatch(clear_selected_geofence_request());
        dispatch(clear_enabled_geofence_pop_ups());
    },
        [dispatch]
    )

    useEffect(() => {
        if (
            geofences.selectedID
            && geofences.byID[geofences.selectedID]
            && geofences.byID[geofences.selectedID].type === GEOFENCE_TYPE_OBJ.POI
            && geofences.byID[geofences.selectedID].coordinates
            && mapControl.mapControl === geofences.selectedID
        ) {
            const thisCircleRef = circleRefs.find(r => r.id === geofences.selectedID)

            if (mapRef) {
                const bounds = thisCircleRef.circle.getBounds();
                mapRef.fitBounds(bounds);

                dispatch(set_map_control(0));
            }
        }
    })

    const getCircles = (geofences) => {

        return (
            <MarkerClustererF
                options={{
                    getMinimumClusterSize: () => {
                        return 3
                    }
                }}
            >
                {
                    clusterer => {
                        return geofences.map(geofence => {
                            return <div key={geofence.geoid}>
                                <Circle
                                    onLoad={ref => {
                                        const newCircleRef = {
                                            id: geofence.geoid,
                                            circle: ref
                                        }
                                        circleRefs.push(newCircleRef)
                                        setCircleRefs(circleRefs)
                                    }}
                                    key={`c#${geofence.geoid}`}
                                    center={geofence.coordinates[0]}
                                    radius={geofence.radius}
                                    options={{
                                        // strokeColor: '#ff0000'
                                        strokeColor: returnLatestGeofenceTemplateColorForGeoID(geofenceTemplates, geofence.geoid)
                                    }}
                                    onClick={() => {
                                        dispatch(set_selected_geofence_request(geofence.geoid));
                                        dispatch(set_map_control(geofence.geoid));
                                    }}
                                />

                                <MarkerF
                                    key={geofence.geoid}
                                    icon={getMarker(returnLatestGeofenceTemplateColorForGeoID(geofenceTemplates, geofence.geoid))}
                                    title={geofence.name}
                                    position={geofence.coordinates[0]}
                                    onClick={() => {
                                        dispatch(set_selected_geofence_request(geofence.geoid));
                                        dispatch(set_map_control(geofence.geoid));
                                    }}
                                    clusterer={clusterer}
                                />

                                {
                                    mapControl.UIControl.showAllGeofencesInfoWindow &&
                                    <OverlayView
                                        options={{ disableAutoPan: true }}
                                        position={geofence.coordinates[0]}
                                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                        getPixelPositionOffset={(width, height) => ({
                                            x: -(width / 2),
                                            y: -height - 45,
                                        })}
                                    >
                                        <div
                                            style={{
                                                background: 'white',

                                                border: '1px solid #ccc',
                                                borderRadius: '10px',
                                                width: 8 * (geofence.name.length || 1),
                                                paddingLeft: 10,
                                                paddingRight: 5,
                                                paddingTop: 5,
                                                paddingBottom: 0,
                                            }}
                                        >
                                            <h4>{geofence.name}</h4>
                                            <div>{geofence.comment}</div>
                                        </div>
                                    </OverlayView>
                                }
                            </div>

                        })
                    }
                }
            </MarkerClustererF>
        )
    }

    const POIs = Object.values(geofences.byID).filter(geofence => {
        return mapControl.UIControl.showAllGeofence && geofence.type === 'POI';
    })

    return (
        vehicles && getCircles(POIs)
    )
}

export default React.memo(POIGeofences)