export const LightenDarkenColor = (color, percent) => {
    const f = parseInt(color.slice(1), 16),
      t = percent < 0 ? 0 : 255,
      p = percent < 0 ? percent * -1 : percent,
      R = f >> 16,
      G = (f >> 8) & 0x00ff,
      B = f & 0x0000ff;
    return (
      "#" +
      (
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)
    );
}

export const getTextColor = (col) => {
    const rgbCol = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(col);
  
    const r = parseInt(rgbCol[1], 16);
    const g = parseInt(rgbCol[2], 16);
    const b = parseInt(rgbCol[3], 16);
  
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  
    const textCol = yiq > 125 ? "black" : "white";
  
    return textCol;
}
  
export const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};