import 
    React,
    {
        useState
    }
from 'react'
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';

// Components
import VehicleInfoSelector from './components/VehicleInfoSelector'
import FATimelineChart from './components/FATimelineChart'
import InfographicDashboard from './components/InfographicDashboard'

import showSecondarySidebar from '../../layout/MainLayout/helpers/showSecondarySidebar' 
import Page from "../../components/Decoration/Page";

// Handler
import { vehicles_handler } from '../../handlers'

// Redux Action
import { v3_vehicleReports } from '../../services/redux/actions'

// Util
import { REDUX as UTIL_REDUX, TIME } from '../../services/util'

const {
    reduxStoreWrapper
} = UTIL_REDUX

const {
    referenceStartTime,
    referenceEndTime
} = TIME

const {
    vehicleStatesMerger
} = vehicles_handler

const {
    get_vehicle_report_fuel_analysis_by_timeframe_request
} = v3_vehicleReports

const FuelAnalysisReport = () => {

    const dispatch = useDispatch()

    const vLogs = useSelector(reduxStoreWrapper('vehicleReportFuelAnalysis.logByVID'))
    const summaries = useSelector(reduxStoreWrapper('vehicleReportFuelAnalysis.summaryByVID'))
    const fuelReports = useSelector(reduxStoreWrapper('vehicleReportFuelAnalysis.reportByVID'))

    const vehicles = useSelector(reduxStoreWrapper('vehicles.staticsByID'))
    const vehicleStates = useSelector(reduxStoreWrapper('vehicles.stateByID'))
    const loading = useSelector(reduxStoreWrapper('style.loading.fuelAnalysisDashboard'))

    const [ selectedVID, setSelectedVID ] = useState('')
    const [ startTime, setStartTime ] = useState(referenceStartTime())
    const [ endTime, setEndTime ] = useState(referenceEndTime())
    const [ fuelUnitPrice, setFuelUnitPrice ] = useState(2.15)

    const SubmitForm = () => {
        dispatch(get_vehicle_report_fuel_analysis_by_timeframe_request(selectedVID, startTime.utc().valueOf(), endTime.utc().valueOf()))
    }

    return (
        <div className = "page-container">
            <Page title = "Fuel Analysis Dashboard">
                <div
                    style = {{
                        display: "block",
                        minHeight: "95px",
                        padding: "10px 10px 10px 10px",
                    }}
                >
                    <VehicleInfoSelector
                        // params
                        vehicles = {vehicleStatesMerger(vehicles, vehicleStates)}
                        vid = {selectedVID}
                        startTime = {startTime}
                        endTime = {endTime}
                        fuelUnitPrice = {fuelUnitPrice}
                        isLoading = {loading}
                        // Handler
                        setVID = {setSelectedVID}
                        setStartTime = {setStartTime}
                        setEndTime = {setEndTime}
                        setFuelUnitPrice = {setFuelUnitPrice}
                        onSubmit = {SubmitForm}
                    />
                </div>
                {
                    selectedVID
                    &&
                    vLogs 
                    && 
                    vLogs[selectedVID]
                    &&
                    <>
                        <InfographicDashboard 
                            report={fuelReports && fuelReports[selectedVID]} 
                            summary={summaries && summaries[selectedVID] || {}} 
                            fuelUnitPrice={fuelUnitPrice}
                        />
                        <FATimelineChart dataPoints ={vLogs[selectedVID] || []}/>
                    </>
                }
            </Page>
        </div>
    )
}

const MemorizedFuelAnalysisReport = React.memo(FuelAnalysisReport);

export default showSecondarySidebar(false)(MemorizedFuelAnalysisReport);