export const FEATURE_NAME = {
    // Management
    GEOFENCE_MANAGEMENT: 'GEOFENCE_MANAGEMENT',
    GEOFENCE_MANAGEMENT_ZONE_CREATE: 'GEOFENCE_MANAGEMENT_ZONE_CREATE',
    GEOFENCE_MANAGEMENT_ZONE_EDIT: 'GEOFENCE_MANAGEMENT_ZONE_EDIT',
    GEOFENCE_MANAGEMENT_POI_CREATE: 'GEOFENCE_MANAGEMENT_POI_CREATE',
    GEOFENCE_MANAGEMENT_POT_EDIT: 'GEOFENCE_MANAGEMENT_POI_EDIT',
    DEVICE_MANAGEMENT: 'DEVICE_MANAGEMEN',
    VEHICLE_MANAGEMENT: 'VEHICLE_MANAGEMENT',
    VEHICLE_GROUP_MANAGEMENT: 'VEHICLE_GROUP_MANAGEMENT',
    RULE_ENGINE_MANAGEMENT: 'RULE_ENGINE_MANAGEMENT',
    NOTIFICATION_MANAGEMENT: 'NOTIFICATION_MANAGEMENT',
    USER_MANAGEMENT: 'USER_MANAGEMENT',
    OVERALL_MANAGEMENT: 'OVERALL_MANAGEMENT',
    // Highway Surveillance Management
    HIGHWAY_SURVEILLANCE_MANAGEMENT: 'HIGHWAY_SURVEILLANCE_MANAGEMENT',
    // Maintenance Management
    OVERALL_MAINTENANCE_MANAGEMENT: 'OVERALL_MAINTENANCE_MANAGEMENT',
    OVERALL_MAINTENANCE_STATE: 'OVERALL_MAINTENANCE_STATE',
    MAINTENANCE_PROFILE_MANAGEMENT: 'MAINTENANCE_PROFILE_MANAGEMENT',
    MAINTENANCE_CONFIGURATION_MANAGEMENT: 'MAINTENANCE_CONFIGURATION_MANAGEMENT',
    // VAM
    OVERALL_VEHICLE_ADVANCE_MAINTENANCE: 'OVERALL_VEHICLE_ADVANCE_MAINTENANCE',
    VEHICLE_REMINDER_MANAGEMENT: 'VEHICLE_REMINDER_MANAGEMENT',
    VEHICLE_ENTRY_MANAGEMENT: 'VEHICLE_ENTRY_MANAGEMENT',
    VEHICLE_ADVANCE_MAINTENANCE_CONFIGURATION_MANAGEMENT: 'VEHICLE_AVDANCE_MAINTENANCE_CONFIGURATION_MANAGEMENT',
    //Report
    ESCALATION_CALL_REPORT: 'ESCALATION_CALL_REPORT',
    VEHICLE_OVERALL_REPORT: 'VEHICLE_OVERALL_REPORT',
    FUEL_ANALYSIS_DASHBOARD: 'FUEL_ANALYSIS_DASHBOARD',
    // App Info
    VEHICLE_INFORMATION_PANEL: 'VEHICLE_INFORMATION_PANEL',
    VEHICLE_ROUTE_PLAYBACK: 'VEHICLE_ROUTE_PLAYBACK',
    VEHICLE_EVENT_TRACE: 'VEHICLE_EVENT_TRACE',
    USER_PROFILE: 'USER_PROFILE',
    USER_HIERARCHY_MAP: 'USER_HIERARCHY_MAP',
    LANDING_PAGE: 'LANDING_PAGE',
    // Third Party
    GREENER_WHEELS: 'GREENER_WHEELS'
}