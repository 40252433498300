import React, { useState } from 'react'
import {
    MarkerF,
    InfoWindow,
} from '@react-google-maps/api';

import { vehicles_handler } from '../../../../handlers'

const {
    locationMarkiser
} = vehicles_handler

const IndicatorMarker = (props) => {

    const {
        KEY = '-',
        location,
        InfoComponent,
        markerProps,
        infoWindowProps,
        infoDefault,
        shown = false
    } = props

    const [ indicatorState, setIndicatorMarkerState ] = useState((shown || infoDefault) || false)
    const position = locationMarkiser(location)

    return (
        <>
            <MarkerF
                key={`${KEY}#marker`}
                {...markerProps}
                position = {position}
                onClick = {() => setIndicatorMarkerState(!indicatorState)}
                {...markerProps}
            >
                {
                    (
                        shown
                        ||
                        indicatorState
                    )
                    &&
                    <InfoWindow
                        key={`${KEY}#info#window`}
                        zIndex = {1000}
                        position = {position}
                        options = {{
                            disableAutoPan: true,
                        }}
                        {...infoWindowProps}
                    >
                        <InfoComponent key={`${KEY}#info#comp`}/>
                    </InfoWindow>
                }
            </MarkerF>
        </>
    )
}

export default IndicatorMarker