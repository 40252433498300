import 
    React,
    {
        useState,
        useEffect
    }
from "react";
import {
    useSelector
} from 'react-redux'
import { 
    Form, 
    Radio,
    Select
} from 'antd';

// Constant
import { TEMPLATE } from '../../../../../../../../constants'

// Services
import { REDUX } from '../../../../../../../../services/util'

// Components

// Routes

// Redux Action

// Styling
import './index.css'

const FormItem = Form.Item;
const { Option } = Select;

const { MAINTENANCE  } = TEMPLATE
const { SERVICE_CODE, STATUS_CODE } = MAINTENANCE

const Maintenance_Service_CU = ({mainsid, form}) => {

    const maintenanceComponents = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.componentByID'))
    const maintenanceServices = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.serviceByID'))
    const maintenanceServicesByMANCID = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.serviceByMAINCID'))

    const [selectedMAINCID, setSelectedMAINCID] = useState('')
    const [activeStatus, setActiveStatus ] = useState(1)
    const [serviceParam, setServiceParam ] = useState()

    useEffect(() => {
        if(mainsid) {
            const maintenanceService =  maintenanceServices[mainsid]
            
            setServiceParam(maintenanceService.serviceParam)
            setActiveStatus(maintenanceService.activeStatus)
            setSelectedMAINCID(maintenanceService.maincid)
        }

    }, [mainsid])

    return (
        <>
            <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                fields={[
                    {
                        name: ["maincid"],
                        value: selectedMAINCID
                    },
                    {
                        name: ["serviceParam"],
                        value: serviceParam
                    },
                    {
                        name: ["activeStatus"],
                        value: activeStatus
                    },
                ]}
            >
                <FormItem label="Component" name="maincid" rules={[{ required: true, message: 'Please select one component!' }]}>
                    <Select 
                        showSearch
                        style={{ width: '40vw' }} 
                        onChange={(value) => setSelectedMAINCID(value)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {
                            Object.keys(maintenanceComponents).map(maincid => (
                                <Option key={maincid} value={maincid}>{maintenanceComponents[maincid] && maintenanceComponents[maincid].name}</Option>
                            ))
                        }
                    </Select>
                </FormItem>
                <FormItem label="Service Param" name="serviceParam" rules={[{ required: true, message: 'Please select one serivce param' }]}>
                    <Select 
                        showSearch
                        style={{ width: '40vw' }} 
                        disabled={!selectedMAINCID}
                        onChange={(value) => setServiceParam(value)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {
                            selectedMAINCID
                            &&
                            Object.keys(SERVICE_CODE)
                            .map(code => {

                                const orgArr = maintenanceServicesByMANCID[selectedMAINCID]

                                const compArr = orgArr && orgArr.filter(mainsid => maintenanceServices[mainsid].serviceParam !==  Number(code))

                                return (
                                    orgArr && compArr && orgArr.length !== compArr.length ?
                                        <Option key={code} value={code} disabled>{SERVICE_CODE[code]}</Option>
                                    :
                                        <Option key={code} value={code}>{SERVICE_CODE[code]}</Option>
                                )
                            })
                        }
                    </Select>
                </FormItem>
                <FormItem label="Status" name="activeStatus" rules={[{ required: true, message: 'Please choose status!' }]}>
                    <Radio.Group 
                        options={Object.keys(STATUS_CODE).map(code => ({label: STATUS_CODE[code][0], value: code}))} 
                        onChange={({ target: { value } }) => setActiveStatus(value)} 
                        value={activeStatus} 
                        optionType="button" 
                        buttonStyle="solid"
                    />
                </FormItem>
            </Form>
        </>
    )
}

export default Maintenance_Service_CU