import 
    React,
    {
        useState,
        useEffect
    }
from "react";
import { 
    useDispatch,
    useSelector
} from 'react-redux'
import { useNavigate } from 'react-router'
import { message } from "antd";

/**
 * Sub Components
 */
import SSOLoginForm from './components/SSOLoginForm'

/**
 * Config
 */
import * as CONFIG from '../../config'

/**
 * Constant
 */
import { ROOT_CONSTANT } from '../../constants'

/**
 * Services
 */

/**
 * Redux Actions
 */
import * as ACTIONS from '../../services/redux/actions';


const SSOLogin= (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const auth = useSelector(state => state.root.auth)

    const [loading, setLoading] = useState(false)

    const login_onPress = (email, password) => {
        dispatch(ACTIONS.main_user.auth_user_sign_in(email.trim(), password, ({status, err}) => {
           if(status === 200) {
                setLoading(false)
           } else if (status === 404) {
                message.error(err)
                setLoading(false)
           } 
        }))
        setLoading(true)
    };

    useEffect(() => {
        auth.currentUser && navigate('/')

        return (() => {})
    }, [auth, dispatch])
    
    return (
        <div
            style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                flex: 1,
                alignItems: "center"
            }}
        >
            <div
                style={{
                    position: "absolute",
                    height: "100vh",
                    width: "100%",
                    backgroundImage:`url(${ROOT_CONSTANT.THEME.LOGIN_BACKGROUND})`,
                    backgroundSize: "cover"
                }}
            />

            <div
                style={{
                    zIndex: 1,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            padding: "35px 30px 15px 30px",
                            borderRadius: "17px"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}
                        >
                            <img
                                src={ROOT_CONSTANT.THEME.LOGO_SUB_WORD}
                                alt={CONFIG.APP_INFO.PUBLICATION_COMPANY}
                                style={{ height: "120px" }}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}
                        >
                            <div
                                style={{
                                    width: "250px",
                                    paddingTop: "30px"
                                }}
                            >
                                <SSOLoginForm
                                    {...props}
                                    isLoginLoading={loading}
                                    onLoginPress={(email, password) =>
                                        login_onPress(email, password)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    backgroundColor: 'black',
                    borderRadius: "17px",
                    paddingRight: '20px',
                    paddingLeft: '20px',
                    color: 'white',
                    position: 'absolute',
                    bottom: '2vh',
                    right: '5vw',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    zIndex: 1
                }}
            >
                 {/* {CONFIG.APP_INFO.PUBLICATION_COMPANY} - {CONFIG.APP_INFO.PROJECT_NAME} - {CONFIG.APP_INFO.APP_VERSION} */}
                 {CONFIG.APP_INFO.PROJECT_NAME} - {CONFIG.APP_INFO.APP_VERSION}
            </div>
        </div>
    );
}

export default React.memo(SSOLogin)