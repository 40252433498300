import React from "react";
import { 
  useSelector,
} from 'react-redux';
import { useLocation, useNavigate } from 'react-router'

import Layout from "./Layout";

// Constants & Config
import { FEATURE, ROOT_CONSTANT, ROUTES	} from '../../constants'

// Services
import { defineAccessible, ACCESSIBLE_ACTION } from "../../services/ability";
import { REDUX } from '../../services/util'

const LayoutContainer = (props) => {

	const location = useLocation()
	const navigate = useNavigate()

	const userRole = useSelector(REDUX.reduxStoreWrapper('user.uroleid'))
	const accountPackages = useSelector(REDUX.reduxStoreWrapper('user.accountPackages'))
	const porgid = useSelector(REDUX.reduxStoreWrapper('user.porgid'))

	const navigateTo = pageKey => {
		navigate(ROUTES.MODULE_ROUTE[pageKey]);
	}

	const sidebarItems = FEATURE.FEATURE_MENU_ITEMS_COMP.filter(listItem => {
		if(userRole)
			return defineAccessible(userRole, accountPackages, porgid).can(ACCESSIBLE_ACTION.READ, listItem.feature)
		else
			return false
	})

	return (
		<Layout
			sidebarItems = {sidebarItems}
			selectedkeys = {location ? location.pathname : ""}
			themeColor = {ROOT_CONSTANT.THEME.THEME_COLOR}
			onClickSidebarItem = {sidebarItem => navigateTo(sidebarItem.key)}
		>
			{props.children}
		</Layout>
	);
}

export default LayoutContainer;