import * as API from "../../api"
import * as ActionTypes from "../action-types";
import { REDUX } from '../../../constants'
import { batch } from "react-redux";

/**
 * ThirdParty Library
 */
 import {
    message
} from 'antd'

/**
 * Redux Action
 */
import { 
    get_geofence_request,
    get_geofences_request
} from './geofences'

/**
 * Get Geofence Template
 */

export const get_geofence_template_request = (geotid, uid = null) => dispatch => {
    

    API
    .getGeofenceTemplateByGEOTID (geotid)
    .then(info => {
        
        try {
            const {
                geofenceTemplate
            } = info

            dispatch(get_geofence_template_success(geofenceTemplate, uid))
            geofenceTemplate && geofenceTemplate.geofences && dispatch(get_geofence_request(geofenceTemplate.geofences))


        } catch(err) {
            message.error('Error in getting geofence templates. Refresh page?')
        }

    })
}

const get_geofence_template_success = (geofenceTemplate, uid = null) => {
    return {
        type: ActionTypes.GET_GEOFENCE_TEMPLATE,
        geofenceTemplate,
        uid
    };
}

/**
 * Get Geofence Templates
 */

 export const get_geofence_templates_request = (geotids, uid = null) => dispatch => {

    let geotidBatch = []

    batch(() => {

        geotids.map((geotid, index) => {

            geotidBatch.push(geotid)
    
            if( 
                (index+1) % REDUX.REDUX_GEOFENCE_TEMPLATE_BATCH_GET_SIZE === 0
                ||
                index+1 === geotids.length
            ) {
                dispatch(batch_get_geofence_templates_request(geotidBatch, uid))
                geotidBatch = []
            }
    
            return null
        })

    })

 }

/**
 * Batch Get Geofence Templates
 */
 export const batch_get_geofence_templates_request = (geotids, uid = null) => dispatch => {

    API
    .getGeofenceTemplateBatch(geotids)
    .then(info => {
        
        try {
            const {
                geofenceTemplates
            } = info

            dispatch(batch_get_geofence_templates_success(geofenceTemplates, uid))

            geofenceTemplates 
            && geofenceTemplates.length 
            && dispatch(
                get_geofences_request(
                    geofenceTemplates.reduce((acc, cur) => [...acc, ...cur.geofences], [])
                )
            )


        } catch(err) {
            message.error('Error in batch getting geofence templates. Refresh page?')
        }

    })
}

const batch_get_geofence_templates_success = (geofenceTemplates, uid = null) => {
    return {
        type: ActionTypes.GET_GEOFENCE_TEMPLATES,
        geofenceTemplates,
        uid
    };
}

/**
 * CU Geofence Template
 */

export const cu_geofence_template_request = (item) => dispatch => {

    const { actionAPI, uid, geofences, defaultGEOTID } = item

    API
    .CU_GeofenceTemplate(item, uid)
    .then(info => {

        try {

            const {
                status,
                geofenceTemplate,
                msg
            } = info
    
            switch(status) {
                case 200: {

                    message.success(`Success ${REDUX.REDUX_CRUD_API_ACTION_THRU_CODE[actionAPI]} geofence template.`)
    
                    dispatch(cu_geofence_template_success(geofenceTemplate))
                    dispatch(cu_unbind_geofences_from_geofence_template_success(geofences, defaultGEOTID))
    
                    break;
                }
                case 500: {
                    throw msg
                }
                default: {
                    break;
                }
            }

        } catch(err) {
            console.log("Error", err)
            message.error(`Error in ${REDUX.REDUX_CRUD_API_ACTION_THRU_CODE[actionAPI]} geofence template. Refresh page?`)
        }

    })

}

const cu_geofence_template_success = (geofenceTemplate) => {
    return {
        type: ActionTypes.CU_GEOFENCE_TEMPLATE,
        geofenceTemplate
    };
}

const cu_unbind_geofences_from_geofence_template_success = (geoids, geotid) => {
    return {
        type: ActionTypes.CU_UNBIND_GEOFENCES_FROM_GEOFENCE_TEMPLATE,
        geoids,
        geotid
    }
}

export const cu_geofence_asset_relation_request = (geotids, geoid) => dispatch => {
    dispatch(cu_geofence_asset_relation_success(geotids, geoid))
}

const cu_geofence_asset_relation_success = (geotids, geoid) => {
    return {
        type: ActionTypes.CU_GEOFENCE_ASSET_RELATION,
        geotids,
        geoid
    }
}