export const SCREENLAYOUT = {
    FULL_SIDEBAR_WIDTH: 400, // window.innerWidth*0.2, //Usual 400px,
    EXPAND_SIDEBAR_WIDTH: 700 // window.innerWidth*0.3 //Usual 400px,
}

export const CHART_COLORS = {
    FLAT_GREEN: '#b6ffb5',
    FLAT_RED: '#ff6666',
    FLAT_BLUE: '#9ccfff',
    FLAT_TURQUOISE: '#6effd6',
}