import {
    COL_DETAIL,
    COL_TRANSIT,
    COL_TRIP,
    COL_DAILY_SUMMARY
} from './columns'

import {
    FORMATTER_TRIP,
    FORMATTER_TRANSIT,
    FORMATTER_DETAIL,
    FORMATTER_DAILY_SUMMARY
} from './formatter'

export const VEHICLE_REPORT_DEFAULT_FILTER = 'All'
export const VEHICLE_REPORT_DAY_QUERY_WINDOW = 4

export const VEHICLE_REPORT_TABLE_TYPE_KEYS = {
    TRIP: 'Trip',
    TRANSIT: 'Transit',
    DAILY_SUMMARY: 'Daily Summary',
    DETAIL: 'Detail',
    // Special Report
    IDLING_ANALYSIS: 'Idling Analysis'
}

export const VEHICLE_REPORT_QUERY_LIMIT = {
    [VEHICLE_REPORT_TABLE_TYPE_KEYS.TRANSIT]: true,
    [VEHICLE_REPORT_TABLE_TYPE_KEYS.TRIP]: true,
    [VEHICLE_REPORT_TABLE_TYPE_KEYS.DAILY_SUMMARY]: false,
    [VEHICLE_REPORT_TABLE_TYPE_KEYS.DETAIL]: true,
    // Special Report
    [VEHICLE_REPORT_TABLE_TYPE_KEYS.IDLING_ANALYSIS]: true
}

export const VEHICLE_REPORT_TABLE = ({vehicles, geofences, FILTER_OUT_ROLE, userRole}) => ({
    [VEHICLE_REPORT_TABLE_TYPE_KEYS.TRIP]: {
        titile: [VEHICLE_REPORT_TABLE_TYPE_KEYS.TRIP],
        columns: COL_TRIP({vehicles, geofences, exportState:false}),
        export: COL_TRIP({vehicles, geofences, exportState:true}),
        extra: <></>,
        dataSrcFormatter: ({vids, tripRecords, vehicleGeoStateLogs}) => FORMATTER_TRIP({vids, tripRecords, vehicleGeoStateLogs}),
    },
    [VEHICLE_REPORT_TABLE_TYPE_KEYS.TRANSIT]: {
        titile: [VEHICLE_REPORT_TABLE_TYPE_KEYS.TRANSIT],
        columns: COL_TRANSIT({vehicles, geofences, exportState:false}),
        export: COL_TRANSIT({vehicles, geofences, exportState:true}),
        extra: <></>,
        dataSrcFormatter: ({vids, transitRecords, vehicleGeoStateLogs}) => FORMATTER_TRANSIT({vids, transitRecords, vehicleGeoStateLogs})
    },
    [VEHICLE_REPORT_TABLE_TYPE_KEYS.DAILY_SUMMARY]: {
        title: [VEHICLE_REPORT_TABLE_TYPE_KEYS.DAILY_SUMMARY],
        columns: COL_DAILY_SUMMARY({vehicles, geofences, exportState:false}),
        export: COL_DAILY_SUMMARY({vehicles, geofences, exportState:true}),
        extra: <></>,
        dataSrcFormatter: ({vids, vehicleReportDailySummaries}) => FORMATTER_DAILY_SUMMARY({vids, vehicleReportDailySummaries})
    },
    [VEHICLE_REPORT_TABLE_TYPE_KEYS.DETAIL]: {
        titile: [VEHICLE_REPORT_TABLE_TYPE_KEYS.DETAIL],
        columns: COL_DETAIL({vehicles, geofences, FILTER_OUT_ROLE, userRole, exportState:false}),
        export: COL_DETAIL({vehicles, geofences, FILTER_OUT_ROLE, userRole, exportState:true}),
        extra: <></>,
        dataSrcFormatter: ({vids, vehicleLogs, vehicleDerivativeLogs, vehicleEventLogs, vehicleGeoStateLogs}) => FORMATTER_DETAIL({vids, vehicleLogs, vehicleDerivativeLogs, vehicleEventLogs, vehicleGeoStateLogs})
    }
})