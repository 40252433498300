const VEHICLE_PACKAGE_STATUS = {
    SUSPEND: 'SUSPEND',
    OVERDUE: 'OVERDUE',
    IN_SERVICE: 'IN SERVICE',
    NEAR_DUE: 'NEAR DUE'
}

const VEHICLE_PACKAGE_STATUS_CODE = {
    [-2]: VEHICLE_PACKAGE_STATUS.SUSPEND,
    [-1]: VEHICLE_PACKAGE_STATUS.OVERDUE,
    [1]: VEHICLE_PACKAGE_STATUS.IN_SERVICE,
    [2]: VEHICLE_PACKAGE_STATUS.NEAR_DUE
}

const VEHICLE_PACKAGE_STATUS_COLOR_CODE = {
    [VEHICLE_PACKAGE_STATUS.SUSPEND]: '#A020F0',
    [VEHICLE_PACKAGE_STATUS.OVERDUE]: '#FF0000',
    [VEHICLE_PACKAGE_STATUS.IN_SERVICE]: '#50C878',
    [VEHICLE_PACKAGE_STATUS.NEAR_DUE]: '#FFAE42',
}

const WS_PROCESS_PACKAGE = {
    VHC: 'vhc',
    GEO: 'geo'
}

export {
    VEHICLE_PACKAGE_STATUS_CODE,
    VEHICLE_PACKAGE_STATUS_COLOR_CODE,
    WS_PROCESS_PACKAGE
}