import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, Tag, Button } from 'antd'
import {
    EditOutlined,
} from '@ant-design/icons';

// Constants

// Components
import { EmptyIndicator } from '../../../../components/Indicator'

// Services
import { REDUX } from '../../../../services/util'

// Handler
import {
    table_handler,
} from '../../../../handlers'

const { getColumnSearchProps } = table_handler

const VehicleGroupPanel = () => {

    const vehicleGroups = useSelector(REDUX.reduxStoreWrapper('vehicleGroups'))

    const style = useSelector(REDUX.reduxStoreWrapper('style'))

    const [ dataSource ] = useState([])
    
    const searchTextState = useState('')
    const searchedColumnState = useState('')

    const VehicleManagementEditOnClick = () => {}

    const vehicleGroupPanelColumns = [
        {
          title: "Vehicle Group",
          dataIndex: "vhcgid",
          render: (vhcgid) => (
              <>
                <Tag color="blue" key={vhcgid}>
                    {vehicleGroups.byID[vhcgid].name}
                </Tag>
              </>
          )
        },
        {
          title: "Type",
          dataIndex: "type",
          ...getColumnSearchProps("Type", "type", searchTextState, searchedColumnState),
        },
        {
          title: "Model",
          dataIndex: "model",
          ...getColumnSearchProps("Model", "model", searchTextState, searchedColumnState),
        },
        {
            title: "Actions",
            fixed: 'right',
            render: vehicle => (
              <Button.Group>
                <button className = "transparent-button">
                  <EditOutlined
                    onClick = {VehicleManagementEditOnClick(vehicle)}
                  />
                </button>
              </Button.Group>
            )
          }
      ];

    return (
        <div style = {{ display: "flex", flex: 1 }}>
            {
                dataSource.length > 0 ?
                    <Table
                        loading={style.loading.general}
                        columns={vehicleGroupPanelColumns && vehicleGroupPanelColumns.filter(c => !c.isHidden)}
                        dataSource={dataSource}
                        pagination={true}
                        scroll={{
                            y: window.innerHeight,
                            x: vehicleGroupPanelColumns && vehicleGroupPanelColumns.length * 150
                        }}
                    /> 
                :
                    <EmptyIndicator />
            }
        </div>
    )
}

export default VehicleGroupPanel