import
  React,
  {
    useCallback,
    useEffect
  }
from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

// Components
import SSOAdvice from '../SSOAdvice';

// Container
import AppV3 from '../../../v3'

// Container Redux Action
import { 
  v3_user
} from '../../../v3/services/redux/actions'

const SSOPanel = () => {

  const loadingAuthState = useSelector(state => state.containers.main.style.loading.auth)

  const auth = useSelector(state => state.root.auth);
  const user = useSelector(state => state.containers.main.user);
  const userV3 = useSelector(state => state.containers.v3.user);

  const userStages = user && user.stages && user.stages[0]

  const stage = userStages || 'UNKNOWN'

  const uid = (auth.currentUser && auth.currentUser.uid) || (user && user.uid);

  const dispatch = useDispatch();

  const redux_get_user_v3 = useCallback((uid) => dispatch(v3_user.user_sign_in_request(uid)), [dispatch])

  useEffect(() => {
    switch (stage) {
      // Version 2+ --> V3
      case 'V3': {
            redux_get_user_v3(uid)
        break;
      }
      default: {
        break;
      }
    }

    return (() => {})
    
  }, [dispatch, auth, uid, userV3.uid, stage, redux_get_user_v3])

  const renderContainer = () => {

    if(!loadingAuthState && auth) {
      switch (stage) {
        case 'V3': {
          return <AppV3 />
        }
        default: {
          return <SSOAdvice />;
        }
      }
    } else {
      return <></>
    }
  }

  return (
    <>
      {renderContainer()}
      <Outlet/>
    </>
  )

}

export default React.memo(SSOPanel)