import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    DatePicker, Select, message
} from 'antd';

// Components
import Page from '../../components/Decoration/Page'
import VehiclePlateECUTag from '../../components/VehiclePlateECUTag'
import showSecondarySidebar from '../../layout/MainLayout/helpers/showSecondarySidebar';
import {
    SearchFields,
    TabulatedReport
} from './components'
import { PrimaryButton } from '../../components/Decoration/Button';

// Constant
import { TEMPLATE } from '../../constants';

// Handler
import { xlsx_handler } from '../../handlers'

// Service
import { TIME, REDUX } from '../../services/util';

// Redux Action
import * as ACTIONS from '../../services/redux/actions'

const {
    referenceEndTime,
    referenceStartTime,
    referenceEndOfDay,
    dayDiffABS,
    dayJSLocalize,
    parseTime
} = TIME

const {
    REPORT
} = TEMPLATE

const {
    exportReportFile
} = xlsx_handler

const VehicleOverallReport = () => {

    const dispatch = useDispatch()

    const loadingVehicleReportState = useSelector(state => state.containers.v3.style.loading.vehicleReport)
    const vehicles = useSelector(state => state.containers.v3.vehicles.staticsByID)
    const geofences = useSelector(state => state.containers.v3.geofences.byID)
    const curUserRole = useSelector(state => state.containers.v3.user && state.containers.v3.user.uroleid)

    const apiUsage = useSelector(REDUX.reduxStoreWrapper('utils.api.usage.vehicleReport'))

    const transitRecords = useSelector(REDUX.reduxStoreWrapper('transitRecords'))
    const tripRecords = useSelector(REDUX.reduxStoreWrapper('tripRecords'))
    const vehicleLogs = useSelector(REDUX.reduxStoreWrapper('vehicleLogs'))
    const vehicleDerivativeLogs = useSelector(REDUX.reduxStoreWrapper('vehicleDerivativeLogs'))
    const vehicleEventLogs = useSelector(REDUX.reduxStoreWrapper('vehicleEventLogs'))
    const vehicleGeoStateLogs = useSelector(REDUX.reduxStoreWrapper('vehicleGeoStateLogs'))
    const vehicleReportDailySummaries = useSelector(REDUX.reduxStoreWrapper('vehicleReportDailySummaries'))

    const [ VIDS, setVIDS ] = useState([])
    const [ startTime, setStartTime ] = useState(referenceStartTime())
    const [ endTime, setEndTime ] = useState(referenceEndTime())
    const [ selectedTable, setSelectedTable ] = useState(REPORT.VEHICLE_REPORT_TABLE_TYPE_KEYS.TRIP)
    const [ filteredDataSrc, setFilteredDataSrc ] = useState([])

    const formatTableJSON = REPORT.VEHICLE_REPORT_TABLE({vehicles, geofences, FILTER_OUT_ROLE: TEMPLATE.USER_ROLE.FILTER_OUT_ROLE, userRole:curUserRole})

    const dataSubmitHandler = (dayDiff, max_query_window, check) => {

        if(check) {

            if(dayDiff <= max_query_window) {	
                dispatch(ACTIONS.v3_cep.get_vehicle_report_multi_params_request(selectedTable, VIDS, startTime.utc().valueOf(), endTime.utc().valueOf()))
            } else {
                message.warning(`Please query the vehicle report with ${ max_query_window} days`)
            }

        } else {
            dispatch(ACTIONS.v3_cep.get_vehicle_report_multi_params_request(selectedTable, VIDS, startTime.utc().valueOf(), endTime.utc().valueOf()))
        }
    }

    const submitFormHandler = () => {

        const dayDiff = dayDiffABS(startTime, endTime)

        if(startTime.utc().valueOf() > endTime.utc().valueOf()) {
			message.warning(`Please query the vehicle report with end time larger than start time`)
			return 
		}

        if(REPORT.VEHICLE_REPORT_QUERY_LIMIT[selectedTable]) {
            dataSubmitHandler(dayDiff, REPORT.VEHICLE_REPORT_DAY_QUERY_WINDOW, true)
        } else {
            dataSubmitHandler(dayDiff, 0, false)
        }
    }

    const handleCalendarDisabledDate = (current) => current && current > referenceEndOfDay()

    const exportTitle = () => {
        const vehiclePlates = VIDS.map(vid => vehicles[vid].plate).join('_')

        return `${selectedTable}_${parseTime(startTime)}_${parseTime(endTime)}_[${vehiclePlates}]`
    }

    const exportDataHandler = () => {
        const col = 
            formatTableJSON[selectedTable].export
            &&
            formatTableJSON[selectedTable].export.filter(c => !c.isHidden)

        exportReportFile(filteredDataSrc, col, "Main", exportTitle())
    }

    useEffect(() => {
        const newFilteredDataSrc = formatTableJSON[selectedTable]
            .dataSrcFormatter(
                {
                    vids: VIDS, 
                    transitRecords, 
                    tripRecords, 
                    vehicleLogs, 
                    vehicleDerivativeLogs, 
                    vehicleEventLogs, 
                    vehicleGeoStateLogs, 
                    vehicleReportDailySummaries
                }
            )
        setFilteredDataSrc(newFilteredDataSrc)

    }, [apiUsage])

    return (
        <div className = 'page-container'>
            <Page title = 'Vehicle Report'>
                <div className = "formField" style = {{ marginBottom: 5 }}>
                    <>
                        <div className = "formField">
                            <SearchFields
                                selectedVehicle = {VIDS.map(vid => `${vehicles[vid].displayName}@${vid}`)}
                                onVIDChange = {(newIDs) => setVIDS(newIDs)}
                                render = {(plate, vehicle) => <VehiclePlateECUTag plate={plate} vehicle={vehicle} />}
                            />

                            <div className = "formField">
                                <span className = "formFieldLabel">Timeframe: </span>

                                <DatePicker.RangePicker
                                    showTime
                                    disabledDate={handleCalendarDisabledDate}
                                    value = {[dayJSLocalize(startTime), dayJSLocalize(endTime)]}
                                    onChange = {(dates) => {
                                        setStartTime(dates[0])
                                        setEndTime(dates[1])
                                    }}
                                    style = {{ width: '380px' }}
                                />
                            </div>

                            <div className = "formField">
                                <span className = "formFieldLabel">Report: </span>

                                <Select
                                    placeholder = {"Select a Report"}
                                    value = {selectedTable && formatTableJSON[selectedTable].title}
                                    onChange = {(newTable) => setSelectedTable(newTable)}
                                    style = {{ width: 200 }}
                                >
                                    {
                                        Object.keys(formatTableJSON).map((currKey) =>
                                            <Select.Option key = {currKey} value = {currKey}>
                                                {formatTableJSON[currKey].title}
                                            </Select.Option>
                                        )
                                    }
                                </Select>
                            </div>
                        </div>
                    </>

                    <div className = "formField">
                        <PrimaryButton
                            loading = {loadingVehicleReportState}
                            disabled = {!(selectedTable && VIDS && VIDS.length)}
                            onClick = {submitFormHandler}
                            style = {{ marginLeft: '15px' }}
                        >
                            Submit
                        </PrimaryButton>

                        <PrimaryButton
                            disabled = {!(filteredDataSrc.length)}
                            onClick = {exportDataHandler}
                            style = {{ marginLeft: '15px' }}
                        >
                            Export
                        </PrimaryButton>
                    </div>
                </div>

                <TabulatedReport
                    reportJSON = {formatTableJSON}
                    selectedTable = {selectedTable}
                    dataSrc = {filteredDataSrc}
                    loading = {loadingVehicleReportState}
                />
                
            </Page>
        </div>
    )
}

const MemorizedVehicleOverallReport = React.memo(VehicleOverallReport);

export default showSecondarySidebar(false)(MemorizedVehicleOverallReport);