import 
    React,
    {
        useState
    }
from "react";
import { useDispatch } from 'react-redux'
import { 
    Modal,
    Form
} from 'antd';

// Constant
import { TEMPLATE, REDUX as REDUX_CONSTANT } from '../../../../../../constants'

// Services
import { REDUX } from '../../../../../../services/util'

// Components
import Maintenance_CS_Selection from './components/Maintenance_CS_Selection'
import Maintenance_Component_CU from './components/Maintenance_Component_CU'
import Maintenance_Service_CU from './components/Maintenance_Service_CU'

// Routes

// Redux Action
import * as REDUX_ACTION from '../../../../../../services/redux/actions'

// Styling
import './index.css'

const { MAINTENANCE } = TEMPLATE
const {
    MODAL_CS_PROCESS_ACTION,
    MODAL_CS_PROCESS_TITLE,
    MODAL_CS_PROCESS_CREATION_ORDER,
    MODAL_CS_PROCESS_CREATION_ORDER_MAX,
    MODAL_CS_PROCESS_SELECTION
 } = MAINTENANCE

const Maintenance_CS_Modal = ({keyID = "", maintid, type, action, open, onClose}) => {

    const dispatch = useDispatch()

    const [form] = Form.useForm();

    const [modalOrder, setModalOrder] = useState(1)
    const [modalCSelection, setModalCSelection] = useState(1)
    const [modalTitle, setModalTitle] = useState('')

    const prevModalOrder = REDUX.usePrevious(modalOrder) 

    const ModelONClose = () => {
        setModalOrder(1)
        onClose()
    }

    const onSubmit = async() => {
        const values = await form.validateFields();

        let uploadValue = {}

        Object.keys(values).map(k => {

            const v = isNaN(values[k]) ? values[k] : Number(values[k])

            uploadValue[k] = v

            return 1
        })

        if(action === MODAL_CS_PROCESS_ACTION.CREATE && prevModalOrder === modalOrder && modalOrder < MODAL_CS_PROCESS_CREATION_ORDER_MAX) {
            setModalOrder(modalOrder+1)
        } else {

            if(action === MODAL_CS_PROCESS_ACTION.CREATE) {

                if(modalOrder === MODAL_CS_PROCESS_CREATION_ORDER[MODAL_CS_PROCESS_ACTION.CREATE]) {
                   
                    switch(modalCSelection) {
                        case MODAL_CS_PROCESS_SELECTION.COMPONENT: {
                            dispatch(REDUX_ACTION.v3_maintenanceConfigs.cu_maintenance_component_request({
                                actionAPI: REDUX_CONSTANT.REDUX_CRUD_API_ACTION.CREATE,
                                ...uploadValue,
                                maintid
                            }))
                            break;
                        }
                        case MODAL_CS_PROCESS_SELECTION.SERVICE: {
                            dispatch(REDUX_ACTION.v3_maintenanceConfigs.cu_maintenance_service_request({
                                actionAPI: REDUX_CONSTANT.REDUX_CRUD_API_ACTION.CREATE,
                                ...uploadValue,
                                maintid
                            }))
                            break;
                        }
                        default: {
                            break
                        }
                    }
    
                }
    
            } else if(action === MODAL_CS_PROCESS_ACTION.EDIT) {
    
                switch(type) {
                    case MODAL_CS_PROCESS_SELECTION.COMPONENT: {
                        dispatch(REDUX_ACTION.v3_maintenanceConfigs.cu_maintenance_component_request({
                            actionAPI: REDUX_CONSTANT.REDUX_CRUD_API_ACTION.UPDATE,
                            maincid: keyID, 
                            ...uploadValue,
                            maintid
                        }))
                        break;
                    }
                    case MODAL_CS_PROCESS_SELECTION.SERVICE: {
                        dispatch(REDUX_ACTION.v3_maintenanceConfigs.cu_maintenance_service_request({
                            actionAPI: REDUX_CONSTANT.REDUX_CRUD_API_ACTION.UPDATE,
                            mainsid: keyID, 
                            ...uploadValue,
                            maintid
                        }))
                        break;
                    }
                    default: {
                        break
                    }
                }
            }

            ModelONClose()
        }
    }

    const CS_CU_PROCCESOR = (selection) => {
        switch (selection) {
            case MODAL_CS_PROCESS_SELECTION.COMPONENT: {
                return <Maintenance_Component_CU maincid={keyID} form={form}/>
            }
            case MODAL_CS_PROCESS_SELECTION.SERVICE: {
                return <Maintenance_Service_CU mainsid={keyID} form={form}/>
            }
            default: {
                return <></>
            } 
        }
    }

    const CS_PROSSESS_CONTENT = ({modalOrder, action}) => {

        if(action === MODAL_CS_PROCESS_ACTION.CREATE) {

            switch(modalOrder) {
                case MODAL_CS_PROCESS_CREATION_ORDER[MODAL_CS_PROCESS_ACTION.SELECTION]: {
                    if(modalTitle !== MODAL_CS_PROCESS_TITLE[MODAL_CS_PROCESS_ACTION.SELECTION]) setModalTitle(MODAL_CS_PROCESS_TITLE[MODAL_CS_PROCESS_ACTION.SELECTION])
                    return <Maintenance_CS_Selection onSelection={selection => setModalCSelection(selection)} form={form}/>
                }
                case MODAL_CS_PROCESS_CREATION_ORDER[MODAL_CS_PROCESS_ACTION.CREATE]: {
                    if(modalTitle !== MODAL_CS_PROCESS_TITLE[MODAL_CS_PROCESS_ACTION.CREATE](modalCSelection)) setModalTitle(MODAL_CS_PROCESS_TITLE[MODAL_CS_PROCESS_ACTION.CREATE](modalCSelection))
                    return CS_CU_PROCCESOR(modalCSelection)
                }
                default: {
                    return <></>
                } 
            } 

        } else if (action === MODAL_CS_PROCESS_ACTION.EDIT) {

            if(modalTitle !== MODAL_CS_PROCESS_TITLE[MODAL_CS_PROCESS_ACTION.EDIT](type)) setModalTitle(MODAL_CS_PROCESS_TITLE[MODAL_CS_PROCESS_ACTION.EDIT](type))
            return CS_CU_PROCCESOR(type)
        }
    }

    return (
        <Modal
            title={modalTitle}
            centered
            open={open}
            okText={action}
            onOk={() => onSubmit()}
            onCancel={() => ModelONClose()}
            width={'90vw'}
        >
            {
                CS_PROSSESS_CONTENT({modalOrder, action})
            }
        </Modal>
    )
}

export default Maintenance_CS_Modal 