import React from "react";
import { 
    Tabs
} from 'antd';

// Constant

// Services

// Components
import {
    VAMLineItemPanel
} from './components'

// Routes

// Redux Action

// Styling
import './index.css'


const VAMConfigurationManagement = () => {

    return (
        <>
            <Tabs 
                defaultActiveKey="0" 
                centered
                items={[
                    {
                        key: '0',
                        label: 'Line Items',
                        children: (
                            <>
                                <VAMLineItemPanel />
                            </>
                        )
                    }
                ]}
            />
        </>
    )
}

export default VAMConfigurationManagement