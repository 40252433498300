import React from 'react'
import { useSelector } from 'react-redux'

// Constant
import showSecondarySidebar from '../../../../layout/MainLayout/helpers/showSecondarySidebar'

// Component
import UserInfoTab from './components/UserInfoPanel'

// Services
import { REDUX } from '../../../../services/util'

const {
    reduxStoreWrapper
} = REDUX

const UserInfoMainPanel = () => {

    const uid = useSelector(reduxStoreWrapper('userHierarchy.selectedID'))

    return (
        <>
            <UserInfoTab uid={uid}/>
        </>
    )
}

export default React.memo(showSecondarySidebar(true)(UserInfoMainPanel))