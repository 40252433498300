import React from 'react'
import { 
    Col,
    Row,
} from "antd";

// Constant
import { TEMPLATE } from '../../constants'

// Service
import { REDUX as REDUX_UTIL } from '../../services/util'

// Style
import "./index.css";

const { reduxStoreWrapper } = REDUX_UTIL

const GeoAddress = props => {

    const {
        vid,
        keyIndex
    } = props

    const DEFAULT_GEOFENCE_TEMPLATE = TEMPLATE.GEOFENCE.DEFAULT_GEOFENCE_TEMPLATE
    const DEFAULT_GEOFENCE_COLOR = TEMPLATE.GEOFENCE.DEFAULT_GEOFENCE_COLOR

    const vehicleGeoState = reduxStoreWrapper(`vehicles.geoStateByID.${vid}`)
    const geofences = reduxStoreWrapper('geofences.byID')
    const geofenceTemplates = reduxStoreWrapper(`geofenceTemplates.byID`)
    const geofenceTemplate = (geoid) => geoid && geofenceTemplates.find(gt => gt.name !== DEFAULT_GEOFENCE_TEMPLATE && gt.geofences.includes(geoid))

    return (
        vid
        && vehicleGeoState
        && vehicleGeoState.geoIN
        && vehicleGeoState.geoIN.length
        && (
            <div key={`${vid}#${keyIndex}`} className= "address">
                {
                    vehicleGeoState
                    && vehicleGeoState.geoIN
                    && vehicleGeoState.geoIN.length
                    && vehicleGeoState.geoIN.map((geoid, index) => (
                        geoid
                        &&
                        geofences[geoid]
                        &&
                        (
                            <Row 
                                key = {`${vid}#${keyIndex}@${geoid}@${index}-row`} 
                                style = {{ 
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: 1, 
                                }}
                            >
                                <Col 
                                    key = {`${vid}#${keyIndex}@${geoid}@${index}-col1`} 
                                    span = {7} 
                                    className = "GEOFENCE" 
                                    style = {{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: 20,
                                        backgroundColor: geoid && geofenceTemplate(geoid) && geofenceTemplate(geoid).colorHexCode || DEFAULT_GEOFENCE_COLOR
                                    }}
                                >
                                    {geofences[geoid] && geofences[geoid].type}
                                </Col>

                                <Col 
                                    key = {`${vid}#${keyIndex}@${geoid}@${index}-col2`} 
                                    span = {16} 
                                    offset = {1} 
                                    className = "GEOFENCE_Description"
                                >
                                    {geofences[geoid] && geofences[geoid].name}
                                </Col>
                            </Row>
                        )
                        ||
                        <div key={`${vid}#${keyIndex}@${geoid}@${index}-row`}></div>
                    ))
                }
            </div>
        )
        ||
        <></>
    )
}

export default GeoAddress;