import React from "react";
import { Routes, Route } from "react-router";

// Constant
import {
    ROUTES, COMMON_FEATURES
} from '../../../../constants'

// Components
import { asyncComponent } from '../../../../components'

// Modules
import MapPanel from '../../../../modules/MapPanel'
import UserHierarchyMap from '../../../../modules/UserHiearchyMap'
import VehicleInformationPanel from '../../../../modules/VehicleInformationPanel'
import OverallManagementPanel from "../../../../modules/OverallManagementPanel";
import MaintenanceManagementModule from '../../../../modules/MaintenanceManagementModule'
import UserInfoMainPanel from '../../../../modules/UserManagement/modules/UserInfoMainPanel'
import HighwaySurveillanceManagement from '../../../../modules/HighwaySurveillanceManagement'
import FuelAnalysisDashboard from '../../../../modules/FuelAnalysisDashboard'
import VehicleOverallReport from '../../../../modules/VehicleOverallReport'
// // Thiry Party
import GreenerWheelsModule from '../../../../modules/GreenerWheelsModule'

import {
    // VAM
    VAM
} from '../../../../modules'

const {
    MAP_ROUTE,
    MODULE_ROUTE,
    OVERALL_MANAGEMENT_ROUTE,
    MAINTENANCE_MANAGEMENT_MODULE_ROUTE,
    VAM_MODULE_ROUTE
} = ROUTES

const AsyncNotFoundPage = asyncComponent(() => import("../../../../pages/NotFoundPage"));

const MainRoutes = () => {

    return (
        <Routes>

            {
                Object.keys(MAP_ROUTE).map((key) =>
                    <Route 
                        key={key} 
                        exact
                        path={MAP_ROUTE[key]}
                        element={<MapPanel />}
                    />
                )
            }

            <Route exact
                path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.USER_HIERARCHY_MAP]}
                element={<UserHierarchyMap />}
            />

            <Route exact
                path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.VEHICLE_INFORMATION_PANEL]}
                element={<VehicleInformationPanel/>}
            />

            {/** Management */}

            <Route exact 
                path = {MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.USER_MANAGEMENT]} 
                element={<UserInfoMainPanel />}
            />

            <Route exact 
                path = {MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.HIGHWAY_SURVEILLANCE_MANAGEMENT]} 
                element={<HighwaySurveillanceManagement />}
            />

            {
                Object.keys(OVERALL_MANAGEMENT_ROUTE).map((key) =>
                    <Route 
                        key={key} exact
                        path={OVERALL_MANAGEMENT_ROUTE[key]}
                        element={<OverallManagementPanel />}
                    />
                )
            }

             {/** Maintenance Management Module*/}

             {
                Object.keys(MAINTENANCE_MANAGEMENT_MODULE_ROUTE).map((key) =>
                    <Route 
                        key={key} exact
                        path={MAINTENANCE_MANAGEMENT_MODULE_ROUTE[key]}
                        element={<MaintenanceManagementModule />}
                    />
                )
            }

             {/** VAM */}

             {
                Object.keys(VAM_MODULE_ROUTE).map((key) =>
                    <Route 
                        key={key} exact
                        path={VAM_MODULE_ROUTE[key]}
                        element={<VAM />}
                    />
                )
            }

            {/** Report */}

            <Route exact
                path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.FUEL_ANALYSIS_DASHBOARD]}
                element={<FuelAnalysisDashboard />}
            />

            <Route exact
                path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.VEHICLE_OVERALL_REPORT]}
                element={<VehicleOverallReport />}
            />

            {/** Greener Wheels */}

            <Route exact
                path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.GREENER_WHEELS]}
                element={<GreenerWheelsModule />}
            />

            <Route element={<AsyncNotFoundPage />} />

        </Routes>
    )
}
export default MainRoutes