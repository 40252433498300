import * as ActionTypes from '../action-types'
import { deepMerge } from '../../util'

const vehicleGroups_DefaultState = {
    concatentedVG: {
        vhcgid: 'ALL',
        name: `ALL`,
        vehicles: []
    }, 
    relationByUID: {},
    byID: {},
    allIDs: []
}

export const vehicleGroups = (state = vehicleGroups_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_VEHICLE_GROUP: {

            const vehicleGroup = action.vehicleGroup 
            const uid = action.uid

            const vhcgid = vehicleGroup && vehicleGroup.vhcgid

            if(vhcgid) {

                if(uid) {
                    if(Object.prototype.hasOwnProperty.call(newState.relationByUID, uid)) {
                        if(!newState.relationByUID[uid].includes(vhcgid)) newState.relationByUID[uid].push(vhcgid)
                    } else {
                        newState.relationByUID[uid] = [vhcgid]
                    }
                }

                if(Object.prototype.hasOwnProperty.call(newState.byID, vhcgid)) newState.byID[vhcgid] = deepMerge(newState.byID[vhcgid], vehicleGroup)
                else newState.byID[vhcgid] = vehicleGroup

                if(!newState.allIDs.includes(vhcgid)) newState.allIDs.push(vhcgid)
            }

            newState.concatentedVG.vehicles = newState.allIDs.reduce((acc, curvhcgid) => [...acc, ...newState.byID[curvhcgid].vehicles], []).filter((vid, pos, ary) => ary.indexOf(vid) === pos)

            return newState
        }
        case ActionTypes.GET_VEHICLE_GROUPS: {

            const vehicleGroups = action.vehicleGroups
            const uid = action.uid

            vehicleGroups.map(vehicleGroup => {

                const vhcgid = vehicleGroup && vehicleGroup.vhcgid

                if(vhcgid) {

                    if(uid) {
                        if(Object.prototype.hasOwnProperty.call(newState.relationByUID, uid)) {
                            if(!newState.relationByUID[uid].includes(vhcgid)) newState.relationByUID[uid].push(vhcgid)
                        } else {
                            newState.relationByUID[uid] = [vhcgid]
                        }
                    }

                    if(Object.prototype.hasOwnProperty.call(newState.byID, vhcgid)) newState.byID[vhcgid] = deepMerge(newState.byID[vhcgid], vehicleGroup)
                    else newState.byID[vhcgid] = vehicleGroup

                    if(!newState.allIDs.includes(vhcgid)) newState.allIDs.push(vhcgid)
                }

                return null
            })

            newState.concatentedVG.vehicles = newState.allIDs.reduce((acc, curvhcgid) => [...acc, ...newState.byID[curvhcgid].vehicles], []).filter((vid, pos, ary) => ary.indexOf(vid) === pos)

            return newState
        }
        default: {
            return newState;
        }
    }
}