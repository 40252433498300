import * as API from "../../api"
import * as ActionTypes from "../action-types";

import { message } from 'antd'

// Constants and Config

export const get_all_plus_cctv_section_request = () => dispatch => {

    API
    .getAllPLUSCCTVs()
    .then(info => {

        const {
            code,
            sections
        } = info

        if(code === 200) {
            dispatch(get_all_plus_cctv_section_success(sections))
        } else {
            message.error('Error. Refresh page?');
        }
    })
}

const get_all_plus_cctv_section_success = (sections) => {
    return {
        type: ActionTypes.GET_ALL_PLUS_CCTV_SECTIONS,
        sections
    };
}

export const set_selected_plus_cctv_section_request = (section) => dispatch => {

    dispatch(set_selected_plus_cctv_section_success(section))
}

const set_selected_plus_cctv_section_success = (section) => {
    return {
        type: ActionTypes.SET_SELECTED_PLUS_CCTV_SECTION,
        section
    };
}

export const unset_selected_plus_cctv_section_request = () => dispatch => {

    dispatch(unset_selected_plus_cctv_section_success())
}

const unset_selected_plus_cctv_section_success = () => {
    return {
        type: ActionTypes.UNSET_SELECTED_PLUS_CCTV_SECTION
    };
}