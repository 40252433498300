import * as ActionTypes from "../action-types";

// Constants & Config
import { ROOT_CONSTANT, STYLE } from '../../../constants'

// Services
import { STYLES_UTIL } from '../../util'

const STYLES_INITIAL_STATE = {
    sidebarWidth: STYLE.SCREENLAYOUT.FULL_SIDEBAR_WIDTH,
    sidebarColor: ROOT_CONSTANT.THEME.THEME_COLOR,
    pageHeaderColor: ROOT_CONSTANT.THEME.THEME_COLOR,
    tableHeaderColor: STYLES_UTIL.LightenDarkenColor(ROOT_CONSTANT.THEME.THEME_COLOR, 0.3),
    textColor: ROOT_CONSTANT.THEME.TEXT_COLOUR,
    buttonColor: ROOT_CONSTANT.THEME.THEME_COLOR,
    logoUrl: ROOT_CONSTANT.THEME.LOGO,
    isSidebarCollapsed : false,
    toggleUpdate: false,
    loading: {
      general: false,
      routePlayback: false,
      fuelAnalysisDashboard: false,
      geofence: false,
      vehicleReport: false,
      action: false
    }
};

export const style = (state = STYLES_INITIAL_STATE, action) => {

  let newState = JSON.parse(JSON.stringify(state)) 

  if(!action) return newState

  switch (action.type) {
    case ActionTypes .TOGGLE_UPDATE: {
      return {...state, toggleUpdate: !state.toggleUpdate}
    }

    case ActionTypes.EXPAND_SIDEBAR: {
      return { ...state, sidebarWidth: STYLE.SCREENLAYOUT.EXPAND_SIDEBAR_WIDTH };
    }

    case ActionTypes.ORIGINAL_SIDEBAR: {
      return { ...state, sidebarWidth: STYLE.SCREENLAYOUT.FULL_SIDEBAR_WIDTH };
    }

    case ActionTypes.TOGGLE_SIDEBAR: {
      return { ...state, isSidebarCollapsed: !state.isSidebarCollapsed };
    }

    case ActionTypes.CLOSE_SIDEBAR: {
      return { ...state, isSidebarCollapsed: true };
    }

    case ActionTypes.OPEN_SIDEBAR: {
      return { ...state, isSidebarCollapsed: false };
    }

    case ActionTypes.CHANGE_PAGE_HEADER_COLOR: {
      const newColor = STYLES_UTIL.LightenDarkenColor(action.color, 0.3);
      const newfontColor = STYLES_UTIL.getTextColor(action.color);
      const newbuttonColor = STYLES_UTIL.LightenDarkenColor(action.color, -0.2);

      return {
        ...state,
        sidebarColor: action.color,
        pageHeaderColor: action.color,
        tableHeaderColor: newColor,
        textColor: newfontColor,
        buttonColor: newbuttonColor
      };
    }

    case ActionTypes.SET_LOGO: {
      return { ...state, logoUrl: action.url };
    }

    case ActionTypes.SET_LOADING_ROUTE_PLAYBACK: {
      newState.loading.routePlayback = true
      return newState
    }

    case ActionTypes.UNSET_LOADING_ROUTE_PLAYBACK: {
      newState.loading.routePlayback = false
      return newState
    }

    case ActionTypes.SET_LOADING_FUEL_ANALYSIS_DASHBOARD: {
      newState.loading.fuelAnalysisDashboard = true
      return newState
    }

    case ActionTypes.UNSET_LOADING_FUEL_ANALYSIS_DASHBOARD: {
      newState.loading.fuelAnalysisDashboard = false
      return newState
    }

    case ActionTypes.SET_LOADING_GENERAL: {
      newState.loading.general = true
      return newState
    }

    case ActionTypes.UNSET_LOADING_GENERAL: {
      newState.loading.general = false
      return newState
    }

    case ActionTypes.SET_LOADING_VEHICLE_REPORT: {
      newState.loading.vehicleReport = true
      return newState
    }

    case ActionTypes.UNSET_LOADING_VEHICLE_REPORT: {
      newState.loading.vehicleReport = false
      return newState
    }

    case ActionTypes.SET_LOADING_ACTIONS: {
      newState.loading.action = true
      return newState
    }

    case ActionTypes.UNSET_LOADING_ACTIONS: {
      newState.loading.action = false
      return newState
    }

    default: {
      return state;
    }
  }
};