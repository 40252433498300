import * as ActionTypes from '../action-types'

/**
 * Constant
 */


const vehicleMaintenances_DefaultState = {
    stateByID: {},
    profileByID: {},
    allStateIDs: [],
    allVIDs: []
}

export const vehicleMaintenances = (state = vehicleMaintenances_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {

        case ActionTypes.GET_VEHICLE_MAINTENANCE_PROFILES: {

            const vehicleMaintenanceProfiles = action.vehicleMaintenanceProfiles

            vehicleMaintenanceProfiles
            &&
            Object.keys(vehicleMaintenanceProfiles).map(vid => {
                const vm = vehicleMaintenanceProfiles[vid]

                newState.profileByID[vid] = vm

                if(!newState.allVIDs.includes(vid)) newState.allVIDs.push(vid)
            })

            return newState
        }

        case ActionTypes.GET_VEHICLE_MAINTENANCE_STATES: {

            const vehicleMaintenanceStates = action.vehicleMaintenanceStates

            vehicleMaintenanceStates
            &&
            Object.keys(vehicleMaintenanceStates).map(vid => {

                vehicleMaintenanceStates[vid].map(vms => {
                    const mainsid = vms.mainsid
                    const state = vms.state

                    const uploadState = {
                        vid,
                        [`${mainsid}@state`]: state
                    }

                    if(!Object.prototype.hasOwnProperty.call(newState.stateByID, vid)) {
                        newState.stateByID[vid] = uploadState
                    } else {
                        newState.stateByID[vid] = {
                            ...newState.stateByID[vid],
                            ...uploadState
                        }
                    }

                    if(!newState.allStateIDs.includes(mainsid)) newState.allStateIDs.push(mainsid)
                })

                if(!newState.allVIDs.includes(vid)) newState.allVIDs.push(vid)
            })

            return newState
        }

        case ActionTypes.CU_VEHICLE_MAINTENANCE_PROFILE: {

            const vehicleMaintenanceProfile = action.vehicleMaintenanceProfile

            const {
                vid,
                maintid
            } = vehicleMaintenanceProfile

            if(Object.prototype.hasOwnProperty.call(newState.profileByID, vid)) {

                const isExist = newState.profileByID[vid].find(vmp => vmp.maintid === maintid)

                if(isExist) {

                    newState.profileByID[vid] =
                        newState.profileByID[vid]
                        .map(vmp => vmp.maintid === maintid && {...vmp, ...vehicleMaintenanceProfile} )

                } else {

                    newState.profileByID[vid].push(vehicleMaintenanceProfile)

                }

            } else {
                newState.profileByID[vid] = [ vehicleMaintenanceProfile ]
            }

            if(!newState.allVIDs.includes(vid)) newState.allVIDs.push(vid)

            return newState
        }

        
        default: {
            return newState;
        }
    }
}