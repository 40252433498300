import React from "react";
import { Layout as LayoutAntd } from "antd";
import Sidebar from "./Sidebar";

const Layout = ({
  sidebarItems = [],
  onClickSidebarItem = () => {},
  selectedkeys,
  themeColor,
  children
}) => {

  return (
    <LayoutAntd>
      <Sidebar
        items={sidebarItems}
        onClick={onClickSidebarItem}
        selectedkeys={selectedkeys}
        themeColor={themeColor}
      />
      <LayoutAntd>{children}</LayoutAntd>
    </LayoutAntd>
  );
};

export default Layout;
