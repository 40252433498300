import * as API from "../../api"
import * as ActionTypes from "../action-types";
import { REDUX } from '../../../constants'

/**
 * ThirdParty Library
 */
 import {
    message
} from 'antd'

/**
 * Redux Action
 */
import { 
    get_device_request,
    get_devices_request
} from './devices'

/**
 * Get Device Group
 */

export const get_device_group_request = (devgid, uid = null) => dispatch => {
    
    API
    .getDeviceGroupByDEVGID(devgid)
    .then(info => {
        
        try {
            const {
                deviceGroup
            } = info

            dispatch(get_device_group_success(deviceGroup, uid))
            deviceGroup && deviceGroup.devices && dispatch(get_device_request(deviceGroup.devices))


        } catch(err) {
            message.error('Error in getting device groups. Refresh page?')
        }

    })
}

const get_device_group_success = (deviceGroup, uid = null) => {
    return {
        type: ActionTypes.GET_DEVICE_GROUP,
        deviceGroup,
        uid
    };
}

/**
 * Get Device Groups
 */

 export const get_device_groups_request = (devgids, uid = null) => dispatch => {

    let devgidBatch = []

    devgids.map((devgid, index) => {

        devgidBatch.push(devgid)

        if( 
            (index+1) % REDUX.REDUX_DEVICE_GROUP_BATCH_GET_SIZE === 0
            ||
            index+1 === devgids.length
        ) {
            dispatch(batch_get_device_groups_request(devgidBatch, uid))
            devgidBatch = []
        }

        return null
    })
 }

/**
 * Batch Get Device Groups
 */
 export const batch_get_device_groups_request = (devgids, uid = null) => dispatch => {

    API
    .getDeviceGroupBatch(devgids)
    .then(info => {
        
        try {
            const {
                deviceGroups
            } = info

            dispatch(batch_get_device_groups_success(deviceGroups, uid))

            deviceGroups 
            && deviceGroups.length 
            && dispatch(
                get_devices_request(
                    deviceGroups.reduce((acc, cur) => [...acc, ...cur.devices], [])
                )
            )


        } catch(err) {
            console.log(`Error`, err)
            message.error('Error in batch getting device groups. Refresh page?')
        }

    })
}

const batch_get_device_groups_success = (deviceGroups, uid = null) => {
    return {
        type: ActionTypes.GET_DEVICE_GROUPS,
        deviceGroups,
        uid
    };
}

