import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, Tag } from 'antd'

// Constants
import { TEMPLATE } from '../../../../constants'

// Components
import { EmptyIndicator } from '../../../../components/Indicator'

// Services
import { REDUX , TIME} from '../../../../services/util'

// Handler
import {
    table_handler,
    // vam_handler
} from '../../../../handlers'

// Redux Action
// import * as REDUX_ACTION from '../../../../services/redux/actions'

const { getColumnSearchProps } = table_handler
const { VAM } = TEMPLATE

const {
    VAM_ENTRY_STATUS_BY_CODE,
    VAM_ENTRY_STATUS_COLOR_BY_CODE
} = VAM

const {
    parseTime
} = TIME

const VAMEntryManagement = () => {

    const style = useSelector(REDUX.reduxStoreWrapper('style'))

    const vamEntries = useSelector(REDUX.reduxStoreWrapper('vamEntries.entryByID'))
    const vamLines = useSelector(REDUX.reduxStoreWrapper('vamLines.itemByID'))
    const vehicles = useSelector(REDUX.reduxStoreWrapper('vehicles.staticsByID'))

    const vameids = useSelector(REDUX.reduxStoreWrapper('vamEntries.allVAMEIDs'))
    
    const searchTextState = useState('')
    const searchedColumnState = useState('')

    const vamEntryReData = () =>  
        vameids
        &&
        vameids.map(vameid => {
            const vamEntry = vamEntries[vameid]
            const {
                vid
            } = vamEntry
            const vehicle = vehicles[vid]

            return {
                key: vameid,
                ...vamEntry,
                ...vehicle
            }
        })
        ||
        []

    const vamEntryPanelCol = [
        {
            title: "Entry ID",
            dataIndex: "vameid",
            ...getColumnSearchProps("Entry ID", 'vameid', searchTextState, searchedColumnState),
        },
        {
          title: "Vehicle Plate",
          dataIndex: "plate",
          fixed: 'left',
          ...getColumnSearchProps("Vehicle Plate", 'plate', searchTextState, searchedColumnState),
        },
        {
            title: "Line Items",
            dataIndex: 'vamlids',
            render: (vamlids) => vamlids && vamlids.map(vamlid => <Tag key={vamlid}>{vamLines[vamlid]?.name}</Tag>)
        },
        {
            title: "Status",
            dataIndex: 'status',
            filters: Object.keys(VAM_ENTRY_STATUS_BY_CODE)
                .map(status => (
                    {
                        text: VAM_ENTRY_STATUS_BY_CODE[status],
                        value: status,
                    }
                )),
            onFilter: (value, record) => record.status === value,
            render: status => <Tag color={VAM_ENTRY_STATUS_COLOR_BY_CODE[status]}>{VAM_ENTRY_STATUS_BY_CODE[status]}</Tag>
        },
        {
            title: "Last Completion",
            dataIndex: 'completedAt',
            render: time => parseTime(Number(time)),
        },
        {
            title: "Issued Date",
            dataIndex: 'issuedAt',
            render: time => parseTime(Number(time)),
        },
        {
            title: "Updated Time",
            dataIndex: 'updatedAt',
            render: time => parseTime(Number(time)),
        }
      ];

    return (
        <>
            {
                vamEntryReData().length > 0 ?
                    <Table
                        loading={style.loading.general}
                        columns={vamEntryPanelCol && vamEntryPanelCol.filter(c => !c.isHidden)}
                        dataSource={vamEntryReData()}
                        pagination={true}
                        scroll={{
                            y: window.innerHeight,
                            x: vamEntryPanelCol && vamEntryPanelCol.length * 150
                        }}
                    /> 
                :
                    <EmptyIndicator />
            }
        </>
    )
}

export default VAMEntryManagement