import * as API from "../../api"
import * as ActionTypes from "../action-types";
import { batch } from 'react-redux'

import { message } from 'antd'

// Constants and Config
import { PACKAGE } from '../../../constants'

// Services
import { closeWS, subscribeWS } from '../../websocket';

// Redux Action
import { get_all_subscription_packages_request } from './subscriptionPackages'
import { user_role_get_request } from './userRoles'
import { user_hierarchy_get_request } from './userHierarchy'
import {
    setLoadingGeneral,
    unsetLoadingGeneral
} from './style';
import {
    get_vehicle_groups_request
} from './vehicleGroups'
import {
    get_device_groups_request
} from './deviceGroups'
import {
    get_geofence_templates_request
} from './geofenceTemplates'
import {
    get_rule_templates_request
} from './ruleTemplates'
import {
    get_maintenance_templates_request
} from './maintenanceConfigs'
import {
    get_all_plus_cctv_section_request
} from './surveillances'
import {
    get_vam_line_templates_request
} from './vamLines'
import {
    get_vam_programs_request
} from './vamPrograms'

// Neccessary Actions
import { auth_user_sign_out } from '../../../../main/services/redux/actions/user'

const {
    ACCOUNT_PACKAGE_OWNERSHIP
} = PACKAGE

export const user_sign_in_request = uid => dispatch => {
    dispatch(setLoadingGeneral())

    dispatch(get_all_subscription_packages_request())

    API
    .getInfoByUser(uid)
    .then(info => {

        if (info.errorType === 'ValidationException') {
            message.error('Error. Refresh page?');
        }
        else {
            const {
                accountPackages,
                asset,
                user
            } = info;

            batch(() => {

                if(user) {
                    dispatch(user_sign_in_success(user));
                    dispatch(user_role_get_request(user.uroleid))
                    dispatch(user_hierarchy_get_request(user, user.hierCode))

                    if(user.porgid === ACCOUNT_PACKAGE_OWNERSHIP.AXIATA) {
                        dispatch(get_all_plus_cctv_section_request())
                    }

                    subscribeWS(
                        [uid],
                        uid
                    )
                }
    
                if(accountPackages) {
                    dispatch(user_account_package_get_success(accountPackages))
                }
    
                if(asset) {
    
                    const {
                        devgids,
                        vhcgids,
                        geotids,
                        ruletids,
                        maintids,
                        vamltids,
                        vampids
                    } = asset
        
                    if(vhcgids) dispatch(get_vehicle_groups_request(vhcgids, uid))
                    if(devgids) dispatch(get_device_groups_request(devgids, uid))
                    if(geotids) dispatch(get_geofence_templates_request(geotids, uid))
                    if(ruletids) dispatch(get_rule_templates_request(ruletids, uid))
                    if(maintids) dispatch(get_maintenance_templates_request(maintids))

                    if(vamltids) dispatch(get_vam_line_templates_request(vamltids))
                    if(vampids) dispatch(get_vam_programs_request(vampids))
    
                }

            })
        }
    })
    .finally(() => {
        dispatch(unsetLoadingGeneral())
    })
}

const user_sign_in_success = (user) => {
    return {
        type: ActionTypes.USER_SIGN_IN,
        user
    };
}

const user_account_package_get_success = (accountPackages) => {
    return {
        type: ActionTypes.USER_ACCOUNT_PACKAGES_GET,
        accountPackages
    }
}

export const user_sign_out_request = () => dispatch => {
    closeWS();
    batch(() => {
        dispatch(auth_user_sign_out())
        dispatch(user_sign_out_success())
    })
}

const user_sign_out_success = () => {
    return {
        type: ActionTypes.USER_SIGN_OUT
    };
}