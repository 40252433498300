import 
    React,
    {
        useState
    }
from "react";
import { useSelector } from 'react-redux'
import { Table, Space, Tooltip, Button } from 'antd';
import {
    EditOutlined
} from '@ant-design/icons'

// Constant

// Components

// Services
import { TIME, REDUX } from '../../../../../../services/util'

// Handlers
import { table_handler } from '../../../../../../handlers' 

// Routes

// Redux Action

// Styling
import './index.css'

const { getColumnSearchProps } = table_handler

const { parseTime } = TIME

const MaintenanceComponentPanel = ({onEditMaintenanceComponentForModal}) => {

    const maintenanceComponents = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.componentByID'))
    const maincids = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.allComponentIDs'))

    const searchTextState = useState('')
    const searchedColumnState = useState('')

    const maintenanceComponentReData = () => 
        maincids
        &&
        maincids.map(maincid => (
            {
                key: maincid,
                ...maintenanceComponents[maincid]
            }
        ))
        ||
        []

    const onEditMaintenanceComponent = (maincid) => {
        onEditMaintenanceComponentForModal(maincid)
    }

    const maintenanceComponentColumns = [
        {
            title: 'Component Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            fixed: 'left',
            ...getColumnSearchProps('Component Name', 'name', searchTextState, searchedColumnState)
        },
        {
            title: "Updated At",
            dataIndex: "updatedAt",
            align: 'center',
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.updatedAt - b.updatedAt,
            render: updatedAt => parseTime(Number(updatedAt)),
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            align: 'center',
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.createdAt - b.createdAt,
            render: createdAt => parseTime(Number(createdAt)),
        },
        {
            title: "Actions",
            fixed: 'right',
            render: rowData => (
                <Space size="middle" style={{float: "left"}}>
                    <Tooltip title={'Edit Maintenance Component'}>
                        <Button 
                            shape='circle' 
                            icon={<EditOutlined />}
                            type="primary"
                            onClick={() => onEditMaintenanceComponent(rowData.maincid)}
                        />
                    </Tooltip>
                </Space>
            )
        }
    ]


    return (
        <>
            <Table 
                size={'small'}
                bordered
                columns={maintenanceComponentColumns} 
                dataSource={maintenanceComponentReData()} 
                pagination={{
                    position: ['topRight']
                }}
            />
        </>
    )
}

export default React.memo(MaintenanceComponentPanel)