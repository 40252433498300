import * as ActionTypes from "../action-types";

const util_defaultState = {
    api: {
        usage: {
            vehicleReport: 0
        }
    }
}

export const utils = (
    state = util_defaultState,
    action
) => {
    let newState = JSON.parse(JSON.stringify(state));

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.API_USAGE_FOR_VEHICLE_REPORT_INCREMENT: {

            newState.api.usage.vehicleReport += 1
            
            return newState;
        }
        default: {
            return state;
        }
    }
};
