import * as ActionTypes from '../action-types'

/**
 * Constant
 */


const vamReminders_DefaultState = {
    reminderByID: {},
    vamridByVID: {},
    vamlidByVID: {},
    allVAMRIDs: [],
    selectedVAMRID: ''
}

export const vamReminders = (state = vamReminders_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {

        case ActionTypes.GET_VAM_REMINDERS_THRU_VID: {

            const { vamReminders } = action

            vamReminders
            &&
            vamReminders.map(vamReminder => {
                const { vamrid, vid, vamlid } = vamReminder

                newState.reminderByID[vamrid] = vamReminder

                if(!Object.prototype.hasOwnProperty.call(newState.vamridByVID, vid)) {
                    newState.vamridByVID[vid] = [vamrid] 
                } else {
                    if(!newState.vamridByVID[vid].includes(vamrid)) newState.vamridByVID[vid].push(vamrid)
                }

                if(!Object.prototype.hasOwnProperty.call(newState.vamlidByVID, vid)) {
                    newState.vamlidByVID[vid] = [vamlid] 
                } else {
                    if(!newState.vamlidByVID[vid].includes(vamlid)) newState.vamlidByVID[vid].push(vamlid)
                }

                if(!newState.allVAMRIDs.includes(vamrid)) newState.allVAMRIDs.push(vamrid)
            })

            return newState
        }

        case ActionTypes.CU_VAM_REMINDER: {

            const { vamReminder } = action

            const {
                vamrid, vid, vamlid
            } = vamReminder

            newState.reminderByID[vamrid] = { ...newState.reminderByID[vamrid], ...vamReminder}

            if(!Object.prototype.hasOwnProperty.call(newState.vamridByVID, vid)) {
                newState.vamridByVID[vid] = [vamrid] 
            } else {
                if(!newState.vamridByVID[vid].includes(vamrid)) newState.vamridByVID[vid].push(vamrid)
            }

            if(!Object.prototype.hasOwnProperty.call(newState.vamlidByVID, vid)) {
                newState.vamlidByVID[vid] = [vamlid] 
            } else {
                if(!newState.vamlidByVID[vid].includes(vamlid)) newState.vamlidByVID[vid].push(vamlid)
            }

            if(!newState.allVAMRIDs.includes(vamrid)) newState.allVAMRIDs.push(vamrid)
 
            return newState   
        }

        case ActionTypes.SET_VAM_REMINDER_VAMRID: {

            const { vamrid } = action

            newState.selectedVAMRID = vamrid

            return newState
        }

        case ActionTypes.UNSET_VAM_REMINDER_VAMRID: {
            newState.selectedVAMRID = ''

            return newState
        }
        
        default: {
            return newState;
        }
    }
}