import 
    React, 
    { 
        useState, 
 } from 'react';
import {
    useSelector,
    useDispatch
} from 'react-redux';
import {
    GoogleMap,
    TrafficLayer,
} from '@react-google-maps/api';

// Components
import MapSearchBar from '../../components/MapSearchBar';
import MapControlPanel from '../../components/MapControlPanel';
import GoogleMapsLoadScriptEnabler from "../components/GoogleMapsLoadScript";
import POIGeofences from "../../components/Geofences_POI";
import VehicleMarkers from "../../components/VehicleMarkers";
import ZoneGeofences from "../../components/Geofences_Zone";
import SUVPOIs from '../../components/SUV_POI'

// Constants

// Handler
import { MapInitialiserWithVehicleAndGeofence } from '../handlers/MapInitialiserWithVehicleAndGeofence'
import { UnfocusMap } from '../handlers/UnfocusMap'

// Service

// Styles
import "../gmaps.css";


const GoogleMapsSUVPanel = (props) => {

    const { 
        SUVs,
        OnClick,
    } = props;

    const dispatch = useDispatch()

    // State
    const [mapRef, setMapRef] = useState(null);

    // Redux Store
    const geofences = useSelector(state => state.containers.v3.geofences)
    const vehicles = useSelector(state => state.containers.v3.vehicles)
    const mapControl = useSelector(state => state.containers.v3.mapControl)

    MapInitialiserWithVehicleAndGeofence(vehicles, geofences, mapControl, mapRef)

    return (
        <GoogleMap
            id='googles-map'
            mapContainerStyle={{
                height: '100vh'
            }}
            onLoad={ref => setMapRef(ref)}
            onDragStart={() => {
                UnfocusMap(dispatch)
            }}
        >
            <SUVPOIs SUVs={SUVs} OnClick={OnClick} mapRef={mapRef} />

            <POIGeofences mapRef={mapRef} />

            <VehicleMarkers mapRef={mapRef} />

            <ZoneGeofences mapRef={mapRef} />

            {mapControl.UIControl.showLiveTraffic && <TrafficLayer />}

            <div className="searchBar">
                <MapSearchBar />
            </div>

            <div className="controlPanel">
                <MapControlPanel 
                    geofenceMarkerToggle = {true}
                    geofenceInfoToggle = {true}
                    liveTrafficTroggle = {true}
                    vehicleInfoToggle = {true}
                    SUVResourceToggleVisible = {true}
                    SUVInfoToggle = {true}
                    SUVMakerToggle = {true}
                    SUVAutoFocusToggle = {true}
                />
            </div>

        </GoogleMap>
    )
}

export default GoogleMapsLoadScriptEnabler(GoogleMapsSUVPanel);