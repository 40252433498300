import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
    Card,
    Modal,
    Form,
    Select,
    Row,
    Col,
    InputNumber,
    Checkbox,
    DatePicker,
    Space
} from 'antd';

// Constant
import { REDUX as REDUX_CONSTANT, TEMPLATE } from '../../../../../../constants'

// Components
import { NextDueDeterminator } from '../NextDueDeterminator'

// Handler
import { report_handler, vam_handler } from '../../../../../../handlers'

// Services
import { REDUX, TIME, RoundUp } from '../../../../../../services/util'

// Redux Action
import * as REDUX_ACTION from '../../../../../../services/redux/actions'

const FormItem = Form.Item

const {
    REDUX_CRUD_API_ACTION
} = REDUX_CONSTANT

const {
    VAM_ENTRY_STATUS_CODE_BY_STATUS
} = TEMPLATE.VAM

const {
    mileageSelector,
    MILEAGE_TYPE
} = report_handler

const {
    nextDuePreviewConvertor
} = vam_handler

const {
    parseTimestamp,
    parseHours,
    getCurTime,
    getCurTimeDayJS,
    getCurtStartOfDate
} = TIME

const defaultUsePlatform = {
    odometer: false,
    workingHour: false
}

const CurrentOdometerandWorkingHourCard = ({vehicle}) => {

    const odometer = mileageSelector(vehicle, MILEAGE_TYPE.ODOMETER)
    const workingHrs = vehicle?.plat_hist_engine_hour

    return (
        <>
            <Card title={`${vehicle?.plate} Current Odometer and Working Hour`}>
                <Row gutter={24}>
                        <Col span={8}>{`Odometer: ${!isNaN(odometer) ? `${RoundUp(odometer, 0)} km` : "-"}`}</Col>
                        <Col span={8}>{`Working Hour: ${!isNaN(Number(workingHrs)) ? `${RoundUp(parseHours(workingHrs), 0)} hrs` : "-"}`}</Col>
                    </Row>
            </Card>
        </>
    )
}

export const VAMEntrySubmitModal = ({ action, open, onClose }) => {

    const [form] = Form.useForm();

    const dispatch = useDispatch()

    const vamReminders = useSelector(REDUX.reduxStoreWrapper('vamReminders.reminderByID'))
    const vamLines = useSelector(REDUX.reduxStoreWrapper('vamLines.itemByID'))

    const ownedVAMRIDS = useSelector(REDUX.reduxStoreWrapper('vamReminders.vamridByVID'))

    const vehicles = useSelector(REDUX.reduxStoreWrapper('vehicles.staticsByID'))
    const vehicleDSes = useSelector(REDUX.reduxStoreWrapper('vehicles.derivativeStateByID'))
    const vids = useSelector(REDUX.reduxStoreWrapper('vehicles.allIDs'))

    const vamrids = useSelector(REDUX.reduxStoreWrapper('vamEntries.selectedVAMRIDs'))
    const vid = useSelector(REDUX.reduxStoreWrapper('vamEntries.selectedVID'))

    const loadingAction = useSelector(REDUX.reduxStoreWrapper('style.loading.action'))

    const [ selectedVID, setSelectedVID ] = useState('')
    const [ selectedVAMRIDs, setSelectedVAMRIDs ] = useState([])

    const [ formData, setFormData ] = useState({})

    const [ markedCompleted, setMarkedCompleted ] = useState(true)
    const [ markedIssue, setMarkedIssue ] = useState(false)
    const [ manualDue, setManualDue ] = useState(false)
    const [ usePlatform, setUsePlatform ] = useState(defaultUsePlatform)

    const isSubmittedAction = true

    const ModelONClose = () => {
        form.resetFields()
        setUsePlatform(defaultUsePlatform)
        setManualDue(false)
        setMarkedCompleted(false)
        setMarkedIssue(false)
        onClose()
    }

    const onMarkCompleted = (e) => {
        setMarkedCompleted(e.target.checked)
    }

    const onMarkIssuedDate = (e) => {
        setMarkedIssue(e.target.checked)
    }

    const onManualDueChange = (e) => {
        setManualDue(e.target.checked)
    }

    const onUsePlatformOdometer = (e) => {
        setUsePlatform({
            ...usePlatform,
            odometer: e.target.checked
        })
        if(e.target.checked && selectedVID) {
            setFormData({
                ...formData,
                calibrated_odometer: RoundUp(mileageSelector(vehicleDSes[selectedVID], MILEAGE_TYPE.ODOMETER) || 0, 0)
            })
        }
    }

    const onUsePlatformWorkingHour = (e) => {
        setUsePlatform({
            ...usePlatform,
            workingHour: e.target.checked
        })
        if(e.target.checked && selectedVID) {
            setFormData({
                ...formData,
                calibrated_workingHour: RoundUp(vehicleDSes[selectedVID]?.plat_hist_engine_hour || 0, 0)
            })
        }
    }

    const onSubmit = async() => {

        const values = await form.validateFields();
        const v = JSON.parse(JSON.stringify(values))

        const nextVamReminders = {}

        Object.keys(v).map(k => {

            switch(k) {
                case 'completedAt' :
                case 'issuedAt': {
                    v[k] = parseTimestamp(v[k])
                    break;
                }
                case 'calibrated_odometer': 
                case 'calibrated_workingHour': {
                    const lk = k.split('_')[1]
                    if(usePlatform[lk]) delete v[k]
                    else {
                        if(!usePlatform.odometer && lk == 'odometer' ) v.pre_odometer = mileageSelector(vehicleDSes[selectedVID], MILEAGE_TYPE.ODOMETER)
                        if(!usePlatform.workingHour && lk == 'workingHour' ) v.pre_workingHour = vehicleDSes[selectedVID]?.plat_hist_engine_hour
                    }   

                    break;
                }
                default: {
                    break;
                }
            }
        })

        const NextDueArr = Object.keys(v).filter(k => k.includes('due@'))

        if(NextDueArr &&  NextDueArr.length) {

            NextDueArr.map(k => {
                const ak = k.split('@')
                const vamrid = ak[1]
                const nextDue = ak[2]

                if(!Object.prototype.hasOwnProperty.call(nextVamReminders, vamrid)) {
                    nextVamReminders[vamrid] = {}
                }

                switch(nextDue) {
                    case 'time_next': {
                        nextVamReminders[vamrid][nextDue] = parseTimestamp(v[k])
                        break;
                    }
                    case 'workingHour_next': 
                    case 'odometer_next': {
                        nextVamReminders[vamrid][nextDue] = v[k]
                        break;
                    }
                    default: {
                        break;
                    }
                }

                delete v[k]
            })

        } else {
            selectedVAMRIDs.map(vamrid => {
                const vamReminder = vamReminders[vamrid]

                Object.keys(vamReminder)
                .filter(k => k.includes('_interval'))
                .map(k => {
                    const fk = k.split('_')[0]
                    const ndp = nextDuePreviewConvertor[fk]
                    const vhcD = selectedVID && vehicleDSes[selectedVID] && ndp[3](vehicleDSes[selectedVID])
                    const val = vhcD + vamReminder[k]

                    if(!Object.prototype.hasOwnProperty.call(nextVamReminders, vamrid)) {
                        nextVamReminders[vamrid] = {}
                    }

                    nextVamReminders[vamrid][`${fk}_next`] = val
                })
            })
        }

        if(markedCompleted) {
            v.status = VAM_ENTRY_STATUS_CODE_BY_STATUS.COMPLETED
        }

        v.nextVamReminders = nextVamReminders


        if(Object.prototype.hasOwnProperty.call(v, 'vamrids')) {
            v.vamlids = v.vamrids.map(vamrid => vamReminders[vamrid].vamlid)
        }
        
        dispatch(REDUX_ACTION.v3_vamEntries.cu_vam_entry_request(v, REDUX_CRUD_API_ACTION[action], () => {
            ModelONClose()
        }))

    }

    const onFormValChange = (e) => {

        setFormData({
            ...formData,
            ...e
        })
    }

    useEffect(() => {

        setFormData({
            vid,
            vamrids: vamrids,
            completedAt: getCurTimeDayJS()
        })

        setSelectedVID(vid)
        setSelectedVAMRIDs(vamrids)

    }, [vamrids, vid])

    return (
        <Modal
            title={`${action} Service Entry`}
            centered
            open={open}
            okText={action}
            onOk={() => onSubmit()}
            onCancel={() => ModelONClose()}
            destroyOnClose={true}
            width={'90vw'}
            confirmLoading={loadingAction}
        >
            <Form
                form={form}
                layout="vertical"
                wrapperCol={{ span: 22 }}
                onValuesChange={(e) => onFormValChange(e)}
                fields={[
                    {
                        name: ['vid'],
                        value: formData?.vid
                    },
                    {
                        name: ['vamrids'],
                        value: formData?.vamrids
                    },
                    {
                        name: ['calibrated_odometer'],
                        value: formData?.calibrated_odometer
                    },
                    {
                        name: ['calibrated_workingHour'],
                        value: formData?.calibrated_workingHour
                    },
                    {
                        name: ['completedAt'],
                        value: formData?.completedAt
                    },
                    {
                        name: ['issuedAt'],
                        value: formData?.issuedAt
                    }
                ]}
            >
                <FormItem label="Vehicle Plate" name="vid" rules={[{ required: true, message: 'Please choose available vehicle!' }]}>
                    <Select 
                        showSearch
                        // style={{ width: '40vw' }}
                        onChange={(value) => setSelectedVID(value)}
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        disabled={isSubmittedAction}
                        options={
                            vids
                            .map(v => 
                                ( {
                                    label: vehicles[v].plate,
                                    value: v
                                })
                            )
                            ||
                            []
                        }
                    />
                </FormItem>
                <FormItem label="Reminders" name="vamrids" rules={[{ required: true, message: 'Please choose available reminder!' }]}>
                    <Select 
                        showSearch
                        mode="multiple"
                        // style={{ width: '40vw' }}
                        onChange={(value) => setSelectedVAMRIDs(value)}
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        disabled={isSubmittedAction}
                        options={
                            selectedVID
                            &&
                            ownedVAMRIDS[selectedVID]
                            .map(v => {
                                const vamReminder = vamReminders[v]

                                const {
                                    vid,
                                } =  vamReminder
                    
                                const vDS = vehicleDSes[vid]
                                const odometer = vDS && mileageSelector(vDS, MILEAGE_TYPE.ODOMETER) || 0
                                const workingHour = vDS && parseHours(vDS.plat_hist_engine_hour) || 0

                                return({
                                    label: vamLines[vamReminder?.vamlid]?.name,
                                    value: v,
                                    data: {
                                        ...vamReminder,
                                        odometer: !isNaN(odometer) ? RoundUp(odometer, 0) : "-",
                                        workingHour: !isNaN(workingHour) ? RoundUp(workingHour, 0) : "-",
                                        curTime: getCurTime()
                                    }
                                })
                            })
                            ||
                            []
                        }
                        optionRender={(option) => {
                            return (
                                <Space>
                                    <Row>{option.data.label}</Row>
                                    <Row><NextDueDeterminator data={option.data.data}/></Row>
                                </Space>
                            )
                        }}
                    />
                </FormItem>

                <CurrentOdometerandWorkingHourCard vehicle={{...(vehicles[vid] || {}), ...(vehicleDSes[vid] || {}) }} />
                
                {
                    vamrids
                    &&
                    vamrids
                    .map(vamrid =>  Object.keys(vamReminders[vamrid]))
                    .find(k => k.includes('odometer_next'))
                    &&
                    <Row style={{display : "flex", alignItems: "center"}}>
                        <Col>
                            <FormItem label="Odometer" name="calibrated_odometer" rules={[{ required: true, message: 'Please input calibrated odometer!' }]}>
                                <InputNumber addonAfter="km" disabled={usePlatform.odometer}/>
                            </FormItem>
                        </Col>
                        <Col>
                            <Checkbox onChange={onUsePlatformOdometer} defaultChecked={false}>Use Current Calculated Odometer</Checkbox>
                        </Col>
                    </Row>
                }

                {
                    vamrids
                    &&
                    vamrids
                    .map(vamrid =>  Object.keys(vamReminders[vamrid]))
                    .find(k => k.includes('workingHour_next'))
                    &&
                    <Row style={{display : "flex", alignItems: "center"}}>
                        <Col>
                            <FormItem label="Working Hour" name="calibrated_workingHour" rules={[{ required: true, message: 'Please input calibrated working hour!' }]}>
                                <InputNumber addonAfter="hrs" disabled={usePlatform.workingHour}/>
                            </FormItem>
                        </Col>
                        <Col>
                            <Checkbox onChange={onUsePlatformWorkingHour} defaultChecked={false}>Use Current Calculated Working Hour</Checkbox>
                        </Col>
                    </Row>
                }

                <FormItem label="Completion Date" name="completedAt" rules={[{ required: true, message: 'Please input completion date!' }]}>
                    <DatePicker showTime/>
                </FormItem>

                <Row>
                    <Checkbox onChange={onMarkIssuedDate}>Set Issued Date</Checkbox>
                </Row>

                {
                    markedIssue
                    &&
                    <FormItem label="Issued Date" name="issuedAt" rules={[{ required: true, message: 'Please input issued date!' }]}>
                        <DatePicker showTime/>
                    </FormItem>
                }

                <Row>
                    <Checkbox onChange={onMarkCompleted} defaultChecked={true} disabled>Marked At Completed</Checkbox>
                </Row>

                <Row>
                    <Checkbox onChange={onManualDueChange} defaultChecked={false}>Manually set the due date, odometer and/or working hour for the next reminder</Checkbox>    
                </Row>

                {
                    manualDue ?
                    (
                        selectedVAMRIDs.map(vamrid => {
                            const vamReminder = vamReminders[vamrid]
                            const { vamlid } = vamReminder
                            const vamLine = vamLines[vamlid]
                            const { name } = vamLine
                            return (
                                <Card key={vamrid} title={`${name} Next Due`}>
                                     <Row style={{width: '100%'}}>
                                        {
                                            Object.keys(vamReminder)
                                            .filter(k => k.includes('_interval'))
                                            .map(k => {
                                                switch(k.split('_')[0]) {
                                                    case 'time': {
                                                        return (
                                                            <Col key={`${vamrid}@${k}`} style={{width: '20vw'}}>
                                                                <FormItem label="Next Due Date" name={`due@${vamrid}@time_next`} rules={[{ required: true, message: 'Please input next due date!' }]} >
                                                                    <DatePicker disabledDate={(current) => parseTimestamp(current) < getCurtStartOfDate()}/>
                                                                </FormItem>
                                                            </Col>
                                                        )
                                                    }
                                                    case 'odometer': {
                                                        return (
                                                            <Col key={`${vamrid}@${k}`} style={{width: '20vw'}}>
                                                                <FormItem label="Next Odometer Due" name={`due@${vamrid}@odometer_next`} rules={[{ required: true, message: 'Please input next due odometer!' }]} >
                                                                    <InputNumber style={{width: '100%'}} addonBefore="At" addonAfter="km"/>
                                                                </FormItem>
                                                            </Col>
                                                        )
                                                    }
                                                    case 'workingHour': {
                                                        return (
                                                            <Col key={`${vamrid}@${k}`} style={{width: '20vw'}}>
                                                                <FormItem label="Next Working Hour Due" name={`due@${vamrid}@workingHour_next`} rules={[{ required: true, message: 'Please input next due working hour!' }]} >
                                                                    <InputNumber style={{width: '100%'}} addonBefore="At" addonAfter="hrs"/>
                                                                </FormItem>
                                                            </Col>
                                                        )
                                                    }
                                                    default: {
                                                        return <></>
                                                    }
                                                }
                                            })
                                        }
                                    </Row>
                                </Card>
                            )
                        })
                    )
                    :
                    <></>
                }

            </Form>

        </Modal>
    )
}