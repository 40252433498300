import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {
    GoogleMap,
    OverlayView,
    DrawingManager,
    Polygon
} from '@react-google-maps/api';

// Components
import GoogleMapsLoadScriptEnabler from "../components/GoogleMapsLoadScript";

// Handler
import { MapInitialiserWithVehicleAndGeofence } from '../handlers/MapInitialiserWithVehicleAndGeofence'
import { UnfocusMap } from '../handlers/UnfocusMap'
import { geofenceTemplates_handler } from '../../../../handlers'

// Method
import { getLatLngCenter } from '../../methods'

// Redux Action 
import * as ACTIONS from '../../../../services/redux/actions'

// Styles
import "../gmaps.css";

const {
    returnLatestGeofenceTemplateColorForGeoID
} = geofenceTemplates_handler

const GoogleMapsZone = () => {

    const dispatch = useDispatch();
    
    // Redux Store
    const vehicles = useSelector(state => state.containers.v3.vehicles)
    const geofences = useSelector(state => state.containers.v3.geofences)
    const geofenceTemplates = useSelector(state => state.containers.v3.geofenceTemplates)
    const mapControl = useSelector(state => state.containers.v3.mapControl)

    // State
    const [mapRef, setMapRef] = useState(null);
    const [drawingManagerRef, setDrawingManagerRef] = useState(null);
    const [thisGeofence, setThisGeofence] = useState(mapControl.geofence);

    MapInitialiserWithVehicleAndGeofence(vehicles, geofences, mapControl, mapRef)

    const putPolygonComplete = polygon => {
        // remove previously drawn polygon
        polygon.setMap(null)
    
        const newMarker = {
            ...mapControl.geofence,
            coordinates: polygon.getPath().getArray().map(coordinate => {
                return {
                    lat: coordinate.lat(),
                    lng: coordinate.lng(),
                }
            })
        }
       
        dispatch(ACTIONS.v3_mapControl.set_new_geofence_from_map(newMarker));

        // revert drawingMode to null, ie drag
        if (drawingManagerRef) {
            drawingManagerRef.setDrawingMode(null);
        }
    }

      /**
     * Deep Cloning useEffect()
     */
       const useDeepEffect = (fn, deps) => {
        const isFirst = useRef(true);
        const prevDeps = useRef(deps);
      
        useEffect(() => {
          const isSame = prevDeps.current.every((obj, index) =>
            JSON.parse(JSON.stringify(obj)) === JSON.parse(JSON.stringify(deps[index]))
          );
      
          if (isFirst.current || !isSame) {
            fn();
          }
      
          isFirst.current = false;
          prevDeps.current = deps;
        }, [fn, deps]);
    }

    useDeepEffect(() => {
        setThisGeofence(mapControl.geofence)
    }, [mapControl.geofence])

    useEffect(() => {
        if (mapRef && mapControl.geofence && mapControl.geofence.coordinates && mapControl.geofence.coordinates.length) {
            const bounds = new window.google.maps.LatLngBounds();

            mapControl.geofence.coordinates.map(c => {
                bounds.extend(c)
                return null;
            })

            mapRef.fitBounds(bounds)
        }
    }, [mapControl, mapRef])

    return (
        <GoogleMap
            id='googles-map'
            mapContainerStyle={{
                height: '100vh'
            }}
            onLoad={ref => setMapRef(ref)}
            onDragStart={() => {UnfocusMap(dispatch)}}
            onDragEnd = {() => {
                if (mapRef) {
                    const lat = mapRef.getCenter().lat();
                    const lng = mapRef.getCenter().lng();
                    const zoom = mapRef.getZoom();

                    mapRef.setCenter({ lat, lng });
                    mapRef.setZoom(zoom);
                }
            }}
        >
            {/* Polygon */}
            {
                thisGeofence && thisGeofence.coordinates &&
                <div>
                    <Polygon
                        path = {thisGeofence.coordinates}
                        options = {{
                            strokeColor: returnLatestGeofenceTemplateColorForGeoID(geofenceTemplates, thisGeofence.geoid),
                        }}
                    />
                    
                    <OverlayView
                        options = {{ disableAutoPan: true }}
                        position = {getLatLngCenter(thisGeofence.coordinates)}
                        mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset = {(width, height) => ({
                            x: -(width / 2),
                            y: -height - 3,
                        })}
                    >
                        <div 
                            style = {{
                                background: 'white',
                                border: '1px solid #ccc',
                                borderRadius: '10px',
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 8,
                                paddingBottom: 1,
                            }}
                        >
                            <h4>{thisGeofence.name}</h4>
                            <div>{thisGeofence.comment}</div>
                        </div>
                    </OverlayView>
                </div>
            }
            
            {
                <DrawingManager
                    onLoad = {ref => setDrawingManagerRef(ref)}
                    drawingMode = {window.google.maps.drawing.OverlayType.POLYGON}
                    options = {{
                        drawingControlOptions: {
                            position: window.google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: [
                                window.google.maps.drawing.OverlayType.POLYGON,
                            ],
                        },
                        polygonOptions: {
                            strokeColor: "#ff0000",
                        }
                    }}
                    onPolygonComplete = {polygon => putPolygonComplete(polygon)}
                />
            }
        </GoogleMap>
    )
}

export default GoogleMapsLoadScriptEnabler(GoogleMapsZone)