import * as ActionTypes from '../action-types'

const vehicleReportDailySummaries_DefaultState = {
    reportByVID: {},
    allVIDs: []
}

export const vehicleReportDailySummaries = (state = vehicleReportDailySummaries_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_VEHICLE_DAILY_SUMMARY_REPORTS: {

            const reportVehicleDailySummary = action.reportVehicleDailySummary
            const vid = action.vid

            if(vid) {

                newState.reportByVID[vid] = reportVehicleDailySummary

                if(!newState.allVIDs.includes(vid)) newState.allVIDs.push(vid)

            }

            return newState
        }
        default: {
            return newState;
        }
    }
}