import React from "react";
import { Divider } from "antd";

const PageTitle = props => {
    return (
        <div>
            <h1>{props.title}</h1>
            <Divider />
        </div>
    );
};

export default PageTitle;
