import 
    React
from 'react'
import {
    Row,
    Col
} from 'antd'

// Components
import FASummaryTable from '../FASummaryTable'
import FAEventCard from '../FAEventCard'

// Constant
import { TEMPLATE } from '../../../../constants'

const { EVENT } = TEMPLATE

const InfographicDashboard = (props) => {

    const {
        report,
        summary,
        fuelUnitPrice
    } = props
    
    return (
        <>
            {
                summary
                &&
                <Row 
                    style={{
                        flex: 1
                    }}
                >
                    <Col span={8}>
                        <FAEventCard event={EVENT.FUEL_EVENTS.REFUEL} report={report} fuelUnitPrice={fuelUnitPrice} />
                        <FAEventCard event={EVENT.FUEL_EVENTS.THEFT} report={report} fuelUnitPrice={fuelUnitPrice} />
                    </Col>
                    <Col span={16}>
                        <FASummaryTable summary={summary} fuelUnitPrice={fuelUnitPrice}/>
                    </Col>
                </Row>
            }
        </>
    )
}

const MemorisedInfographicDashboard = React.memo(InfographicDashboard)

export default MemorisedInfographicDashboard