import { useRef, useEffect } from 'react'

export const useDeepEffect = (fn, deps) => {
    const isFirst = useRef(true);
    const prevDeps = useRef(deps);
  
    useEffect(() => {
      const isSame = prevDeps.current.every((obj, index) =>
        JSON.parse(JSON.stringify(obj)) === JSON.parse(JSON.stringify(deps[index]))
      );
  
      if (isFirst.current || !isSame) {
        fn();
      }
  
      isFirst.current = false;
      prevDeps.current = deps;
    }, [fn, deps]);
}

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}