import React, { useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

// Config
import { APP_CONFIG } from '../../../../../config'

const GoogleMapsLoadScriptEnabler = (MapComponent) => {

    const GoogleMapsLoadScript = props => {
        const [
            libraries,
            // setLibraries
        ] = useState(['drawing'])
    
        const { isLoaded, loadError } = useJsApiLoader({
            googleMapsApiKey: APP_CONFIG.APP_GOOGLE_MAP_API_KEY,
            libraries
        })
    
        if (loadError) {
            return <>Map cannot be loaded right now, sorry.</>
        }
    
        return (isLoaded && <MapComponent {...props} />) || <></>
    }

    return React.memo(GoogleMapsLoadScript)
}

export default GoogleMapsLoadScriptEnabler