import * as API_CONSTANT from '../constants'

export const getTransitRecordByTimeFrameSync = (vid, startTime, endTime) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/transit/record/timeframe/get/sync`, {
      method: 'POST',
      headers: API_CONSTANT.headers,
      body: JSON.stringify({
        vid,
        startTime,
        endTime
      })
    })
      .then(res => res.json())
  )