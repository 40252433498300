import * as ActionTypes from '../action-types'
import { TEMPLATE } from '../../../constants'

const {
    ALL_VEHICLE_TRANSIT_STATUS,
    ALL_EVENT_TYPE
} = TEMPLATE.STATUS

const statusBar_DefaultState = {
    selectedVehicleTransitStatus: ALL_VEHICLE_TRANSIT_STATUS[0],
    selectedEventType: ALL_EVENT_TYPE[0],
    allVehicleTransitStatuses:  ALL_VEHICLE_TRANSIT_STATUS,
    allEventTypes: ALL_EVENT_TYPE
}


export const statusBar = (state = statusBar_DefaultState,  action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {

        case ActionTypes.SELECT_VEHICLE_TRANSIT_STATUS: {

            const vehicleTransitStatus = action.vehicleTransitStatus

            newState.selectedVehicleTransitStatus = vehicleTransitStatus

            return newState
        }

        case ActionTypes.SELECT_EVENT_TYPE: {

            const eventType = action.eventType

            newState.selectedEventType = eventType

            return newState
        }

        default:
            return newState
    }
}
