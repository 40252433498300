import 
    React, 
    { 
        useEffect
    } 
from 'react';
import { 
    useDispatch, 
    useSelector 
} from 'react-redux'
import {
    Polygon,
    OverlayView,
} from '@react-google-maps/api';

// Constans
import { TEMPLATE } from '../../../../constants'

// Methods
import { getLatLngCenter } from '../../methods'

// Handelers
import { 
    geofenceTemplates_handler
 } from '../../../../handlers'

// Redux Actions
import {
    v3_geofences,
    v3_mapControl
} from '../../../../services/redux/actions'

// Styles
import "../../GoogleMaps/gmaps.css";

const {
    GEOFENCE_TYPE_OBJ
} = TEMPLATE.GEOFENCE

const {
    returnLatestGeofenceTemplateColorForGeoID
} = geofenceTemplates_handler

const {
    set_selected_geofence_request,
    clear_selected_geofence_request
} = v3_geofences

const {
    clear_enabled_geofence_pop_ups,
    set_map_control
} = v3_mapControl

const ZoneGeofences = (props) => {
    const { mapRef } = props;

    const vehicles = useSelector(state => state.containers.v3.vehicles);
    const geofences = useSelector(state => state.containers.v3.geofences);
    const mapControl = useSelector(state => state.containers.v3.mapControl);
    const geofenceTemplates = useSelector(state => state.containers.v3.geofenceTemplates);

    const dispatch = useDispatch()

    useEffect(() => {
        if (
            geofences.selectedID
            && geofences.byID[geofences.selectedID]
            && geofences.byID[geofences.selectedID].type === GEOFENCE_TYPE_OBJ.ZONE
            && geofences.byID[geofences.selectedID].coordinates
            && mapControl.mapControl === geofences.selectedID
        ) {
            if (mapRef) {
                // const location = getLatLngCenter(geofences.byID[geofences.selectedID].coordinates)
                // mapRef.panTo(location);

                const bounds = new window.google.maps.LatLngBounds()
                geofences.byID[geofences.selectedID].coordinates.map(c => bounds.extend(c))
                mapRef.fitBounds(bounds)

                dispatch(set_map_control(0))
            }
        }
    })

    // Initial mount of component
    useEffect(() => {
        dispatch(clear_enabled_geofence_pop_ups());
        dispatch(clear_selected_geofence_request());
    },
        [dispatch]
    )

    const getPolygon = (geofence) => {
        const polygon = (
            <div key = {geofence.geoid}>
                <Polygon
                    path = {geofence.coordinates}
                    options = {{
                        // strokeColor: 'black'
                        strokeColor: returnLatestGeofenceTemplateColorForGeoID(geofenceTemplates, geofence.geoid)
                    }}
                    onClick = {() => {
                        dispatch(set_selected_geofence_request(geofence.geoid));
                        dispatch(set_map_control(geofence.geoid));
                    }}
                />

                {
                    mapControl.UIControl.showAllGeofencesInfoWindow &&
                    <OverlayView
                        options = {{ disableAutoPan: true }}
                        position = {getLatLngCenter(geofence.coordinates)}
                        mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset = {(width, height) => ({
                            x: -(width / 2),
                            y: -height - 3,
                        })}
                    >
                        <div
                            style = {{
                                background: 'white',

                                border: '1px solid #ccc',
                                borderRadius: '10px',

                                width: 8 * (geofence.name.length || 1),
                                paddingLeft: 10,
                                paddingRight: 5,
                                paddingTop: 5,
                                paddingBottom: 0,
                            }}
                        >
                            <h4>{geofence.name}</h4>
                            <div>{geofence.comment}</div>
                        </div>
                    </OverlayView>
                }
            </div>
        )

        return polygon;
    }

    return (
        vehicles &&
        geofences.allIDs
            .filter(geoid => {
                return mapControl.UIControl.showAllGeofence && geofences.byID[geoid].type === GEOFENCE_TYPE_OBJ.ZONE;
            })
            .map(geoid => {
                return getPolygon(geofences.byID[geoid]);
            })
    )
}


export default React.memo(ZoneGeofences)