import 
    React,
    {
        useEffect
    }
from 'react'
import {
    useDispatch,
    batch 
} from 'react-redux'

// Services
import { FIREBASE } from '../../cloud'
import * as CONFIG from '../../../config'
import { 
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    getAuth
} from 'firebase/auth'

// Redux Action
import { 
    set_auth_user_request,
    unset_auth_user_request,
    set_auth_loading_request
} from '../../redux/actions'
import {
    main_user
} from '../../../containers/main/services/redux/actions'

const {
    sso_user_sign_in_request
} = main_user

export const sign_in = async(email, password) =>
    signInWithEmailAndPassword(FIREBASE.auth, email, password)
    .then(result => {     

        return {
            status: 200,
            data: {
                currentUser: result.user.uid,
                authProvider: CONFIG.APP_CONFIG.AUTHENTICATION_PROVIDER
            }
        }
      })
    .catch(err => {
        return {
            status: 500,
            msg: err.message
        }
    });

export const sign_out = async() => 
    signOut(FIREBASE.auth)

export const AuthBackgroundTask = () => {

    const dispatch = useDispatch()

    useEffect(() => {

        dispatch(set_auth_loading_request())

        const listener = onAuthStateChanged(FIREBASE.auth, user => {

            const cuser = user || getAuth().currentUser

            if (cuser) {
                // User is signed in.

                batch(() => {
                    dispatch(set_auth_user_request({currentUser: cuser.uid}))
                    dispatch(sso_user_sign_in_request(cuser.uid, 'firebase'))
                })

            } else {
                dispatch(unset_auth_user_request())
            }
        })
        
        return listener
    })

    return <></>
}