import React from 'react'
import { useSelector } from 'react-redux'
import {
    EditOutlined,
} from '@ant-design/icons';
import {
    Table,
    Button,
} from "antd";

// Constant
import { TEMPLATE } from '../../../../../../constants'

// Services
import { REDUX, TIME } from '../../../../../../services/util'

const {
    EVENT_TICKET_TYPES_BY_CODES
} = TEMPLATE.STATUS

const RuleEngineTable = () => {

    const ruleTemplates = useSelector(REDUX.reduxStoreWrapper('ruleTemplates'))
    const rules = useSelector(REDUX.reduxStoreWrapper('rules'))

    const RTDataSrc = Object.values(ruleTemplates.byID).map((currRuleTemplate) => {
        return {
            key: currRuleTemplate.ruletid,
            createdAt: currRuleTemplate.createdAt,
            numRules: (currRuleTemplate.rules && currRuleTemplate.rules.length) || 0,
            name: `${currRuleTemplate.name} (${(currRuleTemplate.rules && currRuleTemplate.rules.length)|| 0})`,
        }
    })

    const editRuleOnClick = () => {

    }

    const editRuleTemplateOnClick = () => {

    }

    const expandedRuleRowsRender = (record) => {
        const columnsRule = [
            {
                title: "Rule Name",
                dataIndex: "name",
            },
            {
                title: "Type",
                dataIndex: "type_rule",
                render: code => EVENT_TICKET_TYPES_BY_CODES[code]
            },
            {
                title: "Parameters",
                dataIndex: "parameters",
            },
            {
                title: "Creation Date",
                dataIndex: "createdAt",
                render: datetime => TIME.parseTime(datetime),
            },
            {
                title: "Actions",
                dataIndex: "ruleid",
                render: ruleid => (
                    <Button.Group>
                        <button className = "transparent-button">
                            <EditOutlined 
                                onClick = {editRuleOnClick(ruleid)}
                            />
                        </button>
                    </Button.Group>
                )
            }
        ]

        const ruleArr = 
        ruleTemplates.byID[record.key]
        &&
        ruleTemplates.byID[record.key].rules.map(ruleid => ({
            key: ruleid,
            ruleid: ruleid,
            name: rules.byID[ruleid].name,
            type_rule: rules.byID[ruleid].type_rule,
            createdAt: rules.byID[ruleid].createdAt,
            parameters: `${rules.byID[ruleid].conditions.map(c => c.parameter)}`,
        }))
        ||
        []

        return <Table bordered columns = {columnsRule} dataSource = {ruleArr} pagination = {false} />
    }

    const columnsRuleTemplate = [
        {
            title: "Template Name",
            dataIndex: "name"
        },
        {
            title: "Rules",
            dataIndex: "numRules"
        },
        {
            title: "Creation Date",
            dataIndex: "createdAt",
            render: datetime => TIME.parseTime(datetime),
        },
        {
            title: "Actions",
            dataIndex: "key",
            render: (ruletid) => (
                ruletid
                &&
                !ruleTemplates.byID[ruletid].default
                &&
                <Button.Group>
                    <button className = "transparent-button">
                        <EditOutlined 
                            onClick = {editRuleTemplateOnClick}
                        />
                    </button>
                </Button.Group>
                ||
                null
            )
        }
    ]

    return (
        <Table
            dataSource = {RTDataSrc}
            columns = {columnsRuleTemplate}
            expandable = {{
                rowExpandable: (record) => !record.default,
                expandedRowRender: (record) => expandedRuleRowsRender(record),
            }}
        />
    )
}

export default RuleEngineTable