import * as ActionTypes from '../action-types'

const subscriptionPackages_defaultState = {
    vehiclePKGByID: {},
    accountPKGByID: {},
    allVehiclePKGIDs: [],
    allAccountPKGIDs: [],
}

export const subscriptionPackages = (state = subscriptionPackages_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_ALL_SUBSCRIPTION_PACKAGES: {
            const packages = action.packages;
            
            const { accountPackages, vehiclePackages } = packages

            accountPackages
            &&
            accountPackages.map(apkg => {
                const apkgid = apkg.apkgid

                newState.accountPKGByID[apkgid] = apkg

                if(!newState.allAccountPKGIDs.includes(apkgid)) newState.allAccountPKGIDs.push(apkgid)
            })

            vehiclePackages
            &&
            vehiclePackages.map(vpkg => {
                const vpkgid = vpkg.vpkgid

                newState.vehiclePKGByID[vpkgid] = vpkg

                if(!newState.allVehiclePKGIDs.includes(vpkgid)) newState.allVehiclePKGIDs.push(vpkgid)
            })

            return newState
        }
        default: {
            return state;
        }
    }
}