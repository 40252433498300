import 
    React 
from "react";
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router";
import { Radio } from "antd";

// Constant && Config
import { 
    ROUTES, 
    COMMON_FEATURES 
} from '../../constants'

// Components
import MaintenanceConfigurationManagement from './components/MaintenanceConfigurationManagement'
import MaintenanceProfileManagement from './components/MaintenanceProfileManagement'
import OverallMaintenanceStatePanel from './components/OverallMaintenanceStatePanel'
import showSecondarySidebar from '../../layout/MainLayout/helpers/showSecondarySidebar'
import Page from "../../components/Decoration/Page"

// Services

const MaintenancePanelTab = {
    state: {
        label: 'Overall Maintenance State',
        key: 'state',
        value: COMMON_FEATURES.FEATURE_NAME.OVERALL_MAINTENANCE_STATE,
        component: <OverallMaintenanceStatePanel />,
    },
    profile: {
        label: 'Maintenance Profile',
        key: 'profile',
        value: COMMON_FEATURES.FEATURE_NAME.MAINTENANCE_PROFILE_MANAGEMENT,
        component: <MaintenanceProfileManagement />,
    },
    configuration: {
        label: 'Maintenance Configuration',
        key: 'configuration',
        value: COMMON_FEATURES.FEATURE_NAME.MAINTENANCE_CONFIGURATION_MANAGEMENT,
        component: <MaintenanceConfigurationManagement />,
    }
}

const MaintenanceManagementModule = () => {

    const navigate = useNavigate()
    const routerLocation = useLocation()

    const curUserRole = useSelector(state => state.containers.v3.user && state.containers.v3.user.uroleid)

    const onChangeTableTab = (e) => {
        navigate(ROUTES.MODULE_ROUTE[e.target.value])
    }

    let tab = routerLocation.pathname.split('/').pop()

    if (!MaintenancePanelTab[tab] || tab === 'overall') {
        tab = MaintenancePanelTab.state.key
    }

    return (
        <div className="page-container">
            <Page title="Overall Maintenance Management">
                <Radio.Group value={MaintenancePanelTab[tab].value} buttonStyle="solid" onChange={onChangeTableTab}>
                    {
                        Object.values(MaintenancePanelTab)
                            .filter(panel => {
                                if (!curUserRole) return false
                                if (!panel.hiddenFrom) return true
                                return !panel.hiddenFrom.includes(curUserRole)
                            })
                            .map(panel =>
                                <Radio.Button key={panel.key} value={panel.value}>
                                    {panel.label}
                                </Radio.Button>
                            )
                    }
                </Radio.Group>

                <div style={{ marginTop: 30 }}>
                    {
                        MaintenancePanelTab[tab] && MaintenancePanelTab[tab].component
                    }
                </div>
            </Page>
        </div>
    )
}

const MemorizedMaintenanceManagementModule = React.memo(MaintenanceManagementModule);

export default showSecondarySidebar(false)(MemorizedMaintenanceManagementModule);