import * as ActionTypes from "../action-types/style";

export const expandSidebar = () => {
    return {
        type: ActionTypes.EXPAND_SIDEBAR,
    };
};

export const originalSidebar = () => {
    return {
        type: ActionTypes.ORIGINAL_SIDEBAR,
    };
};

export const toggleSidebar = () => {
    return {
        type: ActionTypes.TOGGLE_SIDEBAR,
    };
};

export const closeSidebar = () => {
    return {
        type: ActionTypes.CLOSE_SIDEBAR,
    };
};

export const openSidebar = () => {
    return {
        type: ActionTypes.OPEN_SIDEBAR,
    };
};

export const changePageHeaderColor = color => {
    return {
        type: ActionTypes.CHANGE_PAGE_HEADER_COLOR,
        color,
    };
};

export const setLogo = url => {
    return {
        type: ActionTypes.SET_LOGO,
        url,
    };
};

export const setLoadingRoutePlayback = () => {
    return {
        type: ActionTypes.SET_LOADING_ROUTE_PLAYBACK
    }
}

export const unsetLoadingRoutePlayback = () => {
    return{
        type: ActionTypes.UNSET_LOADING_ROUTE_PLAYBACK
    }
}

export const setLoadingFuelAnalysisDashboard = () => {
    return {
        type: ActionTypes.SET_LOADING_FUEL_ANALYSIS_DASHBOARD
    }
}

export const unsetLoadingFuelAnalysisDashboard = () => {
    return{
        type: ActionTypes.UNSET_LOADING_FUEL_ANALYSIS_DASHBOARD
    }
}

export const setLoadingGeneral = () => {
    return {
        type: ActionTypes.SET_LOADING_GENERAL
    }
}

export const unsetLoadingGeneral = () => {
    return{
        type: ActionTypes.UNSET_LOADING_GENERAL
    }
}

export const setLoadingVehicleReport = () => {
    return {
        type: ActionTypes.SET_LOADING_VEHICLE_REPORT
    }
}

export const unsetLoadingVehicleReport = () => {
    return{
        type: ActionTypes.UNSET_LOADING_VEHICLE_REPORT
    }
}

export const setLoadingAction = () => {
    return {
        type: ActionTypes.SET_LOADING_ACTIONS
    }
}

export const unsetLoadingAction = () => {
    return{
        type: ActionTypes.UNSET_LOADING_ACTIONS
    }
}



export const toggleUpdate = () => {
    return{
        type: ActionTypes.TOGGLE_UPDATE
        
    }
}