import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'antd'

// Components
import showSecondarySidebar from '../../layout/MainLayout/helpers/showSecondarySidebar' 
import Page from "../../components/Decoration/Page"
import SurveillanceVideoList from './components/SurveillanceVideoList'
import SurveillanceVideoModal from './components/SurveillanceVideoModal'

// Handler

// Service
import { REDUX } from '../../services/util'

// Redux Action
import { v3_surveillances } from '../../services/redux/actions'

const {
    reduxStoreWrapper
} = REDUX

const HighwaySurveillanceManagement = () => {

    const dispatch = useDispatch()

    const selectedSUVSection = useSelector(reduxStoreWrapper('surveillances.selectedSection'))

    const [ isSUVModalVisible, setSUVModalVisible ] = useState(false)
    const [ filterSUV, setFilterSUV ] = useState('')
    
    const Main_SectionSUVClickHander = (section) => {
        dispatch(v3_surveillances.set_selected_plus_cctv_section_request(section))
        setSUVModalVisible(true)
    }

    const Main_SUVModalCancelHandler = () => {
        dispatch(v3_surveillances.unset_selected_plus_cctv_section_request())
        setSUVModalVisible(false)
    }

    const Main_SUVModalRefreshHandler = (section) => {
        dispatch(v3_surveillances.unset_selected_plus_cctv_section_request())

        setTimeout(()=>{
            dispatch(v3_surveillances.set_selected_plus_cctv_section_request(section))
        }, 1000)

    }

    return (
        <>
            <div className="page-container">
                <Page title="Highway Surveillance Management">
                    {/* Search Header */}
                    <div style = {{ flex: 4, marginBottom: 20 }}>
                        <Input
                            placeholder = "Search SUV"
                            value = {filterSUV}
                            onChange = {(e) => {
                                setFilterSUV(e.target.value)
                            }}
                        />
                    </div>
                    <SurveillanceVideoList
                        sectionSUVClickHander = {Main_SectionSUVClickHander}
                        filterSUV={filterSUV}
                    />
                </Page>
            </div>

            <SurveillanceVideoModal 
                isSUVModalVisible = {isSUVModalVisible}
                SUVModalCancelHandler = {Main_SUVModalCancelHandler}
                section = {selectedSUVSection}
                SUVModalRefreshHandler = {Main_SUVModalRefreshHandler}
            />
        </>
    )
}

const MemorizedHighwaySurveillanceManagement = React.memo(HighwaySurveillanceManagement);

export default showSecondarySidebar(false)(MemorizedHighwaySurveillanceManagement);