import 
    React,
    {
        useState,
        useEffect
    }
from "react";
import { 
    Form, 
    Select,
} from 'antd';

// Constant
import { TEMPLATE } from '../../../../../../../../constants'

// Services

// Components

// Routes

// Redux Action

// Styling
import './index.css'

const { Option } = Select;
const FormItem = Form.Item;

const { MAINTENANCE } = TEMPLATE
const {
    MODAL_CS_PROCESS_SELECTION
 } = MAINTENANCE

const Maintenance_CS_Selection = ({onSelection, form}) => {

    const [ moduleSelection, setModuleSelection ] = useState(MODAL_CS_PROCESS_SELECTION.COMPONENT)

    useEffect(() => {
        onSelection(moduleSelection)
    }, [moduleSelection])

    return (
        <>
            <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                fields={[
                    {
                        name: ["moduleSelection"],
                        value: moduleSelection
                    },
                ]}
            >
                <FormItem label="Module Selection" name="moduleSelection" rules={[{ required: true, message: 'Please select one module!' }]}>
                    <Select 
                        showSearch
                        style={{ width: '40vw' }} 
                        onChange={(value) => setModuleSelection(value)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {
                            Object.keys(MODAL_CS_PROCESS_SELECTION).map(key => (
                                <Option key={`module#${key}`} value={MODAL_CS_PROCESS_SELECTION[key]}>{`${MODAL_CS_PROCESS_SELECTION[key]}`}</Option>
                            ))
                        }
                    </Select>
                </FormItem>
            </Form>
        </>
    )
}

export default Maintenance_CS_Selection