import React from 'react';
import { useSelector } from 'react-redux';
import {
    Tooltip,
} from "antd";
// Icon
import LocalGasStationRoundedIcon from '@mui/icons-material/LocalGasStationRounded';
// Micro

const MultiFuelLevel = ({fuelLevel, n}) => {

    const LowFuelAlertTitle = (
        <>
            <p>{`Critical for Tank #${n} - ${fuelLevel}%`}</p>
        </>
    )

    const WarningFuelAlertTitle = (
        <>
            <p>{`Attention for Tank #${n} - ${fuelLevel}%`}</p>
        </>
    )

    const NormalFuelAlertTitle = (
        <>
            <p>{`Tank #${n} - ${fuelLevel}%`}</p>
        </>
    )

    if(fuelLevel < 15) {
        return (
            <Tooltip 
                placement="top" 
                title={LowFuelAlertTitle}
            >
                    <LocalGasStationRoundedIcon sx={{ fontSize: 20, color: "red", marginInline: "2% 2%" }}/>
            </Tooltip>
        )
    } else if (fuelLevel < 40) {
        return (
            <Tooltip 
                placement="top" 
                title={WarningFuelAlertTitle}
            >
                    <LocalGasStationRoundedIcon sx={{ fontSize: 20, color: "orange", marginInline: "2% 2%" }}/>
            </Tooltip>
        )
    } else if (fuelLevel <= 100) {
        return (
            <Tooltip 
                placement="top" 
                title={NormalFuelAlertTitle}
            >
                    <LocalGasStationRoundedIcon sx={{ fontSize: 20, color: "green", marginInline: "2% 2%" }}/>
            </Tooltip>
        )
    } else {
        return (
            <Tooltip 
                placement="top" 
                title={WarningFuelAlertTitle}
            >
                    <LocalGasStationRoundedIcon sx={{ fontSize: 20, color: "blue", marginInline: "2% 2%" }}/>
            </Tooltip>
        )
    }
}

/**
 * Initially yes
 * Once data received, then would switch to either no/yes
 * @param {} vehicle 
 */
const FuelLevelIconDefiner = (vehicle) => {

    const vehicles = useSelector(state => state.containers.v3.vehicles)

    const UndetectedFuelLevelTitle = (
        <>
            <p>{`Undetectable Fuel Level`}</p>
        </>
    )

    if(!vehicle) 
        return (
            <Tooltip 
                placement="top" 
                title={UndetectedFuelLevelTitle}
            >
                    <LocalGasStationRoundedIcon sx={{ fontSize: 20, color: "black" }}/>
            </Tooltip>
        )
    else {
        const vid = vehicle.vid
        const vhcFuelProfile = vehicles.fuelProfileByID[vid]
        const VHCDs = vehicles.derivativeStateByID[vid]

        if(vhcFuelProfile && Object.keys(vhcFuelProfile).length) {

            const fuel_tankNum = vhcFuelProfile && vhcFuelProfile.tankNum || 0
            const fuelProfile = []

            for(let n = 0; n < fuel_tankNum; n++) {
                const vehicleLevel = (VHCDs && vhcFuelProfile && VHCDs[vhcFuelProfile[`${n}_deviceParam`]]) || 0
                const vfMax = (vhcFuelProfile && vhcFuelProfile [`${n}_max`]) || 0
                const vfMin = (vhcFuelProfile && vhcFuelProfile [`${n}_min`]) || 0
                const fuelLevel = Number((((vehicleLevel-vfMin) / (vfMax-vfMin)) * 100).toFixed(2))
                fuelProfile.push(fuelLevel)
            }

            if(fuelProfile && fuelProfile.length) {

                return (
                    <>
                    {
                        fuelProfile.map((fl , index) => <MultiFuelLevel key={index} fuelLevel={fl} n={index+1}/>)
                    }
                    </>
                )

            } else {
                return (
                    <Tooltip 
                        placement="top" 
                        title={UndetectedFuelLevelTitle}
                    >
                            <LocalGasStationRoundedIcon sx={{ fontSize: 20, color: "black" }}/>
                    </Tooltip>
                )
            }
            

        } else {
            return (
                <Tooltip 
                    placement="top" 
                    title={UndetectedFuelLevelTitle}
                >
                        <LocalGasStationRoundedIcon sx={{ fontSize: 20, color: "black" }}/>
                </Tooltip>
            )
        }
    }
}

const FuelLevelIcon = ({vehicle}) => {
    return <>
        {
            FuelLevelIconDefiner(vehicle)
        }
    </>
}

export default FuelLevelIcon