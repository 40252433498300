import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
    Modal,
    Form,
    Select,
    Input,
    Tag
} from 'antd';

// Constant
import { REDUX as REDUX_CONSTANT, TEMPLATE } from '../../../../../../constants'

// Components

// Handler

// Services
import { REDUX } from '../../../../../../services/util'

// Redux Action
import * as REDUX_ACTION from '../../../../../../services/redux/actions'

const FormItem = Form.Item

const {
    REDUX_CRUD_API_ACTION
} = REDUX_CONSTANT

const {
    VAM_LINE_TYPE_BY_CODE,
    VAM_LINE_TYPE_COLOR_BY_CODE
} = TEMPLATE.VAM

export const VAMLineItemCUModal = ({ action, open, onClose}) => {

    const [form] = Form.useForm();

    const dispatch = useDispatch()

    const vamLines = useSelector(REDUX.reduxStoreWrapper('vamLines.itemByID'))
    const vamlid = useSelector(REDUX.reduxStoreWrapper('vamLines.selectedVAMLID'))
    const vamlineTemplates = useSelector(REDUX.reduxStoreWrapper('vamLines.templateByID'))

    const loadingAction = useSelector(REDUX.reduxStoreWrapper('style.loading.action'))

    const [ formData, setFormData ] = useState({})


    const ModelONClose = () => {
        form.resetFields()
        onClose()
    }

    const onSubmit = async() => {
        const values = await form.validateFields();
        const v = JSON.parse(JSON.stringify(values))

        const vamltid = Object.keys(vamlineTemplates).find(k => vamlineTemplates[k].default)

        if(REDUX_CRUD_API_ACTION[action] === REDUX_CRUD_API_ACTION.UPDATE) {
            v.vamlid = vamlid
        }

        v.vamltid = vamltid

        dispatch(REDUX_ACTION.v3_vamLines.cu_vam_line_request(v, REDUX_CRUD_API_ACTION[action], () => {
            ModelONClose()
        }))

    }

    const onFormValChange = (e) => {

        setFormData({
            ...formData,
            ...e
        })
    }

    useEffect(() => {

        if(vamlid) {

            const vamLine = vamLines[vamlid]

            const {
                name,
                type
            } = vamLine

            setFormData({
                name,
                type
            })

        } else {
            setFormData()
        }

    }, [vamlid])

    return (
        <Modal
            title={`${action} Line Item`}
            centered
            open={open}
            okText={action}
            onOk={() => onSubmit()}
            onCancel={() => ModelONClose()}
            width={'90vw'}
            confirmLoading={loadingAction}
        >
            <Form
                form={form}
                layout="vertical"
                wrapperCol={{ span: 22 }}
                onValuesChange={(e) => onFormValChange(e)}
                fields={[
                    {
                        name: ['name'],
                        value: formData?.name
                    },
                    {
                        name: ['type'],
                        value: formData?.type
                    }
                ]}
            >
                <FormItem label="Line Name" name="name" rules={[{ required: true, message: 'Please input Line Item name!' }]}>
                    <Input />
                </FormItem>
                <FormItem label="Line Type" name="type" rules={[{ required: true, message: 'Please choose available line type!' }]}>
                    <Select 
                        showSearch
                        // style={{ width: '40vw' }}
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={
                            Object.keys(VAM_LINE_TYPE_BY_CODE)
                            .map(code => ({
                                label: VAM_LINE_TYPE_BY_CODE[code],
                                value: code
                            }))
                            ||
                            []
                        }
                        tagRender={({label, value}) => {
                            return (
                                <Tag color={VAM_LINE_TYPE_COLOR_BY_CODE[value]}>{label}</Tag>
                            )
                        }}
                    />
                </FormItem>
            </Form>

        </Modal>
    )
}