import * as API from "../../api"
import * as ActionTypes from "../action-types";
import { REDUX } from '../../../constants'

/**
 * ThirdParty Library
 */
import {
    message
} from 'antd'
import {
    setLoadingAction,
    unsetLoadingAction
} from './style'

/**
 * Get VAM Entry Thru VID
 */

export const get_vam_entry_thru_vid_request = (vid) => dispatch => {
    
    API
    .getVAMEntryThruVID(vid)
    .then(info => {
        
        try {
            const {
                vamEntries
            } = info

            dispatch(get_vam_entry_thru_vid_success(vamEntries))

        } catch(err) {
            message.error('Error in getting VAM Entry. Refresh page?')
        }

    })
}

const get_vam_entry_thru_vid_success = (vamEntries) => {
    return {
        type: ActionTypes.GET_VAM_ENTRY_THRU_VID,
        vamEntries
    };
}

/**
 * Create Update VAM Entry
 */
export const cu_vam_entry_request = (item, actionAPI, callback = () => {}) => dispatch => {

    dispatch(setLoadingAction())

    API
    .CU_VAMEntry(item, actionAPI)
    .then(info => {

        try {
            const {
                status,
                vamEntry,
                msg
            } = info

            switch(status) {
                case 200: {
    
                    dispatch(cu_vam_entry_success(vamEntry))
    
                    break;
                }
                case 500: {
                    throw msg
                }
                default: {
                    break;
                }
            }

            message.success(`Success in ${REDUX.REDUX_CRUD_API_ACTION_THRU_CODE[actionAPI]} entry`)

        } catch(err) {
            console.log(err)
            message.error(`Error in ${REDUX.REDUX_CRUD_API_ACTION_THRU_CODE[actionAPI]} entry. Refresh page?`)
        }

    })
    .finally(() => {
        dispatch(unsetLoadingAction())
        callback()
    })
}

const cu_vam_entry_success = (vamEntry) => {
    return {
        type: ActionTypes.CU_VAM_ENTRY,
        vamEntry
    };
}

/**
 * Set VAMRIDS
 */
export const set_vamrids_request = (vamrids) => dispatch => {
    dispatch(set_vamrids_success(vamrids))
}

const set_vamrids_success = (vamrids) => {
    return {
        type: ActionTypes.SET_VAM_ENTRY_VAMRIDS,
        vamrids
    };
}


/**
 * Unset VAMRIDS
 */
export const unset_vamrids_request = () => dispatch => {
    dispatch(unset_vamrids_success())
}

const unset_vamrids_success = () => {
    return {
        type: ActionTypes.UNSET_VAM_ENTRY_VAMRIDS
    };
}

/**
 * Set VID
 */
export const set_vid_request = (vid) => dispatch => {
    dispatch(set_vid_success(vid))
}

const set_vid_success = (vid) => {
    return {
        type: ActionTypes.SET_VAM_ENTRY_VID,
        vid
    };
}

/**
 * Unset VID
 */
export const unset_vid_request = () => dispatch => {
    dispatch(unset_vid_success())
}

const unset_vid_success = () => {
    return {
        type: ActionTypes.UNSET_VAM_ENTRY_VID
    };
}

/**
 * Set VAMEID
 */
export const set_vameid_request = (vameid) => dispatch => {
    dispatch(set_vameid_success(vameid))
}

const set_vameid_success = (vameid) => {
    return {
        type: ActionTypes.SET_VAM_ENTRY_VAMEID,
        vameid
    };
}

/**
 * Unset VAMEID
 */
export const unset_vameid_request = () => dispatch => {
    dispatch(unset_vameid_success())
}

const unset_vameid_success = () => {
    return {
        type: ActionTypes.UNSET_VAM_ENTRY_VAMEID
    };
}