import React from 'react'
import {
    Table
} from 'antd';

const TabulatedReport = (
    {
        reportJSON,
        selectedTable,
        dataSrc,
        loading
    }
) => {

    return (
        <>
            <Table
                columns = {reportJSON[selectedTable].columns && reportJSON[selectedTable].columns.filter(c => !c.isHidden)}
                dataSource = {dataSrc}
                pagination = {{ pageSize: 25 }}
                loading = {loading}
                scroll = {{
                    x: reportJSON[selectedTable].columns && reportJSON[selectedTable].columns.length * 150,
                }}
            />
        </>
    )
}

export default TabulatedReport;