import * as ActionTypes from '../action-types'
import { deepMerge } from '../../util'

const ruleTemplate_DefaultState = {
    relationByUID: {},
    byID: {},
    allIDs: []
}

export const ruleTemplates = (state = ruleTemplate_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_RULE_TEMPLATE: {

            const ruleTemplate = action.ruleTemplate 
            const uid = action.uid

            const ruletid = ruleTemplate && ruleTemplate.ruletid

            if(ruletid) {

                if(uid) {
                    if(Object.prototype.hasOwnProperty.call(newState.relationByUID, uid)) {
                        if(!newState.relationByUID[uid].includes(ruletid)) newState.relationByUID[uid].push(ruletid)
                    } else {
                        newState.relationByUID[uid] = [ruletid]
                    }
                }

                if(Object.prototype.hasOwnProperty.call(newState.byID, ruletid)) newState.byID[ruletid] = deepMerge(newState.byID[ruletid], ruleTemplate)
                else newState.byID[ruletid] = ruleTemplate

                if(!newState.allIDs.includes(ruletid)) newState.allIDs.push(ruletid)
            }

            return newState

        }
        case ActionTypes.GET_RULE_TEMPLATES: {

            const ruleTemplates = action.ruleTemplates
            const uid = action.uid

            ruleTemplates.map(ruleTemplate => {

                const ruletid = ruleTemplate && ruleTemplate.ruletid

                if(ruletid) {

                    if(uid) {
                        if(Object.prototype.hasOwnProperty.call(newState.relationByUID, uid)) {
                            if(!newState.relationByUID[uid].includes(ruletid)) newState.relationByUID[uid].push(ruletid)
                        } else {
                            newState.relationByUID[uid] = [ruletid]
                        }
                    }

                    if(Object.prototype.hasOwnProperty.call(newState.byID, ruletid)) newState.byID[ruletid] = deepMerge(newState.byID[ruletid], ruleTemplate)
                    else newState.byID[ruletid] = ruleTemplate

                    if(!newState.allIDs.includes(ruletid)) newState.allIDs.push(ruletid)
                }

                return null
            })

            return newState

        }
        default: {
            return newState;
        }
    }
}