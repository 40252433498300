// Constants & Config
import * as CONFIG from "../../../config";

export const wsURL = CONFIG.APP_CONFIG.FMS_V3_FE_WS_GATEWAY;
export const wsReconnectInterval = CONFIG.APP_CONFIG.FMS_V3_FE_WS_GATEWAY_RECONNECTION_INTERVAL;

export var wsc = null;

export const getClient = () => wsc;
export const closeWS = () => {
    try {

        const client = getClient();

        client && client.close(1000);
    } 
    catch (err) {
        console.log('unable to closeWS', err);
    }
}
export const closeWSC = () => wsc && closeWS(wsc);
export const wsState = () => wsc && wsc.readyState;
export const reinitialiseWS = () => { 
    try {

        if(
            !wsc
            || 
            (
                wsc
                &&
                [
                    WebSocket.CLOSING,
                    WebSocket.CLOSED
                ].includes(wsState())
            )
        ) {
            wsc = new WebSocket(wsURL) 
        }

    } catch(err) {
        console.log("Error", err)
    }
};

export const wsClient = (
    client,
    restart = () => { },
    cb = () => { }
) => {

    if (client) {
        window.application = {
            close: () => closeWS(client),
            client: client,
        }

        client.onopen = async () => {}

        client.onmessage = async (event) => {
            const parsedData = JSON.parse(event.data);
            cb(
                null,
                parsedData,
            );
        }

        client.onclose = async (e) => {
            switch (e.code) {
                case 1000:	// CLOSE_NORMAL
                    break
                case 4500:
                default:	// Abnormal closure
                    restart('reconnect', 0);
                    break;
            }
        }

        client.onerror = async (e) => {

            switch (e.code) {
                case 'ECONNREFUSED':
                    restart('reconnect', 0);
                    break;
                default:
                    break;
            }
        }
    }
}