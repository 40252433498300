export const GET_VEHICLE_GROUP = "V3_GET_VEHICLE_GROUP";
export const GET_VEHICLE_GROUPS = "V3_GET_VEHICLE_GROUPS";
export const CREATE_VEHICLE_GROUP = "V3_CREATE_VEHICLE_GROUP";
export const EDIT_VEHICLE_GROUP = "V3_EDIT_VEHICLE_GROUP";

export const REASSIGN_VEHICLE_GROUPS = "V3_REASSIGN_VEHICLE_GROUPS";
export const ASSIGN_VEHICLE_GROUP = "V3_ASSIGN_VEHICLE_GROUP";

export const UPDATE_VEHICLE_GROUP_AFTER_ADD = "V3_UPDATE_VEHICLE_GROUP_AFTER_ADD";
export const UPDATE_VEHICLE_GROUP_AFTER_EDIT = "V3_UPDATE_VEHICLE_GROUP_AFTER_EDIT";
export const UPDATE_VEHICLES_IN_VEHICLE_GROUP = "V3_UPDATE_VEHICLES_IN_VEHICLE_GROUP";