import React from 'react'
import { 
    DatePicker,
    Form, 
    Input,
    Select
} from 'antd'

// Components
import VehiclePlateECUTag from '../../../../components/VehiclePlateECUTag'
import { PrimaryButton } from '../../../../components/Decoration/Button'

// Service
import { TIME } from '../../../../services/util'

const {
    referenceEndOfDay,
    dayJSLocalize
} = TIME

const VehicleInfoSelector = (props) => {

    const {
        // params
        vehicles,
        vid,
        startTime,
        endTime,
        fuelUnitPrice,
        isLoading,
        // Handler
        setVID,
        setStartTime,
        setEndTime,
        setFuelUnitPrice,
        onSubmit
    } = props

    const handleCalendarDisabledDate = (current) => current && current > referenceEndOfDay()

    return (
        <>
            <Form layout = "inline">
                <Form.Item>
                    <span style = {{ marginLeft: '10px' }}>Vehicles: </span>

                    <Select
                        showSearch
                        optionFilterProp = "children"
                        filterOption = {(input, option) => 
                            option.children.props.plate.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        disabled = {isLoading || !(vehicles && Object.values(vehicles).length)}
                        placeholder = "Select a vehicle"
                        value = {vid ? vid : undefined}
                        onChange = {(selectedVID) => setVID(selectedVID)}
                        style = {{ width: 175 }}
                    >
                        {
                            vehicles
                            &&
                            Object.values(vehicles)
                            &&
                            Object.values(vehicles).length
                            &&
                            Object.values(vehicles)
                            .sort((a, b) => {
                                const A = a.displayName && a.displayName.toLowerCase();
                                const B = b.displayName && b.displayName.toLowerCase();
            
                                if (A < B) return -1;
                                if (A > B) return 1;
                                return 0;
                            })
                            .map((vehicle) => 
                                <Select.Option
                                    key = {vehicle.vid}
                                    value = {vehicle.vid}
                                >
                                    <VehiclePlateECUTag
                                        plate={vehicle.displayName}
                                        vehicle={vehicle}
                                    />
                                </Select.Option>
                            )
                        }
                    </Select>
                </Form.Item>

                <Form.Item>
                    <span style = {{ marginLeft: '10px' }}>Timeframe: </span>

                    <DatePicker.RangePicker
                        showTime
                        disabledDate={handleCalendarDisabledDate}
                        value = {[dayJSLocalize(startTime), dayJSLocalize(endTime)]}
                        onChange = {(dates) => {
                            setStartTime(dates[0])
                            setEndTime(dates[1])
                        }}
                        style = {{ width: '380px' }}
                    />

                </Form.Item>

                <Form.Item>
                    <span style = {{ marginLeft: '10px' }}>Fuel Price (RM): </span>

                    <Input
                        type = {"number"}
                        min = {0}
                        value = {fuelUnitPrice}
                        onChange = {event => setFuelUnitPrice(event.target.value)}
                        style = {{ width: 100 }}
                    />
                </Form.Item>

                <Form.Item>
                    <PrimaryButton
                        loading = {isLoading}
                        disabled = {!vid}
                        onClick = {onSubmit}
                    >
                        Submit
                    </PrimaryButton>
                </Form.Item>
            </Form>
        </>
    )
}

const MemorizedVehicleInfoSelector = React.memo(VehicleInfoSelector);

export default MemorizedVehicleInfoSelector