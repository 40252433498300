import * as API from "../../api"
import * as ActionTypes from "../action-types";
import { REDUX } from '../../../constants'

/**
 * ThirdParty Library
 */
 import {
    message
} from 'antd'

/**
 * Redux Action
 */
import { 
    get_vehicle_request,
    get_vehicles_request
} from './vehicles'
import {
    get_vehicle_maintenance_profiles_request,
    get_vehicle_maintenance_states_request
} from './vehicleMaintenances'
import {
    get_vam_entry_thru_vid_request
} from './vamEntries'

/**
 * Get Vehicle Group
 */

export const get_vehicle_group_request = (vhcgid, uid = null) => dispatch => {
    

    API
    .getVehicleGroupByVHCGID(vhcgid)
    .then(info => {
        
        try {
            const {
                vehicleGroup
            } = info

            dispatch(get_vehicle_group_success(vehicleGroup, uid))
            vehicleGroup && vehicleGroup.vehicles && dispatch(get_vehicle_request(vehicleGroup.vehicle, uid))


        } catch(err) {
            message.error('Error in getting vehicle groups. Refresh page?')
        }

    })
}

const get_vehicle_group_success = (vehicleGroup, uid = null) => {
    return {
        type: ActionTypes.GET_VEHICLE_GROUP,
        vehicleGroup,
        uid
    };
}

/**
 * Get Vehicle Groups
 */

 export const get_vehicle_groups_request = (vhcgids, uid = null) => dispatch => {

    let vgidBatch = []

    vhcgids.map((vhcgid, index) => {

        vgidBatch.push(vhcgid)

        if( 
            (index+1) % REDUX.REDUX_VEHICLE_GROUP_BATCH_GET_SIZE === 0
            ||
            index+1 === vhcgids.length
        ) {
            dispatch(batch_get_vehicle_groups_request(vgidBatch, uid))
            vgidBatch = []
        }

        return null
    })
 }

/**
 * Batch Get Vehicle Groups
 */
 export const batch_get_vehicle_groups_request = (vhcgids, uid = null) => dispatch => {

    API
    .getVehicleGroupBatch(vhcgids)
    .then(info => {
        
        try {
            const {
                vehicleGroups
            } = info

            dispatch(batch_get_vehicle_groups_success(vehicleGroups, uid))

            const vids = vehicleGroups && vehicleGroups.reduce((acc, cur) => [...acc, ...cur.vehicles], []) || []

            if(
                vids
                &&
                vids.length
            ) {
                dispatch(get_vehicles_request(vids, uid))

                vids.map(vid => dispatch(get_vam_entry_thru_vid_request(vid)))

                dispatch(get_vehicle_maintenance_profiles_request(vids)) 
                dispatch(get_vehicle_maintenance_states_request(vids))  
            }

        } catch(err) {
            message.error('Error in batch getting vehicle groups. Refresh page?')
        }

    })
}

const batch_get_vehicle_groups_success = (vehicleGroups, uid = null) => {
    return {
        type: ActionTypes.GET_VEHICLE_GROUPS,
        vehicleGroups,
        uid
    };
}
