import React from "react";
import { Tabs } from "antd";

// Components
import VehiceInformationTable from "./components/VehiceInformationTable";
import GeofenceSummaryPanel from "./components/GeofenceSummaryPanel";

import showSecondarySidebar from '../../layout/MainLayout/helpers/showSecondarySidebar' 
import Page from "../../components/Decoration/Page";


const VehicleInformationPanel = () => {

    return(
        <div className = "page-container">
            <Page title = "Vehicle Information Table">
                    <Tabs
                        items={[
                            {
                                key: '1',
                                label: 'Vehicle Listing',
                                children: (
                                    <>
                                        <VehiceInformationTable/>
                                    </>
                                )
                            },
                            {
                                key: '2',
                                label: 'Geofence Summary',
                                children: (
                                    <>
                                        <GeofenceSummaryPanel/>
                                    </>
                                )
                            }
                        ]}
                    />
            </Page>
        </div>
    )

}

const MemorizedVehicleInformationPanel = React.memo(VehicleInformationPanel);

export default showSecondarySidebar(false)(MemorizedVehicleInformationPanel);