export const GET_GEOFENCE_TEMPLATE = "V3_GET_GEOFENCE_TEMPLATE";
export const GET_GEOFENCE_TEMPLATES = "V3_GET_GEOFENCE_TEMPLATES";
export const CU_GEOFENCE_TEMPLATE = "V3_CU_GEOFENCE_TEMPLATE";

export const CU_GEOFENCE_ASSET_RELATION = "V3_CU_GEOFENCE_ASSET_RELATION"
export const CU_UNBIND_GEOFENCES_FROM_GEOFENCE_TEMPLATE = "V3_CU_UNBIND_GEOFENCES_FROM_GEOFENCE_TEMPLATE"

export const REASSIGN_GEOFENCE_TEMPLATES = "V3_REASSIGN_GEOFENCE_TEMPLATES";
export const ASSIGN_GEOFENCES_TO_GEOFENCE_TEMPLATE = "V3_ASSIGN_GEOFENCES_TO_GEOFENCE_TEMPLATE";
export const UNASSIGN_GEOFENCES_FROM_GEOFENCE_TEMPLATE = "V3_UNASSIGN_GEOFENCES_FROM_GEOFENCE_TEMPLATE";

export const UPDATE_GEOFENCE_TEMPLATE_GEOFENCES = "V3_UPDATE_GEOFENCE_TEMPLATE_GEOFENCES";

export const CLEAR_TEMP_NEW_GEOTID = "V3_CLEAR_TEMP_NEW_GEOTID";