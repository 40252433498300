import React from "react";
import { Result } from "antd";

/**
 * Constant
 */

/**
 * Styles
 */
import "./index.css";

const SSOMaintenancePanel = () => {

    return (
        <>
            <Result
                status="500"
                title="500"
                subTitle="Currently your visiting site is under maintenance. Please come back once again after a moment."
                extra={
                    <></>
                }
            />
        </>
    )

}

export default React.memo(SSOMaintenancePanel);