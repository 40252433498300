import React from "react";
import { useSelector } from "react-redux";
import PrimaryButton from "./PrimaryButton";

const PrimaryButtonContainer = props => {

  const color = useSelector(state => state.containers.v3.style.buttonColor)
  
  return <PrimaryButton color={color} {...props} />;
}


export default React.memo(PrimaryButtonContainer);
