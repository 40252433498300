import React from "react";
import { 
  Layout 
} from "antd";

// Constants & Config
import * as CONFIG from '../../../config'
import {
  COMMON_FEATURES
} from '../../../constants'

// Componets
import Logo from "./Logo";
import SidebarItems from "./SidebarItems";

// Styles
import "./index.css";

const { Sider } = Layout;

const Sidebar = ({
  collapsed = "true",
  items = [],
  onClick = () => {},
  theme,
  themeColor,
  selectedkeys
}) => {
  return (
    <Sider
      collapsed = {collapsed}
      style = {{
        height: "100vh",
        overflowY: "hidden",
        backgroundColor: themeColor
      }}
    >
      <Logo pKey={COMMON_FEATURES.FEATURE_NAME.LANDING_PAGE} onClick = {() => onClick({key:COMMON_FEATURES.FEATURE_NAME.LANDING_PAGE})} selectedkeys = {selectedkeys} />

      <SidebarItems
        onClick = {onClick}
        theme = {theme}
        collapsed = {collapsed}
        items = {items}
        selectedkeys = {selectedkeys.toString()}
      />

      <div style = {{
        color: 'white',
        position: 'absolute',
        marginLeft: '15px',
        bottom: '10px'
      }}>
        V {CONFIG.APP_INFO.APP_VERSION}
      </div>
    </Sider>
  );
};

export default Sidebar;
