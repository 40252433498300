import React from 'react';
import {
    Tooltip,
} from "antd";
// Icon
import PowerOffRoundedIcon from '@mui/icons-material/PowerOffRounded';
import PowerRoundedIcon from '@mui/icons-material/PowerRounded';
// Micro

/**
 * Initially yes
 * Once data received, then would switch to either no/yes
 * @param {} vehicle 
 */
const ExtPowerIconDefiner = (vehicle) => {

    const NoExtPowerTitle = (
        <>
            <p>{`No External Power`}</p>
        </>
    )

    const UndetectedExtPowerTitle = (
        <>
            <p>{`Undetectable External Power`}</p>
        </>
    )

    if(!vehicle) 
        return (
            <Tooltip 
                placement="top" 
                title={NoExtPowerTitle}
            >
                    <PowerOffRoundedIcon sx={{ fontSize: 20, color: "red" }}/>
            </Tooltip>
        )
    else if (!Object.prototype.hasOwnProperty.call(vehicle, 'pwr_ext_v')) {
        return (
            <Tooltip 
                placement="top" 
                title={UndetectedExtPowerTitle}
            >
                    <PowerRoundedIcon sx={{ fontSize: 20, color: "black" }}/>
            </Tooltip>
        )
    } else {
        const {
            pwr_ext_v
        } = vehicle

        const LowExtPowerTitle = (
            <>
                <p>{`Connected Low External Power with ${pwr_ext_v} V`}</p>
            </>
        )

        const ExtPowerTitle = (
            <>
                <p>{`Connected External Power with ${pwr_ext_v} V`}</p>
            </>
        )

        if(pwr_ext_v < 1) {
            return (
                <Tooltip 
                    placement="top" 
                    title={NoExtPowerTitle}
                >
                        <PowerOffRoundedIcon sx={{ fontSize: 20, color: "red" }}/>
                </Tooltip>
            )
        } else if ( pwr_ext_v < 10 ) {
            return (
                <Tooltip 
                    placement="top" 
                    title={LowExtPowerTitle}
                >
                        <PowerRoundedIcon sx={{ fontSize: 20, color: "orange" }}/>
                </Tooltip>
            )
        } else {
            return (
                <Tooltip 
                    placement="top" 
                    title={ExtPowerTitle}
                >
                        <PowerRoundedIcon sx={{ fontSize: 20, color: "green" }}/>
                </Tooltip>
            )
        }
    }
}

const ExtPowerIcon = ({vehicle}) => {
    return <>
        {
            ExtPowerIconDefiner(vehicle)
        }
    </>
}

export default ExtPowerIcon