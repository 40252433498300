import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Tooltip } from 'antd'

// Constants
import {
    REDUX as REDUX_CONSTANT,
    TEMPLATE
} from '../../../../../../constants'

// Handler
import {
    vam_handler
} from '../../../../../../handlers'

// Service
import * as REDUX_ACTION from '../../../../../../services/redux/actions'

const {
    VAM_REMINDER_STATUS_COLOR_BY_CODE
} = TEMPLATE.VAM

const {
    REDUX_CRUD_API_ACTION
} = REDUX_CONSTANT

const {
    nextDueDetermine
} = vam_handler

export const NextDueDeterminator = ({data}) => {

    const dispatch = useDispatch()

    const nextDueValidator = (data, ds) => {

        const { vamrid, status } = data

        const updateStatus = ds.reduce((acc, cur) => acc > cur.status && acc || cur.status, status)

        if(updateStatus > status || (!status)) {
            dispatch(REDUX_ACTION.v3_vamReminders.cu_vam_reminder_request(
                {
                    vamrid,
                    status: updateStatus
                }, 
                REDUX_CRUD_API_ACTION.UPDATE
            ))
        }

    }

    const determinators = nextDueDetermine(data)

    useEffect(() => {

        nextDueValidator(data, determinators)

    }, [])
    
    return (
        determinators && determinators.length  
        && 
        <>
            {
                 
                determinators.map(d => 
                    <Tooltip key={`${data.vamrid}#${d.param}`} placement="top" title={`Due on ${d.due}`}>
                        <div  style={{color:VAM_REMINDER_STATUS_COLOR_BY_CODE[d.status]}}>{d.remaining}</div>
                    </Tooltip>
                )
            }
        </>
        || <></>
    )
}