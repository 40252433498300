import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from 'antd'

export const get_vehicle_log_by_timeframe_request = (vid, startTime, endTime) => dispatch => {

    return API
    .getVehicleLogByTimeFrameSync(vid, startTime, endTime) 
    .then(info => {
        
        try {
            const {
                vehicleLogs
            } = info

            vehicleLogs && vehicleLogs.length && dispatch(get_vehicle_log_by_timeframe_success(vehicleLogs, vid, startTime, endTime))


        } catch(err) {
            message.error('Error in getting timeframe vehicle logs. Refresh page?')
        }
    })
    
}

const get_vehicle_log_by_timeframe_success = (vehicleLogs, vid, startTime, endTime) => {
    return {
        type: ActionTypes.GET_TIMEFRAME_VEHICLE_LOGS,
        vehicleLogs,
        vid, startTime, endTime
    }
}

export const set_selected_vehicle_for_vehicle_log_request = (vid) => dispatch => {
    dispatch(set_selected_vehicle_for_vehicle_log_success(vid))
}

const set_selected_vehicle_for_vehicle_log_success = (vid) => {
    return {
        type: ActionTypes.SET_SELECTED_VEHICLE_FOR_VEHICLE_LOG,
        vid
    }
}

export const clear_selected_vehicle_for_vehicle_log_request = () => dispatch => {
    dispatch(clear_selected_vehicle_for_vehicle_log_success())
}

const clear_selected_vehicle_for_vehicle_log_success = () => {
    return {
        type: ActionTypes.UNSET_SELECTED_VEHICLE_FOR_VEHICLE_LOG
    }
}


export const set_selected_vehicle_log_time_request = (vehicleTime) => dispatch => {
    dispatch(set_selected_vehicle_log_time_success(vehicleTime))
}

const set_selected_vehicle_log_time_success = (vehicleTime) => {
    return {
        type: ActionTypes.SET_SELECTED_VEHICLE_LOG_TIME,
        vehicleTime
    }
}

export const get_vehicle_derivative_log_by_timeframe_request = (vid, startTime, endTime) => dispatch => {

    return API
    .getVehicleDerivativeLogByTimeFrameSync(vid, startTime, endTime)
    .then(info => {
        
        try {
            const {
                vehicleDerivativeLogs
            } = info

            vehicleDerivativeLogs && vehicleDerivativeLogs.length && dispatch(get_vehicle_derivative_log_by_timeframe_success(vehicleDerivativeLogs, vid, startTime, endTime))


        } catch(err) {
            message.error('Error in getting timeframe vehicle logs. Refresh page?')
        }
    })
    
}

const get_vehicle_derivative_log_by_timeframe_success = (vehicleDerivativeLogs, vid, startTime, endTime) => {
    return {
        type: ActionTypes.GET_TIMEFRAME_VEHICLE_DERIVATIVE_LOGS,
        vehicleDerivativeLogs,
        vid, startTime, endTime
    }
}

export const get_vehicle_event_log_by_timeframe_request = (vid, startTime, endTime) => dispatch => {

    return API
    .getVehicleEventLogByTimeFrameSync(vid, startTime, endTime) 
    .then(info => {
        
        try {
            const {
                vehicleEventLogs
            } = info

            vehicleEventLogs && vehicleEventLogs.length && dispatch(get_vehicle_event_log_by_timeframe_success(vehicleEventLogs, vid, startTime, endTime))


        } catch(err) {
            message.error('Error in getting timeframe vehicle logs. Refresh page?')
        }
    })
    
}

const get_vehicle_event_log_by_timeframe_success = (vehicleEventLogs, vid, startTime, endTime) => {
    return {
        type: ActionTypes.GET_TIMEFRAME_VEHICLE_EVENT_LOGS,
        vehicleEventLogs,
        vid, startTime, endTime
    }
}

export const get_vehicle_geo_state_log_by_timeframe_request = (vid, startTime, endTime) => dispatch => {

    return API
    .getVehicleGeoStateLogByTimeFrameSync(vid, startTime, endTime)
    .then(info => {
        
        try {
            const {
                vehicleGeoStateLogs
            } = info

            vehicleGeoStateLogs && vehicleGeoStateLogs.length && dispatch(get_vehicle_geo_state_log_by_timeframe_success(vehicleGeoStateLogs, vid, startTime, endTime))

        } catch(err) {
            console.log(err)
            message.error('Error in getting timeframe vehicle geo logs. Refresh page?')
        }
    })
    
}

const get_vehicle_geo_state_log_by_timeframe_success = (vehicleGeoStateLogs, vid, startTime, endTime) => {
    return {
        type: ActionTypes.GET_TIMEFRAME_VEHICLE_GEO_STATE_LOGS,
        vehicleGeoStateLogs,
        vid, startTime, endTime
    }
}

export const get_vehicle_ecu_log_by_timeframe_request = (vid, startTime, endTime) => dispatch => {

    return API
    .getVehicleECULogByTimeFrameSync(vid, startTime, endTime)
    .then(info => {
        
        try {
            const {
                vehicleECULogs
            } = info

            vehicleECULogs && vehicleECULogs.length && dispatch(get_vehicle_ecu_log_by_timeframe_success(vehicleECULogs, vid, startTime, endTime))


        } catch(err) {
            console.log(err)
            message.error('Error in getting timeframe vehicle geo logs. Refresh page?')
        }
    })
    
}

const get_vehicle_ecu_log_by_timeframe_success = (vehicleECULogs, vid, startTime, endTime) => {
    return {
        type: ActionTypes.GET_TIMEFRAME_VEHICLE_ECU_LOGS,
        vehicleECULogs,
        vid, startTime, endTime
    }
}