import * as ActionTypes from '../action-types'

const maintenanceConfigs_DefaultState = {
    templateByID: {},
    templateRelationByID: {},
    serviceByID: {},
    componentByID: {},
    serviceByMAINCID: {},
    ruleByID: {},
    allTemplateIDs: [],
    allServiceIDs: [],
    allComponentIDs: [],
    allRuleIDs: []
}

export const maintenanceConfigs = (state =  maintenanceConfigs_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {

        case ActionTypes.GET_MAINTENANCE_COMPONENTS: {

            const maintenanceComponents = action.maintenanceComponents

            maintenanceComponents
            &&
            maintenanceComponents.map(mc => {

                const maincid = mc.maincid

                newState.componentByID[maincid] = mc

                if(!newState.allComponentIDs.includes(maincid)) newState.allComponentIDs.push(maincid)
            })


            return newState
        }

        case ActionTypes.GET_MAINTENANCE_RULES: {

            const maintenanceRules = action.maintenanceRules

            maintenanceRules
            &&
            maintenanceRules.map(mr => {

                const mainrid = mr.mainrid

                newState.ruleByID[mainrid] = mr

                if(!newState.allRuleIDs.includes(mainrid)) newState.allRuleIDs.push(mainrid)
            })


            return newState
        }

        case ActionTypes.GET_MAINTENANCE_SERVICES: {

            const maintenanceServices = action.maintenanceServices

            maintenanceServices
            &&
            maintenanceServices.map(ms => {

                if(ms) {
                    const mainsid = ms.mainsid
                    const maincid = ms.maincid

                    newState.serviceByID[mainsid] = ms

                    if(Object.prototype.hasOwnProperty.call(newState.serviceByMAINCID, maincid)) {
                        if(!newState.serviceByMAINCID[maincid].includes(mainsid)) newState.serviceByMAINCID[maincid].push(mainsid)
                    } else {
                        newState.serviceByMAINCID[maincid] = [mainsid]
                    }

                    if(!newState.allServiceIDs.includes(mainsid)) newState.allServiceIDs.push(mainsid)
                }
            })


            return newState
        }

        case ActionTypes.GET_MAINTENANCE_TEMPLATES: {

            const maintenanceTemplates = action.maintenanceTemplates

            maintenanceTemplates
            &&
            maintenanceTemplates.map(mt => {

                const maintid = mt.maintid

                const maincids = mt.maincids
                const mainsids = mt.mainsids
                const mainrids = mt.mainrids

                newState.templateByID[maintid] = mt

                if(!Object.prototype.hasOwnProperty.call(newState.templateRelationByID, maintid)) newState.templateRelationByID[maintid] = []

                maincids && maincids.forEach(id => {
                    if (newState.templateRelationByID[maintid].indexOf(id) === -1) newState.templateRelationByID[maintid].push(id);
                    return 1
                })

                mainsids && mainsids.forEach(id => {
                    if (newState.templateRelationByID[maintid].indexOf(id) === -1) newState.templateRelationByID[maintid].push(id);
                    return 1
                })

                mainrids && mainrids.forEach(id => {
                    if (newState.templateRelationByID[maintid].indexOf(id) === -1) newState.templateRelationByID[maintid].push(id);
                    return 1
                })

                if(!newState.allTemplateIDs.includes(maintid)) newState.allTemplateIDs.push(maintid)
            })


            return newState
        }

        case ActionTypes.CU_MAINTENANCE_COMPONENT: {

            const maintenanceComponent = action.maintenanceComponent

            const {
                maincid
            } = maintenanceComponent

            if(Object.prototype.hasOwnProperty.call(newState.componentByID, maincid)) {

                newState.componentByID[maincid] = {
                    ...newState.componentByID[maincid],
                    ...maintenanceComponent
                }

            } else {

                newState.componentByID[maincid] = {
                    ...maintenanceComponent
                }
            }

            if(!Object.prototype.hasOwnProperty.call(newState.serviceByMAINCID, maincid)) newState.serviceByMAINCID[maincid] = []
            if(!newState.allComponentIDs.includes(maincid)) newState.allComponentIDs.push(maincid)

            return newState
        }

        case ActionTypes.CU_MAINTENANCE_SERVICE: {

            const maintenanceService = action.maintenanceService

            const {
                mainsid,
                maincid
            } = maintenanceService

            if(Object.prototype.hasOwnProperty.call(newState.serviceByID, mainsid)) {

                newState.serviceByID[mainsid] = {
                    ...newState.serviceByID[mainsid],
                    ...maintenanceService
                }

            } else {

                newState.serviceByID[mainsid] = {
                    ...maintenanceService
                }
            }

            if(!Object.prototype.hasOwnProperty.call(newState.serviceByMAINCID, maincid)) newState.serviceByMAINCID[maincid] = []
            if(maincid && !newState.serviceByMAINCID[maincid].includes(mainsid)) newState.serviceByMAINCID[maincid].push(mainsid)
            if(!newState.allServiceIDs.includes(mainsid)) newState.allServiceIDs.push(mainsid)

            return newState
        }
        
        default: {
            return newState;
        }
    }
}