export const GEOFENCE_TYPE_OBJ = {
    POI: 'POI',
    ZONE: 'ZONE',
    POLYGON: 'POLYGON'
} 

export const GEOFENCE_NAMING_CONVERTER = (type) => {
    switch(type.toUpperCase()) {
        case GEOFENCE_TYPE_OBJ.POLYGON:
            return GEOFENCE_TYPE_OBJ.ZONE
        default:
            return type
    }
}

export const GEOFENCE_COLORS = [
    "#A020F0", // This is default and therefore first
    "#FF0000",
    "#00FF00",
    "#00FFE8",
    "#FF00FF",
    "#FFA500",
    "#FFFF00",
]

export const GEOFENCE_COLORS_FONT_HEXCODE = {
    "#A020F0": "white",
    "#FF0000": "white",
    "#00FF00": "black",
    "#00FFE8": "black",
    "#FF00FF": "white",
    "#FFA500": "black",
    "#FFFF00": "black"
}


export const DEFAULT_GEOFENCE_TEMPLATE = 'UNGROUP'
export const DEFAULT_GEOFENCE_COLOR = '#A020F0'