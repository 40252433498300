import React from 'react'

// Constant
import { TEMPLATE } from '../../constants'

const VehicleTripStatusTag = (props) => {

    const {
        tripStatus
    } = props

    return (
        <div 
            style={
                { 
                    display: 'flex', 
                    color: 'white', 
                    backgroundColor: TEMPLATE.STATUS.VEHICLE_TRANSIT_STATUS_COLOR[tripStatus], 
                    alignItems:'center', 
                    justifyContent: 'center', 
                    fontSize: 12,
                    fontWeight: 'bold',
                    borderRadius: '20px',
                    paddingRight: '10px',  
                    paddingLeft: '10px'  
                }
            }>
            {tripStatus}
        </div>
    )

}

export default VehicleTripStatusTag