import * as API_CONSTANT from '../constants'

export const getVAMEntryThruVID = (vid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vam/entry/get/vid`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vid
        })
    })
    .then(res => res.json())
)

export const CU_VAMEntry = (item, actionAPI) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vam/entry/cu`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            ...item,
            actionAPI
        })
    })
    .then(res => res.json())
)

export const getVAMLine = (vamlid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vam/line/get`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vamlid
        })
    })
    .then(res => res.json())
)

export const CU_VAMLine = (item, actionAPI) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vam/line/item/cu`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            ...item,
            actionAPI
        })
    })
    .then(res => res.json())
)

export const getVAMLineBatch = (vamlids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vam/line/batch/get`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vamlids
        })
    })
    .then(res => res.json())
)

export const getVAMLineTemplate = (vamltid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vam/line/template/get`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vamltid
        })
    })
    .then(res => res.json())
)

export const getVAMLineTemplateBatch = (vamltids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vam/line/template/batch/get`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vamltids
        })
    })
    .then(res => res.json())
)

export const getVAMProgram = (vampid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vam/program/get`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vampid
        })
    })
    .then(res => res.json())
)

export const getVAMProgramBatch = (vampids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vam/program/batch/get`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vampids
        })
    })
    .then(res => res.json())
)

export const getVAMReminderThruVID = (vid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vam/reminder/get/vid`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vid
        })
    })
    .then(res => res.json())
)

export const CU_VAMReminder = (item, actionAPI) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vam/reminder/cu`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            ...item,
            actionAPI
        })
    })
    .then(res => res.json())
)

export const getVAMSchedule = (vamsid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vam/schedule/get`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vamsid
        })
    })
    .then(res => res.json())
)