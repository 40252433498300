export const ROLE_TYPE = {
    SUPER: "S",
    ADMIN: 'A',
    ADMIN_OPERATOR: 'AO',
    FLEET_OPERATOR: "FO",
    FLEET_OWNER: "F",
    DISTRIBUTOR: "D",
    DISTRIBUTOR_OPERATOR: "DO",
    FLEET_MANAGER: "FM",
    UNKNOWN: "X",
};

export const FILTER_OUT_ROLE = [
    ROLE_TYPE.FLEET_OWNER,
    ROLE_TYPE.FLEET_MANAGER,
    ROLE_TYPE.FLEET_OPERATOR
]