import 
    React,
    {
        useState,
        useEffect
    }
from "react";
import {
    useSelector
} from 'react-redux'
import { 
    Form, 
    Input
} from 'antd';

// Constant

// Services
import { REDUX } from '../../../../../../../../services/util'

// Components

// Routes

// Redux Action

// Styling
import './index.css'

const FormItem = Form.Item;

const Maintenance_Component_CU = ({maincid, form}) => {

    const maintenanceComponents = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.componentByID'))

    const [name, setName ] = useState("")

    useEffect(() => {
        if(maincid) {
            const maintenanceComponent =  maintenanceComponents[maincid]
            
            setName(maintenanceComponent.name)
        }

    }, [maincid])

    return (
        <>
            <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                fields={[
                    {
                        name: ["name"],
                        value: name
                    },
                ]}
            >
                <FormItem label="Component Name" name="name" rules={[{ required: true, message: 'Please input component name!' }]}>
                    <Input 
                        value={name}
                        style={{ width: '40vw' }}
                    />
                </FormItem>
            </Form>
        </>
    )
}

export default Maintenance_Component_CU