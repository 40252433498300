import React from "react";
import { Result } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

/**
 * Constant
 */

/**
 * Styles
 */
import "./index.css";

const SSOLoadingPanel = () => {

    return (
        <>
            <Result
                icon={<LoadingOutlined style={{ fontSize: 96 }} spin/>}
                title="Login..."
            />
        </>
    )

}

export default React.memo(SSOLoadingPanel);