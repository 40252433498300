import 
    React
from 'react'
import {
    Card,
    Avatar,
    Table
} from "antd";
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';

// Constant
import { TEMPLATE } from '../../../../constants'

// Components

// Handler

// Service

const { Meta } = Card;
const { EVENT } = TEMPLATE

const FAEventCard = ({
    fuelUnitPrice,
    report,
    event
}) => {

    const EventReportDefiner = () => {
        switch(event) {
            case EVENT.FUEL_EVENTS.REFUEL: {
                return report.refuels
            }
            case EVENT.FUEL_EVENTS.THEFT: {
                return report.thefts
            }
        }
    }

    const er = EventReportDefiner(event)
    const num = er.length

    const EventAlertColorDefiner = () => {

        let color = 'black'

        switch(event) {
            case EVENT.FUEL_EVENTS.REFUEL: {
                color = (num > 0 && 'blue' )|| 'black'
                break;
            }
            case EVENT.FUEL_EVENTS.THEFT: {       
                color = (num > 0 && 'red' )|| 'black'
                break;
            }
        }

        return color
    }

    const EventIconDefiner = () => {
        switch(event) {
            case EVENT.FUEL_EVENTS.REFUEL: {
                return <LocalGasStationIcon sx={{ fontSize: 40 }}/>
            }
            case EVENT.FUEL_EVENTS.THEFT: {
                return <OilBarrelIcon sx={{ fontSize: 40 }}/>
            }
        }
    }

    const EventDescriptionDefiner = () => {

        const total = er.reduce((acc, cur) => acc + cur.difference, 0)
        const price = total * fuelUnitPrice

        const columns = [
            {
                title: 'Event #',
                dataIndex: 'event_num',
                key: 'event_num',
            },
            {
                title: `${event} (Liter)`,
                dataIndex: 'total_fuel',
                key: 'total_fuel',
            },
            {
                title: `Cost (MYR)`,
                dataIndex: 'price',
                key: 'price',
            }
        ]

        const data = [
            {
                event_num: num,
                total_fuel: total.toFixed(2),
                price: price.toFixed(2)
            }
        ]

        return (
            <>
                <Table columns={columns} dataSource={data} pagination={false}/>
            </>
        )
    }
 
    return (
        <>
            {
                report
                &&
                <Card 
                    style={{ margin: 16 }}
                >
                    <Meta
                        avatar={
                            <Avatar style={{ backgroundColor: EventAlertColorDefiner() }} size={64} icon={EventIconDefiner()} />
                        }
                        title={event}
                        description={EventDescriptionDefiner()}
                    />
                </Card>
            }
        </>
    )
}

const MemorisedFAEventCard = React.memo(FAEventCard)

export default MemorisedFAEventCard