import * as ActionTypes from '../action-types'
import { deepMerge } from '../../util'

const geofenceTemplate_DefaultState = {
    concatentedGT: {
        geotid: 'ALL',
        name: `ALL`,
        geofences: []
    },
    relationByUID: {},
    byID: {},
    allIDs: []
}

export const geofenceTemplates = (state = geofenceTemplate_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_GEOFENCE_TEMPLATE: {

            const geofenceTemplate = action.geofenceTemplate 
            const uid = action.uid

            const geotid = geofenceTemplate && geofenceTemplate.geotid

            if(geotid) {

                if(uid) {
                    if(Object.prototype.hasOwnProperty.call(newState.relationByUID, uid)) {
                        if(!newState.relationByUID[uid].includes(geotid)) newState.relationByUID[uid].push(geotid)
                    } else {
                        newState.relationByUID[uid] = [geotid]
                    }
                }

                if(Object.prototype.hasOwnProperty.call(newState.byID, geotid)) newState.byID[geotid] = deepMerge(newState.byID[geotid], geofenceTemplate)
                else newState.byID[geotid] = geofenceTemplate

                if(!newState.allIDs.includes(geotid)) newState.allIDs.push(geotid)
            }

            newState.concatentedGT.geofences = newState.allIDs.reduce((acc, curgeotid) => [...acc, ...newState.byID[curgeotid].geofences], [])

            return newState

        }
        case ActionTypes.GET_GEOFENCE_TEMPLATES: {

            const geofenceTemplates = action.geofenceTemplates
            const uid = action.uid

            geofenceTemplates.map(geofenceTemplate => {

                const geotid = geofenceTemplate && geofenceTemplate.geotid

                if(geotid) {

                    if(uid) {
                        if(Object.prototype.hasOwnProperty.call(newState.relationByUID, uid)) {
                            if(!newState.relationByUID[uid].includes(geotid)) newState.relationByUID[uid].push(geotid)
                        } else {
                            newState.relationByUID[uid] = [geotid]
                        }
                    }

                    if(Object.prototype.hasOwnProperty.call(newState.byID, geotid)) newState.byID[geotid] = deepMerge(newState.byID[geotid], geofenceTemplate)
                    else newState.byID[geotid] = geofenceTemplate

                    if(!newState.allIDs.includes(geotid)) newState.allIDs.push(geotid)
                }

                return null
            })

            newState.concatentedGT.geofences = newState.allIDs.reduce((acc, curgeotid) => [...acc, ...newState.byID[curgeotid].geofences], [])

            return newState

        }

        case ActionTypes.CU_GEOFENCE_TEMPLATE: {

            const {
                geofenceTemplate
            } = action

            const {
                geotid
            } = geofenceTemplate

            if(Object.prototype.hasOwnProperty.call(newState.byID, geotid)) {

                newState.byID[geotid] = {
                    ...newState.byID[geotid],
                    ...geofenceTemplate
                }

            } else {

                newState.byID[geotid] = {
                    ...geofenceTemplate
                }
            }

            if(!newState.allIDs.includes(geotid)) newState.allIDs.push(geotid)

            return newState
        }

        case ActionTypes.CU_GEOFENCE_ASSET_RELATION: {

            const {
                geotids,
                geoid
            } = action

            Object.values(newState.byID).map(gt => {

                const { geotid } = gt

                if(geotids.includes(geotid)) {
                    if(!newState.byID[geotid].geofences.includes(geoid)) newState.byID[geotid].geofences.push(geoid)
                } else {
                    if(newState.byID[geotid].geofences.includes(geoid)) newState.byID[geotid].geofences = newState.byID[geotid].geofences.filter(g => g !== geoid)
                }

                return 1
            })

            return newState;
        }

        case ActionTypes.CU_UNBIND_GEOFENCES_FROM_GEOFENCE_TEMPLATE: {

            const {
                geoids,
                geotid
            } = action

            newState.byID[geotid].geofences = newState.byID[geotid].geofences.filter(geoid => !geoids.includes(geoid))

            return newState
        }

        default: {
            return newState;
        }
    }
}