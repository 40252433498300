import * as API from "../../api"
import * as ActionTypes from "../action-types";
import { batch } from "react-redux";
import { REDUX } from '../../../constants'

/**
 * ThirdParty Library
 */
 import {
    message
} from 'antd'

/**
 * Get Maintenance Rules
 */

 export const get_maintenance_rules_request = mainrids => dispatch => {

    let mainridBatch = []

    batch(() => {

        mainrids.map((mainrid, index) => {

            mainridBatch.push(mainrid)
    
            if( 
                (index+1) % REDUX.REDUX_MAINTENANCE_RULE_BATCH_GET_SIZE === 0
                ||
                index+1 === mainrids.length
            ) {
                dispatch(batch_get_maintenance_rules_request(mainridBatch))
                mainridBatch = []
            }
    
            return null
        })

    })
 }

/**
 * Batch Get Maintenance Rules
 */
 export const batch_get_maintenance_rules_request = mainrids => dispatch => {

    API
    .getMaintenanceRuleBatch(mainrids)
    .then(info => {
        try {
            const {
                maintenanceRules
            } = info

            dispatch(batch_get_maintenance_rules_success(maintenanceRules))

        } catch(err) {
            message.error('Error in batch getting maintenance rules. Refresh page?')
        }

    })
}

const batch_get_maintenance_rules_success = (maintenanceRules) => {
    return {
        type: ActionTypes.GET_MAINTENANCE_RULES,
        maintenanceRules
    };
}

/**
 * Get Maintenance Services
 */

 export const get_maintenance_services_request = mainsids => dispatch => {

    let mainsidBatch = []

    batch(() => {

        mainsids.map((mainsid, index) => {

            mainsidBatch.push(mainsid)
    
            if( 
                (index+1) % REDUX.REDUX_MAINTENANCE_SERVICE_BATCH_GET_SIZE === 0
                ||
                index+1 === mainsids.length
            ) {
                dispatch(batch_get_maintenance_services_request(mainsidBatch))
                mainsidBatch = []
            }
    
            return null
        })

    })

 }

/**
 * Batch Get Maintenance Services
 */
 export const batch_get_maintenance_services_request = mainsids => dispatch => {

    API
    .getMaintenanceServiceBatch(mainsids)
    .then(info => {
        try {
            const {
                maintenanceServices
            } = info

            dispatch(batch_get_maintenance_services_success(maintenanceServices))

        } catch(err) {
            message.error('Error in batch getting maintenance services. Refresh page?')
        }

    })
}

const batch_get_maintenance_services_success = (maintenanceServices) => {
    return {
        type: ActionTypes.GET_MAINTENANCE_SERVICES,
        maintenanceServices
    };
}

/**
 * Get Maintenance Components
 */

 export const get_maintenance_components_request = maincids => dispatch => {

    let maincidBatch = []

    batch(() => {

        maincids.map((maincid, index) => {

            maincidBatch.push(maincid)
    
            if( 
                (index+1) % REDUX.REDUX_MAINTENANCE_COMPONENT_BATCH_GET_SIZE === 0
                ||
                index+1 === maincids.length
            ) {
                dispatch(batch_get_maintenance_components_request(maincidBatch))
                maincidBatch = []
            }
    
            return null
        })

    })

 }

/**
 * Batch Get Maintenance Components
 */
 export const batch_get_maintenance_components_request = maincids => dispatch => {

    API
    .getMaintenanceComponentBatch(maincids)
    .then(info => {
        try {
            const {
                maintenanceComponents
            } = info

            dispatch(batch_get_maintenance_components_success(maintenanceComponents))

        } catch(err) {
            message.error('Error in batch getting maintenance components. Refresh page?')
        }

    })
}

const batch_get_maintenance_components_success = (maintenanceComponents) => {
    return {
        type: ActionTypes.GET_MAINTENANCE_COMPONENTS,
        maintenanceComponents
    };
}

/**
 * Get Maintenance Templates
 */

 export const get_maintenance_templates_request = maintids => dispatch => {

    let maintidBatch = []

    batch(() => {

        maintids.map((maintid, index) => {

            maintidBatch.push(maintid)
    
            if( 
                (index+1) % REDUX.REDUX_MAINTENANCE_TEMPLATE_BATCH_GET_SIZE === 0
                ||
                index+1 === maintids.length
            ) {
                dispatch(batch_get_maintenance_templates_request(maintidBatch))
                maintidBatch = []
            }
    
            return null
        })

    })

 }

/**
 * Batch Get Maintenance Templates
 */
 export const batch_get_maintenance_templates_request = maintids => dispatch => {

    API
    .getMaintenanceTemplateBatch(maintids)
    .then(info => {
        try {
            const {
                maintenanceTemplates
            } = info

            batch(() => {

                dispatch(batch_get_maintenance_templates_success(maintenanceTemplates))

                maintenanceTemplates
                && dispatch(
                    get_maintenance_components_request(
                        maintenanceTemplates.reduce((acc, cur) => [...acc, ...cur.maincids], [])
                    )
                )

                maintenanceTemplates
                && dispatch(
                    get_maintenance_services_request(
                        maintenanceTemplates.reduce((acc, cur) => [...acc, ...cur.mainsids], [])
                    )
                )

                maintenanceTemplates
                && dispatch(
                    get_maintenance_rules_request(
                        maintenanceTemplates.reduce((acc, cur) => [...acc, ...cur.mainrids], [])
                    )
                )

            })


        } catch(err) {
            console.log("Error", err)
            message.error('Error in batch getting maintenance templates. Refresh page?')
        }

    })
}

const batch_get_maintenance_templates_success = (maintenanceTemplates) => {
    return {
        type: ActionTypes.GET_MAINTENANCE_TEMPLATES,
        maintenanceTemplates
    };
}

/**
 * CU Maintenance Component
 */

export const cu_maintenance_component_request = (item) => dispatch => {

    const { actionAPI } = item

    API
    .CU_MaintenanceComponent(item)
    .then(info => {

        try {

            const {
                status,
                maintenanceComponent,
                msg
            } = info
    
            switch(status) {
                case 200: {
    
                    dispatch(cu_maintenance_component_success(maintenanceComponent))
    
                    break;
                }
                case 500: {
                    throw msg
                }
                default: {
                    break;
                }
            }

        } catch(err) {
            message.error(`Error in ${REDUX.REDUX_CRUD_API_ACTION_THRU_CODE[actionAPI]} maintenance component. Refresh page?`)
        }

    })

}

const cu_maintenance_component_success = (maintenanceComponent) => {
    return {
        type: ActionTypes.CU_MAINTENANCE_COMPONENT,
        maintenanceComponent
    };
}

/**
 * CU Maintenance Service
 */

export const cu_maintenance_service_request = (item) => dispatch => {

    const { actionAPI } = item

    API
    .CU_MaintenanceService(item)
    .then(info => {

        try {

            const {
                status,
                maintenanceService,
                msg
            } = info
    
            switch(status) {
                case 200: {
    
                    dispatch(cu_maintenance_service_success(maintenanceService))
    
                    break;
                }
                case 500: {
                    throw msg
                }
                default: {
                    break;
                }
            }

        } catch(err) {
            console.log("Error", err)
            message.error(`Error in ${REDUX.REDUX_CRUD_API_ACTION_THRU_CODE[actionAPI]} maintenance service. Refresh page?`)
        }

    })

}

const cu_maintenance_service_success = (maintenanceService) => {
    return {
        type: ActionTypes.CU_MAINTENANCE_SERVICE,
        maintenanceService
    };
}