import 
    React, 
    { 
        useState, 
        useEffect
    } 
from 'react';
import { 
    useDispatch, 
    useSelector 
} from 'react-redux';
import {
    MarkerF,
    OverlayView,
} from '@react-google-maps/api';

// Constans
import { 
    TEMPLATE
} from '../../../../constants'

// Componetents
import VehicleInformationBox from '../VehicleInformationBox'

// Handlers

// Services

// Redux Actions
import {
    v3_mapControl,
    v3_vehicles
} from '../../../../services/redux/actions'

// Styles
import "../../GoogleMaps/gmaps.css";

const {
    VEHICLE_TRANSIT_STATUS_COLOR
} = TEMPLATE.STATUS

const {
    DEFAULT_VEHICLE_GROUP_COLOR,
} = TEMPLATE.GROUP

const {
    clear_selected_vehicle_request,
    set_selected_vehicle_request
} = v3_vehicles

const {
    clear_enabled_vehicle_pop_ups,
    set_map_control,
    add_enabled_vehicle_pop_up
} = v3_mapControl

const VehicleMarkers = (props) => {
    const { mapRef } = props;

    // State
    const [hasListener, setHasListener] = useState(false);

    // Redux Store
    const vehicles = useSelector(state => state.containers.v3.vehicles);
    const mapControl = useSelector(state => state.containers.v3.mapControl);
    const vehicleGroups = useSelector(state => state.containers.v3.vehicleGroups);

    const dispatch = useDispatch()

    // ComponentDidUpdate
    useEffect(() => {
        let location, zoom;

        /**
         * Pan to selected device
         */
        if (
            vehicles.selectedID
            && vehicles.stateByID[vehicles.selectedID]
            && vehicles.stateByID[vehicles.selectedID].location
            && vehicles.stateByID[vehicles.selectedID].location.lat
            && vehicles.stateByID[vehicles.selectedID].location.lon
            && mapControl.mapControl === vehicles.selectedID
        ) {
            zoom = 20;

            location = {
                lat: parseFloat(vehicles.stateByID[vehicles.selectedID].location.lat),
                lng: parseFloat(vehicles.stateByID[vehicles.selectedID].location.lon),
            }

            if (mapRef) {
                mapRef.panTo(location);
                mapRef.setZoom(zoom);
            }
        }
    })

    // Initial mount of component - ComponentDidMount()
    useEffect(() => {
        dispatch(clear_selected_vehicle_request());
        dispatch(clear_enabled_vehicle_pop_ups());
    },
        [dispatch]
    )

    // Add an event listener to disable map panning to vehicle once the map is dragged
    useEffect(() => {
        if (mapRef && !hasListener) {
            setHasListener(true);

            mapRef.addListener('dragstart', () => {
                dispatch(set_map_control(0));
                dispatch(clear_selected_vehicle_request());
            });
        }
    },
        [mapRef, dispatch, setHasListener, hasListener]
    )

    // Update the pop up when web socket returns
    useEffect(() => {
        // console.log("Test");
    },
        [vehicles]
    )

    const getVehicleTransitColor = (vid) => {

        const transitStatus = vehicles.derivativeStateByID[vid] && vehicles.derivativeStateByID[vid].transitStatus || null
        
        return (transitStatus && VEHICLE_TRANSIT_STATUS_COLOR[transitStatus]) || VEHICLE_TRANSIT_STATUS_COLOR[null];
    }

    const returnLatestVehicleGroupColorForVID = (vid) => {
        let latestGroup = {};

        Object.values(vehicleGroups.byID)
            .filter((currGroup) => currGroup.vehicles.includes(vid)) // Only check geofence templates containing this geofence ID
            .forEach((currGroup) => {
                if (!latestGroup.createdAt || currGroup.createdAt > latestGroup.createdAt) {
                    latestGroup = currGroup;
                }
            })

        return latestGroup.colorHexCode ? latestGroup.colorHexCode : DEFAULT_VEHICLE_GROUP_COLOR;
    }

    const getVehicleMarker = (thisVehicle) => {
        if (
            thisVehicle &&
            thisVehicle.location
        ) {
            const paddingInt = 0;
            const vid = thisVehicle.vid;

            const thisVehicleStatic = vehicles.staticsByID[vid]

            return (
                <MarkerF
                    key = {vid}
                    icon = {{
                        path: window.google && window.google.maps && window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        scale: 5,
                        strokeColor: getVehicleTransitColor(vid),
                        rotation: Number(thisVehicle.heading),
                        fillOpacity: 1,

                        // 24 is sprite width and 28 is sprite height. Make sure to match future anchors accordingly
                        // anchor: new window.google.maps.Point(24 / 2, 28 / 2),
                    }}
                    position = {{
                        lat: parseFloat(thisVehicle.location.lat),
                        lng: parseFloat(thisVehicle.location.lon)
                    }}
                    onClick = {() => {
                        // console.log(device.location);

                        dispatch(add_enabled_vehicle_pop_up(vid));
                        dispatch(set_selected_vehicle_request(vid));
                        dispatch(set_map_control(vid));
                    }}
                >
                    {
                        mapControl.UIControl.showInfoWindowVehicles.includes(vid)
                            && vehicles.selectedID
                            && vehicles.selectedID === vid ?
                            <VehicleInformationBox 
                                thisVehicle={thisVehicle}
                                thisVehicleStatic={thisVehicleStatic}
                                paddingInt={paddingInt}
                            />
                            :
                            mapControl.UIControl.showAllVehiclesInfoWindow &&
                                <OverlayView
                                    mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                                    options = {{
                                        disableAutoPan: true,
                                        maxWidth: 250
                                    }}
                                    position = {{
                                        lat: parseFloat(thisVehicle.location.lat),
                                        lng: parseFloat(thisVehicle.location.lon)
                                    }}
                                    getPixelPositionOffset = {(width, height) => ({
                                        x: -(width / 2),
                                        y: -height - 5,
                                    })}
                                >
                                    <div
                                        style = {{
                                            background: 'white',
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            width: 14 * (thisVehicleStatic.displayName.length || 1),
                                            backgroundColor: returnLatestVehicleGroupColorForVID(vid),
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            paddingTop: 8,
                                            paddingBottom: 1,
                                        }}
                                    >
                                        <h2>{thisVehicleStatic.displayName}</h2>
                                    </div>
                                </OverlayView>
                    }
                </MarkerF>
            )
        }
    }

    return (
        vehicles &&
        vehicles.allIDs.map(vid => {
            const thisVehicle = {...vehicles.stateByID[vid], ...vehicles.derivativeStateByID[vid]};
            return getVehicleMarker(thisVehicle)
        })
    )
}

export default React.memo(VehicleMarkers)