import React, { memo }  from 'react'
import { useSelector } from 'react-redux'
import {
    Timeline,
} from 'antd';

// Constant
import { TEMPLATE } from '../../../../../constants'

// Components
import IndicatorMarker from '../../../components/IndicatorMarker';

// Handler
import { event_handler } from '../../../../../handlers'

// Service
import { TIME, REDUX as REDUX_UTIL } from '../../../../../services/util'

const {
    eventNameTranslator
} = event_handler

const {
    parseTime
} = TIME

const {
    reduxStoreWrapper
} = REDUX_UTIL

const {
    EVENT_TICKET_TYPES_BY_CODES,
    TRIP_RECORD_EVENT_ICON
} = TEMPLATE.STATUS

const EventTrace = () => {

    const vid = useSelector(reduxStoreWrapper(`vehicles.selectedID`))
    const vehicleEventLogs = useSelector(reduxStoreWrapper(`vehicleEventLogs.logByVID.${vid}`)) || []
    const rules = useSelector(reduxStoreWrapper(`rules.byID`))

    const selectedVehicleEventLogs =
        vehicleEventLogs
        .map(eventLog => {
            let rLog = {...eventLog, ...rules[eventLog.eventid]}
            rLog.name = eventNameTranslator(rLog)

            return rLog
        })

    const TimelineItems = (eLog, index) => [
        {
            key: `${vid}#${eLog.eventid}#${eLog.vehicleTime}#${index}#info`,
            dot: (
                <img
                    src = {TRIP_RECORD_EVENT_ICON[EVENT_TICKET_TYPES_BY_CODES[eLog.type_rule]]}
                    alt = ""
                    style = {{
                        width: 16,
                        height: 16,
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                    }}
                />
            ),
            children: (
                <>
                    {parseTime(eLog.vehicleTime)}

                    <h4 style = {{ fontWeight: 'bold' }}>{EVENT_TICKET_TYPES_BY_CODES[eLog.type_rule]}</h4>

                    <div>
                        <p>{eLog.name}</p>
                    </div>
                </>
            )
        }
    ]

    return (
        <>
            {
                selectedVehicleEventLogs
                &&
                selectedVehicleEventLogs
                .map((eLog, index) => (
                    <IndicatorMarker
                        key = {`${vid}#${eLog.eventid}#${eLog.vehicleTime}#${index}`} 
                        location = {eLog.location}
                        markerProps = { {
                            // clusterer: clusterer,
                            icon: TRIP_RECORD_EVENT_ICON[EVENT_TICKET_TYPES_BY_CODES[eLog.type_rule]] 
                        }}
                        infoDefault = {false}
                        InfoComponent = { () => 
                            <>
                                <div style = {{ paddingTop: 10 }}>
                                    <Timeline
                                        items={TimelineItems(eLog, index)}
                                    />
                                </div>
                            </>
                        }
                    />
                ))
            }
        </>
    )
}

export default memo(EventTrace)