import 
    React,
    {
        useState
    }
from "react";

// Constant
import { STYLE, TEMPLATE } from '../../../../constants'

// Components
import { ChartJS } from '../../../../components/Chart'

// Handler
import { hookHDLR, report_handler } from '../../../../handlers'

// Service
import { TIME } from '../../../../services/util'

const {
    CHART_COLORS
} = STYLE

const {
    VEHICLE_TRANSIT_STATUS_COLOR
} = TEMPLATE.STATUS

const {
    useDeepEffect, usePrevious
} = hookHDLR

const {
    DERIVATIVE_DATA_TYPE,
    MILEAGE_TYPE,
    mileageSelector,
    speedSelector
} = report_handler

const {
    parseTimeFNS
} = TIME

const FATimelineChart = (props) => {

    const {
        dataPoints
    } = props

    const [ dateState, setDataState ] = useState({
        labels: [],
        datasets: []
    })

    const [ options, setOptions ] = useState({
        maintainAspectRatio: false,
        scales: {}
    })

    const [ plugins, setPlugins ] = useState([])

    const prevDataPoints = usePrevious(dataPoints)

    useDeepEffect(() => {

        if(
            dataPoints
            &&
            dataPoints.length
            &&
            prevDataPoints != dataPoints
        ) {
            const sortedDPs = dataPoints.sort((a,b) => a.vehicleTime - b.vehicleTime)

            const min_hist_mileage = Number((mileageSelector(sortedDPs[0], MILEAGE_TYPE.ODOMETER)))
            const max_hist_mileage = Number((mileageSelector(sortedDPs[sortedDPs.length-1], MILEAGE_TYPE.ODOMETER)))

            const newDP = sortedDPs.map(vl => {{

                const color = VEHICLE_TRANSIT_STATUS_COLOR[vl.transitStatus]

                return ({
                    x: parseTimeFNS(vl.vehicleTime),
                    yFuel: vl.fuel,
                    ySpd: speedSelector(vl, DERIVATIVE_DATA_TYPE.STATE),
                    yOdo: mileageSelector(vl, MILEAGE_TYPE.ODOMETER),
                    transitStatus: vl.transitStatus,
                    color
                })
            }})

            const newDS = {
                labels: newDP.map((d) => d.x),
                datasets: [
                    {
                        label: 'Fuel (L)',
                        data: newDP.map(d => !isNaN(d.yFuel) && Number(d.yFuel.toFixed(2))),
                        yAxisID: 'yFuel',
                        fill: false,
                        pointRadius: 3,
                        pointHoverRadius: 10,
                        borderColor: newDP.map(d => d.color),
                        pointBorderColor: newDP.map(d => d.color),
                        pointBackgroundColor: newDP.map(d => d.color),
                        borderWidth: 1,
                        tension: 0,
                    },
                    {
                        label: 'Historical Mileage (km)',
                        data: newDP.map(d => !isNaN(d.yOdo) && Number(d.yOdo.toFixed(2))),
                        yAxisID: 'yOdo',
                        fill: false,
                        pointRadius: 3,
                        pointHoverRadius: 10,
                        backgroundColor: CHART_COLORS.FLAT_BLUE,
                        borderColor: CHART_COLORS.FLAT_BLUE,
                        pointBorderColor: CHART_COLORS.FLAT_BLUE,
                        pointBackgroundColor: CHART_COLORS.FLAT_BLUE,
                        borderWidth: 1,
                        tension: 0,
                    },
                    {
                        label: 'Speed (km/h)',
                        data: newDP.map(d => !isNaN(d.ySpd) && Number(d.ySpd.toFixed(2))),
                        yAxisID: 'ySpd',
                        fill: true,
                        pointRadius: 1,
                        pointHoverRadius: 3,
                        backgroundColor: CHART_COLORS.FLAT_TURQUOISE,
                        borderColor: CHART_COLORS.FLAT_TURQUOISE,
                        pointBorderColor: CHART_COLORS.FLAT_TURQUOISE,
                        pointBackgroundColor: CHART_COLORS.FLAT_TURQUOISE,
                        borderWidth: 1,
                        tension: 0,
                    }                    
                ]
            }

            const newOptions = {
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: 'time',
                        time: {
                           unit: 'hour'
                        },
                        display: true
                    },
                    yFuel: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        min: 0
                    },
                    yOdo: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        min: min_hist_mileage,
                        max: max_hist_mileage
                    },
                    ySpd: {
                        display: true,
                        position: 'right',
                        min: 0,
                        suggestedMax: 200,
                    }
                },
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                plugins: {
                    tooltip: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        cornerRadius: 1,
                        callbacks: {
                            afterLabel: (tooltipItem) => {
                                const index = tooltipItem.dataIndex
                                return `Transit Status: ${sortedDPs[index].transitStatus}`
                            }
                        }
                    },
                    hover: {
                        mode: 'nearest',
                        intersect: true
                    },
                    legend: {
                        display: true,
                        fillStyle: CHART_COLORS.FLAT_TURQUOISE
                    },
                },
                async: true
            }

            const newPlugins = []

            setDataState(newDS)
            setOptions(newOptions)
            setPlugins(newPlugins)
        }

    }, [dataPoints])

    return (
        <>
            {
                dateState
                &&
                dateState.datasets
                &&
                <ChartJS
                    type={'line'}
                    data={dateState}
                    options = {options}
                    plugins={plugins}
                    chartHeight={window.innerHeight * 0.5}
                />
            }
        </>
    )
}

const MemorisedFATimelineChart = React.memo(FATimelineChart)

export default MemorisedFATimelineChart