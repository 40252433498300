import * as CONFIG from '../../config'

const fms_v3_fe_path = CONFIG.APP_CONFIG.FMS_V3_FE_API_GATEWAY
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}


export const PLUS_Highway_Surveillance_VideoClip = (section) => {
    return fetch(
        `${fms_v3_fe_path}/surveillance/plus/video/get`, 
        {
            method: 'POST',
            headers,
            body:JSON.stringify({section})
        }
    )
    .then(data => data.blob())
    .catch(err => {
        console.log("Error", err)
    })
}
    