import React from 'react'
import { useSelector } from 'react-redux'

// Constants & Config
import { STYLE } from './constants'

// Components
import BackgroundTask from './components/BackgroundTask'
import NetworkValidator from './components/NetworkValidator'
import MainLayout from './layout/MainLayout'

// Route
import MainRoutes from './services/navigation/route/main'
import SideBarRoutes from './services/navigation/route/sidebar'

// styles
import './App.css';
import './index.css';

const App = (props) => {

    const style = useSelector(state => state.containers.v3.style)

    return(
        <>
            <BackgroundTask />
            <MainLayout>
                <>
                    {
                        <NetworkValidator />
                    }
                    <div className="app-frame">

                        {
                            style && Object.prototype.hasOwnProperty.call(style,'isSidebarCollapsed') && !style.isSidebarCollapsed &&
                            <div
                                className="sidebar card animate-left"
                                id="SideBar"
                                style={{ width: STYLE.SCREENLAYOUT.FULL_SIDEBAR_WIDTH }}
                            >
                                <div className="sidebar-content-container">
                                    <SideBarRoutes />
                                </div>
                            </div>
                        }

                        <div className="app-content">
                            <MainRoutes {...props} />
                        </div>
                    </div>
                </>
            </MainLayout>
        </>
    )
}

export default React.memo(App)