import * as ActionTypes from '../action-types'
import { deepMerge } from '../../util'

const deviceGroups_DefaultState = {
    concatentedDG: {
        devgid: 'ALL',
        name: `ALL`,
        devices: []
    }, 
    relationByUID: {},
    byID: {},
    allIDs: []
}

export const deviceGroups = (state = deviceGroups_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_DEVICE_GROUP: {

            const deviceGroup = action.deviceGroup 
            const uid = action.uid

            const devgid = deviceGroup && deviceGroup.devgid

            if(devgid) {

                if(uid) {
                    if(Object.prototype.hasOwnProperty.call(newState.relationByUID, uid)) {
                        if(!newState.relationByUID[uid].includes(devgid)) newState.relationByUID[uid].push(devgid)
                    } else {
                        newState.relationByUID[uid] = [devgid]
                    }
                }

                if(Object.prototype.hasOwnProperty.call(newState.byID, devgid)) newState.byID[devgid] = deepMerge(newState.byID[devgid], deviceGroup)
                else newState.byID[devgid] = deviceGroup

                if(!newState.allIDs.includes(devgid)) newState.allIDs.push(devgid)
            }

            newState.concatentedDG.devices = newState.allIDs.reduce((acc, curdevgid) => [...acc, ...newState.byID[curdevgid].devices], [])

            return newState
        }
        case ActionTypes.GET_DEVICE_GROUPS: {

            const deviceGroups = action.deviceGroups
            const uid = action.uid

            deviceGroups.map(deviceGroup => {

                const devgid = deviceGroup && deviceGroup.devgid

                if(devgid) {

                    if(uid) {
                        if(Object.prototype.hasOwnProperty.call(newState.relationByUID, uid)) {
                            if(!newState.relationByUID[uid].includes(devgid)) newState.relationByUID[uid].push(devgid)
                        } else {
                            newState.relationByUID[uid] = [devgid]
                        }
                    }

                    if(Object.prototype.hasOwnProperty.call(newState.byID, devgid)) newState.byID[devgid] = deepMerge(newState.byID[devgid], deviceGroup)
                    else newState.byID[devgid] = deviceGroup

                    if(!newState.allIDs.includes(devgid)) newState.allIDs.push(devgid)
                }

                return null
            })

            newState.concatentedDG.devices = newState.allIDs.reduce((acc, curdevgid) => [...acc, ...newState.byID[curdevgid].devices], [])

            return newState
        }
        default: {
            return newState;
        }
    }
}