import * as API from "../../api"
import * as ActionTypes from "../action-types";
import { REDUX } from '../../../constants'

/**
 * ThirdParty Library
 */
 import {
    message
} from 'antd'

// Redux Action

/**
 * Get Vehicles Maintenance State
 */

 export const get_vehicle_maintenance_states_request = vids => dispatch => {

    let vidBatch = []

    vids.map((vid, index) => {

        vidBatch.push(vid)

        if( 
            (index+1) % REDUX.REDUX_VEHICLE_BATCH_GET_SIZE === 0
            ||
            index+1 === vids.length
        ) {
            dispatch(batch_get_vehicle_maintenance_states_request(vidBatch))
            vidBatch = []
        }

        return null
    })
 }

/**
 * Batch Get Vehicle Maintenance State
 */
 export const batch_get_vehicle_maintenance_states_request = vids => dispatch => {

    API
    .getVehicleMaintenanceStateBatch(vids)
    .then(info => {
        
        try {
            const {
                vehicleMaintenanceStates
            } = info

            dispatch(batch_get_vehicle_maintenance_states_success(vehicleMaintenanceStates))


        } catch(err) {
            message.error('Error in batch getting vehicles. Refresh page?')
            console.log('Error', err)
        }

    })
}

const batch_get_vehicle_maintenance_states_success = (vehicleMaintenanceStates) => {
    return {
        type: ActionTypes.GET_VEHICLE_MAINTENANCE_STATES,
        vehicleMaintenanceStates
    };
}

/**
 * Get Vehicles Maintenance Profile
 */

 export const get_vehicle_maintenance_profiles_request = vids => dispatch => {

    let vidBatch = []

    vids.map((vid, index) => {

        vidBatch.push(vid)

        if( 
            (index+1) % REDUX.REDUX_VEHICLE_BATCH_GET_SIZE === 0
            ||
            index+1 === vids.length
        ) {
            dispatch(batch_get_vehicle_maintenance_profiles_request(vidBatch))
            vidBatch = []
        }

        return null
    })
 }

/**
 * Batch Get Vehicle Maintenance Profile
 */
 export const batch_get_vehicle_maintenance_profiles_request = vids => dispatch => {

    API
    .getVehicleMaintenanceProfileBatch(vids)
    .then(info => {
        
        try {
            const {
                vehicleMaintenanceProfiles
            } = info

            dispatch(batch_get_vehicle_maintenance_profiles_success(vehicleMaintenanceProfiles))


        } catch(err) {
            message.error('Error in batch getting vehicles. Refresh page?')
            console.log('Error', err)
        }

    })
}

const batch_get_vehicle_maintenance_profiles_success = (vehicleMaintenanceProfiles) => {
    return {
        type: ActionTypes.GET_VEHICLE_MAINTENANCE_PROFILES,
        vehicleMaintenanceProfiles
    };
}

/**
 * CU Vehicle Maintenance Profile
 */

 export const cu_vehicle_maintenance_profile_request = (item) => dispatch => {

    const { actionAPI } = item
    
    API
    .CU_VehicleMaintenanceProfile(item)
    .then(info => {

        try {

            const {
                status,
                vehicleMaintenanceProfile,
                msg
            } = info
    
            switch(status) {
                case 200: {
    
                    dispatch(cu_vehicle_maintenance_profile_success(vehicleMaintenanceProfile))
    
                    break;
                }
                case 500: {
                    throw msg
                }
                default: {
                    break;
                }
            }

        } catch(err) {
            console.log("Error", err)
            message.error(`Error in ${REDUX.REDUX_CRUD_API_ACTION_THRU_CODE[actionAPI]} maintenance profile. Refresh page?`)
        }

    })

}

const cu_vehicle_maintenance_profile_success = (vehicleMaintenanceProfile) => {
    return {
        type: ActionTypes.CU_VEHICLE_MAINTENANCE_PROFILE,
        vehicleMaintenanceProfile
    };
}