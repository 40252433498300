import 
    React,
    {
        useState,
        useEffect,
        useRef
    }
from "react";
import { 
    useDispatch, 
    useSelector
} from "react-redux";
import { 
    TreeSelect 
} from "antd";

// Redux Actions
import { 
    v3_geofences,
    v3_mapControl,
    v3_vehicles
} from "../../../../services/redux/actions";

const {
    set_map_control
} = v3_mapControl

const {
    set_selected_geofence_request,
    clear_selected_geofence_request
} = v3_geofences

const {
    clear_selected_vehicle_request
} = v3_vehicles

const { TreeNode } = TreeSelect;

const MapSearchBar = () => {

    const dispatch = useDispatch();

    const geofences = useSelector(state => state.containers.v3.geofences)
    const geofenceTemplates = useSelector(state => state.containers.v3.geofenceTemplates)

    const [ dataSourceObj, setDataSourceObj] = useState({})

    const seperatorKey = " - ";

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }

    const prevGeofences = usePrevious(geofences);        
    const prevGeofenceTemplates = usePrevious(geofenceTemplates);        

    const refreshDataSrc = (searchText = "") => {

        let newDataSourceObj = {};

        const gtidList = Object.keys(geofenceTemplates.byID).sort((a, b) => {
            const vA = geofenceTemplates.byID[a].name.toLowerCase();
            const vB = geofenceTemplates.byID[b].name.toLowerCase();
            
            if (vA === "ungroup") {
                return -1;
            }
            else if (vB === "ungroup") {
                return 1;
            }
            else {
                if (vA < vB) return -1;
                if (vA > vB) return 1;
            }
      
            return 0;
        });

        gtidList.forEach((currGTID) => {
            const currGTGeofences = geofenceTemplates.byID[currGTID].geofences.filter((currGeoID) => Object.keys(geofences.byID).includes(currGeoID));

            if (currGTGeofences.length > 0) {
                // console.log("Current GT Geofences:", currGTGeofences);

                // Sort GeoID list first
                currGTGeofences.sort((a, b) => {
                    const vA = geofences.byID[a].name.toLowerCase();
                    const vB = geofences.byID[b].name.toLowerCase();
              
                    if (vA < vB) return -1;
                    if (vA > vB) return 1;
                    return 0;
                })

                // Set up empty array if new GTID
                if (!Object.keys(newDataSourceObj).includes(currGTID)) {
                    newDataSourceObj[currGTID] = [];
                }

                currGTGeofences.forEach((currGeoID) => {
                    const currGeofence = geofences.byID[currGeoID];
    
                    // console.log("Current Geofence Name:", currGeofence.name);
    
                    if (currGeofence.name.toLowerCase().includes(searchText.toLowerCase())) {
                        newDataSourceObj[currGTID].push(currGeoID);
                    }
                })
            }
            return 0;
        });

        setDataSourceObj(newDataSourceObj)
    }

    useEffect(() => {
        if (
            (prevGeofences !== geofences || prevGeofenceTemplates !== geofenceTemplates) 
            && Object.keys(geofences.byID).length > 0
            && Object.keys(geofenceTemplates.byID).length > 0
        ) {
            refreshDataSrc()
        }
    })
    
    useEffect(() => {
        /**
         * Unmount
         */
        return () => {
            dispatch(clear_selected_geofence_request())
        }
    }, [dispatch])

    return (
        <TreeSelect
            showSearch
            filterTreeNode = {false} // Needs this or search works funny
            placeholder = "Search Geofences"
            treeDefaultExpandedKeys = {[geofenceTemplates.concatentedGT.geotid]}
            dropdownStyle = {{
                maxHeight: 400, 
                overflow: 'auto', 
            }}
            onSearch = {(searchText) => {
                refreshDataSrc(searchText)
            }}
            onSelect = {(value) => {
                dispatch(clear_selected_vehicle_request());
                dispatch(set_selected_geofence_request(value.split(seperatorKey)[1]));
                dispatch(set_map_control(1));
            }}
            style = {{ width: "100%" }}
        >
            {
                Object.keys(dataSourceObj).map((currGTID) => {

                    if (dataSourceObj[currGTID].length > 0) {
                        return (
                            <TreeNode 
                                key = {`${currGTID}`} 
                                value = {`${currGTID}`}
                                selectable = {false}
                                title = {geofenceTemplates.byID[currGTID] ? `${geofenceTemplates.byID[currGTID].name} (${dataSourceObj[currGTID].length})` : ""}
                            >
                                {
                                    dataSourceObj[currGTID].map((currGeoID) => {
                                        return(
                                            <TreeNode 
                                                key = {`${currGTID}${seperatorKey}${currGeoID}`} 
                                                value = {`${currGTID}${seperatorKey}${currGeoID}`}
                                                title = {geofences.byID[currGeoID] && geofences.byID[currGeoID].name} 
                                            />
                                        );
                                    })
                                }
                            </TreeNode>
                        );
                    }

                    return 0;
                })
            }
        </TreeSelect>
    )
}

export default React.memo(MapSearchBar);