import { useRef, useEffect } from 'react' 

export const reduxStoreWrapper = (keys) => (state) => {

    let returnState = state.containers.v3

    const keyArr = keys.split('.')

    keyArr.map(k => {
        returnState = returnState && returnState[k] || ((Object.prototype.hasOwnProperty.call(returnState, k)) ? returnState[k] : null)
        return 1
    })
        
    return returnState
}

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}

export const get_items_request = (items, batch_get_items_request, { SIZE, others }) => dispatch => {

    let itemBatch = []

    items.map((item, index) => {

        itemBatch.push(item)

        if( 
            (index+1) % SIZE === 0
            ||
            index+1 === items.length
        ) {
            dispatch(batch_get_items_request(itemBatch, others))
            itemBatch = []
        }

        return null
    })
}