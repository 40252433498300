import 
    React,
    {
        useEffect
    }
from "react";
import { 
    useDispatch,
    useSelector
} from 'react-redux'
import { useNavigate } from 'react-router'

/**
 * Sub Components
 */
import SSOAdviceDescription from './components/SSOAdviceDescription'

/**
 * Config
 */
import * as CONFIG from '../../config'

/**
 * Constant
 */
import { ROOT_CONSTANT } from '../../constants'

/**
 * Services
 */

/**
 * Redux Actions
 */
import * as ACTIONS from '../../services/redux/actions';


const SSOAdvice = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const auth = useSelector(state => state.root.auth)

    const adviceClickHandler = () => {
        dispatch(ACTIONS.main_user.auth_user_sign_out())
    };

    useEffect(() => {
        auth.currentUser && navigate('/')

        return (() => {})
    }, [auth, dispatch])
    
    return (
        <div
            style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                flex: 1,
                alignItems: "center"
            }}
        >
            <div
                style={{
                    position: "absolute",
                    height: "100vh",
                    width: "100%",
                    backgroundImage:`url(${ROOT_CONSTANT.THEME.LOGIN_BACKGROUND})`,
                    backgroundSize: "cover"
                }}
            />

            <div
                style={{
                    zIndex: 1,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            padding: "35px 30px 15px 30px",
                            borderRadius: "17px"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}
                        >
                            <img
                                src={ROOT_CONSTANT.THEME.LOGO}
                                alt={CONFIG.APP_INFO.PUBLICATION_COMPANY}
                                style={{ height: "80px" }}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}
                        >
                            <SSOAdviceDescription
                                {...props}
                                onClick={adviceClickHandler}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    backgroundColor: 'black',
                    borderRadius: "17px",
                    paddingRight: '20px',
                    paddingLeft: '20px',
                    color: 'white',
                    position: 'absolute',
                    bottom: '2vh',
                    right: '5vw',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    zIndex: 1
                }}
            >
                 {CONFIG.APP_INFO.PUBLICATION_COMPANY} - {CONFIG.APP_INFO.PROJECT_NAME} - {CONFIG.APP_INFO.APP_VERSION}
            </div>
        </div>
    );
}

export default React.memo(SSOAdvice)