import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from 'antd'

// Redux Action
import { 
    setLoadingFuelAnalysisDashboard, 
    unsetLoadingFuelAnalysisDashboard 
} from './style'

export const get_vehicle_report_daily_summary_by_timeframe_request = (vid, startTime, endTime) => dispatch => {

    return API
    .getVehicleReportDailySummaryTimeFrameSync(vid, startTime, endTime)
    .then(info => {
        
        try {
            const {
                reportVehicleDailySummary
            } = info

            reportVehicleDailySummary && dispatch(get_vehicle_report_daily_summary_by_timeframe_success(vid, reportVehicleDailySummary))


        } catch(err) {
            message.error('Error in getting timeframe vehicle daily summary report. Refresh page?')
        }
    })
    
}

const get_vehicle_report_daily_summary_by_timeframe_success = (vid, reportVehicleDailySummary) => {
    return {
        type: ActionTypes.GET_VEHICLE_DAILY_SUMMARY_REPORTS,
        vid,
        reportVehicleDailySummary
    }
}

export const get_vehicle_report_fuel_analysis_by_timeframe_request = (vid, startTime, endTime) => dispatch => {

    dispatch(setLoadingFuelAnalysisDashboard())

    return API
    .getVehicleReportFuelAnalysisTimeFrameSync(vid, startTime, endTime)
    .then(info => {
        
        try {
            const {
                report
            } = info

            report && dispatch(get_vehicle_report_fuel_analysis_by_timeframe_success(vid, report))


        } catch(err) {
            message.error('Error in getting timeframe vehicle daily summary report. Refresh page?')
        }
    })
    .finally(() => {
        dispatch(unsetLoadingFuelAnalysisDashboard())
    })
    
}

const get_vehicle_report_fuel_analysis_by_timeframe_success = (vid, report) => {
    return {
        type: ActionTypes.GET_VEHICLE_FUEL_ANALYSIS_REPORTS,
        vid,
        report
    }
}