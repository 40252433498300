import * as XLSX from "xlsx";

export const exportFile = (srcData, sheetName, sheetFileName) => {
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(srcData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `${sheetFileName}.xlsx`);
}

const colRender = (c, d) => Object.prototype.hasOwnProperty.call(c, 'render') && c.render(d) || d

export const exportReportFile = (srcData, srcCol, sheetName, sheetFileName) => {

    const titleCOLXLSX = srcCol.map(c => c.title)
    const dataCOLXSX = srcData.map(d => {
        return srcCol.map(c => {
            if(Object.prototype.hasOwnProperty.call(c, 'dataIndex')) {
                return colRender(c, d[c.dataIndex])
            } else {
                return colRender(c, d)
            }
        })
    })

    const srcXLSX = [ titleCOLXLSX, ...dataCOLXSX]

    exportFile(srcXLSX, sheetName, sheetFileName)
}