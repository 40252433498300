import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { 
    Tabs,
    Tooltip,
    Button,
    Space
} from 'antd';
import {
    PlusOutlined
} from '@ant-design/icons'

// Constant
import { TEMPLATE, REDUX as REDUX_CONSTANT } from '../../../../constants'

// Services
import { REDUX } from '../../../../services/util'

// Components
import MaintenanceComponentPanel from './components/MaintenanceComponentPanel'
import MaintenanceServicePanel from './components/MaintenanceServicePanel'
import Maintenance_CS_Modal from './components/Maintenance_CS_Modal'

// Routes

// Redux Action
import * as REDUX_ACTION from '../../../../services/redux/actions'

// Styling
import './index.css'

const { MAINTENANCE } = TEMPLATE
const { MODAL_CS_PROCESS_ACTION, MODAL_CS_PROCESS_SELECTION } = MAINTENANCE

const MaintenanceConfigurationManagement = () => {

    const dispatch = useDispatch()

    const maintenanceTemplates = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.templateByID'))
    const maintenanceTemplate_Default = maintenanceTemplates && Object.values(maintenanceTemplates).find(v => v.default)
    const maintid_Default = maintenanceTemplate_Default && maintenanceTemplate_Default.maintid

    const [ Maintenance_CS_ModalState , setMaintenance_CS_ModalState ] = useState(false)
    const [ keyID, setKeyID ] = useState("")
    const [ type, setType ] = useState("")
    const [ action, setAction ] = useState(MODAL_CS_PROCESS_ACTION.CREATE)

    const Maintenance_CS_Click = () => {
        setAction(MODAL_CS_PROCESS_ACTION.CREATE)
        setMaintenance_CS_ModalState(true)
    }

    const Maintenance_CS_Close = () => {
        setMaintenance_CS_ModalState(false)
    }

    const MaintenanceService_Edit_Click = (mainsid) => {
        setKeyID(mainsid)
        setAction(MODAL_CS_PROCESS_ACTION.EDIT)
        setType(MODAL_CS_PROCESS_SELECTION.SERVICE)
        setMaintenance_CS_ModalState(true)
    }

    const MaintenanceComponent_Edit_Click = (maincid) => {
        setKeyID(maincid)
        setAction(MODAL_CS_PROCESS_ACTION.EDIT)
        setType(MODAL_CS_PROCESS_SELECTION.COMPONENT)
        setMaintenance_CS_ModalState(true)
    }

    const MaintenanceService_Enabler = (mainsid, activeStatus) => {

        dispatch(REDUX_ACTION.v3_maintenanceConfigs.cu_maintenance_service_request({
            actionAPI: REDUX_CONSTANT.REDUX_CRUD_API_ACTION.UPDATE,
            mainsid,
            maintid: maintid_Default,
            activeStatus
        }))
    }

    return (
        <>
            <>
                <Space size="middle" style={{float: "right"}}>
                    <Tooltip title={'Add Maintenance Component/Service'}>
                        <Button 
                            shape='circle' 
                            icon={<PlusOutlined />}
                            type="primary"
                            onClick={() => Maintenance_CS_Click()}
                        />
                    </Tooltip>
                </Space>
                <Maintenance_CS_Modal
                    keyID={keyID}
                    maintid={maintid_Default}
                    type={type}
                    action={action}
                    open={Maintenance_CS_ModalState}
                    onClose={() => Maintenance_CS_Close()}
                />
                <Tabs 
                    defaultActiveKey="0" 
                    centered
                    items={[
                        {
                            key: '0',
                            label: 'Maintenance Service',
                            children: (
                                <>
                                    <MaintenanceServicePanel 
                                        onChangeMSActiveStatus={(mainsid, activeStatus) => MaintenanceService_Enabler(mainsid, activeStatus)}
                                        onEditMaintenanceServiceForModal={(mainsid) => MaintenanceService_Edit_Click(mainsid)}
                                    />
                                </>
                            )
                        },
                        {
                            key: '1',
                            label: 'Maintenance Component',
                            children: (
                                <>
                                    <MaintenanceComponentPanel 
                                        onEditMaintenanceComponentForModal={(maincid) => MaintenanceComponent_Edit_Click(maincid)}
                                    />
                                </>
                            )
                        }
                    ]}
                />
            </>
        </>
    )
}

export default React.memo(MaintenanceConfigurationManagement)