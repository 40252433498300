import { TEMPLATE } from '../../constants'
import { mileageSelector, MILEAGE_TYPE } from '../report'
import { TIME, RoundUp } from '../../services/util'

const { 
    VAM_DUE_PARAM, 
    VAM_DUE_PARAM_REFLECT, 
    VAM_REMINDER_STATUS_CODE_BY_STATUS,
    VAM_DUE_PARAM_DUE_STATUS,
    TIME_UNIT
} = TEMPLATE.VAM

const {
    durationDiffFormat,
    parseDate,
    parseDurationInUnit,
    parseStartOfDate,
    parseHours
} = TIME

const dueRemainingCal = (val, param) => {

    let str = '-'

    switch(param) {
        case VAM_DUE_PARAM.time: {
            str = durationDiffFormat(val)
            break
        }
        case VAM_DUE_PARAM.odometer: {
            str = `${val} km`
            break
        }
        case VAM_DUE_PARAM.workingHour: {
            str = `${val} hrs`
            break
        }
        default: {
            break
        }
    }

    return str
}

export const nextDueDetermine = (data) => {

    const status = []

    Object.keys(VAM_DUE_PARAM_REFLECT).map(param => {
        if(Object.prototype.hasOwnProperty.call(data, `${param}_next`) && data[`${param}_next`]) {
            let s = {}
            const target = data[`${param}_next`]
            const thres = data[`${param}_dueSoon_thres`]
            const curVal = data[VAM_DUE_PARAM_REFLECT[param]]

            const rem = target - curVal
            const diffRem = Math.abs(rem)

            s.param = param

            switch(param) {
                case VAM_DUE_PARAM.time: {
                    s.due = parseDate(target)
                    break
                }
                case VAM_DUE_PARAM.odometer: {
                    s.due = `${target} km`
                    break
                }
                case VAM_DUE_PARAM.workingHour: {
                    s.due = `${target} hrs`
                    break
                }
                default: {
                    break
                }
            }

            if(rem < 0) {
                s.status = VAM_REMINDER_STATUS_CODE_BY_STATUS.OVERDUE
                s.remaining = `${dueRemainingCal(diffRem, param)} ${VAM_DUE_PARAM_DUE_STATUS[param].OVERDUE}`
            } else if ( rem <= thres ) {
                s.status = VAM_REMINDER_STATUS_CODE_BY_STATUS.DUE_SOON
                s.remaining = `${dueRemainingCal(diffRem, param)} ${VAM_DUE_PARAM_DUE_STATUS[param].DUE_SOON}`
            } else {
                s.status = VAM_REMINDER_STATUS_CODE_BY_STATUS.UPCOMING
                s.remaining = `${dueRemainingCal(diffRem, param)} ${VAM_DUE_PARAM_DUE_STATUS[param].UPCOMING}`
            }

            status.push(s)
        }
    })    

    return status
}

export const parseTimeAsSelectorUnit = (time) => {

    const unitQ = parseDurationInUnit(time) 
    
    const decimalDeterminor = (n) => (n - Math. floor(n)) !== 0

    const du = Object.keys(unitQ).find(q => !decimalDeterminor(unitQ[q]))

    return ({
        val: du && unitQ[du],
        unit: du && TIME_UNIT[du][0]
    })
}

export const parseTimeUnit2Timestamp = (timeUnit) => {
    const { val, unit = TIME_UNIT.day[1] } = timeUnit

    return val*TIME_UNIT[unit][2]
}

export const nextDuePreviewConvertor = {
    time: [ 'Time Due', parseDate, '', (d) => parseStartOfDate(d.updatedAt)  ],
    odometer: [ 'Odometer Due', (a) => a, 'km', (d) => mileageSelector(d, MILEAGE_TYPE.ODOMETER, 1, false) ],
    workingHour: [ 'Working Hour Due', (a) => RoundUp(parseHours(a), 0), 'hrs', (d) => d.plat_hist_engine_hour ]
}