import React from 'react'

// Constants 
import { TEMPLATE } from '../../../../../../../constants'

// Component
import GeoLocation from '../../../../../../GeoLocation'

// Service
import { TIME, RoundUp } from '../../../../../../../services/util'

const {
    EVENT_TICKET_TYPES_BY_CODES,
    EVENT_TICKET_TYPES_OBJECT,
    TRIP_RECORD_EVENT_ICON,
    VEHICLE_TRANSIT_STATUS_OBJECT,
    VEHICLE_TRANSIT_STATUS_COLOR
} = TEMPLATE.STATUS

const {
    parseDuration,
    parseTime
} = TIME

export const PointType = {
    TRANSIT_RECORD: {
        label: 'TRANSIT_RECORD',
        components: {
            timeline: {
                customProps: (p) => ({
                    color: VEHICLE_TRANSIT_STATUS_COLOR[p.transitStatus]
                }),
                wrappedItem: (p) => (
                    <>
                        <div
                            style = {{
                                display: `flex`,
                                flexDirection: `row`,
                                justifyContent: `space-between`,
                            }}
                        >
                            <h4 style = {{ fontWeight: 'bold' }}>
                                {p.transitStatus}
                            </h4>

                            {parseTime(p.startTime)}
                        </div>

                        <>
                            <h4>
                                {p.duration && parseDuration(p.duration)}
                            </h4>

                            <h4>
                                {p.transitStatus === VEHICLE_TRANSIT_STATUS_OBJECT.MOVING && `${(p.totalMileage && RoundUp(p.totalMileage, 3) || 0)} km`}
                            </h4>

                            <h4>
                                {
                                    (
                                        p.start_geocoded
                                        &&
                                        `Address: ${p.start_addr || "Unknown address"}`
                                    )
                                    ||
                                    (
                                        <GeoLocation location={p.start_loc} />
                                    )
                                }
                            </h4>
                        </>
                    </>
                )
            }
        }
    },
    EVENT_LOG: {
        label: 'EVENT_LOG',
        components: {
            timeline: {
                customProps: (p) => ({
                    dot: <img
                        src = {TRIP_RECORD_EVENT_ICON[EVENT_TICKET_TYPES_BY_CODES[p.type_rule]] || TRIP_RECORD_EVENT_ICON[EVENT_TICKET_TYPES_OBJECT.INFO]}
                        alt = ""
                        style = {{
                            width: 16,
                            height: 16,
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                        }}
                    />
                }),
                wrappedItem: (p) => (
                    <>
                        <div
                            style = {{
                                display: `flex`,
                                flexDirection: `row`,
                                justifyContent: `space-between`,
                            }}
                        >
                            <h4 style = {{ fontWeight: 'bold' }}>
                                {EVENT_TICKET_TYPES_BY_CODES[p.type_rule]}
                            </h4>

                            {parseTime(p.vehicleTime)}
                        </div>

                        <>
                            <h4>
                                {p.name}
                            </h4>
                        </>
                    </>
                )
            }
        }
    }
}

export const PointTimeline = (props) => {

    const {
        onTimelineItemClick,
        point,
        selectedKey
    } = props

    return (
        {
            key: point.key,
            style: {
                cursor: `pointer`,
                padding: 10,
                boxShadow: selectedKey === point.key && `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`
            },
            ...( (PointType[point.type].components.timeline.customProps(point)) || {}),
            onClick: () => onTimelineItemClick(point.key, point.type),
            children: PointType[point.type].components.timeline.wrappedItem(point)
        }
    )
}