import * as ActionTypes from '../action-types'

/**
 * Constant
 */


const vamLine_DefaultState = {
    itemByID: {},
    templateByID: {},
    allVAMLTIDs: [],
    allVAMLIDs: [],
    selectedVAMLID: ''
}

export const vamLines = (state = vamLine_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {

        case ActionTypes.GET_VAM_LINE_TEMPLATES: {

            const { vamLineTemplates } = action

            vamLineTemplates
            &&
            vamLineTemplates.map(vamLineTemplate => {
                const { vamltid } = vamLineTemplate

                newState.templateByID[vamltid] = vamLineTemplate

                if(!newState.allVAMLTIDs.includes(vamltid)) newState.allVAMLTIDs.push(vamltid)
            })

            return newState
        }

        case ActionTypes.GET_VAM_LINE_TEMPLATE: {

            const { vamLineTemplate } = action

            const { vamltid } = vamLineTemplate

            newState.templateByID[vamltid] = vamLineTemplate

            if(!newState.allVAMLTIDs.includes(vamltid)) newState.allVAMLTIDs.push(vamltid)

            return newState
        }

        case ActionTypes.GET_VAM_LINE_ITEMS: {

            const { vamLineItems } = action

            vamLineItems
            &&
            vamLineItems.map(vamLineItem => {
                const { vamlid } = vamLineItem 

                newState.itemByID[vamlid] = { ...(newState.itemByID[vamlid] || {}), ...vamLineItem }

                if(!newState.allVAMLIDs.includes(vamlid)) newState.allVAMLIDs.push(vamlid)
            })

            return newState
        }

        case ActionTypes.GET_VAM_LINE_ITEM:
        case ActionTypes.CU_VAM_LINE_ITEM: {

            const { vamLineItem } = action

            const { vamlid } = vamLineItem

            newState.itemByID[vamlid] = { ...(newState.itemByID[vamlid] || {}), ...vamLineItem }

            if(!newState.allVAMLIDs.includes(vamlid)) newState.allVAMLIDs.push(vamlid)

            return newState
        }

        case ActionTypes.SET_VAM_LINE_VAMLID: {
            newState.selectedVAMLID = action?.vamlid

            return newState
        }

        case ActionTypes.UNSET_VAM_LINE_VAMLID: {
            newState.selectedVAMLID = ''

            return newState
        }
        
        
        default: {
            return newState;
        }
    }
}