import React from "react";
import { Button } from "antd";

const PrimaryButton = props => {
  const { color, children, onClick, ...otherProps } = props;

  let reduxlessProps = {...otherProps};
  delete reduxlessProps.dispatch

  return (
    <Button
      {...reduxlessProps}
      type="primary"
      onClick={onClick}
      style={{
        ...reduxlessProps.style,
        backgroundColor: !otherProps.disabled && color,
        borderColor: !otherProps.disabled && color
      }}
    >
      {children}
    </Button>
  );
};

export default (PrimaryButton);
