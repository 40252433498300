/**
 * App Info
 */
 export const APP_INFO = {
    APP_VERSION:  process.env.REACT_APP_PROJECT_VERSION,
    APP_MODE: process.env.REACT_APP_PROJECT_MODE,
    APP_MAINTENANCE_MODE: process.env.REACT_APP_PROJECT_MAINTENANCE_MODE,
    APP_PLATFORM: process.env.REACT_APP_PLATFORM,
    COPYRIGHT_YEAR: process.env.REACT_APP_COPYRIGHT_YEAR,
    PROJECT_NAME: process.env.REACT_APP_PROJECT_NAME,
    PUBLICATION_COMPANY: process.env.REACT_APP_PUBLICATION_COMPANY
}

const AWS_AMPLIFY_EXTRA = JSON.parse(process.env.REACT_APP_AWS_AMPLIFY_CONFIG_EXTRA)

/**
 * App Config
 */
export const APP_CONFIG = {
    EXT_IFRAME: JSON.parse(process.env.REACT_APP_EXT_IFRAME),
    AWS_AMPLIFY: JSON.parse(process.env.REACT_APP_AWS_AMPLIFY_CONFIG), 
    AWS_AMPLIFY_EXTRA: AWS_AMPLIFY_EXTRA,
    FIREBASE: JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG), 
    AUTHENTICATION_PROVIDER: process.env.REACT_APP_AUTHENTICATION_PROVIDER,
    APP_GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API,
    FMS_V3_SSO_API_GATEWAY: process.env.REACT_APP_FMS_V3_SSO_API_GATEWAY,
    FMS_V3_FE_API_GATEWAY: process.env.REACT_APP_FMS_V3_FE_API_GATEWAY,
    FMS_V3_FE_WS_GATEWAY: process.env.REACT_APP_FMS_V3_FE_WS_GATEWAY,
    FMS_V3_FE_WS_GATEWAY_RECONNECTION_INTERVAL: process.env.REACT_APP_FMS_V3_FE_WS_GATEWAY_RECONNECTION_INTERVAL
}