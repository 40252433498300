import * as ActionTypes from "../action-types";

export const increase_vehicle_report_api_usage_request = () => dispatch => {
    dispatch(increase_vehicle_report_api_usage_success())
}

const increase_vehicle_report_api_usage_success = () => {
    return {
        type: ActionTypes.API_USAGE_FOR_VEHICLE_REPORT_INCREMENT
    }
}