import 
    React,
    {
        useEffect
    }
from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux'
import {
    MarkerF,
    OverlayView,
    MarkerClustererF,
} from '@react-google-maps/api';

// Constans

// Handler

// Service
 
// Redux Actions
import {
    v3_mapControl
} from '../../../../services/redux/actions'

// Styles
import "../../GoogleMaps/gmaps.css";

const {
    set_map_control
} = v3_mapControl

const SUVPOIs = (props) => {

    const { 
        SUVs,
        OnClick,
        mapRef
    } = props;

    const dispatch = useDispatch()

    // State

    // Redux Stores

    const mapControl = useSelector(state => state.containers.v3.mapControl)

    useEffect(() => {
        if (
            mapControl.UIControl
            &&
            mapControl.UIControl.autoFocusHighwaySUV
            && 
            mapControl.mapControl !== "SUV"
        ) {
            if (mapRef) {
                const bounds = new window.google.maps.LatLngBounds()

                SUVs
                &&
                SUVs.length
                &&
                SUVs.map(SUV => {
                    const c = {
                        lat: SUV.location.lat,
                        lng: SUV.location.lon
                    }
                    bounds.extend(c)
                    return null;
                })

                mapRef.fitBounds(bounds)

                dispatch(set_map_control("SUV"))
            }
        }
    })

    const getSUVMarker = (filteredSUVs) => {

        return (
            <MarkerClustererF
                options={{
                    getMinimumClusterSize: () => {
                        return 3
                    }
                }}
            >
                {
                    clusterer => {
                        return (
                            filteredSUVs
                            .map(SUV => {
                                const label = SUV.label
                                const name = SUV.label
                                const location = {
                                    lat: SUV.location.lat,
                                    lng: SUV.location.lon
                                }

                                return  (
                                    <div key={label}>

                                        <MarkerF
                                            key={label}
                                            title={name}
                                            position={location}
                                            onClick={() => OnClick(label)}
                                            clusterer={clusterer}
                                        />

                                        {
                                            mapControl.UIControl.showHighwayInfoSUV
                                            &&
                                            <OverlayView
                                                options={{ disableAutoPan: true }}
                                                position={location}
                                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                getPixelPositionOffset={(width, height) => ({
                                                    x: -(width / 2),
                                                    y: -height - 45,
                                                })}
                                            >
                                                <div
                                                    style={{
                                                        background: 'white',

                                                        border: '1px solid #ccc',
                                                        borderRadius: '10px',

                                                        paddingLeft: 10,
                                                        paddingRight: 10,
                                                        paddingTop: 8,
                                                        paddingBottom: 1,
                                                    }}
                                                >
                                                    <h4>{name}</h4>
                                                </div>
                                            </OverlayView>
                                        }
                                    </div>
                                )
                            })
                        )
                    }
                }
            </MarkerClustererF>
        )
    }

    const filteredSUVs = SUVs && SUVs.length && mapControl.UIControl.showHighwaySUV && SUVs || []

    return (
        getSUVMarker(filteredSUVs)
    )
}

export default React.memo(SUVPOIs)