import * as ActionTypes from '../action-types'
import { deepMerge } from '../../util'

const devices_DefaultState = {
    staticsByID: {},
    stateByID: {},
    allIDs: []
}

export const devices = (state = devices_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_DEVICE: {

            const deviceStatics = action.deviceStatics
            const deviceState = action.deviceState

            const dvid =  (deviceStatics && deviceStatics.dvid) || (deviceState && deviceState.dvid)

            if(dvid) {

                if(Object.prototype.hasOwnProperty.call(newState.stateByID, dvid)) newState.stateByID[dvid] = deepMerge(newState.stateByID[dvid], deviceState)
                else newState.stateByID[dvid] = deviceState

                if(Object.prototype.hasOwnProperty.call(newState.staticsByID, dvid)) newState.staticsByID[dvid] = deepMerge(newState.staticsByID[dvid], deviceStatics)
                else newState.staticsByID[dvid] = deviceStatics

                if(!newState.allIDs.includes(dvid)) newState.allIDs.push(dvid)

            }

            return newState
        }
        case ActionTypes.GET_DEVICES: {

            const deviceStatics = action.deviceStatics
            const deviceStates = action.deviceStates

            deviceStatics && deviceStatics.length && deviceStatics.map(vs => {

                const dvid = vs && vs.dvid

                if(dvid) {
                    if(Object.prototype.hasOwnProperty.call(newState.staticsByID, dvid)) newState.staticsByID[dvid] = deepMerge(newState.staticsByID[dvid], vs)
                    else newState.staticsByID[dvid] = vs

                    if(!newState.allIDs.includes(dvid)) newState.allIDs.push(dvid)
                }

                return null
            })

            deviceStates && deviceStates.length && deviceStates.map(vs => {

                const dvid = vs && vs.dvid

                if(dvid) {
                    if(Object.prototype.hasOwnProperty.call(newState.stateByID, dvid)) newState.stateByID[dvid] = deepMerge(newState.stateByID[dvid], vs)
                    else newState.stateByID[dvid] = vs
    
                    if(!newState.allIDs.includes(dvid)) newState.allIDs.push(dvid)
                }

                return null
            })

            return newState
        }
        default: {
            return newState;
        }
    }
}