import React from 'react'
import { useSelector } from 'react-redux'
import {
    Card
} from "antd";

// Components
import UserRoleTag from '../../../../../../components/UserRoleTag'

// Services
import { REDUX } from '../../../../../../services/util'

const {
    reduxStoreWrapper
} = REDUX

const UserInfoPanel = ({uid}) => {

    const users = useSelector(reduxStoreWrapper('userHierarchy.byID'))

    const style = useSelector(reduxStoreWrapper('style'))

    const rows = [
        [
          {
            title: "Name",
            value: (uid && users && users[uid] && users[uid].name) || ''
          },
          {
            title: "Email",
            value: (uid && users && users[uid] && users[uid].email) || ''
          }
        ],
        [
          {
            title: "Contact",
            value: (uid && users && users[uid] && users[uid].contact) || ''
          },
          {
            title: "Address",
            value: (uid && users && users[uid] && users[uid].address) || ''
          }
        ],
        [
          {
            title: "Role",
            value: users && users[uid] && <UserRoleTag uroleid={users[uid].uroleid}/>
          },
          {
            title: "",
            value: ""
          }
        ]
    ]

    return (
        <>
            <Card
                title = {
                    <span
                        style = {{
                            display: "inline-table",
                            width: "100%",
                            height: "56px",

                            position: "absolute",
                            top: 0,
                            left: 0,

                            color: style.textColor,
                            backgroundColor: style.pageHeaderColor,
                        }}
                    >
                    <p
                        style = {{
                        fontWeight: "bold",
                        fontSize: "24px",
                        margin: "10px"
                        }}
                    >
                        {uid && users && users[uid] && users[uid].name || "User Name"}
                    </p>
                    </span>
                }
                style = {{
                    margin: "5% 10%"
                }}
            >
                <div style = {{ padding: "10px 15px 10px 15px" }}>
                    {
                    rows.map(row => (
                        <div
                        key = {row[0].title}
                        style = {{
                            display: "flex",
                            flexDirection: "row",
                            margin: "20px"
                        }}
                        >
                        <div style = {{ width: "15%", fontWeight: "bold" }}>
                            {row[0].title}
                        </div>

                        <div style = {{ width: "30%", paddingLeft: "5px" }}>
                            {row[0].value}
                        </div>

                        <div style = {{ width: "10%" }} />

                        <div style = {{ width: "15%", fontWeight: "bold" }}>
                            {row[1].title}
                        </div>

                        <div style = {{ width: "30%", paddingLeft: "5px" }}>
                            {row[1].value}
                        </div>
                        </div>
                    ))
                    }
                </div>
            </Card>
        </>
    )
}

export default React.memo(UserInfoPanel)