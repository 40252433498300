import * as ActionTypes from '../action-types'

/**
 * Constant
 */


const vamProgram_DefaultState = {
    programByID: {},
    allVAMPIDs: []
}

export const vamPrograms = (state = vamProgram_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {

        case ActionTypes.GET_VAM_PROGRAMS: {

            const { vamPrograms } = action

            vamPrograms
            &&
            vamPrograms.map(vamProgram => {
                const { vampid } = vamProgram

                newState.programByID[vampid] = vamProgram

                if(!newState.allVAMPIDs.includes(vampid)) newState.allVAMPIDs.push(vampid)
            })

            return newState
        }

        case ActionTypes.GET_VAM_PROGRAM: {

            const { vamProgram } = action

            const { vampid } = vamProgram

            newState.programByID[vampid] = vamProgram

            if(!newState.allVAMPIDs.includes(vampid)) newState.allVAMPIDs.push(vampid)

            return newState
        }
        
        default: {
            return newState;
        }
    }
}