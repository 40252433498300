import { message } from 'antd'
import { batch } from 'react-redux'

import * as API from "../../api"
import * as ActionTypes from "../action-types";
import * as AUTH from '../../auth'

// Redux Action
import * as ROOT_REDUX_ACTION from '../../../../../services/redux/actions'


export const auth_user_sign_in = (email, password, cb) => dispatch => {

    dispatch(ROOT_REDUX_ACTION.set_auth_loading_request())

    AUTH
    .sign_in(email, password)
    .then(response => {

        const {
            status,
            msg
        } = response

        if(status && status === 200) {
            const { currentUser, authProvider } = response.data

            batch(() => {
                dispatch(sso_user_sign_in_request(currentUser, authProvider))
            })
        
            cb({status: 200})

            return null

        } else {
            throw msg
        }

    })
    .catch(err => {
        dispatch(ROOT_REDUX_ACTION.unset_auth_loading_request())
        cb({status: 404, err:`Error. Refresh page? ${err}`})
    })

}

export const auth_user_sign_out = () => dispatch => {

    dispatch(sso_user_sign_out_request())

    AUTH
    .sign_out()
    .then(() => {})

}

export const sso_user_sign_in_request = (authid, authProvider) => dispatch => {
    API
    .getUserInfoByAuthID(authid, authProvider)
    .then(data => {

        const {
            status,
            user
        } = data

        if (status === 500) {
            message.error('Error. Refresh page?');
        }
        else {
            batch(() => {
                dispatch(ROOT_REDUX_ACTION.unset_auth_loading_request())
                dispatch(sso_user_sign_in_success(user))
            })
        }
    })
}

export const sso_user_sign_in_success = (user = {}) => {
    return {
        type: ActionTypes.SSO_USER_SIGN_IN,
        user
    };
}

export const sso_user_sign_out_request = () => dispatch => {
    dispatch(sso_user_sign_out_success())
}

export const sso_user_sign_out_success = () => {
    return {
        type: ActionTypes.SSO_USER_SIGN_OUT
    };
}