import {
    // Global
    wsc,
    //Constant
    wsState,
    wsClient,
    wsReconnectInterval,
    reinitialiseWS
} from './sdk';

export { closeWS, getClient, closeWSC, wsState, reinitialiseWS } from './sdk'

// Constants & Config
import * as CONFIG from '../../config'

export const wsContainer = (
    cb = () => { },
    resubscribe = () => {}
) => {

    if (!wsc || ![WebSocket.OPEN, WebSocket.CONNECTING].includes(wsState())) {
        reinitialiseWS();
    }

    wsClient(
        wsc,
        (data) => {

            if (data === 'reconnect') {

                resubscribe();
                
                setTimeout(wsContainer(cb, resubscribe), wsReconnectInterval);
            }
        },
        (err, data) => {

            cb(err, data);
        }
    )
}

export const subscribeWS = (ids, uid) => {
    const subscribe_msg = {
        action: "subscribe",
        data: {
            ids: ids,
            uid: uid,
            platform: CONFIG.APP_INFO.APP_PLATFORM
        }
    }

    if (wsState() === 0) {
        const retry = setInterval(
            () => {
                if (wsState() !== 0) {

                    wsc.send(JSON.stringify(subscribe_msg));
                    clearInterval(retry);
                }
            },
            3000
        )
    } 
    else {
        wsc.send(JSON.stringify(subscribe_msg))
    }
}