import React from 'react';
import {
    Tooltip,
} from "antd";
// Icon
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';

/**
 * Initially yes
 * Once data received, then would switch to either no/yes
 * @param {} vehicle 
 */
const ECUIconDefiner = (vehicle) => {

    if(!vehicle) {
        return (null)
    }   
    else if ((Object.prototype.hasOwnProperty.call(vehicle, 'has_ecu') && vehicle.has_ecu)) {


        const ECU_CAN_TITLE = (<>
            <p>{`CANBUS Enabled`}</p>
        </>)

        const ECU_OBD_TITLE = (<>
            <p>{`OBD Enabled`}</p>
        </>)

        const ECU_OBD_OEM_TITLE = (<>
            <p>{`OBD Enabled`}</p>
        </>)

        const ECU_UNKNOWN_OEM_TITLE = (<>
            <p>{`ECU Enabled with Unknown Protocol`}</p>
        </>)

        const {
            has_ecu_can,
            has_ecu_obd,
            has_ecu_obd_oem
        } = vehicle

        if(has_ecu_can) {
            return (
                <Tooltip 
                    placement="top" 
                    title={ECU_CAN_TITLE}
                >
                        <DeveloperBoardIcon sx={{ fontSize: 20, color: "green" }}/>
                </Tooltip>
            )
        } else if(has_ecu_obd) {
            return (
                <Tooltip 
                    placement="top" 
                    title={ECU_OBD_TITLE}
                >
                        <DeveloperBoardIcon sx={{ fontSize: 20, color: "blue" }}/>
                </Tooltip>
            )
        } else if(has_ecu_obd_oem) {
            return (
                <Tooltip 
                    placement="top" 
                    title={ECU_OBD_OEM_TITLE}
                >
                        <DeveloperBoardIcon sx={{ fontSize: 20, color: "purple" }}/>
                </Tooltip>
            )
        } else {
            console.log("Hi")
            return (
                <Tooltip 
                    placement="top" 
                    title={ECU_UNKNOWN_OEM_TITLE}
                >
                        <DeveloperBoardIcon sx={{ fontSize: 20, color: "red" }}/>
                </Tooltip>
            )
        }
    } else {
        return (null)
    }
}

const ECUIcon = ({vehicle}) => {
    return <>
        {
            ECUIconDefiner(vehicle)
        }
    </>
}

export default ECUIcon