import React from "react";
import { Menu } from "antd";

const SidebarItems = ({
  items = [],
  onClick = () => {},
  theme = "dark",
  // collapsed = "true",
  selectedkeys
}) => {
  
  return (
    <Menu
      theme={theme}
      selectedkeys={[selectedkeys]}
      onClick={onClick}
      mode='inline'
      style={{
        height: "calc(90vh - 68px)", // Minus the logo height
        overflowY: "auto",
        background: "transparent"
      }}
      items={items.map(item => {
        return({
          key: item.key,
          label: item.label,
          icon: item.icon
        })
      })}
      trigger={null}
    />
  );
};

export default SidebarItems;
