import 
  React
from 'react';

/**
 * Services
 */
import SSORoutes from './services/navigation/route'

// Styling
import './App.css';
import './index.css';

const App = () => {
  return <SSORoutes/>
}

export default React.memo(App)