import React from 'react'
import { Row, Col } from 'antd'

// Constants
import { TEMPLATE } from '../../../../constants'

// Components

// Services

// Handler

// Style
import "../../index.css";

const {
    PACKAGE
} = TEMPLATE

const VehiclePackageStatus = (props) => {

    const {
        vehiclePackage = {}
    } = props

    const {
        vpkgids = [],
        status
    } = vehiclePackage

    return (
        <>
            <Row 
                style = {{ 
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: 1, 
                }}
            >
                <Col 
                    className = "VEHICLE_PACKAGE_TAG" 
                    style = {
                        vpkgids
                        &&
                        vpkgids.length
                        &&
                        {
                            backgroundColor: PACKAGE.VEHICLE_PACKAGE_STATUS_COLOR_CODE[PACKAGE.VEHICLE_PACKAGE_STATUS_CODE[status]]
                        }
                        ||
                        {}
                    }
                >
                    {vpkgids && vpkgids.length && PACKAGE.VEHICLE_PACKAGE_STATUS_CODE[status]}
                </Col>
            </Row>
        </>
    )

}

export default VehiclePackageStatus