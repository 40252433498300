import 
    React,
    {
        useState,
        useEffect
    }
from "react";
import {
    useDispatch,
    useSelector
} from 'react-redux'
import { 
    Modal,
    Form,
    Select,
    InputNumber,
    DatePicker
} from 'antd';

// Constant
import { TEMPLATE, REDUX as REDUX_CONSTANT } from '../../../../../../constants'

// Services
import { REDUX, TIME } from '../../../../../../services/util'

// Components

// Routes

// Redux Action
import * as REDUX_ACTION from '../../../../../../services/redux/actions'

// Styling
import './index.css'

const {
    isDayJS
} = TIME

const { MAINTENANCE } = TEMPLATE
const {
    SERVICE_CODE,
    SERVICE_CODE_INPUT_TYPE,
    SERVICE_PARAMS_FORM_ENCODER,
    SERVICE_INPUT_TYPE
 } = MAINTENANCE
 
const FormItem = Form.Item;
const { Option } = Select;

const MaintenanceProfile_CU_Modal = ({vid = "", maintid = "", action, open, onClose}) => {

    const [form] = Form.useForm();

    const dispatch = useDispatch()

    const vehicles = useSelector(REDUX.reduxStoreWrapper('vehicles.staticsByID'))
    const vids = useSelector(REDUX.reduxStoreWrapper('vehicles.allIDs'))
    const vehiclesMaintenannceProfiles = useSelector(REDUX.reduxStoreWrapper('vehicleMaintenances.profileByID'))
    const vidsMaintenannce = useSelector(REDUX.reduxStoreWrapper('vehicleMaintenances.allVIDs'))

    const maintenanceComponents = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.componentByID'))
    const maintenanceServices = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.serviceByID'))

    const [ selectedVID, setSelectedVID ] = useState('')
    const [ profileDue, setProfileDue ] = useState({})


    const ModelONClose = () => {
        onClose()
    }

    const onSubmit = async() => {
        const values = await form.validateFields();

        let uploadValue = {}

        Object.keys(values).map(k => {

            const v = isNaN(values[k]) ?
                (
                    isDayJS(values[k]) ?
                        values[k].utc().valueOf()
                    :
                        values[k]
                )
                : 
                Number(values[k])

            uploadValue[k] = v

            return 1
        })

        dispatch(REDUX_ACTION.v3_vehicleMaintenances.cu_vehicle_maintenance_profile_request({
            actionAPI: REDUX_CONSTANT.REDUX_CRUD_API_ACTION[action],
            maintid,
            ...uploadValue
        }))

        ModelONClose()
    }

    const Profile_Due_Input = (serviceParam, keyID) => {

        if(serviceParam) {

            switch(SERVICE_CODE_INPUT_TYPE[serviceParam]) {
                case SERVICE_INPUT_TYPE.DATE: {
                    return <DatePicker
                        onChange={(date) => {
                            setProfileDue({...profileDue, [keyID]: date})
                        }}
                    />
                }
                case SERVICE_INPUT_TYPE.NUMBER: {
                    return <InputNumber onChange={value => setProfileDue({...profileDue, [keyID]: value})}/>
                }
                default: {
                    <></>
                }
            }


        } else {
            return <></>
        }
    }
    
    useEffect(() => {

        if(vid && maintid) {
            setSelectedVID(vid)

            const vehicleMP = vehiclesMaintenannceProfiles[vid].find(mp => mp.maintid === maintid)

            if(vehicleMP) {

                let newProfile = {}

                Object.keys(vehicleMP)
                .filter(f => f.includes('due'))
                .map(f => {
                    newProfile[f] = vehicleMP[f]
                    return 1
                })

                setProfileDue(newProfile)
            }

        }

    }, [vid, maintid])

    return (
        <Modal
            title={`${action} Vehicle Maintenance Profile`}
            centered
            open={open}
            okText={action}
            onOk={() => onSubmit()}
            onCancel={() => ModelONClose()}
            width={'90vw'}
        >
            <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                fields={[
                    {
                        name: ["vid"],
                        value: selectedVID
                    },
                    ...(
                        Object.keys(maintenanceServices)
                        .filter(mainsid => maintenanceServices[mainsid].activeStatus)
                        .map(mainsid => ({
                            name: [`${maintenanceServices[mainsid].maincid}@${mainsid}@due`],
                            value: 
                                profileDue 
                                && 
                                profileDue[`${maintenanceServices[mainsid].maincid}@${mainsid}@due`]
                                &&
                                SERVICE_PARAMS_FORM_ENCODER[SERVICE_CODE[maintenanceServices[mainsid].serviceParam]](profileDue[`${maintenanceServices[mainsid].maincid}@${mainsid}@due`])
                        }))
                    )
                ]}
            >
                <FormItem label="Vehicle Plate" name="vid" rules={[{ required: true, message: 'Please choose available vehicle!' }]}>
                    <Select 
                        showSearch
                        style={{ width: '40vw' }}
                        onChange={(value) => setSelectedVID(value)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {
                            vids
                            .map(v => (
                                vidsMaintenannce.includes(v) ?
                                    <Option key={v} value={v} disabled>{vehicles[v].plate}</Option>
                                :
                                    <Option key={v} value={v}>{vehicles[v].plate}</Option>
                            ))
                        }
                    </Select>
                </FormItem>
                {
                    Object.keys(maintenanceServices)
                    .filter(mainsid =>  maintenanceServices[mainsid].activeStatus >= 1)
                    .map((mainsid, index) => (
                        <FormItem 
                                key={`${maintenanceServices[mainsid].maincid || index}@${mainsid}@due`} 
                                label={`${maintenanceServices[mainsid].maincid &&  maintenanceComponents[maintenanceServices[mainsid].maincid] && maintenanceComponents[maintenanceServices[mainsid].maincid].name || ''} - ${maintenanceServices[mainsid] && SERVICE_CODE[maintenanceServices[mainsid].serviceParam]}`}
                                name={`${mainsid}@due`} 
                                rules={[{ required: true, message: 'Please input valid number where number > 0!' }]}
                        >
                            {
                                Profile_Due_Input(maintenanceServices[mainsid].serviceParam, `${mainsid}@due`)
                            }
                        </FormItem>
                    ))
                }
            </Form>
        </Modal>
    )
}

export default MaintenanceProfile_CU_Modal