import * as ActionTypes from '../action-types'

const vehicleReportFuelAnalysis_DefaultState = {
    reportByVID: {},
    logByVID: {},
    summaryByVID: {},
    allVIDs: []
}

export const vehicleReportFuelAnalysis = (state = vehicleReportFuelAnalysis_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_VEHICLE_FUEL_ANALYSIS_REPORTS: {

            const report = action.report
            const vid = action.vid

            const {
                refuels = [],
                thefts = [],
                vLogs = [],
                summary = {}
            } = report

            if(vid) {

                newState.reportByVID[vid] = {
                    refuels,
                    thefts
                }

                newState.logByVID[vid] = vLogs
                newState.summaryByVID[vid] = summary

                if(!newState.allVIDs.includes(vid)) newState.allVIDs.push(vid)

            }

            return newState
        }
        default: {
            return newState;
        }
    }
}