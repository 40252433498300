import 
    React,
    {
        useState
    }
from 'react'
import { Table } from 'antd';

// Constant

// Components

// Handler
import { hookHDLR } from '../../../../handlers'

// Service
import { STYLES_UTIL, TIME } from '../../../../services/util'

const {
    useDeepEffect, usePrevious
} = hookHDLR

const {
    toTitleCase
} = STYLES_UTIL

const {
    parseDurationHRS
} = TIME

const FASummaryTable = (props) => {

    const {
        fuelUnitPrice,
        summary
    } = props

    const [ dataSrc, setDataSrc ] = useState([])

    const prevSummary = usePrevious(summary)
    const prevFuelUnitPrice = usePrevious(fuelUnitPrice)

    const BeautifyDisplay = (data, row) => {

        let res = ''

        const {
            unit
        } = row

        switch(unit) {
            case 'RM': {
                res = `${unit} ${data.toFixed(2)}`
                break;
            }
            case 'hr': {
                res = parseDurationHRS(data)
                break;
            }
            default: {
                res = `${data && data.toFixed(2)} ${unit}`
                break;
            }
        }

        return res
    }

    useDeepEffect(() => {

        if(
            summary
            &&
            Object.keys(summary)
            &&
            Object.keys(summary).length
            &&
            (
                
                prevSummary != summary
                ||
                prevFuelUnitPrice != fuelUnitPrice
            )
        ) {
            
            const newDS = []

            summary
            &&
            Object.keys(summary)
            &&
            Object.keys(summary).length
            &&
            Object.keys(summary).map(ele => newDS.push({
                key: ele,
                ele: toTitleCase(ele.replace(/_/g, " ")),
                ...summary[ele]
            }))

            newDS.push({
                key: 'Fuel Cost',
                ele: 'Fuel Cost',
                total: summary.fuel_consumption.total * fuelUnitPrice,
                parking: summary.fuel_consumption.parking * fuelUnitPrice,
                moving: summary.fuel_consumption.moving * fuelUnitPrice,
                idling: summary.fuel_consumption.idling * fuelUnitPrice,
                unit: 'RM'
            })

            setDataSrc(newDS)
        }

    }, [summary, fuelUnitPrice])

    const columns = [
        {
            title: 'Element',
            dataIndex: 'ele',
            key: 'ele'
        },
        {
            title: 'MOVING',
            dataIndex: 'moving',
            key: 'moving',
            render: (data, row) => BeautifyDisplay(data, row)
        },
        {
            title: 'IDLING',
            dataIndex: 'idling',
            key: 'idling',
            render: (data, row) => BeautifyDisplay(data, row)
        },
        {
            title: 'PARKING',
            dataIndex: 'parking',
            key: 'parking',
            render: (data, row) => BeautifyDisplay(data, row)
        },
        {
            title: 'TOTAL',
            dataIndex: 'total',
            key: 'total',
            render: (data, row) => BeautifyDisplay(data, row)
        }
    ]

    return (
        <>
            {
                dataSrc
                &&
                <Table 
                    columns={columns} 
                    dataSource={dataSrc} 
                />
            }
        </>
    )
}

const MemorisedFASummaryTable = React.memo(FASummaryTable)

export default MemorisedFASummaryTable