import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { 
    Table,  
    Tooltip,
    Button,
    Space,
    Tag
} from 'antd'
import {
    PlusOutlined,
    EditOutlined
} from '@ant-design/icons'

// Constants
import { TEMPLATE } from '../../../../constants'

// Components
import { EmptyIndicator } from '../../../../components/Indicator'
import MaintenanceProfile_CU_Modal from './components/MaintenanceProfile_CU_Modal'

// Services
import { TIME, REDUX, RoundUp } from '../../../../services/util'

// Handler
import {
    report_handler,
    table_handler
} from '../../../../handlers'

const { getColumnSearchProps } = table_handler
const { parseTime, parseHours } = TIME
const { MAINTENANCE } = TEMPLATE
const { MODAL_CS_PROCESS_ACTION } = MAINTENANCE

const {
    MILEAGE_TYPE,
    mileageSelector
} = report_handler

const ManagementProfileManagementPanel = () => {

    const style = useSelector(REDUX.reduxStoreWrapper('style'))

    const vehicleMaintenanceProfiles = useSelector(REDUX.reduxStoreWrapper('vehicleMaintenances.profileByID'))
    const vids = useSelector(REDUX.reduxStoreWrapper('vehicleMaintenances.allVIDs'))
    const vehicles = useSelector(REDUX.reduxStoreWrapper('vehicles.staticsByID'))
    const vehicleDerivativeStates = useSelector(REDUX.reduxStoreWrapper('vehicles.derivativeStateByID'))

    const maintenanceTemplates = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.templateByID'))
    const maintenanceComponents = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.componentByID'))
    const maintenanceServices = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.serviceByID'))
    const serviceByMAINCID = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.serviceByMAINCID'))
    const maincids = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.allComponentIDs'))

    const maintenanceTemplate_Default = maintenanceTemplates && Object.values(maintenanceTemplates).find(v => v.default)
    const maintid_Default = maintenanceTemplate_Default && maintenanceTemplate_Default.maintid

    const vehicleMaintenanceProfile_DefaultByVID = (vid) => vehicleMaintenanceProfiles[vid] && Object.values(vehicleMaintenanceProfiles[vid]).find(v => v.maintid === maintid_Default)

    const [ Maintenance_Profile_ModalState, setMaintenance_Profile_ModalState ] = useState(false)
    const [ Maintenance_Profile_ModalAction, setMaintenance_Profile_ModalAction ] = useState(MODAL_CS_PROCESS_ACTION.CREATE)
    const [ selectedVID, setSelectedVID ] = useState('')
    const [ selectedMAINTID, setSelectedMAINTID ] = useState(maintid_Default|| '')

    
    const searchTextState = useState('')
    const searchedColumnState = useState('')

    const Maintenance_Profile_Modal_Click = () => {
        setMaintenance_Profile_ModalState(true)
    }

    const Maintenance_Profile_Modal_Close = () => {
        setMaintenance_Profile_ModalState(false)
    }

    const onEditVehicleMaintenanceProfile = (vid, maintid) => {
        setSelectedMAINTID(maintid)
        setSelectedVID(vid)
        setMaintenance_Profile_ModalAction(MODAL_CS_PROCESS_ACTION.EDIT)
        setMaintenance_Profile_ModalState(true)   
    }

    const vehicleMaintenanceProfileReData = () => 
        vids
        &&
        vids.map(vid => {

            const vDS = vehicleDerivativeStates[vid]
            const odometer = vDS && mileageSelector(vDS, MILEAGE_TYPE.ODOMETER) || 0
            const engineHour = vDS && parseHours(vDS.plat_hist_engine_hour) || 0

            return {
                key: vid,
                ...vehicles[vid],
                odometer: !isNaN(odometer) ? `${RoundUp(odometer, 0)} km` : "-",
                engineHour: !isNaN(engineHour) ? `${RoundUp(engineHour, 0)} hrs` : "-",
                ...(vehicleMaintenanceProfile_DefaultByVID(vid) || {})
            }
        })
        ||
    []

    const vehicleMaintenanceProfilePanelColumns = [
        {
          title: "Vehicle Plate",
          dataIndex: "plate",
          fixed: 'left',
          ...getColumnSearchProps("Vehicle Plate", 'plate', searchTextState, searchedColumnState),
        },
        {
          title: "Vehicle Name",
          dataIndex: "displayName",
          ...getColumnSearchProps("Vehicle Name", 'displayName', searchTextState, searchedColumnState),
        },
        {
            title: "Odometer",
            dataIndex: "odometer",
        },

        {
            title: "Engine Hours",
            dataIndex: "engineHour",
        },
        ...maincids
        .filter(id => Object.prototype.hasOwnProperty.call(serviceByMAINCID,id))
        .filter(maincid => serviceByMAINCID[maincid].reduce((acc, mainsid) => maintenanceServices[mainsid].activeStatus >= 1 || acc, 0))
        .map(maincid => (
            {
                title: `${maintenanceComponents[maincid].name}`,
                align: 'center',
                children: 
                    serviceByMAINCID
                    &&
                    serviceByMAINCID[maincid]
                    &&
                    serviceByMAINCID[maincid]
                    .filter(mainsid => maintenanceServices[mainsid].activeStatus >= 1)
                    .map(mainsid => (
                        {
                            title: `${maintenanceServices[mainsid] && MAINTENANCE.SERVICE_CODE[maintenanceServices[mainsid].serviceParam]}`,
                            dataIndex: `${mainsid}@due`,
                            key: `${mainsid}@due`,
                            align: 'center',
                            render: (data) => {
                                return (
                                    <>
                                        {
                                            MAINTENANCE.SERVICE_PARAMS_ENCODER[MAINTENANCE.SERVICE_CODE[maintenanceServices[mainsid].serviceParam]](data)
                                        }
                                    </>
                                )
                            }
                        }
                    ))
                    ||
                    []
            }
        )),
        {
            title: "Active Status",
            dataIndex: "active",
            render: active => <Tag color={MAINTENANCE.PROFILE_ACTIVE_STATUS_TAG_COLOR[active]}>{MAINTENANCE.PROFILE_ACTIVE_STATUS[active]}</Tag>
        },
        {
          title: "Updated At",
          dataIndex: "updatedAt",
          defaultSortOrder: "descend",
          sorter: (a, b) => a.updatedAt - b.updatedAt,
          render: updatedAt => parseTime(Number(updatedAt)),
        },
        {
            title: "Actions",
            fixed: 'right',
            render: rowData => (
                <Space size="middle" style={{float: "left"}}>
                    <Tooltip title={'Edit Vehicle Maintenance Profile'}>
                        <Button 
                            shape='circle' 
                            icon={<EditOutlined />}
                            type="primary"
                            onClick={() => onEditVehicleMaintenanceProfile(rowData.vid, rowData.maintid, rowData)}
                        />
                    </Tooltip>
                </Space>
            )
        }
      ];

    return (
        <>
            <Space size="middle" style={{float: "right"}}>
                <Tooltip title={'Add Maintenance Profile'}>
                    <Button 
                        shape='circle' 
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={() => Maintenance_Profile_Modal_Click()}
                    />
                </Tooltip>
            </Space>
            <MaintenanceProfile_CU_Modal
                vid={selectedVID}
                maintid={selectedMAINTID || maintid_Default}
                action={Maintenance_Profile_ModalAction}
                open={Maintenance_Profile_ModalState}
                onClose={() => Maintenance_Profile_Modal_Close()}
            />
            <>
                {
                    vehicleMaintenanceProfileReData().length > 0 ?
                        <Table
                            loading={style.loading.general}
                            columns={vehicleMaintenanceProfilePanelColumns && vehicleMaintenanceProfilePanelColumns.filter(c => !c.isHidden)}
                            dataSource={vehicleMaintenanceProfileReData()}
                            pagination={true}
                            scroll={{
                                y: window.innerHeight,
                                x: vehicleMaintenanceProfilePanelColumns && vehicleMaintenanceProfilePanelColumns.length * 150
                            }}
                        /> 
                    :
                        <EmptyIndicator />
                }
            </>
        </>
    )
}

export default ManagementProfileManagementPanel