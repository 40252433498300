import React from 'react'
import { Tag } from 'antd'

const IOTag = (props) => {

    const {
        io,
        textOutput = false
    } = props

    return (
        <>
        {
            textOutput ?
            (
                (
                    io
                    &&
                    `ON`
                )
                ||
                `OFF`
            )
            :
            (
                (
                    io
                    &&
                    <Tag color="success">ON</Tag>
                )
                ||
                <Tag color="warning">OFF</Tag>
            )
        }
        </>
    )
}

export default IOTag