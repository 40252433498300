import React from 'react';
import {
    Tooltip,
} from "antd";
// Icon
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import Battery90Icon from '@mui/icons-material/Battery90';
import Battery80Icon from '@mui/icons-material/Battery80';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery20Icon from '@mui/icons-material/Battery20';
// Micro

const ScaleDeviceBatteryLevel = ({batteryLVL}) => {

    const EmptyBatteryAlertTitle = (
        <>
            <p>{`Zero Device Battery Level - ${batteryLVL}%`}</p>
        </>
    )

    const LowBatteryAlertTitle = (
        <>
            <p>{`Critical Device Battery Level - ${batteryLVL}%`}</p>
        </>
    )

    const WarningBattreyAlertTitle = (
        <>
            <p>{`Attention for Device Battery Level - ${batteryLVL}%`}</p>
        </>
    )

    const NormalBatteryAlertTitle = (
        <>
            <p>{`Device Battery Level - ${batteryLVL}%`}</p>
        </>
    )

    const BatteryLevelIcon = ({title, ICON, color}) => (
        <Tooltip 
            placement="top" 
            title={title}
        >
                <ICON sx={{ fontSize: 20, color: color, marginInline: "2% 2%" }}/>
        </Tooltip>
    )

    if (batteryLVL == 0) {
        return <BatteryLevelIcon title={EmptyBatteryAlertTitle} ICON={BatteryFullIcon} color={'red'}/>
    } else if(batteryLVL <= 20) {
        return <BatteryLevelIcon title={LowBatteryAlertTitle} ICON={Battery20Icon} color={'red'}/>
    } else if (batteryLVL <= 30) {
        return <BatteryLevelIcon title={WarningBattreyAlertTitle} ICON={Battery30Icon} color={'yellow'}/>
    } else if (batteryLVL <= 50) {
        return <BatteryLevelIcon title={NormalBatteryAlertTitle} ICON={Battery50Icon} color={'green'}/>
    } else if (batteryLVL <= 60) {
        return <BatteryLevelIcon title={NormalBatteryAlertTitle} ICON={Battery60Icon} color={'green'}/>
    } else if (batteryLVL <= 80) {
        return <BatteryLevelIcon title={NormalBatteryAlertTitle} ICON={Battery80Icon} color={'green'}/>
    } else if (batteryLVL <= 90) {
        return <BatteryLevelIcon title={NormalBatteryAlertTitle} ICON={Battery90Icon} color={'green'}/>
    } else {
        return (<BatteryLevelIcon title={NormalBatteryAlertTitle} ICON={BatteryFullIcon} color={'green'}/>)
    }
}

/**
 * Initially yes
 * Once data received, then would switch to either no/yes
 * @param {} vehicle 
 */
const DeviceBatteryLevelIconDefiner = (vehicle) => {

    const UndetectedDeviceBatteryLevelTitle = (
        <>
            <p>{`Undetectable Device Battery Level`}</p>
        </>
    )

    if(!vehicle) 
        return (
            <Tooltip 
                placement="top" 
                title={UndetectedDeviceBatteryLevelTitle}
            >
                    <BatteryUnknownIcon sx={{ fontSize: 20, color: "black" }}/>
            </Tooltip>
        )
    else {
        
        if( vehicle && Object.prototype.hasOwnProperty.call(vehicle, 'pwr_int_lvl')) {

            const {
                pwr_int_lvl = 0
            } =  vehicle

            return (
                <ScaleDeviceBatteryLevel batteryLVL = {pwr_int_lvl} />
            )

        } else {
            return (
                <Tooltip 
                    placement="top" 
                    title={UndetectedDeviceBatteryLevelTitle}
                >
                        <BatteryUnknownIcon sx={{ fontSize: 20, color: "black" }}/>
                </Tooltip>
            )
        }
    }
}

const DeviceBatteryLevelIcon = ({vehicle}) => {
    return <>
        {
            DeviceBatteryLevelIconDefiner(vehicle)
        }
    </>
}

export default DeviceBatteryLevelIcon