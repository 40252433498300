import { FEATURE_NAME } from '../common/features'

export const ACCOUNT_PACKAGE_OWNERSHIP = {
    AXIATA: 'Axiata',
    GPSFLEET: 'GPSFleet',
    NETS: 'Nets'
}

export const ACCOUNT_PACKAGE = {
    GPSFLEET_ALL: 'All',
    GPSFLEET_MAINTENANCE_MODULE: 'Maintenance Module',
    GPSFLEET_BASIC: 'Basic',
    GPSFLEET_ESG: 'ESG Module',
    GPSFLEET_CARGOMOVE: 'CargoMove',
    GPSFLEET_OPENAPI: 'Open API',
    GPSFLEET_REPORT_IA_D: 'Report Module - Idling Analysis (D)',
    GPSFLEET_REPORT_IA_W: 'Report Module - Idling Analysis (W)',
    GPSFLEET_REPORT_IA_M: 'Report Module - Idling Analysis (M)',
    GPSFLEET_REPORT_SR_D: 'Report Module - Speeding Record (D)',
    GPSFLEET_REPORT_SR_W: 'Report Module - Speeding Record (W)',
    GPSFLEET_REPORT_SR_M: 'Report Module - Speeding Record (M)',
    NETS_ALL: 'All',
    NETS_MAINTENANCE_MODULE: 'Maintenance Module',
    NETS_ESG: 'ESG Module',
    NETS_BASIC: 'Basic',
    NETS_CARGOMOVE: 'CargoMove',
    NETS_OPENAPI: 'Open API',
    AXIATA_ALL: 'All',
    AXIATA_MAINTENANCE_MODULE: 'Maintenance Module',
    AXIATA_BASIC: 'Basic',
    AXIATA_CARGOMOVE: 'CargoMove',
    AXIATA_OPENAPI: 'Open API',
}

export const ACCOUNT_PACKAGE_CODE = {
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_ALL}`]: 'apkg#GPF00',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_MAINTENANCE_MODULE}`] : 'apkg#GP100',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_ESG}`] : 'apkg#GP200',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_BASIC}`]: 'apkg#GP000',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_CARGOMOVE}`]: 'apkg#GP601',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_OPENAPI}`]: 'apkg#GP800',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_REPORT_IA_D}`]: 'apkg#GP301d',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_REPORT_IA_W}`]: 'apkg#GP301w',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_REPORT_IA_M}`]: 'apkg#GP301m',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_REPORT_SR_D}`]: 'apkg#GP302d',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_REPORT_SR_W}`]: 'apkg#GP302w',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_REPORT_SR_M}`]: 'apkg#GP302m',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.AXIATA}@${ACCOUNT_PACKAGE.AXIATA_ALL}`]: 'apkg#AXG00',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.AXIATA}@${ACCOUNT_PACKAGE.AXIATA_MAINTENANCE_MODULE}`] : 'apkg#CE100',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.AXIATA}@${ACCOUNT_PACKAGE.AXIATA_BASIC}`]: 'apkg#CE000',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.AXIATA}@${ACCOUNT_PACKAGE.AXIATA_CARGOMOVE}`]: 'apkg#CE601',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.AXIATA}@${ACCOUNT_PACKAGE.AXIATA_OPENAPI}`]: 'apkg#CE800',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.NETS}@${ACCOUNT_PACKAGE.NETS_ALL}`]: 'apkg#NETG00',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.NETS}@${ACCOUNT_PACKAGE.NETS_MAINTENANCE_MODULE}`] : 'apkg#NET100',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.NETS}@${ACCOUNT_PACKAGE.NETS_ESG}`] : 'apkg#NET200',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.NETS}@${ACCOUNT_PACKAGE.NETS_BASIC}`]: 'apkg#NET000',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.NETS}@${ACCOUNT_PACKAGE.NETS_CARGOMOVE}`]: 'apkg#NET601',
    [`${ACCOUNT_PACKAGE_OWNERSHIP.NETS}@${ACCOUNT_PACKAGE.NETS_OPENAPI}`]: 'apkg#NET800'
}

export const ACCOUNT_PACKAGE_BY_CODE = Object.fromEntries(Object.entries(ACCOUNT_PACKAGE_CODE).map(([k, v]) => [v, k]))

export const VEHICLE_PACKAGE = {
    TRIAL: 'Trial',
    TEST: 'Test',
    BASIC: 'Basic',
    STANDARD: 'Standard',
    PERMIUM: 'Premium' 
}

export const VEHICLE_PACKAGE_CODE = {
    [VEHICLE_PACKAGE.TRIAL]: 'vpkg#GP010',
    [VEHICLE_PACKAGE.TEST]: 'vpkg#GP011',
    [VEHICLE_PACKAGE.BASIC]: 'vpkg#GP000',
    [VEHICLE_PACKAGE.STANDARD]: 'vpkg#GP001',
    [VEHICLE_PACKAGE.PERMIUM]: 'vpkg#GP002'
}

export const VEHICLE_PACKAGE_BY_CODE = {
    [VEHICLE_PACKAGE_CODE[VEHICLE_PACKAGE.TRIAL]]: VEHICLE_PACKAGE.TRIAL,
    [VEHICLE_PACKAGE_CODE[VEHICLE_PACKAGE.TEST]]: VEHICLE_PACKAGE.TEST,
    [VEHICLE_PACKAGE_CODE[VEHICLE_PACKAGE.BASIC]]: VEHICLE_PACKAGE.BASIC,
    [VEHICLE_PACKAGE_CODE[VEHICLE_PACKAGE.STANDARD]]: VEHICLE_PACKAGE.STANDARD,
    [VEHICLE_PACKAGE_CODE[VEHICLE_PACKAGE.PERMIUM]]: VEHICLE_PACKAGE.PERMIUM
}

const CELCOM_PLUS_SPECIAL_PACKAGE = [
    FEATURE_NAME.HIGHWAY_SURVEILLANCE_MANAGEMENT
]

export const ACCOUNT_PACKAGE_ACCESSIBLE_MODULE = {
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_ALL}`]: Object.values(FEATURE_NAME).filter(f =>  !CELCOM_PLUS_SPECIAL_PACKAGE.includes(f) ),
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_BASIC}`]: [
        FEATURE_NAME.GEOFENCE_MANAGEMENT,
        FEATURE_NAME.GEOFENCE_MANAGEMENT_POI_CREATE,
        FEATURE_NAME.GEOFENCE_MANAGEMENT_POT_EDIT,
        FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_CREATE,
        FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_EDIT,
        FEATURE_NAME.USER_MANAGEMENT,
        FEATURE_NAME.OVERALL_MANAGEMENT,
        FEATURE_NAME.RULE_ENGINE_MANAGEMENT,
        FEATURE_NAME.NOTIFICATION_MANAGEMENT,
        FEATURE_NAME.ESCALATION_CALL_REPORT,
        FEATURE_NAME.VEHICLE_OVERALL_REPORT,
        FEATURE_NAME.FUEL_ANALYSIS_DASHBOARD,
        FEATURE_NAME.VEHICLE_INFORMATION_PANEL,
        FEATURE_NAME.VEHICLE_ROUTE_PLAYBACK,
        FEATURE_NAME.VEHICLE_EVENT_TRACE,
        FEATURE_NAME.USER_PROFILE,
        FEATURE_NAME.USER_HIERARCHY_MAP,
        FEATURE_NAME.LANDING_PAGE
    ],
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_MAINTENANCE_MODULE}`]: [
        FEATURE_NAME.OVERALL_MAINTENANCE_MANAGEMENT,
        FEATURE_NAME.OVERALL_MAINTENANCE_STATE,
        FEATURE_NAME.MAINTENANCE_PROFILE_MANAGEMENT,
        FEATURE_NAME.MAINTENANCE_CONFIGURATION_MANAGEMENT,
        // VAM
        FEATURE_NAME.OVERALL_VEHICLE_ADVANCE_MAINTENANCE,
        FEATURE_NAME.VEHICLE_REMINDER_MANAGEMENT,
        FEATURE_NAME.VEHICLE_ENTRY_MANAGEMENT,
        FEATURE_NAME.VEHICLE_ADVANCE_MAINTENANCE_CONFIGURATION_MANAGEMENT
    ],
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_ESG}`]: [
        FEATURE_NAME.GREENER_WHEELS
    ],
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_CARGOMOVE}`]: [],
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.GPSFLEET_OPENAPI}`]: [],
    // Axiata,
    [`${ACCOUNT_PACKAGE_OWNERSHIP.AXIATA}@${ACCOUNT_PACKAGE.AXIATA_ALL}`]: Object.values(FEATURE_NAME),
    [`${ACCOUNT_PACKAGE_OWNERSHIP.AXIATA}@${ACCOUNT_PACKAGE.AXIATA_BASIC}`]: [
        FEATURE_NAME.GEOFENCE_MANAGEMENT,
        FEATURE_NAME.GEOFENCE_MANAGEMENT_POI_CREATE,
        FEATURE_NAME.GEOFENCE_MANAGEMENT_POT_EDIT,
        FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_CREATE,
        FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_EDIT,
        FEATURE_NAME.USER_MANAGEMENT,
        FEATURE_NAME.OVERALL_MANAGEMENT,
        FEATURE_NAME.RULE_ENGINE_MANAGEMENT,
        FEATURE_NAME.NOTIFICATION_MANAGEMENT,
        FEATURE_NAME.ESCALATION_CALL_REPORT,
        FEATURE_NAME.VEHICLE_OVERALL_REPORT,
        FEATURE_NAME.FUEL_ANALYSIS_DASHBOARD,
        FEATURE_NAME.VEHICLE_INFORMATION_PANEL,
        FEATURE_NAME.VEHICLE_ROUTE_PLAYBACK,
        FEATURE_NAME.VEHICLE_EVENT_TRACE,
        FEATURE_NAME.USER_PROFILE,
        FEATURE_NAME.USER_HIERARCHY_MAP,
        FEATURE_NAME.LANDING_PAGE,
        ... CELCOM_PLUS_SPECIAL_PACKAGE
    ],
    [`${ACCOUNT_PACKAGE_OWNERSHIP.AXIATA}@${ACCOUNT_PACKAGE.AXIATA_MAINTENANCE_MODULE}`]: [
        FEATURE_NAME.OVERALL_MAINTENANCE_MANAGEMENT,
        FEATURE_NAME.OVERALL_MAINTENANCE_STATE,
        FEATURE_NAME.MAINTENANCE_PROFILE_MANAGEMENT,
        FEATURE_NAME.MAINTENANCE_CONFIGURATION_MANAGEMENT,
        // VAM
        FEATURE_NAME.OVERALL_VEHICLE_ADVANCE_MAINTENANCE,
        FEATURE_NAME.VEHICLE_REMINDER_MANAGEMENT,
        FEATURE_NAME.VEHICLE_ENTRY_MANAGEMENT,
        FEATURE_NAME.VEHICLE_ADVANCE_MAINTENANCE_CONFIGURATION_MANAGEMENT
    ],
    [`${ACCOUNT_PACKAGE_OWNERSHIP.AXIATA}@${ACCOUNT_PACKAGE.AXIATA_CARGOMOVE}`]: [],
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.AXIATA_OPENAPI}`]: [],
    // Nets,
    [`${ACCOUNT_PACKAGE_OWNERSHIP.NETS}@${ACCOUNT_PACKAGE.NETS_ALL}`]: Object.values(FEATURE_NAME),
    [`${ACCOUNT_PACKAGE_OWNERSHIP.NETS}@${ACCOUNT_PACKAGE.NETS_BASIC}`]: [
        FEATURE_NAME.GEOFENCE_MANAGEMENT,
        FEATURE_NAME.GEOFENCE_MANAGEMENT_POI_CREATE,
        FEATURE_NAME.GEOFENCE_MANAGEMENT_POT_EDIT,
        FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_CREATE,
        FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_EDIT,
        FEATURE_NAME.USER_MANAGEMENT,
        FEATURE_NAME.OVERALL_MANAGEMENT,
        FEATURE_NAME.RULE_ENGINE_MANAGEMENT,
        FEATURE_NAME.NOTIFICATION_MANAGEMENT,
        FEATURE_NAME.ESCALATION_CALL_REPORT,
        FEATURE_NAME.VEHICLE_OVERALL_REPORT,
        FEATURE_NAME.FUEL_ANALYSIS_DASHBOARD,
        FEATURE_NAME.VEHICLE_INFORMATION_PANEL,
        FEATURE_NAME.VEHICLE_ROUTE_PLAYBACK,
        FEATURE_NAME.VEHICLE_EVENT_TRACE,
        FEATURE_NAME.USER_PROFILE,
        FEATURE_NAME.USER_HIERARCHY_MAP,
        FEATURE_NAME.LANDING_PAGE,
        ... CELCOM_PLUS_SPECIAL_PACKAGE
    ],
    [`${ACCOUNT_PACKAGE_OWNERSHIP.NETS}@${ACCOUNT_PACKAGE.NETS_MAINTENANCE_MODULE}`]: [
        FEATURE_NAME.OVERALL_MAINTENANCE_MANAGEMENT,
        FEATURE_NAME.OVERALL_MAINTENANCE_STATE,
        FEATURE_NAME.MAINTENANCE_PROFILE_MANAGEMENT,
        FEATURE_NAME.MAINTENANCE_CONFIGURATION_MANAGEMENT,
        // VAM
        FEATURE_NAME.OVERALL_VEHICLE_ADVANCE_MAINTENANCE,
        FEATURE_NAME.VEHICLE_REMINDER_MANAGEMENT,
        FEATURE_NAME.VEHICLE_ENTRY_MANAGEMENT,
        FEATURE_NAME.VEHICLE_ADVANCE_MAINTENANCE_CONFIGURATION_MANAGEMENT
    ],
    [`${ACCOUNT_PACKAGE_OWNERSHIP.NETS}@${ACCOUNT_PACKAGE.NETS_ESG}`]: [
        FEATURE_NAME.GREENER_WHEELS
    ],
    [`${ACCOUNT_PACKAGE_OWNERSHIP.NETS}@${ACCOUNT_PACKAGE.NETS_CARGOMOVE}`]: [],
    [`${ACCOUNT_PACKAGE_OWNERSHIP.GPSFLEET}@${ACCOUNT_PACKAGE.NETS_OPENAPI}`]: []
}