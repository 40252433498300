import * as API from "../../api"
import * as ActionTypes from "../action-types";
import { REDUX } from '../../../constants'

/**
 * ThirdParty Library
 */
 import {
    message
} from 'antd'

/**
 * Redux Action
 */
import { 
    get_rule_request,
    get_rules_request
} from './rules'

/**
 * Get Rule Template
 */

export const get_rule_template_request = (ruletid, uid = null) => dispatch => {
    

    API
    .getRuleTemplateByRULETID (ruletid)
    .then(info => {
        
        try {
            const {
                ruleTemplate
            } = info

            dispatch(get_rule_template_success(ruleTemplate, uid))
            ruleTemplate && ruleTemplate.rules && dispatch(get_rule_request(ruleTemplate.rules))


        } catch(err) {
            message.error('Error in getting rule templates. Refresh page?')
        }

    })
}

const get_rule_template_success = (ruleTemplate, uid = null) => {
    return {
        type: ActionTypes.GET_RULE_TEMPLATE,
        ruleTemplate,
        uid
    };
}

/**
 * Get Rule Templates
 */

 export const get_rule_templates_request = (ruletids, uid = null) => dispatch => {

    let ruletidBatch = []

    ruletids.map((ruletid, index) => {

        ruletidBatch.push(ruletid)

        if( 
            (index+1) % REDUX.REDUX_RULE_TEMPLATE_BATCH_GET_SIZE === 0
            ||
            index+1 === ruletids.length
        ) {
            dispatch(batch_get_rule_templates_request(ruletidBatch, uid))
            ruletidBatch = []
        }

        return null
    })
 }

/**
 * Batch Get Rule Templates
 */
 export const batch_get_rule_templates_request = (ruletids, uid = null) => dispatch => {

    API
    .getRuleTemplateBatch(ruletids)
    .then(info => {
        
        try {
            const {
                ruleTemplates
            } = info

            dispatch(batch_get_rule_templates_success(ruleTemplates, uid))

            ruleTemplates 
            && ruleTemplates.length 
            && dispatch(
                get_rules_request(
                    ruleTemplates.reduce((acc, cur) => [...acc, ...cur.rules], [])
                )
            )


        } catch(err) {
            message.error('Error in batch getting rule templates. Refresh page?')
        }

    })
}

const batch_get_rule_templates_success = (ruleTemplates, uid = null) => {
    return {
        type: ActionTypes.GET_RULE_TEMPLATES,
        ruleTemplates,
        uid
    };
}

