import * as API_CONSTANT from '../constants'

export const getVehicleMaintenanceStateBatch = (vids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vehicle/maintenance/state/batch/get`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vids
        })
    })
    .then(res => res.json())
)

export const getVehicleMaintenanceProfileBatch = (vids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vehicle/maintenance/profile/batch/get`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vids
        })
    })
    .then(res => res.json())
)

export const CU_VehicleMaintenanceProfile = (item) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/maintenance/vehicle/profile/cu`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify(item)
    })
    .then(res => res.json())
)