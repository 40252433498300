import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, Tag, Button } from 'antd'
import {
    EditOutlined,
} from '@ant-design/icons';

// Constants
import { TEMPLATE } from '../../../../constants'

// Components
import { EmptyIndicator } from '../../../../components/Indicator'
import { VehiclePackageLabel, VehiclePackageStatus } from '../../../../components/VehiclePackage'
import VehiclePlateECUTag from '../../../../components/VehiclePlateECUTag'

// Services
import { TIME, REDUX } from '../../../../services/util'

// Handler
import {
    table_handler,
    vehicles_handler
} from '../../../../handlers'

const { getColumnSearchProps } = table_handler
const { vehicleECUValidator } = vehicles_handler
const { parseDate } = TIME

const VehiclePanel = () => {

    const vehicleStaticses = useSelector(REDUX.reduxStoreWrapper('vehicles.staticsByID'))
    const vehicleStates = useSelector(REDUX.reduxStoreWrapper('vehicles.stateByID'))
    const vehiclePackageProfiles = useSelector(REDUX.reduxStoreWrapper('vehicles.vehiclePKGByID'))
    const vehicleGroups = useSelector(REDUX.reduxStoreWrapper('vehicleGroups'))

    const curUserRole = useSelector(REDUX.reduxStoreWrapper('user.uroleid'))
    const style = useSelector(REDUX.reduxStoreWrapper('style'))

    const [ dataSource, setDataSource ] = useState([])
    
    const searchTextState = useState('')
    const searchedColumnState = useState('')

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }

    const prevVehicles = usePrevious(vehicleStaticses);

    const refreshDataSrc = () => {
        const newDataSource = Object.values(vehicleStaticses)
            .sort((b, a) => b.plate - a.plate)
            .map(vehicleStatics => {
                const vid = vehicleStatics.vid
                const vehiclePackage = vehiclePackageProfiles[vid]
                const vehicleECU = vehicleECUValidator(vehicleStates[vid])

                const vhcgids = vehicleGroups.allIDs.filter(vhcgid => vehicleGroups.byID[vhcgid].vehicles && vehicleGroups.byID[vhcgid].vehicles.includes(vid))

                return {
                    key: vid,
                    vhcgids,
                    vehiclePackage,
                    ...vehicleStatics,
                    ...vehicleECU,
                    registeredAt: vehicleStatics.registeredAt || vehicleStatics.createdAt
                }
            })

        setDataSource(newDataSource)
    }

    const VehicleManagementEditOnClick = () => {}

    const vehiclePanelColumns = [
        {
          title: "Vehicle Plate",
          dataIndex: "plate",
          fixed: 'left',
          ...getColumnSearchProps("Vehicle Plate", 'plate', searchTextState, searchedColumnState, (plate, vehicle) => <VehiclePlateECUTag plate={plate} vehicle={vehicle}/>),
        },
        {
          title: "Vehicle Name",
          dataIndex: "displayName",
          ...getColumnSearchProps("Vehicle Name", 'displayName', searchTextState, searchedColumnState, (plate, vehicle) => <VehiclePlateECUTag plate={plate} vehicle={vehicle}/>),
        },
        {
          title: "Vehicle Groups",
          dataIndex: "vhcgids",
          render: (vhcgids) => (
              <>
                {
                    vhcgids.map(vhcgid => (
                        <Tag color="blue" key={vhcgid}>
                            {vehicleGroups.byID[vhcgid].name}
                        </Tag>
                    ))
                }
              </>
          )
        },
        {
          title: "Type",
          dataIndex: "type",
          ...getColumnSearchProps("Type", "type", searchTextState, searchedColumnState),
        },
        {
          title: "Model",
          dataIndex: "model",
          ...getColumnSearchProps("Model", "model", searchTextState, searchedColumnState),
        },
        {
          title: "Assigned Device",
          dataIndex: "dvid",
          isHidden: TEMPLATE.USER_ROLE.FILTER_OUT_ROLE.includes(curUserRole), //this column will be filtered out if user is fleetOwner
          ...getColumnSearchProps("Device IMEI number", 'dvid', searchTextState, searchedColumnState),
        },
        {
            title: "Vehicle Package",
            dataIndex: "vehiclePackage",
            render: vehiclePackage => <VehiclePackageLabel vehiclePackage={vehiclePackage}/>
        },
        {
            title: "Vehicle Package Status",
            dataIndex: "vehiclePackage",
            filters: Object.keys(TEMPLATE.PACKAGE.VEHICLE_PACKAGE_STATUS_CODE).map(code=> (
              {
                  text: TEMPLATE.PACKAGE.VEHICLE_PACKAGE_STATUS_CODE[code],
                  value: code,
              }
            )),
            onFilter: (value, record) => record.vehiclePackage && Number(record.vehiclePackage.status) === Number(value),
            render: vehiclePackage => <VehiclePackageStatus vehiclePackage={vehiclePackage}/>
        },
        {
            title: "Registration Date",
            dataIndex: "registeredAt",
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.registeredAt - b.registeredAt,
            render: registeredAt => parseDate(Number(registeredAt)),
          },
        {
            title: "Actions",
            fixed: 'right',
            render: vehicle => (
              <Button.Group>
                <button className = "transparent-button">
                  <EditOutlined
                    onClick = {VehicleManagementEditOnClick(vehicle)}
                  />
                </button>
              </Button.Group>
            )
          }
      ];

    useEffect(() => {
        if (
            (prevVehicles !== vehicleStaticses)
        ) {
            refreshDataSrc()
        }
    })

    return (
        <div style = {{ display: "flex", flex: 1 }}>
            {
                dataSource.length > 0 ?
                    <Table
                        loading={style.loading.general}
                        columns={vehiclePanelColumns && vehiclePanelColumns.filter(c => !c.isHidden)}
                        dataSource={dataSource}
                        pagination={true}
                        scroll={{
                            y: window.innerHeight,
                            x: vehiclePanelColumns && vehiclePanelColumns.length * 150
                        }}
                    /> 
                :
                    <EmptyIndicator />
            }
        </div>
    )
}

export default VehiclePanel