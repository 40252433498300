import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
    Card,
    Modal,
    Form,
    Select,
    Row,
    Col,
    Input,
    InputNumber,
    Checkbox,
    DatePicker,
    message
} from 'antd';

// Constant
import { REDUX as REDUX_CONSTANT } from '../../../../../../constants'

// Components
import { TimeIntervalSelector } from './components'

// Handler
import { vam_handler } from '../../../../../../handlers'

// Services
import { REDUX, TIME } from '../../../../../../services/util'

// Redux Action
import * as REDUX_ACTION from '../../../../../../services/redux/actions'

const FormItem = Form.Item

const {
    REDUX_CRUD_API_ACTION
} = REDUX_CONSTANT

const {
    parseTimeAsSelectorUnit,
    parseTimeUnit2Timestamp,
    nextDuePreviewConvertor
} = vam_handler

const {
    parseTimestamp,
    // getCurtStartOfDate
} = TIME

const defaultInterval = {
    time: 0,
    odometer: 0,
    workingHour: 0
}

export const VAMReminderCUModal = ({ action, open, onClose}) => {

    const [form] = Form.useForm();

    const dispatch = useDispatch()

    const vamReminders = useSelector(REDUX.reduxStoreWrapper('vamReminders.reminderByID'))
    const vamLines = useSelector(REDUX.reduxStoreWrapper('vamLines.itemByID'))
    const vamlids = useSelector(REDUX.reduxStoreWrapper('vamLines.allVAMLIDs'))

    const ownedVAMLIDS = useSelector(REDUX.reduxStoreWrapper('vamReminders.vamlidByVID'))
    const vamrid = useSelector(REDUX.reduxStoreWrapper('vamReminders.selectedVAMRID'))

    const vehicles = useSelector(REDUX.reduxStoreWrapper('vehicles.staticsByID'))
    const vehicleDSes = useSelector(REDUX.reduxStoreWrapper('vehicles.derivativeStateByID'))
    const vids = useSelector(REDUX.reduxStoreWrapper('vehicles.allIDs'))

    const loadingAction = useSelector(REDUX.reduxStoreWrapper('style.loading.action'))

    const [ selectedVID, setSelectedVID ] = useState('')

    const [ manualDue, setManualDue ] = useState(false)
    const [ interval, setInterval ] = useState(defaultInterval)
    const [ nextDue, setNextDue ] = useState(defaultInterval)
    const [ formData, setFormData ] = useState({})

    const nonCreateAction = REDUX_CRUD_API_ACTION[action] !== REDUX_CRUD_API_ACTION.CREATE

    const ModelONClose = () => {
        form.resetFields()
        setManualDue(false)
        onClose()
    }

    const onManualDueChange = (e) => {
        setManualDue(e.target.checked)
        if(!e.target.checked) {
            setNextDue(defaultInterval)
        }
    }

    const onSubmit = async() => {
        const values = await form.validateFields();
        const v = JSON.parse(JSON.stringify(values))

        const cond = Object.values(interval).reduce((acc, cur) => acc > cur && acc|| cur, [0])

        if(cond === 0) {

            message.warning("Please insert any interval before submit reminder")

        } else {
            Object.keys(v).map(k => {
                if(k == 'time_interval' || k == 'time_dueSoon_thres' ) v[k] = parseTimeUnit2Timestamp(v[k])
                if(k == 'time_next' ) v[k] = parseTimestamp(v[k])
                if(v[k] == 0 && k != 'remark') delete v[k]
            })

            if(!nonCreateAction) {
                Object.keys(v)
                .filter(k => k.includes('_interval'))
                .map(k => {
                    const fk = k.split('_')[0]
                    if(!Object.prototype.hasOwnProperty.call(v, `${fk}_next`)) {
                        const ndp = nextDuePreviewConvertor[fk]
                        const vhcD = selectedVID && vehicleDSes[selectedVID] && ndp[3](vehicleDSes[selectedVID])
                        v[`${fk}_next`] = vhcD + v[k]
                    }
                })
            }

            if(REDUX_CRUD_API_ACTION[action] === REDUX_CRUD_API_ACTION.UPDATE) {
                v.vamrid = vamrid
            }
    
            dispatch(REDUX_ACTION.v3_vamReminders.cu_vam_reminder_request(v, REDUX_CRUD_API_ACTION[action], () => {
                ModelONClose()
            }))
        }

    }

    const onFormValChange = (e) => {

        Object.keys(e).map(k => {

            const fk = k.split('_')[0]

            switch(k) {
                case 'vid': {
                    setSelectedVID(e[k])
                    break;
                }
                case 'time_interval': {
                    setInterval({
                        ...interval,
                        [fk]: parseTimeUnit2Timestamp(e[k])
                    })
                    break;
                }
                case 'time_next': {
                    setNextDue({
                        ...nextDue,
                        [fk]: parseTimestamp(e[k])
                    })
                    break;
                }
                case 'odometer_interval':
                case 'workingHour_interval': {
                    setInterval({
                        ...interval,
                        [fk]: e[k]
                    })
                    break
                }
                case 'odometer_next':
                case 'workingHour_next': {
                    setNextDue({
                        ...nextDue,
                        [fk]: e[k]
                    })
                    break;
                }
                default: {
                    break;
                }
            }

        })

        setFormData({
            ...formData,
            ...e
        })
    }

    useEffect(() => {

        if(vamrid) {

            const vamReminder = vamReminders[vamrid]
            const {
                vid,
                vamlid,
                time_interval = 0,
                odometer_interval = 0,
                workingHour_interval = 0,
                time_dueSoon_thres = 0,
                odometer_dueSoon_thres = 0,
                workingHour_dueSoon_thres = 0,
                time_next = 0,
                odometer_next = 0,
                workingHour_next = 0,
                remark = ''
            } = vamReminder

            setInterval({
                ...interval,
                time: time_interval,
                odometer: odometer_interval,
                workingHour: workingHour_interval
            })

            setNextDue({
                ...nextDue,
                time: time_next,
                odometer: odometer_next,
                workingHour: workingHour_next
            })

            setFormData({
                vid: vid,
                vamlid: vamlid,
                time_interval: parseTimeAsSelectorUnit(time_interval),
                time_dueSoon_thres: parseTimeAsSelectorUnit(time_dueSoon_thres),
                odometer_interval: odometer_interval,
                odometer_dueSoon_thres: odometer_dueSoon_thres,
                workingHour_interval: workingHour_interval,
                workingHour_dueSoon_thres: workingHour_dueSoon_thres,
                remark: remark
            })

            setSelectedVID(vid)

        } else {
            setInterval(defaultInterval)
            setSelectedVID('')
            setFormData()
        }

    }, [vamrid])

    return (
        <Modal
            title={`${action} Vehicle Reminder`}
            centered
            open={open}
            okText={action}
            onOk={() => onSubmit()}
            onCancel={() => ModelONClose()}
            destroyOnClose={true}
            width={'90vw'}
            confirmLoading={loadingAction}
        >
            <Form
                form={form}
                layout="vertical"
                wrapperCol={{ span: 22 }}
                onValuesChange={(e) => onFormValChange(e)}
                fields={[
                    {
                        name: ['vid'],
                        value: formData?.vid
                    },
                    {
                        name: ['vamlid'],
                        value: formData?.vamlid
                    },
                    {
                        name: ['remark'],
                        value: formData?.remark
                    },
                    {
                        name: ['time_interval'],
                        value: formData?.time_interval
                    },
                    {
                        name: ['time_dueSoon_thres'],
                        value: formData?.time_dueSoon_thres
                    },
                    {
                        name: ['odometer_interval'],
                        value: formData?.odometer_interval
                    },
                    {
                        name: ['odometer_dueSoon_thres'],
                        value: formData?.odometer_dueSoon_thres
                    },
                    {
                        name: ['workingHour_interval'],
                        value: formData?.workingHour_interval
                    },
                    {
                        name: ['workingHour_dueSoon_thres'],
                        value: formData?.workingHour_dueSoon_thres
                    }
                ]}
            >
                <FormItem label="Vehicle Plate" name="vid" rules={[{ required: true, message: 'Please choose available vehicle!' }]}>
                    <Select 
                        showSearch
                        // style={{ width: '40vw' }}
                        onChange={(value) => setSelectedVID(value)}
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        disabled={nonCreateAction}
                        options={
                            vids
                            .map(v => 
                                ( {
                                    label: vehicles[v].plate,
                                    value: v
                                })
                            )
                            ||
                            []
                        }
                    />
                </FormItem>
                <FormItem label="Line Items" name="vamlid" rules={[{ required: true, message: 'Please choose available line item!' }]}>
                    <Select 
                        showSearch
                        // style={{ width: '40vw' }}
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        disabled={nonCreateAction}
                        options={
                            vamlids
                            .map(v => 
                                ({
                                    label: vamLines[v].name,
                                    value: v,
                                    disabled: nonCreateAction || (selectedVID && ownedVAMLIDS[selectedVID]?.includes(v))
                                })
                            )
                            ||
                            []
                        }
                    />
                </FormItem>
                <FormItem label="Remark" name="remark">
                    <Input />
                </FormItem>
                <Row style={{width: '100%'}}>
                    <Col span={12}>
                        <FormItem label="Time Interval" name="time_interval">
                            <TimeIntervalSelector type="interval"/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label="Time Due Soon Threshold" name="time_dueSoon_thres" >
                            <TimeIntervalSelector type="threshold"/>
                        </FormItem>
                    </Col>
                </Row>
                <Row style={{width: '100%'}}>
                    <Col span={12}>
                        <FormItem label="Odometer Interval" name="odometer_interval">
                            <InputNumber style={{width: '100%'}} addonBefore="Every" addonAfter="km"/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label="Odometer Due Soon Threshold" name="odometer_dueSoon_thres" >
                            <InputNumber style={{width: '100%'}} addonAfter="km"/>
                        </FormItem>
                    </Col>
                </Row>
                <Row style={{width: '100%'}}>
                    <Col span={12}>
                        <FormItem label="Working Hour Interval" name="workingHour_interval">
                            <InputNumber style={{width: '100%'}} addonBefore="Every" addonAfter="hrs"/>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem label="Working Hour Due Soon Threshold" name="workingHour_dueSoon_thres" >
                            <InputNumber style={{width: '100%'}} addonAfter="hrs"/>
                        </FormItem>
                    </Col>
                </Row> 

                <Row>
                    <Checkbox onChange={onManualDueChange} defaultChecked={false}>Manually set the due date, odometer and/or working hour for the current reminder</Checkbox>   
                </Row> 

                {
                    manualDue ?
                    (
                        <Row style={{width: '100%'}}>
                            {
                                Object.keys(interval).map(k => {
                                    if(interval[k]) {
                                        switch(k) {
                                            case 'time': {
                                                return <Col key={k} style={{width: '20vw'}}><FormItem label="Current Due Date" name='time_next'><DatePicker /></FormItem></Col>
                                                // return <Col key={k} style={{width: '20vw'}}><FormItem label="Next Due Date" name='time_next'><DatePicker disabledDate={(current) => parseTimestamp(current) < getCurtStartOfDate()}/></FormItem></Col>
                                            }
                                            case 'odometer': {
                                                return <Col key={k} style={{width: '20vw'}}><FormItem label="Current Odometer Due" name='odometer_next'><InputNumber style={{width: '100%'}} addonBefore="At" addonAfter="km"/></FormItem></Col>
                                            }
                                            case 'workingHour': {
                                                return <Col key={k} style={{width: '20vw'}}><FormItem label="Current Working Hour Due" name='workingHour_next'><InputNumber style={{width: '100%'}} addonBefore="At" addonAfter="hrs"/></FormItem></Col>
                                            }
                                            default: {
                                                return <></>
                                            }
                                        }
                                    }
                                })
                            }
                        </Row>
                    )
                    :
                    <></>
                }

            </Form>

            <Card title="Current Due Preview">
                {
                    Object.keys(interval).map(k => {
                        const ndp = nextDuePreviewConvertor[k]
                        const nk = `${k}_next`
                        const cdue = manualDue && nextDue[k] || (vamrid && vamReminders[vamrid][nk] || 0)

                        const vhcD = selectedVID && vehicleDSes[selectedVID] && ndp[3](vehicleDSes[selectedVID])
                        const val = nonCreateAction && cdue || (manualDue && cdue || (vhcD + interval[k])) 

                        if(interval[k]) {
                            return (
                                <Col key={k} style={{width: '20vw'}}>
                                    {`${ndp[0]}: ${ndp[1](val)} ${ndp[2]}` }
                                </Col>
                            )
                        }
                    })
                }
            </Card>

            <Card title="Next Due Preview">
                <Row>
                    {
                        Object.keys(interval).map(k => {
                            const ndp = nextDuePreviewConvertor[k]
                            const nk = `${k}_next`
                            const cdue = manualDue && nextDue[k] || (vamrid && vamReminders[vamrid][nk] || 0)

                            const vhcD = selectedVID && vehicleDSes[selectedVID] && ndp[3](vehicleDSes[selectedVID])
                            const val = nonCreateAction && cdue || (manualDue && cdue || (vhcD + interval[k]))

                            if(interval[k]) {
                                return (
                                    <Col key={k} style={{width: '20vw'}}>
                                        {`${ndp[0]}: ${ndp[1](val + interval[k])} ${ndp[2]}` }
                                    </Col>
                                )
                            }
                        })
                    }
                </Row>
            </Card>

        </Modal>
    )
}