import * as API_CONSTANT from '../constants'

export const getAllPLUSCCTVs = () => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/surveillance/plus/section/get`, {
      method: 'POST',
      headers: API_CONSTANT.headers,
      body: JSON.stringify({
      })
    })
      .then(res => res.json())
  )