import React, { useEffect } from "react";
import {
    useDispatch
} from 'react-redux'
import {
    closeSidebar,
    openSidebar,
} from "../../../services/redux/actions/style";

const showSecondarySidebar = show => WrappedComponent => (props) => {

    const dispatch = useDispatch()

    useEffect(() => {

        if (show) {
            dispatch(openSidebar());
        } else {
            dispatch(closeSidebar());
        }

    }, [dispatch])

    const WComp = () => <WrappedComponent {...props} />
    
    WComp.displayName = 'MainLayout_SecondarySidebar_WrapComp'

    return WComp()

}

export default showSecondarySidebar;
