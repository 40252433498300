import React from 'react'

const GeoLocation = (props) => {

    const {
        location = {},
        address = null
    } = props

    return (
        <>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.google.com.my/maps/place/${location.lat},${location.lon}`}
            >
                { (address && address) || `${location.lat}, ${location.lon}`}
            </a>
        </>
    )
}

export default GeoLocation