const VEHICLE_GROUP_COLORS = [
    "#FFFFFF", // This is default and therefore first
    "#FFCCCC",
    "#E6ECFF",
    "#E6FFE6",
    "#FFFFB3",
    "#FFE6FF",
    "#F2E6FF",
]

const DEFAULT_VEHICLE_GROUP_COLOR = VEHICLE_GROUP_COLORS[0];

export {
    DEFAULT_VEHICLE_GROUP_COLOR,
    VEHICLE_GROUP_COLORS
}