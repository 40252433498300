import * as ActionTypes from "../action-types";

const vehicleLog_defaultState = {
    byID: {},
    logByVID: {},
    selectedVID: "",
    selectedTime: 0,
    allIDs: []
}

export const vehicleLogs = (
    state = vehicleLog_defaultState,
    action
) => {
    let newState = JSON.parse(JSON.stringify(state));

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_TIMEFRAME_VEHICLE_LOGS: {

            const vehicleLogs = action.vehicleLogs

            const vid = action.vid
            const startTime = action.startTime
            const endTime = action.endTIme

            newState.byID[vid] = [startTime, endTime]

            newState.logByVID[vid] = vehicleLogs.sort((a,b) => a.vehicleTime-b.vehicleTime)
            
            if(!newState.allIDs.includes(vid)) newState.allIDs.push(vid)
            
            return newState;
        }

        case ActionTypes.SET_SELECTED_VEHICLE_LOG_TIME: {

            const vehicleTime = action.vehicleTime

            newState.selectedTime = vehicleTime

            return newState;
        }

        case ActionTypes.SET_SELECTED_VEHICLE_FOR_VEHICLE_LOG: {

            const vid = action.vid

            newState.selectedVID = vid

            return newState;
        }

        case ActionTypes.UNSET_SELECTED_VEHICLE_FOR_VEHICLE_LOG: {

            newState.selectedVID = ""

            return newState;
        }

        default: {
            return state;
        }
    }
};
