import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, Tag } from 'antd'

// Constants
import { TEMPLATE } from '../../../../constants'

// Components
import { EmptyIndicator } from '../../../../components/Indicator'

// Services
import { TIME, REDUX, RoundUp } from '../../../../services/util'

// Handler
import {
    report_handler,
    table_handler
} from '../../../../handlers'


const { getColumnSearchProps } = table_handler
const { parseTime, parseHours } = TIME
const { MAINTENANCE } = TEMPLATE

const {
    MILEAGE_TYPE,
    mileageSelector,
} = report_handler

const OverallMaintenanceStatePanel = () => {

    const style = useSelector(REDUX.reduxStoreWrapper('style'))

    const vehicleMaintenanceStates = useSelector(REDUX.reduxStoreWrapper('vehicleMaintenances.stateByID'))
    const vids = useSelector(REDUX.reduxStoreWrapper('vehicleMaintenances.allVIDs'))
    const vehicles = useSelector(REDUX.reduxStoreWrapper('vehicles.staticsByID'))
    const vehicleDerivativeStates = useSelector(REDUX.reduxStoreWrapper('vehicles.derivativeStateByID'))

    const maintenanceComponents = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.componentByID'))
    const maintenanceServices = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.serviceByID'))
    const serviceByMAINCID = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.serviceByMAINCID'))
    const maincids = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.allComponentIDs'))
    
    const searchTextState = useState('')
    const searchedColumnState = useState('')

    const vehicleMaintenanceStateReData = () => 
        vids
        &&
        vids.map(vid => {

            const vDS = vehicleDerivativeStates[vid]
            const odometer = vDS && mileageSelector(vDS, MILEAGE_TYPE.ODOMETER) || 0
            const engineHour = vDS && parseHours(vDS.plat_hist_engine_hour) || 0
            
            return {
                key: vid,
                ...vehicles[vid],
                odometer: !isNaN(odometer) ? `${RoundUp(odometer, 0)} km` : "-",
                engineHour: !isNaN(engineHour) ? `${RoundUp(engineHour, 0)} hrs` : "-",
                ...vehicleMaintenanceStates[vid]
            }
        })
        ||
    []

    const vehicleMaintenanceStatePanelColumns = [
        {
          title: "Vehicle Plate",
          dataIndex: "plate",
          fixed: 'left',
          ...getColumnSearchProps("Vehicle Plate", 'plate', searchTextState, searchedColumnState),
        },
        {
          title: "Vehicle Name",
          dataIndex: "displayName",
          ...getColumnSearchProps("Vehicle Name", 'displayName', searchTextState, searchedColumnState),
        },
        {
            title: "Odometer",
            dataIndex: "odometer",
        },

        {
            title: "Engine Hours",
            dataIndex: "engineHour",
        },
        ...maincids
        .filter(id => Object.prototype.hasOwnProperty.call(serviceByMAINCID,id))
        .filter(maincid => serviceByMAINCID[maincid].reduce((acc, mainsid) => maintenanceServices[mainsid].activeStatus >= 1 || acc, 0))
        .map(maincid => (
            {
                title: `${maintenanceComponents[maincid].name}`,
                align: 'center',
                children: 
                    serviceByMAINCID
                    &&
                    serviceByMAINCID[maincid]
                    &&
                    serviceByMAINCID[maincid]
                    .filter(mainsid => maintenanceServices[mainsid].activeStatus === 1)
                    .map(mainsid => (
                        {
                            title: `${MAINTENANCE.SERVICE_CODE[maintenanceServices[mainsid].serviceParam]}`,
                            dataIndex: `${mainsid}@state`,
                            key: `${mainsid}@state`,
                            align: 'center',
                            render: (data) => <Tag color={MAINTENANCE.SERVICE_STATUS_CODE_TAG_COLOR[data]}>{MAINTENANCE.SERVICE_STATUS_CODE[data]}</Tag>
                        }
                    ))
                    ||
                    []
            }
        )),
        {
          title: "Updated At",
          dataIndex: "updatedAt",
          defaultSortOrder: "ascend",
          sorter: (a, b) => a.updatedAt - b.updatedAt,
          render: updatedAt => parseTime(Number(updatedAt)),
        }
      ];

    return (
        <div style = {{ display: "flex", flex: 1 }}>
            {
                vehicleMaintenanceStateReData().length > 0 ?
                    <Table
                        loading={style.loading.general}
                        columns={vehicleMaintenanceStatePanelColumns && vehicleMaintenanceStatePanelColumns.filter(c => !c.isHidden)}
                        dataSource={vehicleMaintenanceStateReData()}
                        pagination={true}
                        scroll={{
                            y: window.innerHeight,
                            x: vehicleMaintenanceStatePanelColumns && vehicleMaintenanceStatePanelColumns.length * 150
                        }}
                    /> 
                :
                    <EmptyIndicator />
            }
        </div>
    )
}

export default OverallMaintenanceStatePanel