import * as ActionTypes from '../action-types'

/**
 * Constant
 */


const vamEntries_DefaultState = {
    entryByID: {},
    allVAMEIDs: [],
    selectedVAMRIDs: [],
    selectedVID: '',
    selectedVAMEID: ''
}

export const vamEntries = (state = vamEntries_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {

        case ActionTypes.GET_VAM_ENTRY_THRU_VID: {

            const { vamEntries } = action

            vamEntries
            &&
            vamEntries.map(vamEntry => {

                const { vameid } = vamEntry

                newState.entryByID[vameid] = { ...(newState.entryByID[vameid] || {}), ...vamEntry }
    
                if(!newState.allVAMEIDs.includes(vameid)) newState.allVAMEIDs.push(vameid)

            })

            return newState
        }

        case ActionTypes.CU_VAM_ENTRY: {

            const { vamEntry } = action

            const { vameid } = vamEntry

            newState.entryByID[vameid] = { ...(newState.entryByID[vameid] || {}), ...vamEntry }

            if(!newState.allVAMEIDs.includes(vameid)) newState.allVAMEIDs.push(vameid)

            return newState
        }

        case ActionTypes.SET_VAM_ENTRY_VAMRIDS: {
            newState.selectedVAMRIDs = action?.vamrids

            return newState
        }

        case ActionTypes.UNSET_VAM_ENTRY_VAMRIDS: {
            newState.selectedVAMRIDs = []

            return newState
        }

        case ActionTypes.SET_VAM_ENTRY_VID: {
            newState.selectedVID = action?.vid

            return newState
        }

        case ActionTypes.UNSET_VAM_ENTRY_VID: {
            newState.selectedVID = ''

            return newState
        }

        case ActionTypes.SET_VAM_ENTRY_VAMEID: {
            newState.selectedVAMEID = action?.vameid

            return newState
        }

        case ActionTypes.UNSET_VAM_ENTRY_VAMEID: {
            newState.selectedVAMEID = ''

            return newState
        }
        
        default: {
            return newState;
        }
    }
}