import * as API from "../../api"
import * as ActionTypes from "../action-types";
import { REDUX } from '../../../constants'

/**
 * ThirdParty Library
 */
import {
    message
} from 'antd'

/**
 * Redux Action
 */
import {
    setLoadingAction,
    unsetLoadingAction
} from './style'

/**
 * Get VAM Reminders thru VID
 */

export const get_vam_reminders_thru_vid_request = (vid) => dispatch => {
    
    API
    .getVAMReminderThruVID(vid)
    .then(info => {
        
        try {
            const {
                vamReminders
            } = info

            dispatch(get_vam_reminders_thru_vid_success(vamReminders))

        } catch(err) {
            console.log('err', err)
            message.error('Error in getting vam reminders. Refresh page?')
        }

    })
}

const get_vam_reminders_thru_vid_success = (vamReminders) => {
    return {
        type: ActionTypes.GET_VAM_REMINDERS_THRU_VID,
        vamReminders
    };
}

/**
 * CU VAM Reminder
 */

export const cu_vam_reminder_request = (item, actionAPI, callback = () => {}) => dispatch => {

    dispatch(setLoadingAction())
    
    API
    .CU_VAMReminder(item, actionAPI)
    .then(info => {

        try {

            const {
                status,
                vamReminder,
                msg
            } = info
    
            switch(status) {
                case 200: {
    
                    dispatch(cu_vam_reminder_success(vamReminder))
    
                    break;
                }
                case 500: {
                    throw msg
                }
                default: {
                    break;
                }
            }

            message.success(`Success in ${REDUX.REDUX_CRUD_API_ACTION_THRU_CODE[actionAPI]} vam reminder`)

        } catch(err) {
            console.log("Error", err)
            message.error(`Error in ${REDUX.REDUX_CRUD_API_ACTION_THRU_CODE[actionAPI]} vam reminder. Refresh page?`)
        }

    })
    .finally(() => {
        dispatch(unsetLoadingAction())
        callback()
    })

}

const cu_vam_reminder_success = (vamReminder) => {
    return {
        type: ActionTypes.CU_VAM_REMINDER,
        vamReminder
    };
}

/**
 * Set VAMRID
 */
export const set_vamrid_request = (vamrid) => dispatch => {
    dispatch(set_vamrid_success(vamrid))
}

const set_vamrid_success = (vamrid) => {
    return {
        type: ActionTypes.SET_VAM_REMINDER_VAMRID,
        vamrid
    };
}

/**
 * Unset VAMRID
 */
export const unset_vamrid_request = () => dispatch => {
    dispatch(unset_vamrid_success())
}

const unset_vamrid_success = () => {
    return {
        type: ActionTypes.UNSET_VAM_REMINDER_VAMRID
    };
}