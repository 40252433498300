import { translator_handler } from '../../../handlers'

const {
    deviceTranslator
} = translator_handler

export const FORMATTER_TRIP = ({vids, tripRecords, vehicleGeoStateLogs}) => {

    const dataSrc = []

    vids.map(vid => {
        tripRecords.recordByVID[vid] 
        && tripRecords.recordByVID[vid].map(tr => {
            let new_tr = JSON.parse(JSON.stringify(tr))
            new_tr.geoIN = []
            new_tr.geoOUT = []

            const startTime = tr.startTime
            const endTime = tr.endTime
            
            const prevVGeoStateLogs = vehicleGeoStateLogs.logByVID[vid] && vehicleGeoStateLogs.logByVID[vid].filter(vl => vl.vehicleTime < startTime)
            const vGeoStateLogs = vehicleGeoStateLogs.logByVID[vid] && vehicleGeoStateLogs.logByVID[vid].filter(vl => vl.vehicleTime >= startTime && vl.vehicleTime <= endTime)

            if(prevVGeoStateLogs && prevVGeoStateLogs.length) {
                const prevVGeoStateLog = (prevVGeoStateLogs.sort((a, b) => b.vehicleTime - a.vehicleTime))[0]
                new_tr.geoIN = [ ...new_tr.geoIN, ...prevVGeoStateLog.geoIN]
            }
            
            if(vGeoStateLogs && vGeoStateLogs.length) {
                vGeoStateLogs.map(vl => {
                    new_tr.geoIN = [ ...new_tr.geoIN, ...vl.geoIN ]
                    new_tr.geoOUT = [ ...new_tr.geoOUT, ...vl.geoOUT ]
                })
            }

            new_tr.geoIN = new_tr.geoIN.filter((vid, pos, ary) => ary.indexOf(vid) === pos)
            new_tr.geoOUT = new_tr.geoOUT.filter((vid, pos, ary) => ary.indexOf(vid) === pos)
            
            dataSrc.push(new_tr)
        })
    })

    return dataSrc
    .map((d, i) => ({key:i, ...d}))
    .sort((a, b) => a.vid - b.vid)
}

export const FORMATTER_TRANSIT = ({vids, transitRecords, vehicleGeoStateLogs}) => {
    const dataSrc = []

    vids.map(vid => {
        transitRecords.recordByVID[vid] 
        && transitRecords.recordByVID[vid].map(tr => {
            let new_tr = JSON.parse(JSON.stringify(tr))
            new_tr.geoIN = []
            new_tr.geoOUT = []

            const startTime = tr.startTime
            const endTime = tr.endTime
            
            const prevVGeoStateLogs = vehicleGeoStateLogs.logByVID[vid] && vehicleGeoStateLogs.logByVID[vid].filter(vl => vl.vehicleTime < startTime)
            const vGeoStateLogs = vehicleGeoStateLogs.logByVID[vid] && vehicleGeoStateLogs.logByVID[vid].filter(vl => vl.vehicleTime >= startTime && vl.vehicleTime <= endTime)

            if(prevVGeoStateLogs && prevVGeoStateLogs.length) {
                const prevVGeoStateLog = (prevVGeoStateLogs.sort((a, b) => b.vehicleTime - a.vehicleTime))[0]
                new_tr.geoIN = [ ...new_tr.geoIN, ...prevVGeoStateLog.geoIN]
            }
            
            if(vGeoStateLogs && vGeoStateLogs.length) {
                vGeoStateLogs.map(vl => {
                    new_tr.geoIN = [ ...new_tr.geoIN, ...vl.geoIN ]
                    new_tr.geoOUT = [ ...new_tr.geoOUT, ...vl.geoOUT ]
                })
            }

            new_tr.geoIN = new_tr.geoIN.filter((vid, pos, ary) => ary.indexOf(vid) === pos)
            new_tr.geoOUT = new_tr.geoOUT.filter((vid, pos, ary) => ary.indexOf(vid) === pos)
            
            dataSrc.push(new_tr)
        })
    })

    return dataSrc
    .map((d, i) => ({key:i, ...d}))
    .sort((a, b) => a.vid - b.vid)
}

export const FORMATTER_DETAIL = ({vids, vehicleLogs, vehicleDerivativeLogs, vehicleEventLogs, vehicleGeoStateLogs}) => {
    const dataSrc = []

    vids.map(vid => {

        let accVHCLogs = {}

        if(
            vehicleLogs.logByVID[vid]
            &&
            vehicleDerivativeLogs.logByVID[vid]
        ) {

            vehicleLogs.logByVID[vid] && vehicleLogs.logByVID[vid].map(vl => accVHCLogs[vl.vehicleTime] = vl)
            vehicleDerivativeLogs.logByVID[vid] && vehicleDerivativeLogs.logByVID[vid].map(vl => accVHCLogs[vl.vehicleTime] = { ...accVHCLogs[vl.vehicleTime], ...vl })
            vehicleEventLogs.logByVID[vid] && vehicleEventLogs.logByVID[vid].map(vl => accVHCLogs[vl.vehicleTime] = { ...accVHCLogs[vl.vehicleTime], ...vl })


            Object.keys(accVHCLogs).map(vehicleTime => {

                let geoIN = []
                let geoOUT = []

                const prevVGeoStateLogs = vehicleGeoStateLogs.logByVID[vid] && vehicleGeoStateLogs.logByVID[vid].filter(vl => vl.vehicleTime < vehicleTime)
                const vGeoStateLog = vehicleGeoStateLogs.logByVID[vid] && vehicleGeoStateLogs.logByVID[vid].find(vl => vl.vehicleTime === vehicleTime)

                if(prevVGeoStateLogs && prevVGeoStateLogs.length) {
                    const prevVGeoStateLog = (prevVGeoStateLogs.sort((a, b) => b.vehicleTime - a.vehicleTime))[0]
                    geoIN = [ ...geoIN, ...prevVGeoStateLog.geoIN]

                    if(Object.keys(accVHCLogs).filter(t => t >= prevVGeoStateLog.vehicleTime &&  t<= vehicleTime).length < 3) {
                        geoOUT = [ ...geoOUT, ...prevVGeoStateLog.geoOUT]
                    }
                }

                if(vGeoStateLog) {
                    geoIN = [ ...geoIN, ...vGeoStateLog.geoIN]
                    geoOUT = [ ...geoOUT, ...vGeoStateLog.geoOUT]
                }

                accVHCLogs[vehicleTime] = { ...accVHCLogs[vehicleTime], geoIN, geoOUT }

                return null
            })
    
            Object.values(accVHCLogs).map(vl => dataSrc.push(vl))
        }

        return null
     })
 
    return dataSrc
    .map((d, i) => ({key:i, ...deviceTranslator(d)}))
    .sort((a, b) => a.vid - b.vid)
}

export const FORMATTER_DAILY_SUMMARY = ({vids, vehicleReportDailySummaries}) => {

    const dataSrc = []

    vids.map(vid => {

        vehicleReportDailySummaries.reportByVID[vid]
        && vehicleReportDailySummaries.reportByVID[vid]
        .sort((a, b) => a.reportTime - b.reportTime)
        .map(rds => {  
            dataSrc.push(rds)
        })

        return null
     })

    return dataSrc
    .map((d, i) => ({key:i, ...d}))
    .sort((a, b) => a.vid - b.vid)
}