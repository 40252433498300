import * as API from "../../api"
import * as ActionTypes from "../action-types";

/**
 * ThirdParty Library
 */
import {
    message
} from 'antd'

/**
 * 
 * Redux Action
 */
import {
    get_vehicle_groups_request
} from './vehicleGroups'
import {
    get_device_groups_request
} from './deviceGroups'
import {
    get_geofence_templates_request
} from './geofenceTemplates'

/**
 * Main User Get Hierarchy Profile 
 */

export const user_hierarchy_get_request = (user, hierCode) => dispatch => {
    API.getUserHierarchy(hierCode)
        .then(data => {
            if (data.status !== 200) {
                message.error('Error at get_user_hierarchy', data.message)
            }
            else {

                dispatch(user_hierarchy_get_success(user, data))

                const {
                    allIDs
                } = data

                allIDs && allIDs.map(uid => uid !== user.uid && dispatch(hierarchial_user_info_get_request(uid)))

            }
        })
}

const user_hierarchy_get_success = (user, data) => {
    return {
        type: ActionTypes.GET_USER_HIERARCHY,
        user,
        hierarchyTree: data.hierarchyTree,
        allIDs: data.allIDs
    }
}

/**
 * Hierarchical User Info Get 
 */

export const hierarchial_user_info_get_request = (uid) => dispatch => {

    API
    .getInfoByUser(uid, 1)
    .then(info => {
        try {
            const {
                asset,
                user
            } = info

            const {
                devgids,
                vhcgids,
                geotids
            } = asset

            dispatch(hierarchial_user_info_get_success(user))

            if(vhcgids) dispatch(get_vehicle_groups_request(vhcgids, uid))
            if(devgids) dispatch(get_device_groups_request(devgids, uid))
            if(geotids) dispatch(get_geofence_templates_request(geotids, uid))


        } catch(err) {
            message.error('Error. Refresh page?')
        }
    })

}

const hierarchial_user_info_get_success = (user) => {
    return {
        type: ActionTypes.GET_HIERARCHIAL_USER_INFO,
        user
    };
}

/**
 * Set Selected User
 */

export const set_selected_user_for_hierarchy_request = (uid) => dispatch => {
    dispatch(set_selected_user_for_hierarchy_success(uid))
}

const set_selected_user_for_hierarchy_success = (uid) => {
    return {
        type: ActionTypes.SET_SELECTED_USER_FOR_USER_HIERARCHY,
        uid
    }
}

export const unset_selected_user_for_hierarchy_request = () => dispatch => {
    dispatch(unset_selected_user_for_hierarchy_success())
}

const unset_selected_user_for_hierarchy_success = () => {
    return {
        type: ActionTypes.UNSET_SELECTED_USER_FOR_USER_HIERARCHY
    }
}