export const GET_VAM_LINE_ITEM = "V3_GET_VAM_LINE_ITEM";
export const GET_VAM_LINE_ITEMS = "V3_GET_VAM_LINE_ITEMS";
export const GET_VAM_LINE_TEMPLATE = "V3_GET_VAM_LINE_TEMPLATE";
export const GET_VAM_LINE_TEMPLATES = "V3_GET_VAM_LINE_TEMPLATES";
export const GET_VAM_ENTRY_THRU_VID = "V3_GET_VAM_ENTRY_THRU_VID";
export const GET_VAM_PROGRAM = "V3_GET_VAM_PROGRAM";
export const GET_VAM_PROGRAMS = "V3_GET_VAM_PROGRAMS";
export const GET_VAM_REMINDERS_THRU_VID = "V3_GET_VAM_REMINDERS_THRU_VID";
export const GET_VAM_SCHEDULE = "V3_GET_VAM_SCHEDULE";

export const CU_VAM_ENTRY = 'V3_CU_VAM_ENTRY'
export const CU_VAM_LINE_ITEM = 'V3_CU_VAM_LINE_ITEM'
export const CU_VAM_REMINDER = 'V3_CU_VAM_REMINDER'

export const SET_VAM_REMINDER_VAMRID = 'V3_SET_VAM_REMINDER_VAMRID'
export const UNSET_VAM_REMINDER_VAMRID = 'V3_UNSET_VAM_REMINDER_VAMRID'

export const SET_VAM_ENTRY_VAMRIDS = 'V3_SET_VAM_ENTRY_VAMRIDS'
export const UNSET_VAM_ENTRY_VAMRIDS = 'V3_UNSET_VAM_ENTRY_VAMRIDS'
export const SET_VAM_ENTRY_VID = 'V3_SET_VAM_ENTRY_VID'
export const UNSET_VAM_ENTRY_VID = 'V3_UNSET_VAM_ENTRY_VID'
export const SET_VAM_ENTRY_VAMEID = 'V3_SET_VAM_ENTRY_VAMEID'
export const UNSET_VAM_ENTRY_VAMEID = 'V3_UNSET_VAM_ENTRY_VAMEID'

export const SET_VAM_LINE_VAMLID = 'V3_SET_VAM_LINE_VAMLID'
export const UNSET_VAM_LINE_VAMLID = 'V3_UNSET_VAM_LINE_VAMLID'