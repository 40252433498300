import 
    React 
from "react";
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router";
import { Radio } from "antd";

// Constant && Config
import { 
    ROUTES, 
    COMMON_FEATURES 
} from '../../constants'

// Components
import {
    VAMConfigurationManagement,
    VAMEntryManagement,
    VAMReminderManagement
} from './components'
import showSecondarySidebar from '../../layout/MainLayout/helpers/showSecondarySidebar'
import Page from "../../components/Decoration/Page"

const VAMPanelTab = {
    reminder: {
        label: 'Vehicle Reminder Management',
        key: 'reminder',
        value: COMMON_FEATURES.FEATURE_NAME.VEHICLE_REMINDER_MANAGEMENT,
        component: <VAMReminderManagement />,
    },
    entry: {
        label: 'Service Entry Management',
        key: 'entry',
        value: COMMON_FEATURES.FEATURE_NAME.VEHICLE_ENTRY_MANAGEMENT,
        component: <VAMEntryManagement />,
    },
    configuration: {
        label: 'Configuration Management',
        key: 'configuration',
        value: COMMON_FEATURES.FEATURE_NAME.VEHICLE_ADVANCE_MAINTENANCE_CONFIGURATION_MANAGEMENT,
        component: <VAMConfigurationManagement />,
    }
}

const VAM = () => {

    const navigate = useNavigate()
    const routerLocation = useLocation()

    const curUserRole = useSelector(state => state.containers.v3.user && state.containers.v3.user.uroleid)

    const onChangeTableTab = (e) => {
        navigate(ROUTES.MODULE_ROUTE[e.target.value])
    }

    let tab = routerLocation.pathname.split('/').pop()

    if (!VAMPanelTab[tab] || tab === 'overall') {
        tab = VAMPanelTab.reminder.key
    }

    return (
        <div className="page-container">
            <Page title="Overall Vehicle Advance Management">
                <Radio.Group value={VAMPanelTab[tab].value} buttonStyle="solid" onChange={onChangeTableTab}>
                    {
                        Object.values(VAMPanelTab)
                            .filter(panel => {
                                if (!curUserRole) return false
                                if (!panel.hiddenFrom) return true
                                return !panel.hiddenFrom.includes(curUserRole)
                            })
                            .map(panel =>
                                <Radio.Button key={panel.key} value={panel.value}>
                                    {panel.label}
                                </Radio.Button>
                            )
                    }
                </Radio.Group>

                <div style={{ marginTop: 30 }}>
                    {
                        VAMPanelTab[tab] && VAMPanelTab[tab].component
                    }
                </div>
            </Page>
        </div>
    )
}

const MemorizedVAM = React.memo(VAM);

export default showSecondarySidebar(false)(MemorizedVAM);