/**
 * Micro
 */
const rad2degr = (rad) => (rad * 180 / Math.PI)
const degr2rad = (degr) => (degr * Math.PI / 180)


/**
 * Main
 */

/**
 * @return array with the center latitude longtitude pairs in 
 *   degrees.
 */
 export const getLatLngCenter = (coordinates) => {
    // let LATIDX = 0;
    // let LNGIDX = 1;
    let sumX = 0;
    let sumY = 0;
    let sumZ = 0;

    coordinates.map(coordinate=>{
        let lat = degr2rad(coordinate.lat)
        let lng = degr2rad(coordinate.lng)

        sumX += Math.cos(lat) * Math.cos(lng);
        sumY += Math.cos(lat) * Math.sin(lng);
        sumZ += Math.sin(lat);

        return null;
    })

    let avgX = sumX / coordinates.length;
    let avgY = sumY / coordinates.length;
    let avgZ = sumZ / coordinates.length;

    // convert average x, y, z coordinate to latitude and longtitude
    let lng = Math.atan2(avgY, avgX);
    let hyp = Math.sqrt(avgX * avgX + avgY * avgY);
    let lat = Math.atan2(avgZ, hyp);

    return ({
        lat: rad2degr(lat), 
        lng: rad2degr(lng)
    });
}