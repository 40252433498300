import * as API_CONSTANT from '../constants'

export const getGeofenceInfoByGEOID = (geoid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/geofence/getInfo`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            geoid
        })
    })
    .then(res => res.json())
)

export const getGeofenceInfoBatch = (geoids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/geofence/batch/getInfo`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            geoids
        })
    })
    .then(res => res.json())
)

export const CU_Geofence = (item) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/geofence/cu`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify(item)
    })
    .then(res => res.json())
)

export const getGeofenceTemplateByGEOTID  = (geotid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/geofence/getTemplate`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            geotid
        })
    })
    .then(res => res.json())
)

export const getGeofenceTemplateBatch = (geotids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/geofence/batch/getTemplate`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            geotids
        })
    })
    .then(res => res.json())
)

export const CU_GeofenceTemplate = (item, uid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/geofence/template/cu`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            ...item,
            uid
        })
    })
    .then(res => res.json())
)