import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Tag, Space, Tooltip, Button, Row } from 'antd';
import {
    EditOutlined
} from '@ant-design/icons'

// Constant
import { TEMPLATE } from '../../../../../../constants'

// Components
import { VAMLineItemCUModal } from '../VAMLineItemCUModal'

// Handlers
import { table_handler } from '../../../../../../handlers' 

// Services
import { TIME, REDUX } from '../../../../../../services/util'

// Redux Action
import * as REDUX_ACTION from '../../../../../../services/redux/actions'

const {
    VAM
} = TEMPLATE

const {
    VAM_LINE_TYPE_BY_CODE,
    VAM_LINE_TYPE_COLOR_BY_CODE,
    MODAL_CU_PROCESS_ACTION
} = VAM

const { getColumnSearchProps } = table_handler

const { parseTime } = TIME

export const VAMLineItemPanel = () => {

    const dispatch = useDispatch()

    const vamLines = useSelector(REDUX.reduxStoreWrapper('vamLines.itemByID'))
    const vamlids = useSelector(REDUX.reduxStoreWrapper('vamLines.allVAMLIDs'))

    const searchTextState = useState('')
    const searchedColumnState = useState('')

    const [ CUModalVisible, setCUModalVisible ] = useState(false)
    const [ CUModalAction, setCUModalAction ] = useState(MODAL_CU_PROCESS_ACTION.CREATE)

    const vamLineReData = () => 
        vamlids
        &&
        vamlids.map(vamlid => (
            {
                key: vamlid,
                ...vamLines[vamlid]
            }
        ))
        ||
        []

    const onCreateLineItem = () => {
        dispatch(REDUX_ACTION.v3_vamLines.unset_vamlid_request())
        setCUModalAction(MODAL_CU_PROCESS_ACTION.CREATE)
        setCUModalVisible(true)
    }

    const onEditLineItem = ({vamlid}) => {
        dispatch(REDUX_ACTION.v3_vamLines.set_vamlid_request(vamlid))
        setCUModalAction(MODAL_CU_PROCESS_ACTION.EDIT)
        setCUModalVisible(true)
    }

    const onCUModalClose = () => {
        setCUModalVisible(false)
    }

    const vamLineCols = [
        {
            title: 'Line Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            fixed: 'left',
            ...getColumnSearchProps('Line Name', 'name', searchTextState, searchedColumnState)
        },
        {
            title: 'Line Type',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            fixed: 'left',
            filters: Object.keys(VAM_LINE_TYPE_BY_CODE)
                .map(status => (
                    {
                        text: VAM_LINE_TYPE_BY_CODE[status],
                        value: status,
                    }
                )),
            onFilter: (value, record) => record.type === value,
            render: type => <Tag color={VAM_LINE_TYPE_COLOR_BY_CODE[type]}>{VAM_LINE_TYPE_BY_CODE[type]}</Tag>
        },
        {
            title: "Updated At",
            dataIndex: "updatedAt",
            defaultSortOrder: "ascend",
            align: 'center',
            sorter: (a, b) => a.updatedAt - b.updatedAt,
            render: updatedAt => parseTime(Number(updatedAt)),
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            defaultSortOrder: "ascend",
            align: 'center',
            sorter: (a, b) => a.createdAt - b.createdAt,
            render: createdAt => parseTime(Number(createdAt)),
        },
        {
            title: "Actions",
            fixed: 'right',
            render: (data) => (
                <Space size="middle" style={{float: "left"}}>
                    <Tooltip title={'Edit Line Item'}>
                        <Button 
                            shape='circle' 
                            icon={<EditOutlined />}
                            type="primary"
                            onClick={() => {onEditLineItem(data)}}
                        />
                    </Tooltip>
                </Space>
            )
        }
    ]

    return (
        <>
            <VAMLineItemCUModal
                action={CUModalAction}
                open={CUModalVisible}
                onClose={() => onCUModalClose()}
            />
            <Row justify={'end'} style={{padding: '10px'}}>
                <Button type="primary" onClick={() => {onCreateLineItem()}} >Add Line Item</Button>
            </Row>
            <Row style={{justifyContent:"center", width: '100%'}}>
                <Table 
                    size={'small'}
                    style={{width: '100%'}}
                    bordered
                    columns={vamLineCols} 
                    dataSource={vamLineReData()} 
                    pagination={{
                        position: ['topRight']
                    }}
                />
            </Row>
        </>
    )
}