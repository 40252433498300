import React, { } from 'react'
import { 
    useSelector
} from 'react-redux'
import { useNavigate, useLocation } from 'react-router'
import { Radio } from "antd";

// Constants
import { 
    TEMPLATE,
    ROUTES, 
    COMMON_FEATURES 
} from '../../constants'

// Components
import showSecondarySidebar from '../../layout/MainLayout/helpers/showSecondarySidebar' 
import Page from "../../components/Decoration/Page"

// Panel
import DevicePanel from './components/DevicePanel'
import NotificationPanel from './components/NotificationPanel'
import RuleEnginePanel from './components/RuleEnginePanel'
import VehiclePanel from '../VehicleManagement/modules/VehiclePanel'
import VehicleGroupPanel from '../VehicleManagement/modules/VehicleGroupPanel'

// Service

const PANELS = {
    vehicle: {
        label: 'Vehicles',
        key: 'vehicle',
        value: COMMON_FEATURES.FEATURE_NAME.VEHICLE_MANAGEMENT,
        component: <VehiclePanel />,
    },
    vehicleGroup: {
        label: 'Vehicle Group',
        key: 'vehicleGroup',
        value:  COMMON_FEATURES.FEATURE_NAME.VEHICLE_GROUP_MANAGEMENT,
        component: <VehicleGroupPanel />
    },
    device: {
        label: 'Devices',
        key: 'device',
        value:  COMMON_FEATURES.FEATURE_NAME.DEVICE_MANAGEMENT,
        component: <DevicePanel />,
        hiddenFrom: [TEMPLATE.USER_ROLE.ROLE_TYPE.FLEET_OWNER , TEMPLATE.USER_ROLE.ROLE_TYPE.FLEET_MANAGER, TEMPLATE.USER_ROLE.ROLE_TYPE.FLEET_OPERATOR]
    },
    rule: {
        label: 'Rule Engine',
        key: 'rule',
        value: COMMON_FEATURES.FEATURE_NAME.RULE_ENGINE_MANAGEMENT,
        component: <RuleEnginePanel />,
    },
    notification: {
        label: 'Notification',
        key: 'notification',
        value: COMMON_FEATURES.FEATURE_NAME.NOTIFICATION_MANAGEMENT,
        component: <NotificationPanel />,
    },
}

const OverallManagementPanel = () => {

    const navigate = useNavigate()
    const routerLocation = useLocation()

    const curUserRole = useSelector(state => state.containers.v3.user && state.containers.v3.user.uroleid)

    const onChangeTableTab = (e) => {
        navigate(ROUTES.MODULE_ROUTE[e.target.value])
    }

    let tab = routerLocation.pathname.split('/').pop()

    if (!PANELS[tab] || tab === 'overall') {
        tab = PANELS.vehicle.key
    }

    return (
        <div className="page-container">
            <Page title="Overall Management">
                <Radio.Group value={PANELS[tab].value} buttonStyle="solid" onChange={onChangeTableTab}>
                    {
                        Object.values(PANELS)
                            .filter(panel => {
                                if (!curUserRole) return false
                                if (!panel.hiddenFrom) return true
                                return !panel.hiddenFrom.includes(curUserRole)
                            })
                            .map(panel =>
                                <Radio.Button key={panel.key} value={panel.value}>
                                    {panel.label}
                                </Radio.Button>
                            )
                    }
                </Radio.Group>

                <div style={{ marginTop: 30 }}>
                    {
                        PANELS[tab] && PANELS[tab].component
                    }
                </div>
            </Page>
        </div>
    )
}

const MemorizedOverallManagementPanel = React.memo(OverallManagementPanel);

export default showSecondarySidebar(false)(MemorizedOverallManagementPanel);