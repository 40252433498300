import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
    Table,
    Tag
} from "antd";

// Constant

// Components
import VehiceInformationTable from '../VehiceInformationTable';

// Handler
import { table_handler } from '../../../../handlers'

const {
    getColumnSearchProps
} = table_handler

const GeofenceSummaryPanel = () => {

    const vehicles = useSelector(state => state.containers.v3.vehicles.geoStateByID)
    const geofences = useSelector(state => state.containers.v3.geofences.byID)
    const geoids = useSelector(state => state.containers.v3.geofences.allIDs)
    const geofenceTemplates = useSelector(state => state.containers.v3.geofenceTemplates.byID)
    const geotids = useSelector(state => state.containers.v3.geofenceTemplates.allIDs)

    const [ dataSrc, setDataSrc ] = useState([])
    const searchTextState = useState('')
    const searchedColumnState = useState('')

    useEffect(() => {

        if(geoids && geoids.length) {

            const newDataSrc = geoids.map(geoid => ({
                key: geoid,
                ...geofences[geoid],
                geotids: geotids.filter(geotid => geofenceTemplates[geotid].geofences.includes(geoid)),
                vids: Object.keys(vehicles).filter(vid => vehicles[vid].geoIN.includes(geoid)),
                vhcNums: Object.values(vehicles).filter(vhc => vhc.geoIN.includes(geoid)).length,
            }))

            setDataSrc(newDataSrc)
        }

    }, [geoids, geofenceTemplates, geofences, vehicles])

    const GeofenceSummaryColumns = [
        {
            title: "Name",
            dataIndex: "name",
            render: (data) => data ? data : "-",
            ...getColumnSearchProps('Geofence Name', 'name', searchTextState, searchedColumnState),
        },
        {
            title: "Geofence Templates",
            dataIndex: "geotids",
            filters: geotids.map(geotid => ({ text: geofenceTemplates[geotid].name, value: geotid})),
            onFilter: (value, record) => record.geotids && record.geotids.includes(value),
            render: (data) => data && data.length && data.map(geotid => 
                <Tag color={geofenceTemplates[geotid].colorHexCode || 'blue'} key={geotid}>{geofenceTemplates[geotid].name}</Tag>
            ),
        },
        {
            title: "Type",
            dataIndex: "type",
            filters: [{ text: "POI", value: "POI" }, { text: "ZONE", value: "Polygon" }],
            onFilter: (value, record) => record.type && record.type.indexOf(value) === 0,
            render: (data) => (
                <div
                    style = {{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        span = {7}
                        className = "POI"
                        style = {{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",

                            width: 100,
                            height: 20,
                        }}
                    >
                        {data}
                    </div>
                </div>
            ),
        },
        {
            title: "No. of Vehicles Inside",
            dataIndex: "vhcNums",
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.vhcNums - b.vhcNums,
        }
    ]

    return (
        <>
            <div
                style = {{
                    flex: 1,
                    display: "flex",
                }}
            >
                <div
                    className = "scroll-div"
                    style = {{ width: "100%" }}
                >
                    <Table
                        columns = {GeofenceSummaryColumns}
                        dataSource = {dataSrc}
                        expandable = {{
                            rowExpandable: (record) => record.vhcNums || 0,
                            expandedRowRender: (record) => <VehiceInformationTable vids={record.vids} />,
                        }}
                        pagination = {dataSrc.length > 10}
                        scroll = {{
                            x: GeofenceSummaryColumns.length * 150,
                            y: window.innerHeight * 0.6,
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default GeofenceSummaryPanel