// Constant
import {
    TEMPLATE
} from '../../constants'

// Services
import {
    TIME
} from '../../services/util'

const {
    VEHICLE_TRANSIT_STATUS_OBJECT
} = TEMPLATE.STATUS

const {
    timeDiff,
    dayjsDatetimeTranslate,
    durationFormat
} = TIME

export const showVehicleStateLastUpdated_DateTime = (vehicle) => {
    return `Last Updated: ${dayjsDatetimeTranslate(vehicle.vehicleTime || vehicle.deviceTime)}`
}

export const showVehicleTransitStatusChangeDuration = (vehicle) => (
    vehicle.transitStatus 
    && vehicle.transitStatus !== VEHICLE_TRANSIT_STATUS_OBJECT.DISCONNECTED 
    ?
        `${vehicle.transitStatus} For ${timeDiff(vehicle.transitFrom, Date.now())}` 
        :
        (
            Object.prototype.hasOwnProperty.call(vehicle, 'vehicleTime') ?
            `${vehicle.transitStatus} For ${timeDiff(vehicle.transitFrom, Date.now())}` 
            :
            (
                vehicle.gnss_status ?
                    `${vehicle.transitStatus} For ${timeDiff(vehicle.transitFrom, Date.now())}` :
                    `${vehicle.transitStatus} For ${timeDiff(vehicle.transitFrom, vehicle.updatedGPSAt)}`
            )
        )                                    
)

export const showVehicleTransitStatusChangeDuration_WithoutLabel = (vehicle) => {
    return durationFormat(Date.now() - vehicle.transitFrom)
}

export const locationValidator = (location) => location && Object.prototype.hasOwnProperty.call(location, 'lat') && Object.prototype.hasOwnProperty.call(location, 'lon') && true || false

export const locationMarkiser = (location) => location 
    && Object.prototype.hasOwnProperty.call(location, 'lat') 
    && Object.prototype.hasOwnProperty.call(location, 'lon') 
    && {
        lat: Number(location.lat),
        lng: Number(location.lon)
    } 
    || {
        lat: 0,
        lng: 0
    }

export const vehicleECUValidator = (vehicle) => {
    let res = {
        vid: vehicle && vehicle.vid
    }

    if(vehicle) {
        if(Object.prototype.hasOwnProperty.call(vehicle, "has_ecu")) res.has_ecu = vehicle.has_ecu
        if(Object.prototype.hasOwnProperty.call(vehicle, "has_ecu_can")) res.has_ecu_can = vehicle.has_ecu_can
        if(Object.prototype.hasOwnProperty.call(vehicle, "has_ecu_obd")) res.has_ecu_obd = vehicle.has_ecu_obd
        if(Object.prototype.hasOwnProperty.call(vehicle, "has_ecu_obd_oem")) res.has_ecu_obd_oem = vehicle.has_ecu_obd_oem
    }

    return vehicle && res || {}
}

export const vehicleStatesMerger = (statics, states) => {
    let res = []

    statics
    &&
    Object.keys(statics)
    &&
    Object.keys(statics).length
    &&
    Object.keys(statics).map(vid => {
        res[vid] = {...statics[vid], ...states[vid]}
        return null
    })

    return res
}