import 
    React,
    {
        useEffect,
        useState
    }
from 'react'
import { 
    Alert
} from 'antd';

const NetworkValidator = () => {

    const [isOffline, setIsOffline] = useState(null)

    useEffect(() => {
        /**Check network issues */
        window.addEventListener('offline', () => {
            setIsOffline(true)

        })

        window.addEventListener('online', () => {
            setIsOffline(false)
        })

        return () => {
            window.removeEventListener('offline', () => {});
            window.removeEventListener('online', () => {});
        }

    }, [])

    const NetworkAlertRender = () => {
        switch(isOffline) {
            case true: {
                return (
                    <Alert
                        type="error"
                        message="Network error. Refresh page ?"
                        banner
                        closable
                    />
                )
            }
            case false: {
                return (
                    <Alert
                        type="success"
                        message="Back online."
                        banner
                        closable
                    />
                )    
            }
            default: 
                return <></>
        }
    }

    return (
        <>
            { NetworkAlertRender()}
        </>
    )
}

export default NetworkValidator