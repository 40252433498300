import * as ActionTypes from '../action-types'
import { deepMerge } from '../../util'

const geofences_DefaultState = {
    byID: {},
    allIDs: [],
    selectedID: ''
}

export const geofences = (state = geofences_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.CLEAR_SELECTED_GEOFENCE_GEOID: {
            newState.selectedID = ''

            return newState            
        }
        case ActionTypes.GET_GEOFENCE: {

            const geofence = action.geofence

            const geoid = geofence && geofence.geoid

            if(geoid) {
                if(Object.prototype.hasOwnProperty.call(newState.byID, geoid)) {

                    newState.byID[geoid] = deepMerge(newState.byID[geoid], geofence)
    
                } else {
    
                    newState.byID[geoid] = geofence
    
                }
    
                if(!newState.allIDs.includes(geoid)) newState.allIDs.push(geoid)
            }

            return newState
        }
        case ActionTypes.GET_GEOFENCES: {

            const geofences = action.geofences

            geofences && geofences.map(geo => {

                const geoid = geo.geoid

                if(Object.prototype.hasOwnProperty.call(newState.byID, geoid)) {

                    newState.byID[geoid] = deepMerge(newState.byID[geoid], geo)

                } else {

                    newState.byID[geoid] = geo

                }

                if(!newState.allIDs.includes(geoid)) newState.allIDs.push(geoid)

                return null;
            }) 

            return newState
        }
        case ActionTypes.SET_SELECTED_GEOFENCE_GEOID : {
            const geoid = action.geoid

            newState.selectedID = geoid

            return newState            
        }
        case ActionTypes.CU_GEOFENCE: {

            const {
                geofence
            } = action

            const {
                geoid
            } = geofence

            if(Object.prototype.hasOwnProperty.call(newState.byID, geoid)) {

                newState.byID[geoid] = {
                    ...newState.byID[geoid],
                    ...geofence
                }

            } else {

                newState.byID[geoid] = {
                    ...geofence
                }
            }

            if(!newState.allIDs.includes(geoid)) newState.allIDs.push(geoid)

            return newState
        }
        default: {
            return newState;
        }
    }
}