import React, { useState, useEffect } from 'react'
import {
    Button,
    Modal
} from "antd";
import { ReloadOutlined } from '@ant-design/icons'
import ReactPlayer from 'react-player'

// Components
import ModalPortal from '../../../../components/ModalPortal';

// Handler
import { PLUS_Highway_Surveillance_VideoClip } from '../../../../handlers/video'

const SurveillanceVideoModal = (props) => {

    const {
        isSUVModalVisible,
        SUVModalCancelHandler,
        SUVModalRefreshHandler,
        section
    } = props

    const [ url, setUrl ] = useState(null)

    useEffect(() => {
        section
        &&
        PLUS_Highway_Surveillance_VideoClip(section)
        .then(u => {
            setUrl(URL.createObjectURL(u))
        })
    }, [section])

    const TitleComponent = ({section}) => 
        <>
            {
                section
                &&
                <span>{`${section} - Surveillance Video Clip`}</span>
                ||
                <span>{`Surveillance Video Clip`}</span>
            }
            <span style={{paddingLeft:30}}>
                <Button 
                    key = "refresh" 
                    type = "danger"
                    shape="circle"
                    icon = {<ReloadOutlined />}
                    style = {{ marginRight: 10 }}
                    onClick={() => SUVModalRefreshHandler(section)}
                />
            </span>
        </>

    return (
        <>
            <Modal 
                title={<TitleComponent section={section}/>}
                open={isSUVModalVisible} 
                onCancel={SUVModalCancelHandler}
                width={730}
                footer={[
                    <Button 
                        key = "cancel" 
                        type = "danger"
                        style = {{ marginRight: 10 }}
                        onClick={SUVModalCancelHandler}
                    >
                        Cancel
                    </Button>
                ]}
            >
                <div style = {{ padding: "30px 20px 0px 20px" }}>
                    <ReactPlayer 
                        url={url} 
                        className='react-player'
                        playing
                        controls
                        loop={true}
                    />
                </div>
            </Modal>
        </>
    )

}

export default ModalPortal(SurveillanceVideoModal)