import React from "react";
import { Result, Button } from "antd";

/**
 * Constant
 */
import { ROOT_CONSTANT } from '../../../../constants'

/**
 * Styles
 */
import "./index.css";

const SSOAdviceDescription = (props) => {

    return (
        <>
            <Result
                status="403"
                title="403"
                subTitle="It seems you are not authorised to access this app. Please contract your account manager to allow your access."
                extra={
                    <Button
                        style={{
                            width: "100%",
                            backgroundColor: ROOT_CONSTANT.THEME.THEME_COLOR,
                            border: "none",
                            boxShadow: "2px 2px 5px #696969"
                        }}
                        type="primary"
                        className="login-form-button"
                        onClick={props.onClick}
                    >
                            Back
                    </Button>
                }
            />
        </>
    )

}

export default React.memo(SSOAdviceDescription);