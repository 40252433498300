import * as API_CONSTANT from '../constants'

export const getUserInfoByAuthID = (authid, authProvider) => (
  fetch(`${API_CONSTANT.fms_sso_path}/user/authid/getInfo`, {
      method: 'POST',
      headers: API_CONSTANT.headers,
      body: JSON.stringify({
          authid, 
          authProvider
      })
  })
  .then(res => res.json())
)