import React, { useState } from 'react'
import {
    InputNumber,
    Select
} from 'antd'

// Constants
import { TEMPLATE } from '../../../../../../../../constants'

const {
    TIME_UNIT
} = TEMPLATE.VAM

export const TimeIntervalSelector = (props) => {

    const { id, value = {}, onChange, type } = props

    const [val, setVal] = useState(0);
    const [unit, setUnit] = useState(TIME_UNIT.day[0]);

    const triggerChange = (changedValue) => {
        onChange?.({
          val,
          unit,
          ...value,
          ...changedValue,
        });
    };

    const onValChange = (e) => {
        const newVal = parseInt(e || '0', 10);
        if (Number.isNaN(val)) {
          return;
        }
        if (!('val' in value)) {
          setVal(newVal);
        }
        triggerChange({
          val: newVal,
        });
      };
    
    const onUnitChange = (newUnit) => {
        if (!('unit' in value)) {
            setUnit(newUnit);
        }
        triggerChange({
            unit: newUnit,
        });
    };

    return(
        <span id={id}>
            <InputNumber
                addonBefore={type == "interval" && "Every"}
                value={value.val|| val}
                onChange={onValChange}
                style={{
                    width: '70%',
                }}
            />
            <Select
                value={value.unit || unit}
                style={{
                    width: '30%'
                }}
                onChange={onUnitChange}
                options={
                    Object.keys(TIME_UNIT).map(tu => ({
                        label: TIME_UNIT[tu][1],
                        value: TIME_UNIT[tu][0]
                    }))
                }
            />
        </span>   
    )

}