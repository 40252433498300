import React, { memo, useEffect, useState }  from 'react'
import { useSelector, batch } from 'react-redux'
import {
    Polyline
} from '@react-google-maps/api';
import getDistance from 'geolib/es/getDistance';

// Constant
import { MAP } from '../../../../../constants'

// Components
import IndicatorMarker from '../../../components/IndicatorMarker';
import TraceInformation from '../../../components/TraceInformation';

// Handler
import { map_handler, vehicles_handler } from '../../../../../handlers'

// Service
import { REDUX as REDUX_UTIL } from '../../../../../services/util'

const {
    getRouteType
} = map_handler

const {
    locationValidator,
    locationMarkiser
} = vehicles_handler

const {
    reduxStoreWrapper
} = REDUX_UTIL

const RoutePlaybackTrace = (props) => {

    const { 
        mapRef 
    } = props;

    const zoom = mapRef?.zoom

    const vid = useSelector(reduxStoreWrapper('vehicles.selectedID'))
    const selectedVehicleLogs = useSelector(reduxStoreWrapper(`vehicleLogs.logByVID.${vid}`)) || []
    const selectedTransitRecords = useSelector(reduxStoreWrapper(`transitRecords.recordByVID.${vid}`)) || []
    const vhcLogTime = useSelector(reduxStoreWrapper('vehicleLogs.selectedTime'))

    const selectedTransitStartTime = useSelector(reduxStoreWrapper('transitRecords.selectedStartTime'))
    const selectedTransitEndTime = useSelector(reduxStoreWrapper('transitRecords.selectedEndTime'))
    const selectedTripStartTime = useSelector(reduxStoreWrapper('tripRecords.selectedStartTime'))
    const selectedTripEndTime = useSelector(reduxStoreWrapper('tripRecords.selectedEndTime'))

    const isFocusPointOnMap = useSelector(reduxStoreWrapper('mapControl.mapEvent.isFocusPoint'))
    const focusDetailPointTypeOnMap = useSelector(reduxStoreWrapper('mapControl.mapEvent.focusDetailPointType'))

    const UIControl = useSelector(reduxStoreWrapper('mapControl.UIControl'))

    const [ routeTraces, setRouteTraces ] = useState([])

    const [ traceIndicator, setTraceIndicator ] = useState(null)
    const [ journeyStartIndicator, setJourneyStartIndicator ] = useState(null)
    const [ journeyEndIndicator, setJourneyEndIndicator ] = useState(null)

    useEffect(() => {
        
        const newTraces = selectedVehicleLogs
        .map(vl => {

            const vehicleTime = vl.vehicleTime
            const InTransitRecord = selectedTransitRecords.find(tr => tr.startTime === selectedTransitStartTime && tr.endTime === selectedTransitEndTime)

            const isTripSelected = selectedTripStartTime && selectedTripEndTime && vehicleTime >= selectedTripStartTime && vehicleTime <= selectedTripEndTime
            const isTransitSelected = isFocusPointOnMap && focusDetailPointTypeOnMap === MAP.MAP_EVENT_FOCUS_POINT_TYPE_OBJ.TRANSIT && vehicleTime >= selectedTransitStartTime && vehicleTime <= selectedTransitEndTime
            const transitStatus = isFocusPointOnMap && focusDetailPointTypeOnMap === MAP.MAP_EVENT_FOCUS_POINT_TYPE_OBJ.TRANSIT && InTransitRecord && InTransitRecord.transitStatus

            const new_vl = {
                ...vl,
                isTripSelected,
                isTransitSelected,
                transitStatus
            }

            new_vl.traceType = getRouteType(new_vl).label

            return new_vl
        })

        if (mapRef && newTraces.length) {
            const bounds = new window.google.maps.LatLngBounds();

            newTraces.forEach(c => bounds && bounds.extend(locationMarkiser(c.location)));

            mapRef.fitBounds(bounds, [10]);
        }

        newTraces && newTraces.length && setRouteTraces(newTraces)

    }, [mapRef, selectedVehicleLogs, selectedTransitStartTime, selectedTransitEndTime, selectedTripStartTime, selectedTripEndTime, isFocusPointOnMap, focusDetailPointTypeOnMap])

    useEffect(() => {

        const trace = selectedVehicleLogs.find(v => v.vehicleTime === vhcLogTime) || selectedVehicleLogs[0]

        trace && setTraceIndicator(trace)

    }, [selectedVehicleLogs, vhcLogTime])

    useEffect(() => {

        if(selectedVehicleLogs.length) {
            batch(() => {
                setJourneyStartIndicator(selectedVehicleLogs[0])
                setJourneyEndIndicator(selectedVehicleLogs[selectedVehicleLogs.length - 1])
            })
        }

    }, [selectedVehicleLogs])

    const routerTraceBuilder = () => {

        let prevTraceType = null
        const journeyList = []
        const bufJourneyTemplate = {
            options: {},
            paths: []
        }
        let bufJourney = JSON.parse(JSON.stringify(bufJourneyTemplate))

        routeTraces
        .filter((routeTrace) => locationValidator(routeTrace.location))
        .map((routeTrace, r) => {

            const location = routeTrace.location
            const currTraceType = routeTrace.traceType

            if( (prevTraceType && prevTraceType !== currTraceType) ) {
                journeyList.push(bufJourney)
                bufJourney = JSON.parse(JSON.stringify(bufJourneyTemplate))
            }

            if(currTraceType) bufJourney.options = MAP.MAP_ROUTE_TRACE_TYPE[currTraceType].options 

            location && bufJourney.paths.push(locationMarkiser(location))

            if(r === routeTraces.length - 1) {
                journeyList.push(bufJourney)
                bufJourney = JSON.parse(JSON.stringify(bufJourneyTemplate))
            }

            prevTraceType = currTraceType
        })

        const aroundMapCenterValidator = (location) => {
            const {
                lat,
                lon
            } = location

            const mlat = mapRef?.center.lat()
            const mlon = mapRef?.center.lng()

            return getDistance(
                { latitude: lat, longitude: lon },
                { latitude: mlat, longitude: mlon }
            )
        }

        return (
            <>
                {
                    routeTraces
                    .filter((routeTrace) => locationValidator(routeTrace.location))
                    .filter((r) => aroundMapCenterValidator(r.location) <= 400)
                    .map((routeTrace, i) => {
                        return (
                            zoom >= 18
                            &&
                            <IndicatorMarker
                                KEY={`trace#${i}#${routeTrace?.vehicleTime || '-'}`}
                                location = {routeTrace.location}
                                InfoComponent = { () => 
                                    <>
                                        <TraceInformation key={`trace#info#${i}#${routeTrace?.vehicleTime || '-'}`} trace={routeTrace}/>
                                    </>
                                }
                            />
                        )
                    })
                }

                {
                    journeyList.map((journey, j) => {
                        if(j < journeyList.length - 1 ) {
        
                            const nextJourney = journeyList[j + 1];
                            const lastPoint = nextJourney && nextJourney.paths && nextJourney.paths[0];
            
                            if (lastPoint) {
                                journey.paths.push(lastPoint)
                            }
        
                        }        
                        return (
                            <>
                                <Polyline
                                    key = {`Journey#${j}`}
                                    path = {journey.paths}
                                    options = {{
                                        ...journey.options,
                                        icons: [{
                                            icon: { path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                                            repeat: '100px',
                                            offset: '100%'
                                        }],
                                    }}
                                    style = {{ cursor: `pointer` }}
                                />
                            </>
                        )
                    })
                }
            </>
        )
    }

    return (
        <>
            {
                traceIndicator
                &&
                locationValidator(traceIndicator.location)
                &&
                <IndicatorMarker
                    KEY={'journey#indicator'}
                    location = {traceIndicator.location}
                    shown = {UIControl.showIndicatiorInfoWindow}
                    InfoComponent = { () => 
                        <>
                            <TraceInformation trace={traceIndicator}/>
                        </>
                    }
                />
            }

            {
                journeyStartIndicator
                &&
                <IndicatorMarker
                    KEY={'journey#start'}
                    location = {journeyStartIndicator.location}
                    markerProps = { {icon: MAP.MAP_MARKER_INDICATOR.START_FLAG }}
                    infoDefault = {true}
                    InfoComponent = { () => 
                        <>
                            <div
                                style = {{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",

                                    fontWeight: "bold",
                                }}
                            >
                                {`Start Journey`}
                            </div>
                        </>
                    }
                />
            }

            {
                journeyEndIndicator
                &&
                <IndicatorMarker
                    KEY={'journey#end'}
                    location = {journeyEndIndicator.location}
                    markerProps = { {icon: MAP.MAP_MARKER_INDICATOR.END_FLAG }}
                    infoDefault = {true}
                    InfoComponent = { () => 
                        <>
                            <div
                                style = {{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",

                                    fontWeight: "bold",
                                }}
                            >
                                {`End Journey`}
                            </div>
                        </>
                    }
                />
            }

            { routerTraceBuilder() }


        </>
    )
}

export default memo(RoutePlaybackTrace)