import { TIME } from '../../services/util'
import dayjs from 'dayjs'

const { parseDate } = TIME

const SERVICE_PARAMS = {
    DUE_DATE: 'DUE DATE',
    WORKING_HOUR: 'WORKING HOUR',
    ODOMETER: 'ODOMETER'
}

const SERVICE_CODE = {
    [100]: SERVICE_PARAMS.DUE_DATE,
    [101]: SERVICE_PARAMS.WORKING_HOUR,
    [200]: SERVICE_PARAMS.ODOMETER
}

const SERVICE_PARAMS_FORM_ENCODER = {
    [SERVICE_PARAMS.DUE_DATE]: (data) => dayjs(data),
    [SERVICE_PARAMS.WORKING_HOUR]: (data) => data,
    [SERVICE_PARAMS.ODOMETER]: (data) => data
}

const SERVICE_PARAMS_ENCODER = {
    [SERVICE_PARAMS.DUE_DATE]: (data) => parseDate(Number(data)),
    [SERVICE_PARAMS.WORKING_HOUR]: (data) => data,
    [SERVICE_PARAMS.ODOMETER]: (data) => data
}

const SERVICE_INPUT_TYPE = {
    DATE: 'DATE',
    NUMBER: 'NUMBER'
}

const SERVICE_CODE_INPUT_TYPE = {
    100: SERVICE_INPUT_TYPE.DATE,
    101: SERVICE_INPUT_TYPE.NUMBER,
    200: SERVICE_INPUT_TYPE.NUMBER
}

const STATUS_PARAMS = {
    INACTIVE: 'INACTIVE',
    ACTIVE: 'ACTIVE'
}

const STATUS_CODE = {
    [-1]: [STATUS_PARAMS.INACTIVE, '#808080'],
    [1]: [STATUS_PARAMS.ACTIVE, '#228B22']
}

const STATUS_PARAMS_CODE = {
    [STATUS_PARAMS.ACTIVE]: 1,
    [STATUS_PARAMS.INACTIVE]: -1
}

const SERVICE_STATUS_PARAMS = {
    IN_SERVICE: 'IN SERVICE',
    SERVICED: 'SERVICED',
    NEAR_OVERDUE: 'NEAR_OVERDUE',
    OVERDUE: 'OVERDUE',
}

const SERVICE_STATUS_CODE = {
    [100]: SERVICE_STATUS_PARAMS.IN_SERVICE,
    [200]: SERVICE_STATUS_PARAMS.SERVICED,
    [401]: SERVICE_STATUS_PARAMS.NEAR_OVERDUE,
    [404]: SERVICE_STATUS_PARAMS.OVERDUE,
}

export const SERVICE_STATUS_CODE_TAG_COLOR = {
    [100]: 'cyan',
    [200]: 'green',
    [401]: 'orange',
    [404]: 'red',
} 

export const PROFILE_ACTIVE_STATUS = {
    [0]: 'INACTIVE',
    [1]: 'ACTIVE'
}

export const PROFILE_ACTIVE_STATUS_TAG_COLOR = {
    [0]: 'red',
    [1]: 'green'
}

const MODAL_CS_PROCESS_SELECTION = {
    COMPONENT: 'COMPONENT',
    SERVICE: 'SERVICE'
}

const MODAL_CS_PROCESS_ACTION = {
    SELECTION: 'SELECTION',
    CREATE: 'CREATE',
    EDIT: 'EDIT'
}

const MODAL_CS_PROCESS_TITLE = {
    [MODAL_CS_PROCESS_ACTION.SELECTION]: 'Maintenance Component/Service Selection',
    [MODAL_CS_PROCESS_ACTION.CREATE]: (type) => `Maintenance ${type} Creation`,
    [MODAL_CS_PROCESS_ACTION.EDIT]: (type) => `Maintenance ${type} Edit`
}

const MODAL_CS_PROCESS_CREATION_ORDER_MAX = 2

const MODAL_CS_PROCESS_CREATION_ORDER = {
    [MODAL_CS_PROCESS_ACTION.SELECTION]: 1,
    [MODAL_CS_PROCESS_ACTION.CREATE] : 2 
}

export {
    SERVICE_PARAMS,
    SERVICE_CODE,
    SERVICE_INPUT_TYPE,
    SERVICE_CODE_INPUT_TYPE,
    STATUS_PARAMS,
    STATUS_PARAMS_CODE,
    SERVICE_PARAMS_ENCODER,
    SERVICE_PARAMS_FORM_ENCODER,
    STATUS_CODE,
    SERVICE_STATUS_PARAMS,
    SERVICE_STATUS_CODE,
    MODAL_CS_PROCESS_ACTION,
    MODAL_CS_PROCESS_TITLE,
    MODAL_CS_PROCESS_CREATION_ORDER,
    MODAL_CS_PROCESS_CREATION_ORDER_MAX,
    MODAL_CS_PROCESS_SELECTION
}
