import * as API from "../../api"
import * as ActionTypes from "../action-types";
import { REDUX } from '../../../constants'
import { get_items_request } from '../../util/redux'

/**
 * ThirdParty Library
 */
import {
    message
} from 'antd'

/**
 * Get VAM Programs
 */

export const get_vam_program_request = (vampid) => dispatch => {
    

    API
    .getVAMProgram(vampid)
    .then(info => {
        
        try {
            const {
                vamProgram
            } = info

            dispatch(get_vam_program_success(vamProgram))


        } catch(err) {
            message.error('Error in getting vam program. Refresh page?')
        }

    })
}

const get_vam_program_success = (vamProgram) => {
    return {
        type: ActionTypes.GET_VAM_PROGRAM,
        vamProgram
    };
}

/**
 * Get VAM Programs
 */

export const get_vam_programs_request = (vampids) => dispatch => {

    dispatch(get_items_request(vampids, batch_get_vam_programs_request, { SIZE: REDUX.REDUX_VAM_PROGRAM_BATCH_GET_SIZE }))
}

/**
 * Batch Get VAM Programs
 */
export const batch_get_vam_programs_request = (vampids) => dispatch => {

    API
    .getVAMProgramBatch(vampids)
    .then(info => {
        
        try {
            const {
                vamPrograms
            } = info

            dispatch(batch_get_vam_programs_success(vamPrograms))

        } catch(err) {
            message.error('Error in batch getting vam programs. Refresh page?')
        }

    })
}

const batch_get_vam_programs_success = (vamPrograms) => {
    return {
        type: ActionTypes.GET_VAM_PROGRAMS,
        vamPrograms
    };
}
