import * as ActionTypes from '../action-types'

const surveillance_defaultState = {
    section: [],
    selectedSection: null
}

export const surveillances = (state = surveillance_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_ALL_PLUS_CCTV_SECTIONS: {
            const sections = action.sections;
            newState.sections = sections
            return newState
        }
        case ActionTypes.SET_SELECTED_PLUS_CCTV_SECTION: {
            const section = action.section;
            newState.selectedSection = section
            return newState
        }
        case ActionTypes.UNSET_SELECTED_PLUS_CCTV_SECTION: {
            newState.selectedSection = null
            return newState
        }
        default: {
            return state;
        }
    }
}