export const VAM_REMINDER_STATUS_BY_CODE = {
    'VAMR000': 'Upcoming',
    'VAMR001': 'Due Soon',
    'VAMR002': 'Overdue',
    'VAMR003': 'Snoozed'
}

export const VAM_REMINDER_STATUS_CODE_BY_STATUS = {
    "UPCOMING": 'VAMR000',
    'DUE_SOON': 'VAMR001',
    'OVERDUE': 'VAMR002',
    'SNOOZED': 'VAMR003'
}

export const VAM_REMINDER_STATUS_COLOR_BY_CODE = {
    'VAMR000': 'green',
    'VAMR001': 'orange',
    'VAMR002': 'red',
    'VAMR003': 'grey'
}

export const VAM_ENTRY_STATUS_BY_CODE = {
    'VAME000': 'Open',
    'VAME100': 'Pending',
    'VAME200': 'Completed'
}

export const VAM_ENTRY_STATUS_CODE_BY_STATUS = {
    'OPEN': 'VAME000',
    'PENDING': 'VAME100',
    'COMPLETED': 'VAME200'
}

export const VAM_ENTRY_STATUS_COLOR_BY_CODE = {
    'VAME000': 'grey',
    'VAME100': 'orange',
    'VAME200': 'green'
}

export const VAM_LINE_TYPE_BY_CODE = {
    'VAML001': 'Service',
    'VAML002': 'Regulatory'
}

export const VAM_LINE_TYPE_COLOR_BY_CODE = {
    'VAML001': 'green',
    'VAML002': 'purple'
}

export const VAM_DUE_PARAM = {
    'time': 'time',
    'odometer': 'odometer',
    'workingHour': 'workingHour'
}

export const VAM_DUE_PARAM_REFLECT = {
    'time': 'curTime',
    'odometer': 'odometer',
    'workingHour': 'workingHour'
}

export const VAM_DUE_PARAM_DUE_STATUS = {
    'time': {
        UPCOMING: `from now`,
        DUE_SOON: `from now`,
        OVERDUE: `ago`
    },
    'odometer': {
        UPCOMING: `remaining`,
        DUE_SOON: `remaining`,
        OVERDUE: `overdue`
    },
    'workingHour': {
        UPCOMING: `remaining`,
        DUE_SOON: `remaining`,
        OVERDUE: `overdue`
    }
}

export const  MODAL_CU_PROCESS_ACTION = {
    CREATE: 'CREATE',
    EDIT: 'EDIT'
}

export const TIME_UNIT = {
    day: [ 'day', 'day(s)', 24*60*60*1000 ],
    week: [ 'week', 'week(s)', 7*24*60*60*1000 ],
    month: [ 'month', 'month(s)', 30*24*60*60*1000 ],
    year: [ 'year', 'year(s)', 365*24*60*60*1000 ]
}