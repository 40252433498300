import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from 'antd'

export const get_transit_record_by_timeframe_request = (vid, startTime, endTime) => dispatch => {

    return API
    .getTransitRecordByTimeFrameSync(vid, startTime, endTime) 
    .then(info => {
        
        try {
            const {
                transitRecords
            } = info

            dispatch(get_transit_record_by_timeframe_success(transitRecords, vid, startTime, endTime))


        } catch(err) {
            console.log("Err", err)
            message.error('Error in getting timeframe transit records. Refresh page?')
        }
    })
    
}

const get_transit_record_by_timeframe_success = (transitRecords, vid, startTime, endTime) => {
    return {
        type: ActionTypes.GET_TIMEFRAME_TRANSIT_RECORDS,
        transitRecords,
        vid, startTime, endTime
    }
}

export const set_selected_vehicle_for_transit_record_request = (vid) => dispatch => {
    dispatch(set_selected_vehicle_for_transit_record_success(vid))
}

const set_selected_vehicle_for_transit_record_success = (vid) => {
    return {
        type: ActionTypes.SET_SELECTED_VEHICLE_FOR_TRANSIT_RECORD,
        vid
    }
}

export const clear_selected_vehicle_for_transit_record_request = () => dispatch => {
    dispatch(clear_selected_vehicle_for_transit_record_success())
}

const clear_selected_vehicle_for_transit_record_success = () => {
    return {
        type: ActionTypes.UNSET_SELECTED_VEHICLE_FOR_TRANSIT_RECORD
    }
}

export const set_selected_transit_record_time_request = (startTime) => dispatch => {
    dispatch(set_selected_transit_record_time_success(startTime))
}

const set_selected_transit_record_time_success = (startTime) => {
    return {
        type: ActionTypes.SET_SELECTED_TRANSIT_RECORD_TIME,
        startTime
    }
}

export const set_selected_transit_record_start_time_request = (startTime) => dispatch => {
    dispatch(set_selected_transit_record_start_time_success(startTime))
}

const set_selected_transit_record_start_time_success = (startTime) => {
    return {
        type: ActionTypes.SET_SELECTED_TRANSIT_RECORD_START_TIME,
        startTime
    }
}
export const set_selected_transit_record_end_time_request = (endTime) => dispatch => {
    dispatch(set_selected_transit_record_end_time_success(endTime))
}

const set_selected_transit_record_end_time_success = (endTime) => {
    return {
        type: ActionTypes.SET_SELECTED_TRANSIT_RECORD_END_TIME,
        endTime
    }
}

export const unset_selected_transit_record_start_time_request = () => dispatch => {
    dispatch(unset_selected_transit_record_start_time_success())
}

const unset_selected_transit_record_start_time_success = () => {
    return {
        type: ActionTypes.UNSET_SELECTED_TRANSIT_RECORD_START_TIME
    }
}
export const unset_selected_transit_record_end_time_request = () => dispatch => {
    dispatch(unset_selected_transit_record_end_time_success())
}

const unset_selected_transit_record_end_time_success = () => {
    return {
        type: ActionTypes.UNSET_SELECTED_TRANSIT_RECORD_END_TIME
    }
}