import * as API_CONSTANT from '../constants'

export const getInfoByUser = (uid, DLT = 0) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/user/getInfo`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            uid,
            DLT
        })
    })
    .then(res => res.json())
)

export const getRoleByUser = (uroleid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/user/getRole`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            uroleid
        })
    })
    .then(res => res.json())
)

export const getUserHierarchy = (hierCode) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/user/getHierarchy`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            hierCode
        })
    })
    .then(res => res.json())
)