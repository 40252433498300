import 
    React, 
    { 
        useEffect,
        useState
    } 
from "react";
import {
    useSelector
} from 'react-redux'
import ReactFlow from 'react-flow-renderer';

// Components
import showSecondarySidebar from '../../layout/MainLayout/helpers/showSecondarySidebar'

const UserHierarchyMap = () => {

    const [elements, setElements] = useState([]);

    const userRoles = useSelector(state => state.containers.v3.userRoles)


    useEffect(() => {

        let newElements = []

        userRoles
        .allIDs
        .filter((urid) => userRoles.byID[urid].hierarchy >= userRoles.byID[userRoles.currentUROLEID].hierarchy )
        .map((uroleid) => {

            const userRole = userRoles.byID[uroleid]
            const groupid = (userRole && userRole.groupid) || null

            let curIndex = 0

            if(uroleid !==  userRoles.currentUROLEID) {

                userRole.upids.map((ur, urIndex) => {

                    if(groupid) {

                        const gupline = Object.values(userRoles.byID).find(u => u.uroleid === groupid)

                        let upIndex = 0;
        
                        gupline.upids.map(() => {

                            let element = {
                                id: `${uroleid}#${curIndex}`,
                                data: { label: userRole.roleName },
                                position: { x: Number(userRole.position.x) + upIndex*300 , y: Number(userRole.position.y) + upIndex*200 + urIndex*100} 
                            }
        
                            newElements.push(element) 
        
                            let connection = {
                                id: `e${ur}#${upIndex}-${uroleid}#${curIndex}`,
                                source: `${ur}#${upIndex}`,
                                target: `${uroleid}#${curIndex}`,
                                arrowHeadType: 'arrowclosed'
                            }
                            
                            newElements.push(connection)

                            curIndex = curIndex + 1

                            upIndex = upIndex + 1
                            
                            return null
                        })
        
                    } else {

                        let element = {
                            id: `${uroleid}#${urIndex}`,
                            data: { label: userRole.roleName },
                            position: { x: Number(userRole.position.x)+urIndex*300, y: Number(userRole.position.y)+urIndex*200} 
                        }
    
                        newElements.push(element) 
    
                        let connection = {
                            id: `e${ur}#${curIndex}-${uroleid}#${urIndex}`,
                            source: `${ur}#${curIndex}`,
                            target: `${uroleid}#${urIndex}`,
                            arrowHeadType: 'arrowclosed'
                        }
                        
                        newElements.push(connection)

                    }

                    return null

                })

            } else {

                let element = {
                    id: `${uroleid}#${curIndex}`,
                    type: 'input',
                    data: { label: userRole.roleName },
                    position: { x: Number(userRole.position.x) , y: Number(userRole.position.y) } 
                }

                newElements.push(element) 
            }

            return null
        })

        setElements(newElements)

    }, [userRoles])
    
    // const onElementClick = (event, element) => console.log('onElementClick', element)

    return (
        <>
            <ReactFlow 
                elements={elements} 
                // onElementClick={onElementClick}
            />
        </>
    )
}

export default showSecondarySidebar(false)(React.memo(UserHierarchyMap));