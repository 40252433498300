import * as ActionTypes from '../action-types'
import { deepMerge } from '../../util'

const rules_DefaultState = {
    byID: {},
    allIDs: []
}

export const rules = (state = rules_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.CLEAR_SELECTED_RULE_RULEID: {
            newState.selectedID = ''

            return newState            
        }
        case ActionTypes.GET_RULE: {

            const rule = action.rule

            const ruleid = rule && rule.ruleid

            if(ruleid) {
                if(Object.prototype.hasOwnProperty.call(newState.byID, ruleid)) {

                    newState.byID[ruleid] = deepMerge(newState.byID[ruleid], rule)
    
                } else {
    
                    newState.byID[ruleid] = rule
    
                }
    
                if(!newState.allIDs.includes(ruleid)) newState.allIDs.push(ruleid)
            }

            return newState
        }
        case ActionTypes.GET_RULES: {

            const rules = action.rules

            rules && rules.map(rule => {
                const ruleid = rule.ruleid

                if(Object.prototype.hasOwnProperty.call(newState.byID, ruleid)) {

                    newState.byID[ruleid] = deepMerge(newState.byID[ruleid], rule)

                } else {

                    newState.byID[ruleid] = rule

                }

                if(!newState.allIDs.includes(ruleid)) newState.allIDs.push(ruleid)

                return null;
            }) 

            return newState
        }
        case ActionTypes.SET_SELECTED_RULE_RULEID : {
            const ruleid = action.ruleid

            newState.selectedID = ruleid

            return newState            
        }
        default: {
            return newState;
        }
    }
}