import * as API_CONSTANT from '../constants'

export const getMaintenanceTemplateBatch = (maintids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/maintenance/template/batch/get`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            maintids
        })
    })
    .then(res => res.json())
)

export const getMaintenanceComponentBatch = (maincids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/maintenance/component/batch/get`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            maincids
        })
    })
    .then(res => res.json())
)

export const getMaintenanceServiceBatch = (mainsids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/maintenance/service/batch/get`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            mainsids
        })
    })
    .then(res => res.json())
)

export const getMaintenanceRuleBatch = (mainrids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/maintenance/rule/batch/get`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            mainrids
        })
    })
    .then(res => res.json())
)

export const CU_MaintenanceComponent = (item) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/maintenance/component/cu`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify(item)
    })
    .then(res => res.json())
)

export const CU_MaintenanceService = (item) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}//maintenance/service/cu`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify(item)
    })
    .then(res => res.json())
)