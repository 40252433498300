import * as API_CONSTANT from '../constants'

export const getDeviceInfoByDVID = (dvid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/device/getInfo`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            dvid
        })
    })
    .then(res => res.json())
)

export const getDeviceInfoBatch = (dvids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/device/batch/getInfo`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            dvids
        })
    })
    .then(res => res.json())
)

export const getDeviceGroupByDEVGID = (devgid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/device/getGroup`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            devgid
        })
    })
    .then(res => res.json())
)

export const getDeviceGroupBatch = (devgids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/device/batch/getGroup`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            devgids
        })
    })
    .then(res => res.json())
)