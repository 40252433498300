import * as API_CONSTANT from '../constants'

export const getRuleInfoByRULEID = (ruleid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/rule/getInfo`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            ruleid
        })
    })
    .then(res => res.json())
)

export const getRuleInfoBatch = (ruleids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/rule/batch/getInfo`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            ruleids
        })
    })
    .then(res => res.json())
)

export const getRuleTemplateByRULETID  = (ruletid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/rule/getTemplate`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            ruletid
        })
    })
    .then(res => res.json())
)

export const getRuleTemplateBatch = (ruletids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/rule/batch/getTemplate`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            ruletids
        })
    })
    .then(res => res.json())
)