import 
    React,
    {
        useRef,
        useEffect
    } 
from "react";
import {
    Chart as ChartJS,
    registerables
} from 'chart.js'
import 'chartjs-adapter-date-fns';

// Handlers
import { hookHDLR } from '../../../handlers'

const {
    useDeepEffect
} = hookHDLR

ChartJS.register(...registerables)

const Chart = (props) => {

    const {
        type,
        options,
        data,
        plugins = [],
        style,
        chartHeight
    } = props

    const canvasRef = useRef()
    const chartRef = useRef(null)

    const renderChart = () => {
        if (!canvasRef.current) return;
    
        chartRef.current = new ChartJS(canvasRef.current,{
            type,
            options,
            data,
            plugins
        });
    };

    const destroyChart = () => {
        if (chartRef.current) {
          chartRef.current.destroy();
          chartRef.current = null;
        }
    }

    useEffect(() => {

        renderChart()

        return () => {
            destroyChart()
        }

    }, [])

    useDeepEffect(() => {
        if(chartRef.current && chartRef.current.config) {
            chartRef.current.config._config.type = type
            chartRef.current.config._config.data = data
            chartRef.current.config._config.options = options
            chartRef.current.config._config.plugins = plugins
            chartRef.current.update()
        }
    }, [
        type,
        options,
        data,
        plugins
    ])

    return (
        <>
            <div style={style}>
                <canvas height={chartHeight} ref={canvasRef} />
            </div>
        </>
    )
}

const MemorisedChart = React.memo(Chart)

export default MemorisedChart