import * as API from "../../api"
import * as ActionTypes from "../action-types";
import { REDUX } from '../../../constants'

/**
 * ThirdParty Library
 */
 import {
    message
} from 'antd'

/**
 * Get Device
 */

export const get_device_request = dvid => dispatch => {
    

    API
    .getDeviceInfoByDVID(dvid)
    .then(info => {
        
        try {
            const {
                deviceStatics,
                deviceState
            } = info

            dispatch(get_device_success(deviceStatics,deviceState))


        } catch(err) {
            message.error('Error in getting devices. Refresh page?')
        }

    })
}

const get_device_success = (deviceStatics,deviceState) => {
    return {
        type: ActionTypes.GET_DEVICE,
        deviceStatics,
        deviceState
    };
}

/**
 * Get Devices
 */

 export const get_devices_request = dvids => dispatch => {

    let dvidBatch = []

    dvids.map((vid, index) => {

        dvidBatch.push(vid)

        if( 
            (index+1) % REDUX.REDUX_DEVICE_BATCH_GET_SIZE === 0
            ||
            index+1 === dvids.length
        ) {
            dispatch(batch_get_devices_request(dvidBatch))
            dvidBatch = []
        }

        return null
    })
 }

/**
 * Batch Get Device
 */
 export const batch_get_devices_request = dvids => dispatch => {

    API
    .getDeviceInfoBatch(dvids)
    .then(info => {
        try {
            const {
                deviceStatics,
                deviceStates
            } = info

            dispatch(batch_get_devices_success(deviceStatics,deviceStates))


        } catch(err) {
            message.error('Error in batch getting devices. Refresh page?')
        }

    })
}

const batch_get_devices_success = (deviceStatics,deviceStates) => {
    return {
        type: ActionTypes.GET_DEVICES,
        deviceStatics,
        deviceStates
    };
}

