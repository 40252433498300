import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {
    MarkerF,
    Circle,
    GoogleMap,
    OverlayView,
    DrawingManager,
} from '@react-google-maps/api';

// Components
import GoogleMapsLoadScriptEnabler from "../components/GoogleMapsLoadScript";

// Constant
import { MAP } from '../../../../constants'

// Handler
import { MapInitialiserWithVehicleAndGeofence } from '../handlers/MapInitialiserWithVehicleAndGeofence'
import { geofenceTemplates_handler } from '../../../../handlers'
import { UnfocusMap } from '../handlers/UnfocusMap'

// Redux Action 
import * as ACTIONS from '../../../../services/redux/actions'

// Styles
import "../gmaps.css";

const {
    returnLatestGeofenceTemplateColorForGeoID
} = geofenceTemplates_handler

const GoogleMapsPOI = () => {

    const dispatch = useDispatch();
    
    // Redux Store
    const vehicles = useSelector(state => state.containers.v3.vehicles)
    const geofences = useSelector(state => state.containers.v3.geofences)
    const geofenceTemplates = useSelector(state => state.containers.v3.geofenceTemplates)
    const mapControl = useSelector(state => state.containers.v3.mapControl)

    // State
    const [mapRef, setMapRef] = useState(null);
    const [circleRef, setCircleRef] = useState(null);
    const [drawingManagerRef, setDrawingManagerRef] = useState(null);
    const [thisGeofence, setThisGeofence] = useState(mapControl.geofence);

    MapInitialiserWithVehicleAndGeofence(vehicles, geofences, mapControl, mapRef)

    const getMarker = (colorHexCode) => MAP.POI_MARKER_BY_COLOR[colorHexCode.replace("#", "").toUpperCase()]

    const putMarkerComplete = marker => {
        const newMarker = {
            ...mapControl.geofence,
            coordinates: [{
                lat: Number(marker.position.lat()),
                lng: Number(marker.position.lng()),
            }]
        }

        dispatch(ACTIONS.v3_mapControl.set_new_geofence_from_map(newMarker));

        // revert drawingMode to null, ie drag
        if (drawingManagerRef) {
            drawingManagerRef.setDrawingMode(null);
        }
    }

      /**
     * Deep Cloning useEffect()
     */
    const useDeepEffect = (fn, deps) => {
        const isFirst = useRef(true);
        const prevDeps = useRef(deps);
      
        useEffect(() => {
          const isSame = prevDeps.current.every((obj, index) =>
            JSON.parse(JSON.stringify(obj)) === JSON.parse(JSON.stringify(deps[index]))
          );
      
          if (isFirst.current || !isSame) {
            fn();
          }
      
          isFirst.current = false;
          prevDeps.current = deps;
        }, [fn, deps]);
    }

    useDeepEffect(() => {
        setThisGeofence(mapControl.geofence)
    }, [mapControl.geofence])

    useEffect(() => {
        if (circleRef && mapRef) {
            const bounds = circleRef.getBounds()
            mapRef.fitBounds(bounds)
        }
    }, [mapControl, circleRef, mapRef])

    return (
        <GoogleMap
            id='googles-map'
            mapContainerStyle={{
                height: '100vh'
            }}
            onLoad={ref => setMapRef(ref)}
            onDragStart={() => {UnfocusMap(dispatch)}}
            onDragEnd = {() => {
                if (mapRef) {
                    const zoom = mapRef.getZoom();
                    const lat = mapRef.getCenter().lat();
                    const lng = mapRef.getCenter().lng();

                    mapRef.setCenter({ lat, lng });
                    mapRef.setZoom(zoom);
                }
            }}
        >
            {/* POI */}
            {
                thisGeofence
                && thisGeofence.coordinates &&
                    <MarkerF
                        icon = {getMarker(returnLatestGeofenceTemplateColorForGeoID(geofenceTemplates, thisGeofence.geoid))}
                        position = {thisGeofence.coordinates[0]}
                    >
                        <OverlayView
                            options = {{ disableAutoPan: true }}
                            position = {thisGeofence.coordinates[0]}
                            mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                            // getPixelPositionOffset = {(width, height) => ({
                            //     x: -(width / 2),
                            //     y: -height - 3,
                            // })}
                        >
                            <div
                                style = {{
                                    background: 'white',
                                    border: '1px solid #ccc',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 8,
                                    paddingBottom: 1,
                                }}
                            >
                                <h3>{thisGeofence.name}</h3>

                                {thisGeofence.comment && <>{thisGeofence.comment}</>}
                            </div>
                        </OverlayView>
                    </MarkerF>
            }

            {
                thisGeofence
                && thisGeofence.coordinates &&
                    <Circle
                        onLoad = {ref => setCircleRef(ref)}
                        center = {thisGeofence.coordinates[0]}
                        radius = {thisGeofence.radius}
                        options = {{
                            strokeColor: returnLatestGeofenceTemplateColorForGeoID(geofenceTemplates, thisGeofence.geoid)
                        }}
                    />
            }

            {
                <DrawingManager
                    drawingMode = {window.google.maps.drawing.OverlayType.MARKER}
                    options = {{
                        drawingControl: true,
                        drawingControlOptions: {
                            position: window.google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: [
                                window.google.maps.drawing.OverlayType.MARKER,
                            ],
                        },
                        markerOptions: {
                            visible: false,
                        },
                    }}
                    onLoad = {ref => setDrawingManagerRef(ref)}
                    onMarkerComplete = {marker => putMarkerComplete(marker)}
                />
            }
        </GoogleMap>
    )
}

export default GoogleMapsLoadScriptEnabler(GoogleMapsPOI)