import React from 'react';
import {
    Tooltip,
} from "antd";
// Icon
import GpsOffRoundedIcon from '@mui/icons-material/GpsOffRounded';
import GpsNotFixedRoundedIcon from '@mui/icons-material/GpsNotFixedRounded';
import GpsFixedRoundedIcon from '@mui/icons-material/GpsFixedRounded';
// Micro

/**
 * Initially yes
 * Once data received, then would switch to either no/yes
 * @param {} vehicle 
 */
const GNSSSignalIconDefiner = (vehicle) => {

    const NoGNSSSignalTitle = (
        <>
            <p>{`Has No GPS`}</p>
        </>
    )

    const NoGNSSIcon = () => {
        return (
            <Tooltip 
                placement="top" 
                title={NoGNSSSignalTitle}
            >
                    <GpsOffRoundedIcon sx={{ fontSize: 20, color: "red" }}/>
            </Tooltip>
        )
    }

    if(!vehicle) 
        return (
            <NoGNSSIcon />
        )
    else {
        const {
            gnss_status,
            satel_num
        } = vehicle

        const GNSSSignalTitle = (
            <>
                {
                    Object.prototype.hasOwnProperty.call(vehicle, 'satel_num')
                    &&
                    <p>{`Has GPS under ${satel_num} Satellite`}</p>
                    ||
                    <p>{`Has GPS`}</p>
                }
            </>
        )

        const Sleep_GNSSSignalTitle = (
            <>
                {
                    Object.prototype.hasOwnProperty.call(vehicle, 'satel_num')
                    &&
                    <p>{`Sleep GPS under ${satel_num} Satellite`}</p>
                    ||
                    <p>{`Sleep GPS`}</p>
                }
            </>
        )

        if(!gnss_status) 
            return (<NoGNSSIcon />)

        switch(gnss_status) {
            case 3: {
                return (
                    <Tooltip 
                        placement="top" 
                        title={Sleep_GNSSSignalTitle}
                    >
                        <GpsNotFixedRoundedIcon sx={{ fontSize: 20, color: "blue" }}/>
                    </Tooltip> 
                )
            }
            case 2: {
                return (
                    <Tooltip 
                        placement="top" 
                        title={GNSSSignalTitle}
                    >
                        <GpsNotFixedRoundedIcon sx={{ fontSize: 20, color: "green" }}/>
                    </Tooltip> 
                )
            }
            case 1: {
                return (
                    <Tooltip 
                        placement="top" 
                        title={GNSSSignalTitle}
                    >
                        <GpsFixedRoundedIcon sx={{ fontSize: 20, color: "green" }}/>
                    </Tooltip> 
                )
            }
            case 0:
            default: {
                return (
                    <NoGNSSIcon />
                )
            }
        }
    }
}

const GNSSSignalIcon = ({vehicle}) => {
    return <>
        {
            GNSSSignalIconDefiner(vehicle)
        }
    </>
}

export default GNSSSignalIcon