import React from 'react'
import { useSelector } from 'react-redux'

// Components
import GoogleMapsSUVPanel from '../../../../components/Map/GoogleMaps/Map_SUV'

// Handler

// Services
import { REDUX } from '../../../../services/util'

const { reduxStoreWrapper } = REDUX

const SurveillanceVideoList = (props) => {

    const {
        sectionSUVClickHander,
        filterSUV
    } = props

    const sections = useSelector(reduxStoreWrapper('surveillances.sections'))

    return (
        <>
            <GoogleMapsSUVPanel 
                SUVs={
                    sections
                    &&
                    sections.length
                    &&
                    sections
                    .filter(section => filterSUV ? section.label.toLowerCase().includes(filterSUV.toLowerCase()) : true)
                    ||
                    []
                }
                OnClick={sectionSUVClickHander}
            />
        </>
    )

}

export default SurveillanceVideoList