import React from 'react'
import { useSelector } from 'react-redux'
import { Tag } from 'antd'

// Service
import { REDUX } from '../../services/util'

const {
    reduxStoreWrapper
} = REDUX

const UserRoleTag = ({uroleid}) => {

    const userRoleByCode = useSelector(reduxStoreWrapper('userRoles.byID'))

    const urole = userRoleByCode[uroleid]
    const roleName = urole && urole.roleName || null
    const colorCode = urole && urole.colorCode || null

    return (
        <Tag color={colorCode}>
            {roleName}
        </Tag>
    )
}

export default React.memo(UserRoleTag)