import * as API_CONSTANT from '../constants'

export const getAllSubscriptionPackagesSync = () => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/subscription/package/getAll/sync`, {
      method: 'POST',
      headers: API_CONSTANT.headers,
      body: JSON.stringify({
      })
    })
      .then(res => res.json())
  )