import * as API from "../../api"
import * as ActionTypes from "../action-types";

import { message } from 'antd'

// Constants and Config

export const get_all_subscription_packages_request = () => dispatch => {
    
    API
    .getAllSubscriptionPackagesSync()
    .then(info => {

        if (info.status !== 200) {
            message.error('Error at get_user_hierarchy', info.message)
        } else {

            const packages = {
                accountPackages: info.accountPackages,
                vehiclePackages: info.vehiclePackages
            }
            dispatch(get_all_subscription_packages_success(packages))
        }
    })

}

export const get_all_subscription_packages_success = (packages) => {
    return {
        type: ActionTypes.GET_ALL_SUBSCRIPTION_PACKAGES,
        packages
    };
}