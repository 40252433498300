import 
    React, 
    { 
        useState,
        useEffect
    } 
from "react";
import { 
    useSelector
} from "react-redux";
import { useNavigate } from 'react-router'
import {
    Table
} from "antd";

// Components
import EventStatusIcon from "../../../../components/EventStatusIcon";
import VehiclePlateECUTag from '../../../../components/VehiclePlateECUTag'
import GeoAddress from '../../../../components/GeoAddress'
import VehicleTripStatusTag from '../../../../components/VehicleTripStatusTag'
import { EmptyIndicator } from '../../../../components/Indicator'
import { PrimaryButton } from '../../../../components/Decoration/Button'

// Constant
import { 
    ROUTES,
    TEMPLATE,
    COMMON_FEATURES
} from '../../../../constants'

// Handler
import {
    vehicles_handler,
    table_handler,
    report_handler,
    hookHDLR
} from '../../../../handlers'

// Services
import {
    TIME,
    RoundUp
} from '../../../../services/util'

const {
    MODULE_ROUTE
} = ROUTES

const {
    VEHICLE_TRANSIT_STATUS_OBJECT
} = TEMPLATE.STATUS

const {
    parseTime,
    parseHours
} = TIME

const { usePrevious } = hookHDLR

const { getColumnSearchProps } = table_handler

const {
    showVehicleTransitStatusChangeDuration_WithoutLabel,
    vehicleECUValidator
} = vehicles_handler

const {
    DERIVATIVE_DATA_TYPE,
    MILEAGE_TYPE,
    mileageSelector,
    speedSelector
} = report_handler

const VehicleInformationTable = () => {

    const navigate = useNavigate()

    const [ dataSource, setDataSource ] = useState([])
    
    const searchTextState = useState('')
    const searchedColumnState = useState('')

    const vehicles = useSelector(state => state.containers.v3.vehicles)
    const style = useSelector(state => state.containers.v3.style)
    
    const prevVehicles = usePrevious(vehicles);        

    const refreshDataSrc = () => {
        const newDataSource = 
            vehicles.allIDs
            .map(vid => vehicles.staticsByID[vid])
            .sort((b, a) => b.plate - a.plate)
            .map(vehicleStatics => {
                const vid = vehicleStatics.vid
                const vehicleFuelProfile = vehicles.fuelProfileByID[vid]
                const fuel_tankNum = vehicleFuelProfile && vehicleFuelProfile.tankNum || 0
                const vehicle = {...vehicles.stateByID[vid], ...vehicles.derivativeStateByID[vid], ...vehicles.geoStateByID[vid]}
                const vehicleECU = vehicleECUValidator(vehicles.stateByID[vid])

                const fuelProfile = []

                for(let n = 0; n < fuel_tankNum; n++) {
                    const vehicleLevel = (vehicle && vehicleFuelProfile && vehicle[vehicleFuelProfile[`${n}_deviceParam`]]) || 0
                    const vehicleTank_MAX = (vehicleFuelProfile && vehicleFuelProfile[`${n}_max`]) || 0
                    const vehicleTank_MIN = (vehicleFuelProfile && vehicleFuelProfile[`${n}_min`]) || 0
                    const vehicleTank_CPTY = (vehicleFuelProfile && vehicleFuelProfile[`${n}_capacity`]) || 0

                    const fuelLevel = ((vehicleLevel-vehicleTank_MIN) / (vehicleTank_MAX - vehicleTank_MIN)  * vehicleTank_CPTY).toFixed(2)

                    fuelProfile.push(
                        `[Tank ${n + 1}] ${fuelLevel} L`
                    )
                }

                const speed = vehicle && speedSelector(vehicle, DERIVATIVE_DATA_TYPE.STATE) || 0
                const odometer = mileageSelector(vehicle, MILEAGE_TYPE.ODOMETER)

                return {
                    key: vid,
                    plate: vehicleStatics.plate,
                    displayName: vehicleStatics.displayName,
                    transitStatus: vehicle.transitStatus,
                    location: { vehicle },
                    fuelProfile: fuelProfile,
                    updatedAt: vehicle.vehicleTime,
                    duration: showVehicleTransitStatusChangeDuration_WithoutLabel(vehicle),
                    speed: `${(speed.toFixed(2))} km/h`,
                    odometer: !isNaN(odometer) ? `${RoundUp(odometer, 0)} km` : "-",
                    engineHour: vehicle.plat_hist_engine_hour && !isNaN(Number(vehicle.plat_hist_engine_hour)) ? `${RoundUp(parseHours(vehicle.plat_hist_engine_hour), 0)} hrs` : "-",
                    details: vehicle.vid,
                    ...vehicleECU
                }
            })

        setDataSource(newDataSource)
    }

    useEffect(() => {
        if (
            (prevVehicles !== vehicles)
        ) {
            refreshDataSrc()
        }
    })

    const columns = [
        {
            title: "Details",
            dataIndex: "details",
            render: (rowData) => <EventStatusIcon vehicleID={rowData} />
        },
        {
            title: "Vehicle Name",
            dataIndex: "displayName",
            ...getColumnSearchProps('Display', 'displayName', searchTextState, searchedColumnState, (plate, vehicle) => <VehiclePlateECUTag plate={plate} vehicle={vehicle}/>),
        },

        {
            title: "Vehicle Plate",
            dataIndex: "plate",
            ...getColumnSearchProps('Vehicle Plate', 'plate', searchTextState, searchedColumnState, (plate, vehicle) => <VehiclePlateECUTag plate={plate} vehicle={vehicle}/>)
        },

        {
            title: "Status",
            dataIndex: "transitStatus",
            filters: Object.keys(VEHICLE_TRANSIT_STATUS_OBJECT)
                .filter(status => status !== VEHICLE_TRANSIT_STATUS_OBJECT.ALL)
                .map(status => (
                    {
                        text: status,
                        value: VEHICLE_TRANSIT_STATUS_OBJECT[status],
                    }
                )),
            onFilter: (value, record) => record.transitStatus === value,
            render: transitStatus => {
                if (transitStatus) {
                    return (
                        <VehicleTripStatusTag tripStatus={transitStatus}/>
                    )
                }

                return '-'
            }
        },

        {
            title: "Duration",
            dataIndex: "duration"
        },

        {
            title: "Speed",
            dataIndex: "speed",
        },

        {
            title: "Odometer",
            dataIndex: "odometer",
        },

        {
            title: "Engine Hours",
            dataIndex: "engineHour",
        },

        {
            title: "Fuel",
            dataIndex: "fuelProfile",
            render: (fuelProfile = []) => {
                if (fuelProfile.length) {
                    return (
                        <ul>
                            {
                                fuelProfile.map((p, i) => {
                                    return (
                                        <li key={i}>{p}</li>
                                    )
                                })
                            }
                        </ul>
                    )
                }
                return `(No fuel profiles)`
            }
        },

        {
            title: "Location",
            dataIndex: "location",
            width: '15%',
            render: ({ vehicle }) => {
                if (!vehicle) return

                if (
                    vehicle &&
                    vehicle.location &&
                    !isNaN(vehicle.location.lat) &&
                    !isNaN(vehicle.location.lon) &&
                    vehicle.geoIN &&
                    vehicle.geoIN.length
                ) {
                    return (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://www.google.com.my/maps/place/${vehicle.location.lat},${vehicle.location.lon}`}
                        >
                            {
                                vehicle.transitStatus !== VEHICLE_TRANSIT_STATUS_OBJECT.DISCONNECTED && (
                                    <GeoAddress vid={vehicle.vid} keyIndex={1}/>
                                )
                            }
                        </a>
                    )
                }

                return (
                    vehicle &&
                    vehicle.location &&
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.google.com.my/maps/place/${vehicle.location.lat},${vehicle.location.lon}`}
                    >
                        {vehicle.address || `${vehicle.location.lat}, ${vehicle.location.lon}`}
                    </a>
                )
            }
        },
        {
            title: "Last Updated",
            dataIndex: "updatedAt",
            sorter: (a, b) => a.updatedAt - b.updatedAt,
            render: updatedAt => (updatedAt && parseTime(updatedAt)) || '-'
        },
    ]

    return (
        <div
            style={{
                flex: 1,
                display: "flex",
            }}
        >
            <div
                className="scroll-div"
                style={{ width: "100%" }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",

                        padding: 5,
                        marginBottom: 5,
                    }}
                >
                    <PrimaryButton onClick={() => navigate(MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.LANDING_PAGE])}>Dashboard Map</PrimaryButton>
                </div>

                {
                    dataSource.length > 0 ?
                        <Table
                            loading={style.loading.general}
                            columns={columns}
                            dataSource={dataSource}
                            pagination={false}
                            scroll={{
                                y: window.innerHeight,
                                x: columns && columns.length * 150
                            }}
                        /> 
                    :
                        <EmptyIndicator />
                }
            </div>
        </div>
    )
}

export default React.memo(VehicleInformationTable);

