import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {
    GoogleMap,
    TrafficLayer,
} from '@react-google-maps/api';

// Components
import MapSearchBar from '../../components/MapSearchBar';
import MapControlPanel from '../../components/MapControlPanel';
import GoogleMapsLoadScriptEnabler from "../components/GoogleMapsLoadScript";
import POIGeofences from "../../components/Geofences_POI";
import ZoneGeofences from "../../components/Geofences_Zone";

// Handler
import { MapInitialiserWithVehicleAndGeofence } from '../handlers/MapInitialiserWithVehicleAndGeofence'
import { UnfocusMap } from '../handlers/UnfocusMap'

// Redux Action 

// Styles
import "../gmaps.css";

const GoogleMapsGeofence = () => {

    const dispatch = useDispatch();

    // State
    const [mapRef, setMapRef] = useState(null);
    
    // Redux Store
    const vehicles = useSelector(state => state.containers.v3.vehicles)
    const geofences = useSelector(state => state.containers.v3.geofences)
    const mapControl = useSelector(state => state.containers.v3.mapControl)

    MapInitialiserWithVehicleAndGeofence(vehicles, geofences, mapControl, mapRef)

    const getMapCenterAndZoom = () => {
        if (mapRef) {
            return {
                center: mapRef.getCenter(),
                zoom: mapRef.getZoom()
            }
        }
        return {
            center: mapControl.location,
            zoom: mapControl.zoom
        }
    }

    return (
        <GoogleMap
            id='googles-map'
            center={getMapCenterAndZoom().center}
            zoom={getMapCenterAndZoom().zoom}
            mapContainerStyle={{
                height: '100vh'
            }}
            onLoad={ref => setMapRef(ref)}
            onDragStart={() => {
                UnfocusMap(dispatch)
            }}
        >
            
            <POIGeofences mapRef = {mapRef}/>
            <ZoneGeofences mapRef = {mapRef}/>

            {mapControl.UIControl.showLiveTraffic && <TrafficLayer/>}

            <div className = "searchBar">
                <MapSearchBar/>
            </div>

            <div className="controlPanel">
                <MapControlPanel 
                    geofenceMarkerToggle = {true}
                    geofenceInfoToggle = {true}
                    liveTrafficTroggle = {true}
                    vehicleInfoToggle = {false}
                />
            </div>
        </GoogleMap>
    )
}

export default GoogleMapsLoadScriptEnabler(GoogleMapsGeofence)