import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

const ModalPortal = WrappedComponent => (props) => {

    const modalRoot = document.getElementById("modal-root")
    const el = useRef(document.createElement("div"));

    useEffect(() => {
        modalRoot.appendChild(el.current);
    
        return () => {
          modalRoot.removeChild(el.current);
        };
    }, []);

    return createPortal(
        <WrappedComponent {...props} />,
        el.current
    )
}

export default ModalPortal