import * as ActionTypes from '../action-types'
import { deepMerge } from '../../util'

import { TEMPLATE } from '../../../constants'

/**
 * Constant
 */

const {
    VEHICLE_TRANSIT_STATUS_OBJECT
} = TEMPLATE.STATUS

const vehicles_DefaultState = {
    staticsByID: {},
    fuelProfileByID: {},
    vehiclePKGByID: {},
    stateByID: {},
    derivativeStateByID: {},
    geoStateByID: {},
    byTransitStatus: {
        [VEHICLE_TRANSIT_STATUS_OBJECT.ALL]: [],
        [VEHICLE_TRANSIT_STATUS_OBJECT.MOVING]: [],
        [VEHICLE_TRANSIT_STATUS_OBJECT.IDLING]: [],
        [VEHICLE_TRANSIT_STATUS_OBJECT.PARKING]: [],
        [VEHICLE_TRANSIT_STATUS_OBJECT.DISCONNECTED]: []
    },
    allIDs: [],
    selectedID: ''
}

export const vehicles = (state = vehicles_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.CLEAR_SELECTED_VEHICLE: {
            newState.selectedID = ''
            return newState
        }
        case ActionTypes.GET_VEHICLE: {

            const vehicleStatics = action.vehicleStatics
            const vehicleState = action.vehicleState
            const vehicleDerivativeState = action.vehicleDerivativeState
            const vehicleGeoState = action.vehicleGeoState
            const vehicleFuelProfile = action.vehicleFuelProfile
            const vehiclePackageProfile = action.vehiclePackageProfile 

            const vid =  (vehicleStatics && vehicleStatics.vid) || (vehicleState && vehicleState.vid)

            if(vid) {

                if(Object.prototype.hasOwnProperty.call(newState.stateByID, vid)) newState.stateByID[vid] = deepMerge(newState.stateByID[vid], vehicleState)
                else newState.stateByID[vid] = vehicleState

                if(Object.prototype.hasOwnProperty.call(newState.derivativeStateByID, vid)) newState.derivativeStateByID[vid] = deepMerge(newState.derivativeStateByID[vid], vehicleDerivativeState)
                else newState.derivativeStateByID[vid] = vehicleDerivativeState

                if(Object.prototype.hasOwnProperty.call(newState.staticsByID, vid)) newState.staticsByID[vid] = deepMerge(newState.staticsByID[vid], vehicleStatics)
                else newState.staticsByID[vid] = vehicleStatics

                if(Object.prototype.hasOwnProperty.call(newState.geoStateByID, vid)) newState.geoStateByID[vid] = deepMerge(newState.geoStateByID[vid], vehicleGeoState)
                else newState.geoStateByID[vid] = vehicleGeoState

                newState.fuelProfileByID[vid] = vehicleFuelProfile
                newState.vehiclePKGByID[vid] = vehiclePackageProfile

                if(!newState.allIDs.includes(vid)) newState.allIDs.push(vid)

                if(vehicleDerivativeState.transitStatus && !newState.byTransitStatus[vehicleDerivativeState.transitStatus].includes(vid)) {
                    newState.byTransitStatus[vehicleDerivativeState.transitStatus].push(vid)
                } else if (!vehicleDerivativeState.transitStatus || !Object.prototype.hasOwnProperty.call(newState.byTransitStatus, vehicleDerivativeState.transitStatus)) {
                    if(!newState.byTransitStatus[VEHICLE_TRANSIT_STATUS_OBJECT.DISCONNECTED].includes(vid)) {
                        newState.byTransitStatus[VEHICLE_TRANSIT_STATUS_OBJECT.DISCONNECTED].push(vid)
                    }
                }

            }

            newState.byTransitStatus[VEHICLE_TRANSIT_STATUS_OBJECT.ALL] = newState.allIDs || [];

            return newState
        }
        case ActionTypes.GET_VEHICLES: {

            const vehicleStatics = action.vehicleStatics
            const vehicleStates = action.vehicleStates
            const vehicleDerivativeStates = action.vehicleDerivativeStates && action.vehicleDerivativeStates.filter(v => v)
            const vehicleGeoStates = action.vehicleGeoStates && action.vehicleGeoStates.filter(v => v)
            const vehicleFuelProfiles = action.vehicleFuelProfiles
            const vehiclePackageProfiles = action.vehiclePackageProfiles

            vehicleStatics && vehicleStatics.length && vehicleStatics.map(vs => {

                const vid = vs && vs.vid

                if(vid) {
                    if(Object.prototype.hasOwnProperty.call(newState.staticsByID, vid)) newState.staticsByID[vid] = deepMerge(newState.staticsByID[vid], vs)
                    else newState.staticsByID[vid] = vs

                    if(!newState.allIDs.includes(vid)) newState.allIDs.push(vid)
                }

                return null
            })

            vehicleStates && vehicleStates.length && vehicleStates.map(vs => {

                const vid = vs && vs.vid

                if(vid) {
                    if(Object.prototype.hasOwnProperty.call(newState.stateByID, vid)) newState.stateByID[vid] = deepMerge(newState.stateByID[vid], vs)
                    else newState.stateByID[vid] = vs
    
                    if(!newState.allIDs.includes(vid)) newState.allIDs.push(vid)
                }

                return null
            })

            vehicleDerivativeStates && vehicleDerivativeStates.length && vehicleDerivativeStates.map(vs => {

                const vid = vs && vs.vid

                if(vid) {
                    if(Object.prototype.hasOwnProperty.call(newState.derivativeStateByID, vid)) newState.derivativeStateByID[vid] = deepMerge(newState.derivativeStateByID[vid], vs)
                    else newState.derivativeStateByID[vid] = vs
    
                    if(!newState.allIDs.includes(vid)) newState.allIDs.push(vid)

                    if(vs.transitStatus && !newState.byTransitStatus[vs.transitStatus].includes(vid)) {
                        newState.byTransitStatus[vs.transitStatus].push(vid)
                    }

                }

                return null
            })

            vehicleGeoStates && vehicleGeoStates.length && vehicleGeoStates.map(vs => {

                const vid = vs && vs.vid

                if(vid) {
                    if(Object.prototype.hasOwnProperty.call(newState.geoStateByID, vid)) newState.geoStateByID[vid] = deepMerge(newState.geoStateByID[vid], vs)
                    else newState.geoStateByID[vid] = vs
    
                    if(!newState.allIDs.includes(vid)) newState.allIDs.push(vid)
                }

                return null
            })

            vehicleFuelProfiles && vehicleFuelProfiles.length && vehicleFuelProfiles.map(vfp => {
                const vid = vfp && vfp.vid
                newState.fuelProfileByID[vid] = vfp
            })

            vehiclePackageProfiles && vehiclePackageProfiles.length && vehiclePackageProfiles.map(vpp => {
                const vid = vpp && vpp.vid
                newState.vehiclePKGByID[vid] = vpp
            })

            // If vehicle don't have data
            if(vehicleDerivativeStates && vehicleDerivativeStates.length !== vehicleStatics.length) {

                const arr = Object.values(newState.byTransitStatus).reduce((arr, cur) => [...arr, ...cur], [])

                const UnknownArr = newState.allIDs.filter(vid => !arr.includes(vid))

                UnknownArr.length && UnknownArr.map(vid => !newState.byTransitStatus[VEHICLE_TRANSIT_STATUS_OBJECT.DISCONNECTED].includes(vid) && newState.byTransitStatus[VEHICLE_TRANSIT_STATUS_OBJECT.DISCONNECTED].push(vid))

            }

            newState.byTransitStatus[VEHICLE_TRANSIT_STATUS_OBJECT.ALL] = newState.allIDs || [];

            return newState
        }
        case ActionTypes.SET_SELECTED_VEHICLE: {

            const vid = action.vid

            newState.selectedID = vid

            return newState
        }
        case ActionTypes.UPDATE_VEHICLE_STATE_INFO: {

            const {
                vid,
                vehicle
            } = action

            newState.stateByID[vid] = {
                ...newState.stateByID[vid],
                ...vehicle
            }

            // if(!newState.allIDs.includes(vid)) newState.allIDs.push(vid)

            return newState
        }
        /**
         * WS Action
         */
        case ActionTypes.UPDATE_VEHICLE_INFO_THRU_WS: {

            const {
                vid,
                vehicle
            } = action

            const {
                vehicleDerivativeState = {},
                vehicleState = {}
            } = vehicle

            newState.stateByID[vid] = {
                ...newState.stateByID[vid],
                ...vehicleState
            }
            newState.derivativeStateByID[vid] = {
                ...newState.derivativeStateByID[vid],
                ...vehicleDerivativeState
            }

            // if(!newState.allIDs.includes(vid)) newState.allIDs.push(vid)

            // if(vehicleDerivativeState.transitStatus && !newState.byTransitStatus[vehicleDerivativeState.transitStatus].includes(vid)) {
            //     newState.byTransitStatus[vehicleDerivativeState.transitStatus].push(vid)
            // } else if (!vehicleDerivativeState.transitStatus || !Object.prototype.hasOwnProperty.call(newState.byTransitStatus, vehicleDerivativeState.transitStatus)) {
            //     if(!newState.byTransitStatus[VEHICLE_TRANSIT_STATUS_OBJECT.DISCONNECTED].includes(vid)) {
            //         newState.byTransitStatus[VEHICLE_TRANSIT_STATUS_OBJECT.DISCONNECTED].push(vid)
            //     }
            // }

            return newState
        }
        case ActionTypes.UPDATE_VEHICLE_GEO_STATE_THRU_WS: {

            const {
                vid,
                vehicle
            } = action

            const {
                VGState = {}
            } = vehicle

            newState.geoStateByID[vid] = {
                ...newState.geoStateByID[vid],
                ...VGState
            }

            // if(!newState.allIDs.includes(vid)) newState.allIDs.push(vid)

            return newState
        }
        default: {
            return newState;
        }
    }
}