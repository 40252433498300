import SvgGpsFixed from "../../assets/components/GpsFixed.js";
import SvgGpsOff from "../../assets/components/GpsOff.js";
import SvgHourglassEmpty from "../../assets/components/HourglassEmpty.js";
import SvgHourglassFull from "../../assets/components/HourglassFull.js";
import SvgIosSpeedometer from "../../assets/components/IosSpeedometer.js";
import SvgLocalParking from "../../assets/components/LocalParking.js";
import SvgSignalCellular4Bar from "../../assets/components/SignalCellular4Bar.js";
import SvgSignalCellularOff from "../../assets/components/SignalCellularOff.js";
import Info from "../../assets/components/Info.js";

import SVG_ALL from '../../assets/svg/icon_all.svg' ;
import SVG_MOVING from '../../assets/svg/iconmonstr-navigation-1.svg' ;
import SVG_IDLING from '../../assets/svg/iconmonstr-media-control-49.svg' ;
import SVG_PARKING from '../../assets/svg/icon_parking.svg' ;
import SVG_DISCONNECT from '../../assets/svg/icon_ligthing.svg' ;

import SVG_INFO from '../../assets/svg/icon-info.svg'
import SVG_WARNING from '../../assets/svg/icon-warning.svg'
import SVG_CRITICAL from '../../assets/svg/icon-critical.svg'
import SVG_CONTROL from '../../assets/svg/icon-control-24px.svg'

import SVG_TRIP_EVENT_INFO from '../../assets/svg/trip_record_info.svg'
import SVG_TRIP_EVENT_WARNING from '../../assets/svg/trip_record_warning.svg'
import SVG_TRIP_EVENT_CRITICAL from '../../assets/svg/trip_record_critical.svg'

const EVENT_SVG = {
    gpsFull: SvgGpsFixed,
    gpsNo: SvgGpsOff,
    idleOver: SvgHourglassFull,
    idleNormal: SvgHourglassEmpty,
    parking: SvgLocalParking,
    cellFull: SvgSignalCellular4Bar,
    cellNo: SvgSignalCellularOff,
    speed: SvgIosSpeedometer,
    info: Info
}

const VEHICLE_TRANSIT_STATUS_OBJECT = {
    ALL: 'ALL',
    MOVING: 'MOVING',
    IDLING: 'IDLING',
    PARKING: 'PARKING',
    DISCONNECTED: 'DISCONNECTED' 
}

export const CHART_VEHICLE_TRANSIT_STATUS_OBJECT = {
    MOVING: 'MOVING',
    IDLING: 'IDLING',
    PARKING: 'PARKING'
}

const EVENT_TICKET_TYPES_OBJECT = {
    ALL: "ALL",
    INFO: "INFO",
    WARNING: "WARNING",
    CRITICAL: "CRITICAL",
    CONTROL: "CONTROL"
};

const EVENT_TICKET_TYPES_BY_CODES = {
    0: EVENT_TICKET_TYPES_OBJECT.INFO,
    1: EVENT_TICKET_TYPES_OBJECT.WARNING,
    2: EVENT_TICKET_TYPES_OBJECT.CRITICAL
}

const ALL_VEHICLE_TRANSIT_STATUS = 
    [ 
        VEHICLE_TRANSIT_STATUS_OBJECT.ALL,
        VEHICLE_TRANSIT_STATUS_OBJECT.MOVING, 
        VEHICLE_TRANSIT_STATUS_OBJECT.IDLING, 
        VEHICLE_TRANSIT_STATUS_OBJECT.PARKING, 
        VEHICLE_TRANSIT_STATUS_OBJECT.DISCONNECTED
    ]
const ALL_EVENT_TYPE = 
    [
        EVENT_TICKET_TYPES_OBJECT.ALL,
        EVENT_TICKET_TYPES_OBJECT.INFO,
        EVENT_TICKET_TYPES_OBJECT.WARNING,
        EVENT_TICKET_TYPES_OBJECT.CRITICAL,
        EVENT_TICKET_TYPES_OBJECT.CONTROL
    ]

const FILTERABLE_VEHICLE_TRANSIT_STATUS = 
    [
        VEHICLE_TRANSIT_STATUS_OBJECT.MOVING, 
        VEHICLE_TRANSIT_STATUS_OBJECT.IDLING, 
        VEHICLE_TRANSIT_STATUS_OBJECT.PARKING, 
        VEHICLE_TRANSIT_STATUS_OBJECT.DISCONNECTED
    ]

const FILTERABLE_EVENT_TYPE = 
    [ 
        EVENT_TICKET_TYPES_OBJECT.INFO,
        EVENT_TICKET_TYPES_OBJECT.WARNING,
        EVENT_TICKET_TYPES_OBJECT.CRITICAL,
        EVENT_TICKET_TYPES_OBJECT.CONTROL
    ]

const TRIP_STATUS_OBJECT = {
    TRANSIT: 'TRANSIT',
    PARKING: 'PARKING',
    DISCONNECTED: 'DISCONNECTED'
}

const TRANSIT_STATUS_OBJECT = {
    MOVING: 'MOVING',
    IDLING: 'IDLING',
    PARKING: 'PARKING',
    DISCONNECTED: 'DISCONNECTED'
}

const VEHICLE_TRANSIT_STATUS_COLOR = {
    [VEHICLE_TRANSIT_STATUS_OBJECT.ALL] : "#C4C4C4",
    [TRIP_STATUS_OBJECT.TRANSIT]: "#52C41A",
    [VEHICLE_TRANSIT_STATUS_OBJECT.MOVING] : "#52C41A",
    [VEHICLE_TRANSIT_STATUS_OBJECT.IDLING] : "#FFB517",
    [VEHICLE_TRANSIT_STATUS_OBJECT.PARKING] : "#0488DB",
    [VEHICLE_TRANSIT_STATUS_OBJECT.DISCONNECTED] : "#333333",
    'NULL': "#333333"
}

export const CHART_VEHICLE_TRANSIT_STATUS_COLOR = {
    [VEHICLE_TRANSIT_STATUS_OBJECT.MOVING] : "#52C41A",
    [VEHICLE_TRANSIT_STATUS_OBJECT.IDLING] : "#FFB517",
    [VEHICLE_TRANSIT_STATUS_OBJECT.PARKING] : "#0488DB"
}

const VEHICLE_TRANSIT_STATUS_ICON = {
    [VEHICLE_TRANSIT_STATUS_OBJECT.ALL]: SVG_ALL,
    [VEHICLE_TRANSIT_STATUS_OBJECT.MOVING]: SVG_MOVING,
    [VEHICLE_TRANSIT_STATUS_OBJECT.IDLING]: SVG_IDLING, 
    [VEHICLE_TRANSIT_STATUS_OBJECT.PARKING]: SVG_PARKING,
    [VEHICLE_TRANSIT_STATUS_OBJECT.DISCONNECTED]: SVG_DISCONNECT
}

const EVENT_TICKET_COLOR = {
    [EVENT_TICKET_TYPES_OBJECT.ALL]: "#C4C4C4",
    [EVENT_TICKET_TYPES_OBJECT.INFO]: "#52C41A", //old color: #37c837
    [EVENT_TICKET_TYPES_OBJECT.WARNING]: "#FFB517", //old color: #7F2AFF
    [EVENT_TICKET_TYPES_OBJECT.CRITICAL]: "#0488DB", //old color: #D4FF2A
    [EVENT_TICKET_TYPES_OBJECT.CONTROL]: "#856B37", //old color: #2A2AFF,
    'NULL': "#333333"
};

const TRIP_RECORD_EVENT_ICON = {
    [EVENT_TICKET_TYPES_OBJECT.INFO]: SVG_TRIP_EVENT_INFO,
    [EVENT_TICKET_TYPES_OBJECT.WARNING]: SVG_TRIP_EVENT_WARNING,
    [EVENT_TICKET_TYPES_OBJECT.CRITICAL]: SVG_TRIP_EVENT_CRITICAL
}

const EVENT_TICKET_TYPES_ICON = {
    [EVENT_TICKET_TYPES_OBJECT.ALL]: SVG_ALL,
    [EVENT_TICKET_TYPES_OBJECT.INFO]: SVG_INFO,
    [EVENT_TICKET_TYPES_OBJECT.WARNING]: SVG_WARNING,
    [EVENT_TICKET_TYPES_OBJECT.CRITICAL]: SVG_CRITICAL,
    [EVENT_TICKET_TYPES_OBJECT.CONTROL]: SVG_CONTROL
}

const TRANSIT_AND_EVENT_DURATIONS_OBJECT = {
    ALL: 'ALL',
    ONE_MINUTE: 'ONE_MINUTE',
    TWO_MINUTES: 'TWO_MINUTES',
    FIVE_MINUTES: 'FIVE_MINUTES',
    TEN_MINUTES: 'TEN_MINUTES',
    FIFTEEN_MINUTES: 'FIFTEEN_MINUTES',
    THIRTY_MINUTES: 'THIRTY_MINUTES'
}

const TRANSIT_AND_EVENT_DURATIONS_TYPE = {
    [TRANSIT_AND_EVENT_DURATIONS_OBJECT.ALL]: {
        label: 'ALL',
        value: 0
    },
    [TRANSIT_AND_EVENT_DURATIONS_OBJECT.ONE_MINUTE]: {
        label: '> 1 min',
        value: 60 * 1000
    },
    [TRANSIT_AND_EVENT_DURATIONS_OBJECT.TWO_MINUTES]: {
        label: '> 2 mins',
        value: 2 * 60 * 1000
    },
    [TRANSIT_AND_EVENT_DURATIONS_OBJECT.FIVE_MINUTES]: {
        label: '> 5 mins',
        value: 5 * 60 * 1000
    },
    [TRANSIT_AND_EVENT_DURATIONS_OBJECT.TEN_MINUTES]: {
        label: '> 10 mins',
        value: 10 * 60 * 1000
    },
    [TRANSIT_AND_EVENT_DURATIONS_OBJECT.FIFTEEN_MINUTES]: {
        label: '> 15 mins',
        value: 15 * 60 * 1000
    },
    [TRANSIT_AND_EVENT_DURATIONS_OBJECT.THIRTY_MINUTES]: {
        label: '> 30 mins',
        value: 30 * 60 * 1000
    },
}

export {
    ALL_VEHICLE_TRANSIT_STATUS,
    ALL_EVENT_TYPE,
    EVENT_SVG,
    EVENT_TICKET_COLOR,
    EVENT_TICKET_TYPES_ICON,
    EVENT_TICKET_TYPES_OBJECT,
    EVENT_TICKET_TYPES_BY_CODES,
    VEHICLE_TRANSIT_STATUS_COLOR,
    VEHICLE_TRANSIT_STATUS_ICON,
    VEHICLE_TRANSIT_STATUS_OBJECT,
    // Trip
    TRIP_STATUS_OBJECT,
    TRANSIT_STATUS_OBJECT,
    FILTERABLE_VEHICLE_TRANSIT_STATUS,
    FILTERABLE_EVENT_TYPE,
    TRIP_RECORD_EVENT_ICON,
    // Transit
    TRANSIT_AND_EVENT_DURATIONS_OBJECT,
    TRANSIT_AND_EVENT_DURATIONS_TYPE
}