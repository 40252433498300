export const COMMON_ACTION = {
    EDIT: 'EDIT',
    ADD: 'ADD',
    CREATE: 'CREATE',
    DELETE: 'DELETE',
    ARCHIVE: 'ARCHIVE'
}

export const COMMON_ACTION_TITLE = {
    [COMMON_ACTION.EDIT]: 'Edit',
    [COMMON_ACTION.ADD]: 'Add',
    [COMMON_ACTION.CREATE]: 'Create',
    [COMMON_ACTION.DELETE]: 'Delete',
    [COMMON_ACTION.ARCHIVE]: 'Archive'
}