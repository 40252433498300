import * as API from "../../api"
import * as ActionTypes from "../action-types";
import { REDUX } from '../../../constants'
import { batch } from "react-redux";

// Other Redux Actions
import {
    cu_geofence_asset_relation_request
} from './geofenceTemplates'

/**
 * ThirdParty Library
 */
 import {
    message
} from 'antd'

/**
 * Get Geofence
 */

export const get_geofence_request = geoid => dispatch => {
    

    API
    .getGeofenceInfoByGEOID(geoid)
    .then(info => {
        
        try {
            const {
                geofence
            } = info

            dispatch(get_geofence_success(geofence))


        } catch(err) {
            message.error('Error in getting geofences. Refresh page?')
        }

    })
}

const get_geofence_success = (geofence) => {
    return {
        type: ActionTypes.GET_GEOFENCE,
        geofence
    };
}

/**
 * Get Geofences
 */

 export const get_geofences_request = geoids => dispatch => {

    let geoidBatch = []

    batch(() => {

        geoids.map((vid, index) => {

            geoidBatch.push(vid)
    
            if( 
                (index+1) % REDUX.REDUX_GEOFENCE_BATCH_GET_SIZE === 0
                ||
                index+1 === geoids.length
            ) {
                dispatch(batch_get_geofences_request(geoidBatch))
                geoidBatch = []
            }
    
            return null
        })

    })

 }

/**
 * Batch Get geofence
 */
 export const batch_get_geofences_request = geoids => dispatch => {

    API
    .getGeofenceInfoBatch(geoids)
    .then(info => {
        
        try {
            const {
                geofences
            } = info

            dispatch(batch_get_geofences_success(geofences))


        } catch(err) {
            message.error('Error in batch getting geofences. Refresh page?')
        }

    })
}

const batch_get_geofences_success = (geofences) => {
    return {
        type: ActionTypes.GET_GEOFENCES,
        geofences
    };
}

/**
 * Set Selected Geofence
 */
 export const set_selected_geofence_request = (geoid) => dispatch => {
    dispatch(set_selected_geofence_success(geoid))
}

const set_selected_geofence_success = (geoid) => {
    return {
        type: ActionTypes.SET_SELECTED_GEOFENCE_GEOID,
        geoid
    }
}

/**
 * Clear Selected Geofence
 */
export const clear_selected_geofence_request = () => dispatch => {
    dispatch(clear_selected_geofence_success())
}

const clear_selected_geofence_success = () => {
    return {
        type: ActionTypes.CLEAR_SELECTED_GEOFENCE_GEOID
    }
}

/**
 * CU Geofence
 */
export const cu_geofence_request = (item) => dispatch => {
    const { actionAPI, geotids } = item

    API
    .CU_Geofence(item)
    .then(info => {

        try {

            const {
                status,
                geofence,
                msg
            } = info
    
            switch(status) {
                case 200: {

                    message.success(`Success ${REDUX.REDUX_CRUD_API_ACTION_THRU_CODE[actionAPI]} geofence`)
    
                    dispatch(cu_geofence_asset_relation_request(geotids, geofence.geoid))
                    dispatch(cu_geofence_success(geofence))
    
                    break;
                }
                case 500: {
                    throw msg
                }
                default: {
                    break;
                }
            }

        } catch(err) {
            console.log("Error", err)
            message.error(`Error in ${REDUX.REDUX_CRUD_API_ACTION_THRU_CODE[actionAPI]} geofence. Refresh page?`)
        }

    })
}

const cu_geofence_success = (geofence) => {
    return {
        type: ActionTypes.CU_GEOFENCE,
        geofence
    }
}