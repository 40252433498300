export const GET_VEHICLE = `V3_GET_VEHICLE`;
export const GET_VEHICLES = `V3_GET_VEHICLES`;
export const CLEAR_TEMP_NEW_VID = "V3_CLEAR_TEMP_NEW_VID";
export const CLEAR_SELECTED_VEHICLE = "V3_CLEAR_SELECTED_VEHICLE";
export const SET_SELECTED_VEHICLE = "V3_SET_SELECTED_VEHICLE";
export const TEMP_UPDATE_VEHICLE_ADDRESS = "V3_TEMP_UPDATE_VEHICLE_ADDRESS";

export const UPDATE_VEHICLE_STATE_INFO = `V3_UPDATE_VEHICLE_STATE_INFO`;
/**
 * WS Action
 */
export const UPDATE_VEHICLE_INFO_THRU_WS = `V3_UPDATE_VEHICLE_INFO_THRU_WS`;
export const UPDATE_VEHICLE_GEO_STATE_THRU_WS = `V3_UPDATE_VEHICLE_GEO_STATE_THRU_WS`;