import React from "react";
import { useLocation } from 'react-router'

// Constant
import {
    ROUTES,
    COMMON_FEATURES
} from '../../constants'
import showSecondarySidebar from '../../layout/MainLayout/helpers/showSecondarySidebar'

// Components
import Map_MainPanel from '../../components/Map/GoogleMaps/Map_MainPanel'
import Map_RoutePlayback from '../../components/Map/GoogleMaps/Map_RoutePlayback'
import Map_Geofence from '../../components/Map/GoogleMaps/Map_Geofence'
import Map_POI from '../../components/Map/GoogleMaps/Map_POI'
import Map_Zone from '../../components/Map/GoogleMaps/Map_Zone'

const {
    MAP_ROUTE
} = ROUTES

const MapPanel = () => {

    const location = useLocation()

    const renderMap = pathname => {

        switch (pathname) {
            case MAP_ROUTE[COMMON_FEATURES.FEATURE_NAME.VEHICLE_ROUTE_PLAYBACK]: 
                return <Map_RoutePlayback />
            case MAP_ROUTE[COMMON_FEATURES.FEATURE_NAME.GEOFENCE_MANAGEMENT]:
                return <Map_Geofence />
            case MAP_ROUTE[COMMON_FEATURES.FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_CREATE]:
            case MAP_ROUTE[COMMON_FEATURES.FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_EDIT]:
                return <Map_Zone />
            case MAP_ROUTE[COMMON_FEATURES.FEATURE_NAME.GEOFENCE_MANAGEMENT_POI_CREATE]:
            case MAP_ROUTE[COMMON_FEATURES.FEATURE_NAME.GEOFENCE_MANAGEMENT_POT_EDIT]:
                return <Map_POI />
            case MAP_ROUTE[COMMON_FEATURES.FEATURE_NAME.LANDING_PAGE]:
            default:
                return <Map_MainPanel />
        }
    }

    return (
        <>
            {
                renderMap(location.pathname)
            }
        </>
    )
}

export default showSecondarySidebar(true)(MapPanel);