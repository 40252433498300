export const GET_BROWSER_LOC = "V3_GET_BROWSER_LOC";

export const SET_MAP_CONTROL = "V3_SET_MAP_CONTROL"
export const RESET_MAP_LOCATION = "V3_RESET_MAP_LOCATION"
export const SET_MAP_LOCATION = "V3_SET_MAP_LOCATION"
export const SET_MAP_ZOOM = "V3_SET_MAP_ZOOM"

export const CLEAR_GEOFENCE = "V3_CLEAR_GEOFENCE";
export const SET_NEW_GEOFENCE = "V3_SET_NEW_GEOFENCE";

export const SET_SELECTED_GEOFENCE_TEMPLATES = "V3_SET_SELECTED_GEOFENCE_TEMPLATES";
export const CLEAR_SELECTED_GEOFENCE_TEMPLATES = "V3_CLEAR_SELECTED_GEOFENCE_TEMPLATES";

export const ADD_MAP_SHAPE_INTO_LIST = "V3_ADD_MAP_SHAPE_INTO_LIST";
export const CLEAR_MAP_SHAPES_FROM_LIST = "V3_CLEAR_MAP_SHAPES_FROM_LIST";

export const SET_NEW_GEOFENCE_FILTER_STRING = "V3_SET_NEW_GEOFENCE_FILTER_STRING";
export const RESET_GEOFENCE_FILTER_STRING = "V3_RESET_GEOFENCE_FILTER_STRING";

// UI Control Stuff
//================================================================================
export const SET_MAP_FILTERS = "V3_SET_MAP_FILTERS";

export const ADD_ENABLED_VEHICLE_POP_UP = "V3_ADD_ENABLED_VEHICLE_POP_UP";
export const REMOVE_ENABLED_VEHICLE_POP_UP = "V3_REMOVE_ENABLED_VEHICLE_POP_UP";
export const CLEAR_ENABLED_VEHICLE_POP_UP = "V3_CLEAR_ENABLED_VEHICLE_POP_UP";

export const ADD_ENABLED_GEOFENCE_POP_UP = "V3_ADD_ENABLED_GEOFENCE_POP_UP";
export const CLEAR_ENABLED_GEOFENCE_POP_UP = "V3_CLEAR_ENABLED_GEOFENCE_POP_UP";

export const ENABLE_SHOW_VEHICLE_INFO = "V3_ENABLE_SHOW_VEHICLE_INFO";
export const DISABLE_SHOW_VEHICLE_INFO = "V3_DISABLE_SHOW_VEHICLE_INFO";

export const ENABLE_SHOW_GEOFENCE_INFO = "V3_ENABLE_SHOW_GEOFENCE_INFO";
export const DISABLE_SHOW_GEOFENCE_INFO = "V3_DISABLE_SHOW_GEOFENCE_INFO";

export const ENABLE_SHOW_GEOFENCES = "V3_ENABLE_SHOW_GEOFENCES";
export const DISABLE_SHOW_GEOFENCES = "V3_DISABLE_SHOW_GEOFENCES";

export const ENABLE_SHOW_LIVE_TRAFFIC = "V3_ENABLE_SHOW_LIVE_TRAFFIC";
export const DISABLE_SHOW_LIVE_TRAFFIC = "V3_DISABLE_SHOW_LIVE_TRAFFIC";

export const ENABLE_SHOW_VEHICLE_TRAIL = "V3_ENABLE_SHOW_VEHICLE_TRAIL";
export const DISABLE_SHOW_VEHICLE_TRAIL = "V3_DISABLE_SHOW_VEHICLE_TRAIL";

export const ADD_ENABLED_EVENT_POP_UP = "V3_ADD_ENABLED_EVENT_POP_UP";
export const CLEAR_ENABLED_EVENT_POP_UPS = "V3_CLEAR_ENABLED_EVENT_POP_UPS";
export const REMOVE_ENABLED_EVENT_POP_UP = "V3_REMOVE_ENABLED_EVENT_POP_UP";

export const RESET_UI_CONTROL = "V3_RESET_UI_CONTROL";

export const ENABLE_SHOW_HIGHWAY_SUV =  "V3_ENABLE_SHOW_HIGHWAY_SUV";
export const DISABLE_SHOW_HIGHWAY_SUV =  "V3_DISABLE_SHOW_HIGHWAY_SUV";

export const ENABLE_SHOW_HIGHWAY_SUV_INFO =  "V3_ENABLE_SHOW_HIGHWAY_SUV_INFO";
export const DISABLE_SHOW_HIGHWAY_SUV_INFO =  "V3_DISABLE_SHOW_HIGHWAY_SUV_INFO";

export const AUTOFOCUS_HIGHWAY_SUV =  "V3_AUTOFOCUS_HIGHWAY_SUV_INFO";
export const DISABLE_AUTOFOCUS_HIGHWAY_SUV = "V3_DISABLE_AUTOFOCUS_HIGHWAY_SUV";

export const ENABLE_INDICATOR_INFO_WINDOW =  "V3_ENABLE_INDICATOR_INFO_WINDOW";
export const DISABLE_INDICATOR_INFO_WINDOW = "V3_DISABLE_INDICATOR_INFO_WINDOW";

//================================================================================

//Map Event
//================================================================================

export const SET_MAP_FOCUS_DETAIL_POINT_TYPE = 'V3SET_MAP_FOCUS_DETAIL_POINT_TYPE'
export const FOCUS_ON_MAP_DETAIL_POINT = 'V3FOCUS_ON_MAP_DETAIL_POINT'
export const UNFOCUS_ON_MAP_DETAIL_POINT = 'V3UNFOCUS_ON_MAP_DETAIL_POINT'

export const OPEN_TRANSIT_DRAWER_ON_MAP = 'V3OPEN_TRANSIT_DRAWER'
export const CLOSE_TRANSIT_DRAWER_ON_MAP = 'V3CLOSE_TRANSIT_DRAWER'