import 
    React,
    {
        useState
    }
from "react";
import { useSelector } from 'react-redux'
import { Table, Tag, Space, Tooltip, Button } from 'antd';
import {
    CloseOutlined,
    CheckOutlined
} from '@ant-design/icons'

// Constant
import { TEMPLATE } from '../../../../../../constants'

// Components

// Services
import { TIME, REDUX } from '../../../../../../services/util'

// Handlers
import { table_handler } from '../../../../../../handlers' 

// Routes

// Redux Action

// Styling
import './index.css'

const { getColumnSearchProps } = table_handler

const { parseTime } = TIME

const { MAINTENANCE } = TEMPLATE

const MaintenanceServicePanel = ({onChangeMSActiveStatus}) => {

    const maintenanceServices = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.serviceByID'))
    const maintenanceComponents = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.componentByID'))
    const mainsids = useSelector(REDUX.reduxStoreWrapper('maintenanceConfigs.allServiceIDs'))

    const searchTextState = useState('')
    const searchedColumnState = useState('')

    const maintenanceServiceReData = () => 
        mainsids
        &&
        mainsids.map(mainsid => (
            {
                key: mainsid,
                componentName:
                    maintenanceServices[mainsid] 
                    && maintenanceServices[mainsid].maincid 
                    && maintenanceComponents[maintenanceServices[mainsid].maincid]
                    && maintenanceComponents[maintenanceServices[mainsid].maincid].name,
                ...maintenanceServices[mainsid]
            }
        ))
        ||
        []

    const onChangeMaintenanceServiceActiveStatus = (mainsid, activeStatus) => {
        onChangeMSActiveStatus(mainsid, activeStatus)
    }

    const maintenanceServiceColumns = [
        {
            title: 'Component Name',
            dataIndex: 'componentName',
            key: 'componentName',
            align: 'center',
            fixed: 'left',
            ...getColumnSearchProps('Component Name', 'componentName', searchTextState, searchedColumnState)
        },
        {
            title: 'Service Type',
            dataIndex: 'serviceParam',
            key: 'serviceParam',
            align: 'center',
            fixed: 'left',
            filters: Object.keys(MAINTENANCE.SERVICE_CODE).map(code => ({
                text: MAINTENANCE.SERVICE_CODE[code],
                value: code,
            })),
            onFilter: (value, record) => record.serviceParam === value,
            render: (data) => <Tag>{data && MAINTENANCE.SERVICE_CODE[data]}</Tag>,
        },
        {
            title: 'Status',
            dataIndex: 'activeStatus',
            key: 'activeStatus',
            align: 'center',
            fixed: 'left',
            filters: Object.keys(MAINTENANCE.STATUS_CODE).map(code => ({
                text: MAINTENANCE.STATUS_CODE[code][0],
                value: code,
            })),
            onFilter: (value, record) => record.activeStatus === value,
            render: (data) => <Tag color={data && MAINTENANCE.STATUS_CODE[data][1]}>{data && MAINTENANCE.STATUS_CODE[data][0]}</Tag>,
        },
        {
            title: "Updated At",
            dataIndex: "updatedAt",
            defaultSortOrder: "ascend",
            align: 'center',
            sorter: (a, b) => a.updatedAt - b.updatedAt,
            render: updatedAt => parseTime(Number(updatedAt)),
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            defaultSortOrder: "ascend",
            align: 'center',
            sorter: (a, b) => a.createdAt - b.createdAt,
            render: createdAt => parseTime(Number(createdAt)),
        },
        {
            title: "Actions",
            fixed: 'right',
            render: rowData => (
                <Space size="middle" style={{float: "left"}}>
                    {
                        MAINTENANCE.STATUS_CODE[rowData.activeStatus][1] ===  MAINTENANCE.STATUS_CODE[1][1]?
                        (
                            <Tooltip title={'Disable Maintenance Service'}>
                                <Button 
                                    shape='circle' 
                                    icon={<CloseOutlined />}
                                    type="danger"
                                    onClick={() => onChangeMaintenanceServiceActiveStatus(rowData.mainsid, MAINTENANCE.STATUS_PARAMS_CODE[MAINTENANCE.STATUS_PARAMS.INACTIVE])}
                                />
                            </Tooltip>
                        )
                        :
                        (
                            <Tooltip title={'Enable Maintenance Service'}>
                                <Button 
                                    shape='circle' 
                                    icon={<CheckOutlined />}
                                    type="primary"
                                    onClick={() => onChangeMaintenanceServiceActiveStatus(rowData.mainsid, MAINTENANCE.STATUS_PARAMS_CODE[MAINTENANCE.STATUS_PARAMS.ACTIVE])}
                                />
                            </Tooltip>
                        )
                    }
                </Space>
            )
        }
    ]

    return (
        <>
            <Table 
                size={'small'}
                bordered
                columns={maintenanceServiceColumns} 
                dataSource={maintenanceServiceReData()} 
                pagination={{
                    position: ['topRight']
                }}
            />
        </>
    )
}

export default React.memo(MaintenanceServicePanel)