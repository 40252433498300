import * as ActionTypes from "../action-types";

const tripRecord_defaultState = {
    byID: {},
    recordByVID: {},
    selectedVID: "",
    selectedTime: 0,
    selectedStartTime: 0,
    selectedEndTime: 0,
    allIDs: []
}

export const tripRecords = (
    state = tripRecord_defaultState,
    action
) => {
    let newState = JSON.parse(JSON.stringify(state));

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_TIMEFRAME_TRIP_RECORDS: {

            const tripRecords = action.tripRecords

            const vid = action.vid
            const startTime = action.startTime
            const endTime = action.endTime

            newState.byID[vid] = [ startTime, endTime ]

            newState.recordByVID[vid] = tripRecords.sort((a,b) => a.startTime-b.startTime )
            
            if(!newState.allIDs.includes(vid)) newState.allIDs.push(vid)
            
            return newState;
        }

        case ActionTypes.SET_SELECTED_TRIP_RECORD_TIME: {

            const startTime = action.startTime

            newState.selectedTime = startTime

            return newState;
        }

        case ActionTypes.SET_SELECTED_VEHICLE_FOR_TRIP_RECORD: {

            const vid = action.vid

            newState.selectedVID = vid

            return newState;
        }

        case ActionTypes.UNSET_SELECTED_VEHICLE_FOR_TRIP_RECORD: {

            newState.selectedVID = ""

            return newState;
        }

        case ActionTypes.SET_SELECTED_TRIP_RECORD_START_TIME: {
            const startTime = action.startTime

            newState.selectedStartTime = startTime

            return newState;
        }

        case ActionTypes.SET_SELECTED_TRIP_RECORD_END_TIME: {
            const endTime = action.endTime

            newState.selectedEndTime = endTime

            return newState;
        }

        case ActionTypes.UNSET_SELECTED_TRIP_RECORD_START_TIME: {
            newState.selectedStartTime = 0

            return newState;
        }

        case ActionTypes.UNSET_SELECTED_TRIP_RECORD_END_TIME: {
            newState.selectedEndTime = 0

            return newState;
        }


        default: {
            return state;
        }
    }
};
