import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from 'antd'

export const get_trip_record_by_timeframe_request = (vid, startTime, endTime) => dispatch => {

    return API
    .getTripRecordByTimeFrameSync(vid, startTime, endTime) 
    .then(info => {
        
        try {
            const {
                tripRecords
            } = info

            dispatch(get_trip_record_by_timeframe_success(tripRecords, vid, startTime, endTime))


        } catch(err) {
            message.error('Error in getting timeframe trip records. Refresh page?')
        }
    })
    
}

const get_trip_record_by_timeframe_success = (tripRecords, vid, startTime, endTime) => {
    return {
        type: ActionTypes.GET_TIMEFRAME_TRIP_RECORDS,
        tripRecords,
        vid, startTime, endTime
    }
}

export const set_selected_vehicle_for_trip_record_request = (vid) => dispatch => {
    dispatch(set_selected_vehicle_for_trip_record_success(vid))
}

const set_selected_vehicle_for_trip_record_success = (vid) => {
    return {
        type: ActionTypes.SET_SELECTED_VEHICLE_FOR_TRIP_RECORD,
        vid
    }
}

export const clear_selected_vehicle_for_trip_record_request = () => dispatch => {
    dispatch(clear_selected_vehicle_for_trip_record_success())
}

const clear_selected_vehicle_for_trip_record_success = () => {
    return {
        type: ActionTypes.UNSET_SELECTED_VEHICLE_FOR_TRIP_RECORD
    }
}

export const set_selected_trip_record_time_request = (startTime) => dispatch => {
    dispatch(set_selected_trip_record_time_success(startTime))
}

const set_selected_trip_record_time_success = (startTime) => {
    return {
        type: ActionTypes.SET_SELECTED_TRIP_RECORD_TIME,
        startTime
    }
}

export const set_selected_trip_record_start_time_request = (startTime) => dispatch => {
    dispatch(set_selected_trip_record_start_time_success(startTime))
}

const set_selected_trip_record_start_time_success = (startTime) => {
    return {
        type: ActionTypes.SET_SELECTED_TRIP_RECORD_START_TIME,
        startTime
    }
}
export const set_selected_trip_record_end_time_request = (endTime) => dispatch => {
    dispatch(set_selected_trip_record_end_time_success(endTime))
}

const set_selected_trip_record_end_time_success = (endTime) => {
    return {
        type: ActionTypes.SET_SELECTED_TRIP_RECORD_END_TIME,
        endTime
    }
}

export const unset_selected_trip_record_start_time_request = () => dispatch => {
    dispatch(unset_selected_trip_record_start_time_success())
}

const unset_selected_trip_record_start_time_success = () => {
    return {
        type: ActionTypes.UNSET_SELECTED_TRIP_RECORD_START_TIME
    }
}
export const unset_selected_trip_record_end_time_request = () => dispatch => {
    dispatch(unset_selected_trip_record_end_time_success())
}

const unset_selected_trip_record_end_time_success = () => {
    return {
        type: ActionTypes.UNSET_SELECTED_TRIP_RECORD_END_TIME
    }
}