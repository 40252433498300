import React from 'react'

// Components
import ECUIcon from '../EventStatusIcon/components/ECUIcon'

const VehiclePlateECUTag = ({vehicle, plate}) => {
    return (
        <>
            <>{plate}</>
            <ECUIcon vehicle={vehicle}/>
        </>
    )
}

export default VehiclePlateECUTag