/**
 * Logo
 */
 import logo from '../../assets/logo/logo.png'
 import logo_sub_word from '../../assets/logo/logo_sub_word.png'
 
 /**
  * Background
  */
import landing_background from '../../assets/theme/landing_background.png'

import * as THEME from '../../assets/theme/color'
 
 
 /**
  * Color Theme
  */
  const ThemeColor = THEME.color
  const ThemeText = THEME.text
 
 const img = {
     logo: logo,
     logo_sub_word: logo_sub_word,
     background: landing_background
 }
 
 const color = {
     themeColor: ThemeColor,
     themeText: ThemeText
 }
 
 export const LOGO = img.logo;
 export const LOGO_SUB_WORD = img.logo_sub_word;
 export const LOGIN_BACKGROUND = img.background;
 
 export const THEME_COLOR = color.themeColor;
 export const TEXT_COLOUR = color.themeText;