import React from 'react'
import {
    PlusOutlined,
} from '@ant-design/icons';
import {
    Tooltip
} from "antd";

// Components
import RuleEngineTable from './components/RuleEngineTable'
import { PrimaryButton } from "../../../../components/Decoration/Button";

const RuleEnginePanel = () => {

    const AddRuleTemplateOnClick = () => {

    }

    const AddRuleOnClick = () => {

    }

    return (
        <div style = {{ display: "flex", flex: 1 }}>
            <div
                className = "scroll-div"
                style = {{
                    width: "95%",
                    margin: "0 5%"
                }}
            >
                <div
                    style = {{
                        display: "flex",
                        justifyContent: "flex-end",

                        padding: 5,
                        marginBottom: 10,
                    }}
                >
                    <PrimaryButton 
                        icon = {<PlusOutlined/>} 
                        onClick = {AddRuleTemplateOnClick}
                    >
                        Add New Rule Template
                    </PrimaryButton>

                    <Tooltip
                        placement = "top"
                        title = {`No custom rule template found. Add a new rule template first`}
                        open = {true}
                    >
                        <PrimaryButton
                            disabled = {true}
                            icon = {<PlusOutlined/>}
                            onClick = {AddRuleOnClick}
                            style = {{ marginLeft: 10 }}
                        >
                            Add New Rule
                        </PrimaryButton>
                    </Tooltip>
                </div>

                <>
                    <RuleEngineTable />
                </>
            </div>
        </div>
    )
}

export default RuleEnginePanel