// Constant
import {
    TEMPLATE
} from '../../constants'

const {
    DEFAULT_GEOFENCE_COLOR,
    GEOFENCE_COLORS_FONT_HEXCODE
} = TEMPLATE.GEOFENCE

export const returnLatestGeofenceTemplateColorForGeoID = (geofenceTemplates, geoID = null, geotID = null) => {
    let latestTemplate = {};

    if(geoID) {

        geofenceTemplates
        &&
        Object.values(geofenceTemplates.byID)
        .filter((currTemplate) => currTemplate.name !== TEMPLATE.GEOFENCE.DEFAULT_GEOFENCE_TEMPLATE && currTemplate.geofences.includes(geoID)) // Only check geofence templates containing this geofence ID
        .forEach((currTemplate) => {
            if (!latestTemplate.createdAt || currTemplate.createdAt > latestTemplate.createdAt) {
                latestTemplate = currTemplate;
            }
        })
    } else if (geotID) {
       latestTemplate = geofenceTemplates.byID[geotID]
    }

    return latestTemplate.colorHexCode ? latestTemplate.colorHexCode : DEFAULT_GEOFENCE_COLOR;
}

export const geofenceTemplateStyleBar = (colorHexCode, cond) => (
    cond
    &&
    colorHexCode
    &&
    {
        backgroundColor: colorHexCode,
        color: GEOFENCE_COLORS_FONT_HEXCODE[colorHexCode]
    }
    || 
    {}
)