import * as ActionTypes from "../action-types";

const vehicleEventLog_defaultState = {
    byID: {},
    logByVID: {},
    allIDs: []
}

export const vehicleEventLogs = (
    state = vehicleEventLog_defaultState,
    action
) => {
    let newState = JSON.parse(JSON.stringify(state));

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_TIMEFRAME_VEHICLE_EVENT_LOGS: {

            const vehicleEventLogs = action.vehicleEventLogs

            const vid = action.vid
            const startTime = action.startTime
            const endTime = action.endTIme

            newState.byID[vid] = [startTime, endTime]

            newState.logByVID[vid] = vehicleEventLogs.sort((a,b) => a.vehicleTime-b.vehicleTime)
            
            if(!newState.allIDs.includes(vid)) newState.allIDs.push(vid)
            
            return newState;
        }
        default: {
            return state;
        }
    }
};
