import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'antd'

// Constants

// Components

// Services
import { REDUX } from '../../../../services/util'

// Handler

// Style
import "../../index.css";

const VehiclePackageLabel = (props) => {

    const {
        vehiclePackage = {}
    } = props

    const {
        vpkgids = []
    } = vehiclePackage

    const vehiclePackages = useSelector(REDUX.reduxStoreWrapper('subscriptionPackages.vehiclePKGByID')) 

    return (
        <>  
        {
            vpkgids
            &&
            vpkgids.length
            &&
            vpkgids.map((vpkgid,index) => (
                <Row 
                    key = {`${index}-${vpkgid}`}
                    style = {{ 
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 1, 
                    }}
                >
                    <Col 
                        className = "VEHICLE_PACKAGE_TAG" 
                        style = {
                            vpkgid
                            &&
                            vehiclePackages[vpkgid].colorCode
                            &&
                            {
                                backgroundColor: vehiclePackages[vpkgid].colorCode
                            }
                            ||
                            {}
                        }
                    >
                        {vpkgid && vehiclePackages[vpkgid].name}
                    </Col>
                </Row>
            ))
        }
        </>
    )

}

export default VehiclePackageLabel