import React from 'react'
import { useSelector } from 'react-redux'
import { TreeSelect } from 'antd'

// Constant
import { TEMPLATE } from '../../../../constants'

// Handlers
import { vehicles_handler } from '../../../../handlers'

// Styles
import '../../index.css'

const {
    TreeNode,
    SHOW_PARENT
} = TreeSelect;

const {
    REPORT
} = TEMPLATE

const {
    vehicleECUValidator
} = vehicles_handler

const DEFAULT_FILTER = REPORT.VEHICLE_REPORT_DEFAULT_FILTER;

const SearchFields = (props) => {

    const {
        selectedVehicle,
        onVIDChange,
        render,
    } = props

    const vehicles = useSelector(state => state.containers.v3.vehicles.staticsByID)
    const vehicleStates = useSelector(state => state.containers.v3.vehicles.stateByID)

    const convertVehicle2VID = (string) => string.split('@')[1]

    return (
        <div className = "formField">
            <span className = "formFieldLabelLeft">Vehicles: </span>

            <TreeSelect
                allowClear
                treeCheckable
                showCheckedStrategy = {SHOW_PARENT}
                placeholder = {Object.keys(vehicles).length ? 'Select Vehicles' : "No Vehicles Found"}
                disabled = {!Object.keys(vehicles).length}
                value = {selectedVehicle}
                style = {{ width: 175 }}
                onChange = {value => {
                    const submitedValue = 
                        !value.includes(DEFAULT_FILTER) ?
                            (
                                value.map(v => convertVehicle2VID(v))
                            )
                        :
                            Object.values(vehicles)
                                .filter((currVehicle) => currVehicle.displayName)
                                .sort((a, b) => {
                                    const vA = a.displayName && a.displayName.toLowerCase();
                                    const vB = b.displayName && b.displayName.toLowerCase();
                            
                                    if (vA < vB) return -1;
                                    if (vA > vB) return 1;
                                    return 0;
                                })
                                .map((currVehicle) => currVehicle.vid)

                    // console.log(submitedValue)

                    onVIDChange(submitedValue);
                }}
            >
                {
                    <TreeNode
                        key = {DEFAULT_FILTER}
                        value = {DEFAULT_FILTER}
                        title = {"All Vehicles"}
                    >
                        {
                            Object.values(vehicles)
                            .filter((currVehicle) => currVehicle.displayName)
                            .sort((a, b) => {
                                const vA = a.displayName && a.displayName.toLowerCase();
                                const vB = b.displayName && b.displayName.toLowerCase();
                        
                                if (vA < vB) return -1;
                                if (vA > vB) return 1;
                                return 0;
                            })
                            .map((currVehicle) => 
                                <TreeNode
                                    key = {`${currVehicle.displayName}@${currVehicle.vid}`}
                                    value = {`${currVehicle.displayName}@${currVehicle.vid}`}
                                    title = {render(currVehicle.displayName, vehicleECUValidator(vehicleStates[currVehicle.vid]))}
                                />
                            )
                        }
                    </TreeNode>
                }
            </TreeSelect>
        </div>
    )
}

export default SearchFields