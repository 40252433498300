export const EXPAND_SIDEBAR = "V3_EXPAND_SIDEBAR";
export const ORIGINAL_SIDEBAR = "V3_ORIGINAL_SIDEBAR";
/** most likely EXPAND_SIDEBAR and ORIGINAL_SIDEBAR will be deprecated in the future
 * in favor of TOGGLE_SIDEBAR, CLOSE_SIDEBAR and OPEN_SIDEBAR
 */
export const TOGGLE_SIDEBAR = "V3_TOGGLE_SIDEBAR";
export const CLOSE_SIDEBAR = "V3_CLOSE_SIDEBAR";
export const OPEN_SIDEBAR = "V3_OPEN_SIDEBAR";
export const CHANGE_PAGE_HEADER_COLOR = "V3_CHANGE_PAGE_HEADER_COLOR";
export const SET_LOGO = "V3_SET_LOGO";

export const SET_LOADING_ROUTE_PLAYBACK ="V3_SET_LOADING_ROUTE_PLAYBACK";
export const UNSET_LOADING_ROUTE_PLAYBACK = "V3_UNSET_LOADING_ROUTE_PLAYBACK";
export const SET_LOADING_FUEL_ANALYSIS_DASHBOARD ="V3_SET_FUEL_ANALYSIS_DASHBOARD";
export const UNSET_LOADING_FUEL_ANALYSIS_DASHBOARD = "V3_UNSET_LOADING_FUEL_ANALYSIS_DASHBOARD";
export const SET_LOADING_GENERAL = "V3_SET_LOADING_GENERAL";
export const UNSET_LOADING_GENERAL = "V3_UNSET_LOADING_GENERAL";
export const SET_LOADING_VEHICLE_REPORT ="V3_SET_LOADING_VEHICLE_REPORT";
export const UNSET_LOADING_VEHICLE_REPORT = "V3_UNSET_LOADING_VEHICLE_REPORT";

export const SET_LOADING_ACTIONS = 'V3SET_LOADING_ACTIONS';
export const UNSET_LOADING_ACTIONS = 'V3UNSET_LOADING EDIT'

export const TOGGLE_UPDATE = "V3_TOGGLE_UPDATE";