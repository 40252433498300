import * as ActionTypes from '../action-types'

const hierarchy_DefaultState = {
    byID: {},
    hierarchyTree: {},
    allIDs: [],
    selectedID: ''
}

export const userHierarchy = (state = hierarchy_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_USER_HIERARCHY: {     
            const rootUser = action.user      
            const hierarchyTree = action.hierarchyTree
            const allIDs = action.allIDs

            newState.byID[rootUser.uid] = rootUser
            newState.hierarchyTree = hierarchyTree 
            newState.allIDs = allIDs

            return newState
        }
        case ActionTypes.GET_HIERARCHIAL_USER_INFO: {

            const user = action.user;

            const uid = user && user.uid

            if(uid) {
                newState.byID[uid] = user
            }

            return newState
        }
        case ActionTypes.SET_SELECTED_USER_FOR_USER_HIERARCHY: {
            const uid = action.uid
            newState.selectedID = uid
            return newState
        }
        case ActionTypes.UNSET_SELECTED_USER_FOR_USER_HIERARCHY: {
            newState.selectedID = ``
            return newState
        }
        default: {
            return newState;
        }
    }
}