// Constant
import { MAP, TEMPLATE } from '../../constants'

export const getRouteType = (trace) => {
    const isTripSelected = trace.isTripSelected;
    const isTransitSelected = trace.isTransitSelected;
    const isMemory = trace.devicePackage.includes('memory');

    if (isTransitSelected) {
        switch(trace.transitStatus) {
            case TEMPLATE.STATUS.TRANSIT_STATUS_OBJECT.MOVING: {
                return MAP.MAP_ROUTE_TRACE_TYPE.SELECTED_TRANSIT_MOVING
            }
            case TEMPLATE.STATUS.TRANSIT_STATUS_OBJECT.IDLING:
            default: {
                return MAP.MAP_ROUTE_TRACE_TYPE.SELECTED_TRANSIT_IDLING
            }
        }
    }
    else if (isTripSelected) return MAP.MAP_ROUTE_TRACE_TYPE.SELECTED_TRIP
    else if (isMemory) return MAP.MAP_ROUTE_TRACE_TYPE.MEMORY
    else return MAP.MAP_ROUTE_TRACE_TYPE.NORMAL
}