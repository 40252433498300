import React from 'react'

// Config
import { APP_CONFIG } from '../../config'

// Components
import showSecondarySidebar from '../../layout/MainLayout/helpers/showSecondarySidebar' 
import Page from "../../components/Decoration/Page"

// Styles Library
import styles from "./index.module.css";

const {
    EXT_IFRAME
} = APP_CONFIG

const GreenerWheelsModule = () => {

    const hostname = window.location.hostname

    const IframeURLFinder = () => EXT_IFRAME.gw.domain[hostname] || EXT_IFRAME.gw.domain.unknown

    return (
        <>
         <div className="page-container">
                <Page title="Greener Wheels Module">
                    <iframe 
                    className={styles['iframe']} 
                    title='Greener Wheels' 
                    // sandbox="allow-same-origin allow-scripts"
                    src={IframeURLFinder()}
                />
                </Page>
            </div>
        </>
    )
}

const MemorizedGreenerWheelsModule = React.memo(GreenerWheelsModule);

export default showSecondarySidebar(false)(MemorizedGreenerWheelsModule);