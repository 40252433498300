import React from "react";
import PageTitle from "./PageTitle";
import "./index.css";

const Page = props => {
    return (
        <div className="page">
            <PageTitle title={props.title} />
            {props.children}
        </div>
    );
};

export default Page;
