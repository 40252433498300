import * as API from "../../api"
import * as ActionTypes from "../action-types";
import { REDUX } from '../../../constants'
import { get_items_request } from '../../util/redux'

/**
 * ThirdParty Library
 */
import {
    message
} from 'antd'

/**
 * Redux Action
 */
import {
    setLoadingAction,
    unsetLoadingAction
} from './style'

/**
 * Get VAM Line Template
 */

export const get_vam_line_template_request = (vamltid) => dispatch => {
    
    API
    .getVAMLineTemplate(vamltid)
    .then(info => {
        
        try {
            const {
                vamLineTemplate
            } = info

            const {
                lineItems: vamlids
            } = vamLineTemplate

            dispatch(get_vam_line_template_success(vamLineTemplate))
            vamlids &&  vamlids.length && dispatch(get_vam_lines_request(vamlids))

        } catch(err) {
            message.error('Error in getting vam line template. Refresh page?')
        }

    })
}

const get_vam_line_template_success = (vamLineTemplate) => {
    return {
        type: ActionTypes.GET_VAM_LINE_TEMPLATE,
        vamLineTemplate
    };
}

/**
 * Get VAM Line Templates
 */

export const get_vam_line_templates_request = (vamltids) => dispatch => {

    dispatch(get_items_request(vamltids, batch_get_vam_line_templates_request, { SIZE: REDUX.REDUX_VAM_LINE_TEMPLATE_BATCH_GET_SIZE }))
}

/**
 * Batch Get VAM Line Templates
 */
export const batch_get_vam_line_templates_request = (vamltids) => dispatch => {

    API
    .getVAMLineTemplateBatch(vamltids)
    .then(info => {
        
        try {
            const {
                vamLineTemplates
            } = info

            const vamlids = vamLineTemplates && vamLineTemplates.reduce((acc, cur) => [...acc, ...cur.lineItems], [])

            dispatch(batch_get_vam_line_templates_success(vamLineTemplates))
            vamlids && vamlids.length && dispatch(get_vam_lines_request(vamlids))

        } catch(err) {
            message.error('Error in batch getting vam line templates. Refresh page?')
        }

    })
}

const batch_get_vam_line_templates_success = (vamLineTemplates) => {
    return {
        type: ActionTypes.GET_VAM_LINE_TEMPLATES,
        vamLineTemplates
    };
}

/**
 * Get VAM Line
 */

export const get_vam_line_request = (vamlid) => dispatch => {
    
    API
    .getVAMLine(vamlid)
    .then(info => {
        
        try {
            const {
                vamLineItem
            } = info


            dispatch(get_vam_line_success(vamLineItem))

        } catch(err) {
            message.error('Error in getting vam line item. Refresh page?')
        }

    })
}

const get_vam_line_success = (vamLineItem) => {
    return {
        type: ActionTypes.GET_VAM_LINE_ITEM,
        vamLineItem
    };
}


/**
 * Get VAM Line Items
 */

export const get_vam_lines_request = (vamlids) => dispatch => {
    dispatch(get_items_request(vamlids, batch_get_vam_lines_request, { SIZE: REDUX.REDUX_VAM_LINE_BATCH_GET_SIZE }))
}

export const batch_get_vam_lines_request = (vamlids) => dispatch => {

    API
    .getVAMLineBatch(vamlids)
    .then(info => {
        
        try {
            const {
                vamLineItems
            } = info

            dispatch(batch_get_vam_lines_success(vamLineItems))

        } catch(err) {
            message.error('Error in batch getting vam line items. Refresh page?')
        }

    })
}

const batch_get_vam_lines_success = (vamLineItems) => {
    return {
        type: ActionTypes.GET_VAM_LINE_ITEMS,
        vamLineItems
    };
}

/**
 * Create Update VAM Line
 */
export const cu_vam_line_request = (item, actionAPI, callback = () => {}) => dispatch => {

    dispatch(setLoadingAction())

    API
    .CU_VAMLine(item, actionAPI)
    .then(info => {

        try {
            const {
                status,
                vamLineItem,
                msg
            } = info

            switch(status) {
                case 200: {
    
                    dispatch(cu_vam_line_success(vamLineItem))
    
                    break;
                }
                case 500: {
                    throw msg
                }
                default: {
                    break;
                }
            }

            message.success(`Success in ${REDUX.REDUX_CRUD_API_ACTION_THRU_CODE[actionAPI]} line items`)

        } catch(err) {
            console.log(err)
            message.error(`Error in ${REDUX.REDUX_CRUD_API_ACTION_THRU_CODE[actionAPI]} line items. Refresh page?`)
        }

    })
    .finally(() => {
        dispatch(unsetLoadingAction())
        callback()
    })
}

const cu_vam_line_success = (vamLineItem) => {
    return {
        type: ActionTypes.CU_VAM_LINE_ITEM,
        vamLineItem
    };
}

/**
 * Set VAMLID
 */
export const set_vamlid_request = (vamlid) => dispatch => {
    dispatch(set_vamlid_success(vamlid))
}

const set_vamlid_success = (vamlid) => {
    return {
        type: ActionTypes.SET_VAM_LINE_VAMLID,
        vamlid
    };
}

/**
 * Unset VAMLID
 */
export const unset_vamlid_request = () => dispatch => {
    dispatch(unset_vamlid_success())
}

const unset_vamlid_success = () => {
    return {
        type: ActionTypes.UNSET_VAM_LINE_VAMLID
    };
}