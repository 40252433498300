import * as API from "../../api"
import * as ActionTypes from "../action-types";

/**
 * ThirdParty Library
 */
 import {
    message
} from 'antd'

/**
 * Get User Role
 */


export const user_role_get_request = uroleid => dispatch => {
    API
    .getRoleByUser(uroleid)
    .then(info => {
        
        try {
            const {
                uroles
            } = info

            dispatch(user_role_get_success(uroleid, uroles))

        } catch(err) {
            message.error('Error. Refresh page?')
        }

    })
}

const user_role_get_success = (uroleid, uroles) => {
    return {
        type: ActionTypes.USER_ROLE_GET,
        currentUROLEID: uroleid,
        userRoles: uroles
    };
}