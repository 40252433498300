import React from 'react'
import { defineAbility } from '@casl/ability';
import {
  Can
} from '@casl/react'

// Constants & Config
import { TEMPLATE, FEATURE, PACKAGE } from '../../constants'

const {
  ACCOUNT_PACKAGE,
  ACCOUNT_PACKAGE_CODE,
  ACCOUNT_PACKAGE_BY_CODE,
  ACCOUNT_PACKAGE_ACCESSIBLE_MODULE
} = PACKAGE

export const ACCESSIBLE_ACTION = {
  READ: 'read',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  MANAGE: 'manage'
}

export const ACCESSIBLE_TYPE = {
  PUBLIC: 'public',
  RESTRICTED: 'restricted'
}

export const defineAccessible = (
  role, 
  accountPackages = [
    ACCOUNT_PACKAGE_CODE[ACCOUNT_PACKAGE.PINETOP_BASIC]
  ]
) => {
  return defineAbility(can => {

    can(ACCESSIBLE_ACTION.READ, ACCESSIBLE_TYPE.PUBLIC);

    FEATURE.FEATURE_MENU_ITEMS
    .filter(menu => 
      accountPackages
      .map(ap => {
        return (
          ap
          &&
          Object.prototype.hasOwnProperty.call(ACCOUNT_PACKAGE_BY_CODE, ap)
          &&
          Object.prototype.hasOwnProperty.call(ACCOUNT_PACKAGE_ACCESSIBLE_MODULE, `${ACCOUNT_PACKAGE_BY_CODE[`${ap}`]}`)
          &&
          ACCOUNT_PACKAGE_ACCESSIBLE_MODULE[`${ACCOUNT_PACKAGE_BY_CODE[`${ap}`]}`].includes(menu.feature)
        )
      })
      .reduce((acc, cur) => cur || acc, false)
    )
    .map(menu =>  can(ACCESSIBLE_ACTION.READ, menu.feature))

    switch (role) {
      case TEMPLATE.USER_ROLE.ROLE_TYPE.SUPER:
      case TEMPLATE.USER_ROLE.ROLE_TYPE.ADMIN:
      case TEMPLATE.USER_ROLE.ROLE_TYPE.DISTRIBUTOR:

        can(ACCESSIBLE_ACTION.CREATE, ACCESSIBLE_TYPE.RESTRICTED)
        can(ACCESSIBLE_ACTION.UPDATE, ACCESSIBLE_TYPE.RESTRICTED)
        can(ACCESSIBLE_ACTION.CREATE, ACCESSIBLE_TYPE.PUBLIC)
        can(ACCESSIBLE_ACTION.UPDATE, ACCESSIBLE_TYPE.PUBLIC)

      break;

      case TEMPLATE.USER_ROLE.ROLE_TYPE.FLEET_OWNER:

        can(ACCESSIBLE_ACTION.CREATE, ACCESSIBLE_TYPE.PUBLIC)
        can(ACCESSIBLE_ACTION.UPDATE, ACCESSIBLE_TYPE.PUBLIC)

        // Feature

      break;

      case TEMPLATE.USER_ROLE.ROLE_TYPE.FLEET_OPERATOR:

        // Feature

      break;

      default:
        return
    }
  })
}

// HOC Accessbile Component
export const AccessibleModule = (action, module, role) => WrappedComponent => {

  const WComp = () =>
    <Can I={action} a={module} ability={defineAccessible(role)}>
      <WrappedComponent />
    </Can>

  WComp.displayName = 'AccessibleModule_WrapComp'

  return WComp()
}