import React from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route } from "react-router";

// Constants
import { ROUTE } from '../../../constants'

/**
 * Components
 */
import { asyncComponent } from '../../../components'

 /**
 * Pages
 */
import SSOPanel from '../../../pages/SSOPanel'
import SSOLogin from '../../../pages/SSOLogin'
import SSOLoading from '../../../pages/SSOLoading'

const AsyncNotFoundPage = asyncComponent(() => import("../../../pages/NotFoundPage"));

const AuthSelector = () => {

    const auth = useSelector(state => state.root.auth)

    const _authSelector = () => {
        if(auth.loadingState) {
            return <SSOLoading />
        } else {
            if(auth.currentUser) {
                return <SSOPanel currentUser={auth.currentUser}/>
            } else {
                return <SSOLogin />
            }
        }
    }

    return (
        <>
        {
            _authSelector()
        }
        </>
    )
}

const SSORoutes = () => {

    return (
        <>
            <Routes>

                <Route
                    exact
                    path={ROUTE.MODULE_ROUTE.Login}
                    element={<SSOLogin/>}
                />

                <Route 
                    index
                    path={ROUTE.MODULE_ROUTE.Landing_Page} 
                    element={<AuthSelector />}
                />

                <Route element={<AsyncNotFoundPage />}/>

            </Routes>
        </>
    )
}

export default SSORoutes;