import * as ActionTypes from "../action-types";

const transitRecord_defaultState = {
    byID: {},
    recordByVID: {},
    selectedVID: "",
    selectedTime: 0,
    selectedStartTime: 0,
    selectedEndTime: 0,
    allIDs: []
}

export const transitRecords = (
    state = transitRecord_defaultState,
    action
) => {
    let newState = JSON.parse(JSON.stringify(state));

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_TIMEFRAME_TRANSIT_RECORDS: {

            const transitRecords = action.transitRecords

            const vid = action.vid
            const startTime = action.startTime
            const endTime = action.endTime

            newState.byID[vid] = [ startTime, endTime ]

            newState.recordByVID[vid] = transitRecords && transitRecords.sort((a,b) => a.startTime-b.startTime ) || []
            
            if(!newState.allIDs.includes(vid)) newState.allIDs.push(vid)
            
            return newState;
        }

        case ActionTypes.SET_SELECTED_TRANSIT_RECORD_TIME: {

            const startTime = action.startTime

            newState.selectedTime = startTime

            return newState;
        }

        case ActionTypes.SET_SELECTED_VEHICLE_FOR_TRANSIT_RECORD: {

            const vid = action.vid

            newState.selectedVID = vid

            return newState;
        }

        case ActionTypes.UNSET_SELECTED_VEHICLE_FOR_TRANSIT_RECORD: {

            newState.selectedVID = ""

            return newState;
        }

        case ActionTypes.SET_SELECTED_TRANSIT_RECORD_START_TIME: {
            const startTime = action.startTime

            newState.selectedStartTime = startTime

            return newState;
        }

        case ActionTypes.SET_SELECTED_TRANSIT_RECORD_END_TIME: {
            const endTime = action.endTime

            newState.selectedEndTime = endTime

            return newState;
        }

        case ActionTypes.UNSET_SELECTED_TRANSIT_RECORD_START_TIME: {
            newState.selectedStartTime = 0

            return newState;
        }

        case ActionTypes.UNSET_SELECTED_TRANSIT_RECORD_END_TIME: {
            newState.selectedEndTime = 0

            return newState;
        }

        default: {
            return state;
        }
    }
};
