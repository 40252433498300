// Constant
import { TEMPLATE } from '../../../constants';

// Redux
import { 
    setLoadingRoutePlayback, 
    unsetLoadingRoutePlayback,
    setLoadingVehicleReport,
    unsetLoadingVehicleReport
} from './style'
import { 
    get_transit_record_by_timeframe_request, 
    set_selected_vehicle_for_transit_record_request,
    clear_selected_vehicle_for_transit_record_request 
} from './transitRecords'
import { 
    get_trip_record_by_timeframe_request, 
    set_selected_vehicle_for_trip_record_request,
    clear_selected_vehicle_for_trip_record_request 
} from './tripRecords'
import { 
    get_vehicle_log_by_timeframe_request,
    get_vehicle_derivative_log_by_timeframe_request,
    get_vehicle_event_log_by_timeframe_request,
    get_vehicle_geo_state_log_by_timeframe_request,
    set_selected_vehicle_for_vehicle_log_request,
    clear_selected_vehicle_for_vehicle_log_request
} from './vehicleLogs'
import {
    set_selected_vehicle_request,
    clear_selected_vehicle_request
} from './vehicles'
import { get_vehicle_report_daily_summary_by_timeframe_request } from './vehicleReports'
import { increase_vehicle_report_api_usage_request } from './utils'

const {
    REPORT
} = TEMPLATE

// Set Select Vehicle for Route Playback
export const set_selected_vehicle_for_route_playback_request = (vid) => dispatch => {
    dispatch(set_selected_vehicle_request(vid))
    dispatch(set_selected_vehicle_for_vehicle_log_request(vid))
    dispatch(set_selected_vehicle_for_transit_record_request(vid))
    dispatch(set_selected_vehicle_for_trip_record_request(vid))
}

export const clear_selected_vehicle_for_route_playback_request = () => dispatch => {
    dispatch(clear_selected_vehicle_request())
    dispatch(clear_selected_vehicle_for_transit_record_request())
    dispatch(clear_selected_vehicle_for_trip_record_request())
    dispatch(clear_selected_vehicle_for_vehicle_log_request())
}

// Get Trip Route Playback

export const get_trip_route_playback_by_vid_request = (vid, startTime, endTime) => (dispatch) => {

    dispatch(setLoadingRoutePlayback())

    const funcPromise = [
        dispatch(get_transit_record_by_timeframe_request(vid, startTime, endTime)),
        dispatch(get_trip_record_by_timeframe_request(vid, startTime, endTime)),
        dispatch(get_vehicle_log_by_timeframe_request(vid, startTime, endTime)),
        dispatch(get_vehicle_event_log_by_timeframe_request(vid, startTime, endTime))
    ]

    return Promise.all(funcPromise)
    .finally(() => dispatch(unsetLoadingRoutePlayback()))
}

export const get_vehicle_report_multi_params_request = (report_type, vids, startTime, endTime) => dispatch => {

    dispatch(setLoadingVehicleReport())

    const funcPromise = []

    vids.map(vid => {

        switch(report_type) {

            case REPORT.VEHICLE_REPORT_TABLE_TYPE_KEYS.TRIP: {

                funcPromise.push(
                    ...[
                        dispatch(get_trip_record_by_timeframe_request(vid, startTime, endTime)),
                        dispatch(get_vehicle_geo_state_log_by_timeframe_request(vid, startTime, endTime)) 
                    ]
                )

                break;
            }
            case REPORT.VEHICLE_REPORT_TABLE_TYPE_KEYS.TRANSIT: {

                funcPromise.push(
                    ...[
                        dispatch(get_transit_record_by_timeframe_request(vid, startTime, endTime)),
                        dispatch(get_vehicle_geo_state_log_by_timeframe_request(vid, startTime, endTime))
                    ]
                )

                break;
            }
            case REPORT.VEHICLE_REPORT_TABLE_TYPE_KEYS.DETAIL: {

                funcPromise.push(
                    ...[
                        dispatch(get_vehicle_log_by_timeframe_request(vid, startTime, endTime)),
                        dispatch(get_vehicle_derivative_log_by_timeframe_request(vid, startTime, endTime)),
                        dispatch(get_vehicle_geo_state_log_by_timeframe_request(vid, startTime, endTime))
                    ]
                )

                break;
            }
            case REPORT.VEHICLE_REPORT_TABLE_TYPE_KEYS.DAILY_SUMMARY : {

                funcPromise.push(
                    ...[
                        dispatch(get_vehicle_report_daily_summary_by_timeframe_request(vid, startTime, endTime))
                    ]
                )

                break;
            }
            default: {
                break;
            }

        }
    })

    return Promise.all(funcPromise)
    .finally(() => {
        dispatch(unsetLoadingVehicleReport())
        dispatch(increase_vehicle_report_api_usage_request())
    })

}