import React from 'react';
import { 
    useSelector
 } from 'react-redux';
 import {
    Row,
    Col,
} from "antd";
// Micro
// Components
import CellSignalIcon from './components/CellSignalIcon'
import DeviceBatteryLevelIcon from './components/DeviceBatteryLevelIcon'
import ExtPowerIcon from './components/ExtPowerIcon'
import FuelLevelIcon from './components/FuelLevelIcon';
import GNSSSignalIcon from './components/GNSSSignalIcon'

const EventStatusIcon = props => {

    const vehicles = useSelector(state => state.containers.v3.vehicles)

    const {
        vehicleID
    } = props;

    const vehicleState = vehicles.stateByID[vehicleID];

    return (
        <div
            style={{
                display: 'flex',
                alignItems: `center`
            }}
        >
            <Row gutter={8}>
                <Col span={4}>
                    <FuelLevelIcon vehicle={vehicleState} />
                </Col>

                <Col span={4}>
                    <ExtPowerIcon vehicle={vehicleState} />
                </Col>

                <Col span={4}>
                    <DeviceBatteryLevelIcon vehicle={vehicleState} />
                </Col>

                <Col span={4}>
                    <CellSignalIcon vehicle={vehicleState} />
                </Col>

                <Col span={4}>
                    <GNSSSignalIcon vehicle={vehicleState} />
                </Col>
            </Row>
        </div>
    )
}

export default EventStatusIcon;