import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Table, Tag, Row, Col, Tooltip, Space, Statistic } from 'antd'
import {
    EditOutlined,
    CheckCircleOutlined,
    AlertTwoTone,
    WarningTwoTone
} from '@ant-design/icons'

// Constants
import { TEMPLATE } from '../../../../constants'

// Components
import { EmptyIndicator } from '../../../../components/Indicator'
import { NextDueDeterminator, VAMReminderCUModal, VAMEntrySubmitModal } from './components'

// Services
import { TIME, REDUX, RoundUp } from '../../../../services/util'

// Handler
import {
    report_handler,
    table_handler,
} from '../../../../handlers'

// Redux Action
import * as REDUX_ACTION from '../../../../services/redux/actions'

const { getColumnSearchProps } = table_handler
const { parseTime, parseHours, getCurTime } = TIME
const { VAM } = TEMPLATE

const {
    MILEAGE_TYPE,
    mileageSelector,
} = report_handler

const {
    VAM_LINE_TYPE_COLOR_BY_CODE,
    VAM_LINE_TYPE_BY_CODE,
    VAM_REMINDER_STATUS_CODE_BY_STATUS,
    VAM_REMINDER_STATUS_BY_CODE,
    VAM_REMINDER_STATUS_COLOR_BY_CODE,
    MODAL_CU_PROCESS_ACTION
} = VAM

const VAMReminderManagement = () => {

    const dispatch = useDispatch()

    const style = useSelector(REDUX.reduxStoreWrapper('style'))

    const vamReminders = useSelector(REDUX.reduxStoreWrapper('vamReminders.reminderByID'))
    const vamLines = useSelector(REDUX.reduxStoreWrapper('vamLines.itemByID'))
    const vamrids = useSelector(REDUX.reduxStoreWrapper('vamReminders.allVAMRIDs'))
    const vehicles = useSelector(REDUX.reduxStoreWrapper('vehicles.staticsByID'))
    const vehicleDerivativeStates = useSelector(REDUX.reduxStoreWrapper('vehicles.derivativeStateByID'))
    
    const searchTextState = useState('')
    const searchedColumnState = useState('')

    const [ CUModalVisible, setCUModalVisible ] = useState(false)
    const [ EntrySubmitModalVisible, setEntrySubmitModalVisible ] = useState(false)
    const [ CUModalAction, setCUModalAction ] = useState(MODAL_CU_PROCESS_ACTION.CREATE)

    const vamReminderReData = () => 
        vamrids
        &&
        vamrids.map(vamrid => {

            const vamReminder = vamReminders[vamrid]

            const {
                vid,
                vamlid
            } =  vamReminder

            const vDS = vehicleDerivativeStates[vid]
            const odometer = vDS && mileageSelector(vDS, MILEAGE_TYPE.ODOMETER) || 0
            const workingHour = vDS && parseHours(vDS.plat_hist_engine_hour) || 0
            
            return {
                key: vamrid,
                ...vehicles[vid],
                odometer: !isNaN(odometer) ? RoundUp(odometer, 0) : "-",
                workingHour: !isNaN(workingHour) ? RoundUp(workingHour, 0) : "-",
                lineName: vamLines[vamlid]?.name,
                lineType: vamLines[vamlid]?.type,
                ...vamReminder,
                curTime: getCurTime()
            }
        })
        ||
    []

    const getStatus = (status) => vamReminderReData().reduce((acc, cur) => {
        return cur.status === status ? acc + 1 : acc
    }, 0)

    const onCreateReminder = () => {
        dispatch(REDUX_ACTION.v3_vamReminders.unset_vamrid_request())
        setCUModalAction(MODAL_CU_PROCESS_ACTION.CREATE)
        setCUModalVisible(true)
    }

    const onEditReminder = ({vamrid}) => {
        dispatch(REDUX_ACTION.v3_vamReminders.set_vamrid_request(vamrid))
        setCUModalAction(MODAL_CU_PROCESS_ACTION.EDIT)
        setCUModalVisible(true)
    }

    const onCreateEntry = ({vid, vamrid}) => {
        dispatch(REDUX_ACTION.v3_vamEntries.set_vid_request(vid))
        dispatch(REDUX_ACTION.v3_vamEntries.set_vamrids_request([vamrid]))
        setEntrySubmitModalVisible(true)
    }

    const onCUModalClose = () => {
        setCUModalVisible(false)
    }

    const onEntrySubmitModalClose = () => {
        setEntrySubmitModalVisible(false)
    }

    const vamReminderPanelCols = [
        {
          title: "Vehicle Plate",
          dataIndex: "plate",
          fixed: 'left',
          ...getColumnSearchProps("Vehicle Plate", 'plate', searchTextState, searchedColumnState),
        },
        {
          title: "Vehicle Name",
          dataIndex: "displayName",
          ...getColumnSearchProps("Vehicle Name", 'displayName', searchTextState, searchedColumnState),
        },
        {
            title: "Line Item",
            dataIndex: 'lineName'
        },
        {
            title: "Line Type",
            dataIndex: 'lineType',
            filters: Object.keys(VAM_LINE_TYPE_BY_CODE)
                .map(status => (
                    {
                        text: VAM_LINE_TYPE_BY_CODE[status],
                        value: status,
                    }
                )),
            onFilter: (value, record) => record.lineType === value,
            render: lineType => <Tag color={VAM_LINE_TYPE_COLOR_BY_CODE[lineType]}>{VAM_LINE_TYPE_BY_CODE[lineType]}</Tag>
        },
        {
            title: "Status",
            dataIndex: 'status',
            filters: Object.keys(VAM_REMINDER_STATUS_BY_CODE)
                .map(status => (
                    {
                        text: VAM_REMINDER_STATUS_BY_CODE[status],
                        value: status,
                    }
                )),
            onFilter: (value, record) => record.status === value,
            render: status => <Tag color={VAM_REMINDER_STATUS_COLOR_BY_CODE[status]}>{VAM_REMINDER_STATUS_BY_CODE[status]}</Tag>
        },
        {
            title: "Next Due",
            render: (data) => <NextDueDeterminator data={data}/>
        },
        {
            title: "Remark",
            dataIndex: "remark"
        },
        {
            title: "Last Completed",
            dataIndex: "lastCompletedAt",
            sorter: (a, b) => a.lastCompletedAt - b.lastCompletedAt,
            render: lastCompletedAt => parseTime(Number(lastCompletedAt)),
        },
        {
          title: "Updated At",
          dataIndex: "updatedAt",
          defaultSortOrder: "ascend",
          sorter: (a, b) => a.updatedAt - b.updatedAt,
          render: updatedAt => parseTime(Number(updatedAt)),
        },
        {
            title: "Actions",
            fixed: 'right',
            render: (data) => (
                <Space size="middle" style={{float: "left"}}>
                    <Tooltip title={'Edit Reminder'}>
                        <Button 
                            shape='circle' 
                            icon={<EditOutlined />}
                            type="primary"
                            onClick={() => {onEditReminder(data)}}
                        />
                    </Tooltip>
                    <Tooltip title={'Create Entry'}>
                        <Button 
                            shape='circle' 
                            icon={<CheckCircleOutlined />}
                            type="primary"
                            onClick={() => {onCreateEntry(data)}}
                        />
                    </Tooltip>
                </Space>
            )
        }
    ];

    return (
        <>
            <VAMReminderCUModal
                action={CUModalAction}
                open={CUModalVisible}
                onClose={() => onCUModalClose()}
            />
            <VAMEntrySubmitModal
                action={MODAL_CU_PROCESS_ACTION.CREATE}
                open={EntrySubmitModalVisible}
                onClose={() => onEntrySubmitModalClose()}
            />
            <Row gutter={24}>
                <Col>
                    <Statistic title="OverDue" value={getStatus(VAM_REMINDER_STATUS_CODE_BY_STATUS.OVERDUE)} prefix={<AlertTwoTone twoToneColor="red" style={{ fontSize: '32px' }} />} />
                </Col>
                <Col>
                    <Statistic title="Due Soon" value={getStatus(VAM_REMINDER_STATUS_CODE_BY_STATUS.DUE_SOON)} prefix={<WarningTwoTone twoToneColor="orange" style={{ fontSize: '32px' }} />} />
                </Col>
            </Row>
            <Row justify={'end'} style={{padding: '10px'}}>
                <Button type="primary" onClick={() => {onCreateReminder()}} >Add Reminder</Button>
            </Row>
            <Row style={{justifyContent:"center"}}>
            {
                vamReminderReData().length > 0 ?
                    <Table
                        loading={style.loading.general}
                        columns={vamReminderPanelCols && vamReminderPanelCols.filter(c => !c.isHidden)}
                        dataSource={vamReminderReData()}
                        pagination={true}
                        scroll={{
                            y: window.innerHeight,
                            x: vamReminderPanelCols && vamReminderPanelCols.length * 150
                        }}
                    /> 
                :
                    <EmptyIndicator />
            }
            </Row>
        </>
    )
}

export default VAMReminderManagement