const paramsConvertor = (obj_old, obj_new, oldParams, newParams) => {
    if(Object.prototype.hasOwnProperty.call(obj_old, oldParams)) {
        obj_new[newParams] = obj_old[oldParams]
        delete obj_new[oldParams]
    }
}

const refactor = (dl, paramsChange) => {
    let rl = JSON.parse(JSON.stringify(dl))
    paramsChange.map(pc => paramsConvertor(dl, rl, pc[0], pc[1]) )
    return rl
}

const dev_params_change = [
    ['Digital_Output_0', 'do_0'],
    ['Digital_Output_1', 'do_1'],
    ['Digital_Output_2', 'do_2'],
    ['Digital_Input_0', 'di_0'],
    ['Digital_Input_1', 'di_1'],
    ['Digital_Input_2', 'di_2'],
    ['Analog_Input_0', 'ai_0'],
    ['Analog_Input_1', 'ai_1'],
    ['Analog_Input_2', 'ai_2'],
    ['isAlive', 'connectivity'],
    ['packageTime', 'devicePackageTime'],
    ['package', 'devicePackage'],
    ['gpsStatus', 'gnss_status'],
    ['lac', 'cell_lac'],
    ['LAC', 'cell_lac'],
    ['cellid', 'cell_mnc_mcc'],
    ['MNC_MCC', 'cell_mnc_mcc'],
    ['fuel', 'ain_1'],
    ['fuel1', 'ain_2'],
    ['Axis_X', 'axis_x'],
    ['Axis_Y', 'axis_y'],
    ['Axis_Z', 'axis_z'],
    ['axis_X', 'axis_x'],
    ['axis_Y', 'axis_y'],
    ['axis_Z', 'axis_z'],
    ['Green_Driving_Type', 'eco_drive_type'],
    ['Green_Driving_Value', 'eco_drive_value'],
    ['Green_Driving_Event_Duration', 'eco_drive_duration'],
    ['ecoDriveType', 'eco_drive_type'],
    ['ecoDriveValue', 'eco_drive_value'],
    ['coDriveDuration', 'eco_drive_duration'],
    ['batteryCurrent', 'pwr_int_i'],
    ['batteryVoltage', 'pwr_int_v'],
    ['externalVoltage', 'pwr_ext_v'],
    ['isMoving', 'is_moving'],
    ['iButton', 'ibutton'],
    ['ICCID1', 'iccid1'],
    ['ICCID2', 'iccid2']
]

module.exports.deviceTranslator = (dev) => refactor(dev, dev_params_change)