import * as API from "../../api"
import * as ActionTypes from "../action-types";
import { REDUX } from '../../../constants'

/**
 * ThirdParty Library
 */
 import {
    message
} from 'antd'

/**
 * Get Geofence
 */

export const get_rule_request = ruleid => dispatch => {
    

    API
    .getRuleInfoByRULEID(ruleid)
    .then(info => {
        
        try {
            const {
                rule
            } = info

            dispatch(get_rule_success(rule))


        } catch(err) {
            message.error('Error in getting rules. Refresh page?')
        }

    })
}

const get_rule_success = (rule) => {
    return {
        type: ActionTypes.GET_RULE,
        rule
    };
}

/**
 * Get Rules
 */

 export const get_rules_request = ruleids => dispatch => {

    let ruleidBatch = []

    ruleids.map((vid, index) => {

        ruleidBatch.push(vid)

        if( 
            (index+1) % REDUX.REDUX_RULE_BATCH_GET_SIZE === 0
            ||
            index+1 === ruleids.length
        ) {
            dispatch(batch_get_rules_request(ruleidBatch))
            ruleidBatch = []
        }

        return null
    })
 }

/**
 * Batch Get rule
 */
 export const batch_get_rules_request = ruleids => dispatch => {

    API
    .getRuleInfoBatch(ruleids)
    .then(info => {
        
        try {
            const {
                rules
            } = info

            dispatch(batch_get_rules_success(rules))


        } catch(err) {
            message.error('Error in batch getting rules. Refresh page?')
        }

    })
}

const batch_get_rules_success = (rules) => {
    return {
        type: ActionTypes.GET_RULES,
        rules
    };
}

/**
 * Set Selected Rule
 */
 export const set_selected_rule_request = (ruleid) => dispatch => {
    dispatch(set_selected_rule_success(ruleid))
}

const set_selected_rule_success = (ruleid) => {
    return {
        type: ActionTypes.SET_SELECTED_RULE_RULEID,
        ruleid
    }
}

/**
 * Clear Selected Rule
 */
export const clear_selected_rule_request = () => dispatch => {
    dispatch(clear_selected_rule_success())
}

const clear_selected_rule_success = () => {
    return {
        type: ActionTypes.CLEAR_SELECTED_RULE_RULEID
    }
}