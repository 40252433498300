import React, { useState } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import { Select, Timeline } from 'antd'
import { CloseOutlined } from '@ant-design/icons';

// Constants
import { TEMPLATE, MAP } from '../../../../../constants'

// Components
import { PointTimeline, PointType } from './micro/PointTimeline'

// Handlers
import { event_handler } from '../../../../../handlers'

// Redux Action
import * as ACTIONS from '../../../../../services/redux/actions'

// Styles Constants
const styles = {
    headers: {
        color: 'white',
        backgroundColor: 'black',
        paddingLeft: 10,
    }
}

const {
    eventNameTranslator
} = event_handler

const {
    EVENT_TICKET_TYPES_BY_CODES,
    EVENT_TICKET_TYPES_OBJECT,
    FILTERABLE_EVENT_TYPE,
    FILTERABLE_VEHICLE_TRANSIT_STATUS,
    TRANSIT_AND_EVENT_DURATIONS_OBJECT,
    TRANSIT_AND_EVENT_DURATIONS_TYPE,
    TRIP_RECORD_EVENT_ICON
} = TEMPLATE.STATUS

const TransitRecordsDrawer = () => {

    const dispatch = useDispatch()

    // State
    const [ eventTypeFilters, setEventTypeFilters] = useState(FILTERABLE_EVENT_TYPE)
    const [ transitTypeFilters, setTransitTypeFilters] = useState(FILTERABLE_VEHICLE_TRANSIT_STATUS)
    const [ transitAndEventDurationFilterKey, setTransitAndEventDurationFilterKey] = useState(TRANSIT_AND_EVENT_DURATIONS_OBJECT.ALL)
    const [ timelineItemItemSelectedKey, setTimelineItemSelectedKey ] = useState(null)

    // Redux Store
    const vid = useSelector(state => state.containers.v3.vehicles.selectedID)
    const vehicleTransitRecords = useSelector(state => (vid && state.containers.v3.transitRecords.recordByVID[vid]) || [] )
    const vehicleEventLogs = useSelector(state => (vid && state.containers.v3.vehicleEventLogs.logByVID[vid]) || [] )

    const rules = useSelector(state => state.containers.v3.rules.byID)

    const vehicleTripSelectedStartTime = useSelector(state => state.containers.v3.tripRecords.selectedStartTime )
    const vehicleTripSelectedEndTime = useSelector(state => state.containers.v3.tripRecords.selectedEndTime )

    const vehicleSelectedTransitRecords = 
        vehicleTransitRecords
        .filter(transitRecord => vehicleTripSelectedStartTime <= transitRecord.startTime && vehicleTripSelectedEndTime >= transitRecord.endTime)
        .filter(transitRecord => transitTypeFilters.includes(transitRecord.transitStatus))
        .filter(transitRecord => {
            const duration = TRANSIT_AND_EVENT_DURATIONS_TYPE[transitAndEventDurationFilterKey];

            if (!duration) return true

            return transitRecord.duration >= duration.value
        })
        .map((transitRecord, index) => {
            return {
                ...transitRecord,
                key: transitRecord.tsid || `ts#${index}`,
                generatedAt: transitRecord.startTime,
                type: PointType.TRANSIT_RECORD.label,
            }
        })

    const vehicleSelectedeEventLogs = 
        vehicleEventLogs
        .filter(eventLog => vehicleTripSelectedStartTime <= eventLog.vehicleTime && vehicleTripSelectedEndTime >= eventLog.vehicleTime)
        .map(eventLog => ({...eventLog, ...rules[eventLog.eventid]}))
        .filter(eventLog => eventTypeFilters.includes(EVENT_TICKET_TYPES_BY_CODES[eventLog.type_rule]))
        .map((eventLog, index) => {
            return {
                ...eventLog,
                name: eventNameTranslator(eventLog),
                key: eventLog.eventTime || `event#${index}`,
                generatedAt: eventLog.vehicleTime,
                type: PointType.EVENT_LOG.label
            }
        })

    const telemetryPoints = [...vehicleSelectedTransitRecords, ...vehicleSelectedeEventLogs].sort((a, b) => a.generatedAt - b.generatedAt);

    const timelineClickHander = (key, type) => {

        const point = telemetryPoints.find(p => p.key === key )

        if(point) {
            switch(type) {
                case PointType.EVENT_LOG.label: {
                    dispatch(ACTIONS.v3_mapControl.focus_on_map_detail_point_request())
                    dispatch(ACTIONS.v3_mapControl.set_map_focuse_detail_point_type_request(MAP.MAP_EVENT_FOCUS_POINT_TYPE_OBJ.EVENT))
                    break;
                }
                case PointType.TRANSIT_RECORD.label: {
                    batch(() => {
                        dispatch(ACTIONS.v3_mapControl.focus_on_map_detail_point_request())
                        dispatch(ACTIONS.v3_mapControl.set_map_focuse_detail_point_type_request(MAP.MAP_EVENT_FOCUS_POINT_TYPE_OBJ.TRANSIT))
                        dispatch(ACTIONS.v3_transitRecords.set_selected_transit_record_start_time_request(point.startTime))
                        dispatch(ACTIONS.v3_transitRecords.set_selected_transit_record_end_time_request(point.endTime))
                    })
                    break;
                }
                default: {
                    break;
                }
            }

        }

        setTimelineItemSelectedKey(key)

        return null;
    }

    return (
        <>
            <div
                style = {{
                    display: 'flex',
                    flexDirection: 'column',

                    position: 'absolute',
                    overflow: `auto`,
                    resize: `horizontal`,
                    backgroundColor: 'white',
                    
                    right: 0,
                    zIndex: 99,
                    opacity: 0.9,
                    height: '100%',
                    width: 300,
                }}
            >
                <div style = {{ flex: 1 }}>
                    <div
                        style = {{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: `space-between`,

                            marginLeft: 5,
                        }}
                    >
                        <h3>Trip Details</h3>
                        <CloseOutlined
                            onClick = {() => {
                                setTransitAndEventDurationFilterKey(null)
                                dispatch(ACTIONS.v3_mapControl.close_transit_record_drawer_on_map_request())
                            }}
                            style = {{ margin: 5 }}
                        />
                    </div>

                    <div style = {{ paddingBottom: 10 }}>
                        <div style = {styles.headers}>
                            Duration
                        </div>

                        <Select
                            defaultValue = {transitAndEventDurationFilterKey}
                            onChange = {(transitAndEventDurationFilterKey) => {
                                setTransitAndEventDurationFilterKey(transitAndEventDurationFilterKey)
                            }}
                            style = {{ width: '100%' }}
                        >
                            {
                                Object.keys(TEMPLATE.STATUS.TRANSIT_AND_EVENT_DURATIONS_OBJECT).map(key => {
                                    const duration = TEMPLATE.STATUS.TRANSIT_AND_EVENT_DURATIONS_TYPE[key];

                                    return (
                                        <Select.Option key = {key}>
                                            {duration.label}
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </div>


                    <div style = {{ paddingBottom: 10 }}>
                        <div style = {styles.headers}>
                            Event Types
                        </div>

                        <Select
                            mode = 'multiple'
                            defaultValue = {eventTypeFilters}
                            onChange = {eventTypes => {
                                setEventTypeFilters(eventTypes)
                            }}
                            style = {{ width: '100%' }}
                        >
                            {
                                TEMPLATE.STATUS.FILTERABLE_EVENT_TYPE.map(eventType => {
                                    return (
                                        <Select.Option key = {eventType}>
                                            <img
                                                src = {TRIP_RECORD_EVENT_ICON[eventType] || TRIP_RECORD_EVENT_ICON[EVENT_TICKET_TYPES_OBJECT.INFO]}
                                                alt = ""
                                                style = {{
                                                    width: 16,
                                                    height: 16,
                                                    marginRight: 10,
                                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                                }}
                                            />

                                            {eventType}
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </div>

                    <div style = {{ paddingBottom: 10 }}>
                        <div style = {styles.headers}>
                            Transit Types
                        </div>
                        
                        <Select
                            mode = 'multiple'
                            defaultValue = {transitTypeFilters}
                            onChange = {transitTypes => {
                                setTransitTypeFilters(transitTypes)
                            }}
                            style = {{ width: '100%' }}
                        >
                            {
                                TEMPLATE.STATUS.FILTERABLE_VEHICLE_TRANSIT_STATUS.map(transitType => {
                                    return (
                                        <Select.Option key = {transitType}>
                                            {transitType}
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </div>
                </div>

                <div
                    style = {{
                        flex: 5,
                        padding: 10,
                        overflow: 'scroll',
                    }}
                >
                    <Timeline 
                        items={
                            telemetryPoints
                            .filter(point => point.type)
                            .map((point, index) => PointTimeline(
                                {
                                    key: index,
                                    point: point,
                                    onTimelineItemClick: timelineClickHander,
                                    selectedKey: timelineItemItemSelectedKey
                                }
                            ))
                        }
                    />
                </div>
            </div>
        </>
    )
}

export default TransitRecordsDrawer;