import { Tag } from 'antd'

// Constant
import { TRANSIT_STATUS_OBJECT, TRIP_STATUS_OBJECT } from '../status'

// Component
import GeoLocation from '../../../components/GeoLocation'
import IOTag from '../../../components/Decoration/IOTag'
import VehicleTripStatusTag from '../../../components/VehicleTripStatusTag'
import CellSignalIcon from '../../../components/EventStatusIcon/components/CellSignalIcon'
import GNSSSignalIcon from '../../../components/EventStatusIcon/components/GNSSSignalIcon'

// Handler
import { report_handler } from '../../../handlers'

// Service 
import { RoundUp, TIME } from '../../../services/util'

const {
    parseDuration,
    parseTime,
    parseDate,
    parseHours
} = TIME

const {
    DERIVATIVE_DATA_TYPE,
    MILEAGE_TYPE,
    mileageSelector,
    speedSelector
} = report_handler

export const COL_TRANSIT = ({vehicles, geofences, exportState}) => [
    {
        title: 'Vehicle',
        key: 'vehicle',
        dataIndex: 'vid',
        render: (vid) => vehicles[vid] && vehicles[vid].plate || vid
    },
    {
        title: 'Transit Status',
        key: 'transitStatus',
        dataIndex: 'transitStatus',
        filters: Object.keys(TRANSIT_STATUS_OBJECT).map(status => (
            {
                text: status,
                value: TRANSIT_STATUS_OBJECT[status],
            }
        )),
        onFilter: (value, record) => record.transitStatus === value,
        render: (data) => exportState ? data : <VehicleTripStatusTag tripStatus={data}/>
    },
    {
        title: 'Start Date',
        key: 'startTime',
        dataIndex: 'startTime',
        render: (time) => parseTime(time),
    },
    {
        title: 'End Date',
        key: 'endTime',
        dataIndex: 'endTime',
        render: (time) => parseTime(time),
    },
    {
        title: 'Total Duration (hrs)',
        key: 'duration',
        dataIndex: 'duration',
        render: (data) => parseDuration(data),
    },
    {
        title: 'Start Location',
        key: 'start_loc',
        dataIndex: 'start_loc',
        render: (data, rowData) => exportState ?
            (
                rowData
                &&
                rowData.start_geocoded
                &&
                rowData.start_addr
                ||
                `${data.lat}, ${data.lon}`
            )
            :
            (
                rowData
                &&
                rowData.start_geocoded
                &&
                <GeoLocation location={data} address={rowData.start_addr}/>
                ||
                <GeoLocation location={data}/>
            )
    },
    {
        title: 'End Location',
        key: 'end_loc',
        dataIndex: 'end_loc',
        render: (data, rowData) => exportState ?
        (
            rowData
            &&
            rowData.end_geocoded
            &&
            rowData.end_addr
            ||
            `${data.lat}, ${data.lon}`
        )
        :
        (
            rowData
            &&
            rowData.end_geocoded
            &&
            <GeoLocation location={data} address={rowData.end_addr}/>
            ||
            <GeoLocation location={data}/>
        )
    },
    {
        title: 'Total Mileage (km)',
        key: 'totalMileage',
        render: (rowData) => RoundUp(mileageSelector(rowData, MILEAGE_TYPE.TRIP_MILEAGE), 3)
    },
    {
        title: "Accumlative Geofence In",
        key: "geoIN",
        dataIndex: "geoIN",
        render: (data) => 
            (
                data
                &&
                data.length 
                && 
                exportState ? 
                (
                    data && data.map(geoid => geoid && geofences[geoid].name).join(', ')
                )
                :
                (
                    data && data.map(geoid => <Tag color="blue" key={geoid}>{geoid && geofences[geoid].name}</Tag>)
                )
            )
            ||
            '-'
    },
    {
        title: "Accumlative Geofence Out",
        key: "geoOUT",
        dataIndex: "geoOUT",
        render: (data) => 
            (
                data
                &&
                data.length 
                && 
                exportState ? 
                (
                    data && data.map(geoid => geoid && geofences[geoid].name).join(', ')
                )
                :
                (
                    data && data.map(geoid => <Tag color="blue" key={geoid}>{geoid && geofences[geoid].name}</Tag>)
                )
            )
            ||
            '-'
    },
]

export const COL_TRIP = ({vehicles, geofences, exportState}) => [
    {
        title: 'Vehicle',
        key: 'vehicle',
        dataIndex: 'vid',
        render: (vid) => vehicles[vid] && vehicles[vid].plate || vid
    },
    {
        title: 'Trip Status',
        key: 'tripStatus',
        dataIndex: 'tripStatus',
        filters: Object.keys(TRIP_STATUS_OBJECT).map(status => (
            {
                text: status,
                value: TRIP_STATUS_OBJECT[status],
            }
        )),
        onFilter: (value, record) => record.tripStatus === value,
        render: (data) => exportState ? data : <VehicleTripStatusTag tripStatus={data}/>
    },
    {
        title: 'Start Date',
        key: 'startTime',
        dataIndex: 'startTime',
        render: (time) => parseTime(time),
    },
    {
        title: 'End Date',
        key: 'endTime',
        dataIndex: 'endTime',
        render: (time) => parseTime(time),
    },
    {
        title: 'Total Duration (hrs)',
        key: 'duration',
        dataIndex: 'duration',
        render: (data) => parseDuration(data),
    },
    {
        title: 'Start Location',
        key: 'start_loc',
        dataIndex: 'start_loc',
        render: (data, rowData) => exportState ?
            (
                rowData
                &&
                rowData.start_geocoded
                &&
                rowData.start_addr
                ||
                `${data.lat}, ${data.lon}`
            )
            :
            (
                rowData
                &&
                rowData.start_geocoded
                &&
                <GeoLocation location={data} address={rowData.start_addr}/>
                ||
                <GeoLocation location={data}/>
            )
    },
    {
        title: 'End Location',
        key: 'end_loc',
        dataIndex: 'end_loc',
        render: (data, rowData) => exportState ?
        (
            rowData
            &&
            rowData.end_geocoded
            &&
            rowData.end_addr
            ||
            `${data.lat}, ${data.lon}`
        )
        :
        (
            rowData
            &&
            rowData.end_geocoded
            &&
            <GeoLocation location={data} address={rowData.end_addr}/>
            ||
            <GeoLocation location={data}/>
        )
    },
    {
        title: 'Total Mileage (km)',
        key: 'totalMileage',
        render: (rowData) => RoundUp(mileageSelector(rowData, MILEAGE_TYPE.TRIP_MILEAGE), 3)
    },
    {
        title: "Accumlative Geofence In",
        key: "geoIN",
        dataIndex: "geoIN",
        render: (data) => 
            (
                data
                &&
                data.length 
                && 
                exportState ? 
                (
                    data && data.map(geoid => geoid && geofences[geoid].name).join(', ')
                )
                :
                (
                    data && data.map(geoid => <Tag color="blue" key={geoid}>{geoid && geofences[geoid].name}</Tag>)
                )
            )
            ||
            '-'
    },
    {
        title: "Accumlative Geofence Out",
        key: "geoOUT",
        dataIndex: "geoOUT",
        render: (data) => 
            (
                data
                &&
                data.length 
                && 
                exportState ? 
                (
                    data && data.map(geoid => geoid && geofences[geoid].name).join(', ')
                )
                :
                (
                    data && data.map(geoid => <Tag color="blue" key={geoid}>{geoid && geofences[geoid].name}</Tag>)
                )
            )
            ||
            '-'
    },
]

export const COL_DETAIL = ({vehicles, geofences, FILTER_OUT_ROLE, userRole, exportState}) => [
    {
        title: 'Vehicle',
        key: 'vehicle',
        dataIndex: 'vid',
        render: vid => vehicles[vid] && vehicles[vid].plate || vid
    },
    {
        title: "Vehicle Time",
        key: 'vehicleTime',
        dataIndex: "vehicleTime",
        render: (time) => parseTime(time),
    },
    {
        title: 'Server Time',
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: (time) => parseTime(time),
        isHidden: FILTER_OUT_ROLE.includes(userRole)
    },
    {
        title: 'Device IMEI',
        key: 'deviceIMEI',
        dataIndex: 'dvid',
        render: (data) => data,
        isHidden: FILTER_OUT_ROLE.includes(userRole)
    },
    {
        title: 'Data Package',
        key: 'devicePackage',
        dataIndex: 'devicePackage',
        render: (data) => data,
        isHidden: FILTER_OUT_ROLE.includes(userRole)
    },
    // {
    //     title: 'Trip Status',
    //     key: 'tripStatus',
    //     dataIndex: 'tripStatus',
    //     filters: Object.keys(TRIP_STATUS_OBJECT).map(status => (
    //         {
    //             text: status,
    //             value: TRIP_STATUS_OBJECT[status],
    //         }
    //     )),
    //     onFilter: (value, record) => record.tripStatus === value,
    //     render: (data) => exportState ? data : <VehicleTripStatusTag tripStatus={data}/>
    // },
    // {
    //     title: 'Transit Status',
    //     key: 'transitStatus',
    //     dataIndex: 'transitStatus',
    //     filters: Object.keys(TRANSIT_STATUS_OBJECT).map(status => (
    //         {
    //             text: status,
    //             value: TRANSIT_STATUS_OBJECT[status],
    //         }
    //     )),
    //     onFilter: (value, record) => record.transitStatus === value,
    //     render: (data) => exportState ? data : <VehicleTripStatusTag tripStatus={data}/>
    // },
    {   title: 'Connectivity',
        key: 'connectivity',
        dataIndex: 'connectivity',
        render: (data) => !exportState ?  <IOTag io={data} textOutput={true} /> :  <IOTag io={data}/>
    },
    {   title: 'Cell Signal',
        key: 'cell_signal',
        render: (rowData) => !exportState ? <CellSignalIcon vehicle={rowData}/> : `${rowData.cell_signal || rowData.connectivity }` 
    },
    {
        title: "GPS Status",
        key: "gnss_status",
        render: (rowData) => !exportState ?  <GNSSSignalIcon vehicle={rowData}/> : rowData.gnss_status
    },
    {
        title: "Engine Ignition",
        key: "ignition",
        dataIndex: "ignition",
        render: (data) => !exportState ?  <IOTag io={data} /> :  data
    },
    {
        title: "Location",
        key: "location",
        dataIndex: "location",
        render: (data) => !exportState ? <GeoLocation location={data}/> :`${data.lat}, ${data.lon}`
    },
    {
        title: "Geofence In",
        key: "geoIN",
        dataIndex: "geoIN",
        render: (data) => 
            (
                data
                &&
                data.length 
                && 
                !exportState ? 
                (
                    data && data.map(geoid => <Tag color="blue" key={geoid}>{geoid && geofences[geoid].name}</Tag>)
                )
                :
                (
                    data && data.map(geoid => geoid && geofences[geoid].name).join(', ')
                )
            )
            ||
            '-'
    },
    {
        title: "Geofence Out",
        key: "geoOUT",
        dataIndex: "geoOUT",
        render: (data) => 
            (
                data
                &&
                data.length 
                && 
                !exportState ? 
                (
                    data && data.map(geoid => <Tag color="blue" key={geoid}>{geoid && geofences[geoid].name}</Tag>)
                )
                :
                (
                    data && data.map(geoid => geoid && geofences[geoid].name).join(', ')
                )
            )
            ||
            '-'
    },
    {
        title: "Speed (km/h)",
        key: "speed",
        render: (rowData) => speedSelector(rowData, DERIVATIVE_DATA_TYPE.STATE)
    },
    {
        title: "Altitude",
        key: "altitude",
        dataIndex: "altitude",
        render: (data) => data
    },
    {
        title: "Direction (Degree)",
        key: "heading",
        dataIndex: "heading",
        render: (data) => data
    },
    {
        title: "External Voltage (V)",
        key: "externalVoltage",
        dataIndex: "pwr_ext_v",
        render: (data) => RoundUp(Number(data), 3)
    },
    {
        title: "Battery Voltage (V)",
        key: "batteryVoltage",
        dataIndex: "pwr_int_v",
        render: (data) => RoundUp(Number(data), 3)
    },
    {
        title: "Battery Current (A)",
        key: "batteryCurrent",
        dataIndex: "pwr_int_i",
        render: (data) => RoundUp(Number(data), 3)
    },
    {
        title: 'Digital Output 1',
        key: "do_1",
        dataIndex: "do_1",
        isHidden: FILTER_OUT_ROLE.includes(userRole),
        render: (data) => !exportState ?  <IOTag io={data} textOutput={true} /> : data
    },
    {
        title: 'Digital Input 1',
        key: "di_1",
        dataIndex: "di_1",
        isHidden: FILTER_OUT_ROLE.includes(userRole),
        render: (data) => !exportState ?  <IOTag io={data} textOutput={true} /> :  data
    },
    {
        title: 'Analog Input 1',
        key: "ai_1",
        dataIndex: "ai_1",
        isHidden: FILTER_OUT_ROLE.includes(userRole),
        render: (data) => !exportState ?  <IOTag io={data} textOutput={true} /> :  data  
    }
]

export const COL_DAILY_SUMMARY = ({vehicles, geofences, exportState}) => [
    {
        title: 'Vehicle',
        key: 'vehicle',
        dataIndex: 'vid',
        render: vid => vehicles[vid] && vehicles[vid].plate || vid
    },
    {
        title: "Report Date",
        key: 'reportTime',
        dataIndex: "reportTime",
        render: (time) => parseDate(time),
    },
    {
        title: "Daily Mileage (km)",
        key: 'plat_daily_mileage',
        dataIndex: "plat_daily_mileage",
        render: (data) => (
            data
            &&
            !exportState ?
            (
                `${RoundUp(data, 2)} km`
            )
            :
            (
                RoundUp(data, 2)
            )
        )
    },
    {
        title: "Daily Engine Hours (hrs)",
        key: 'plat_daily_engine_hour',
        dataIndex: "plat_daily_engine_hour",
        render: (data) => (
            data
            &&
            !exportState ?
            (
                parseDuration(data)
            )
            :
            (
                RoundUp(parseHours(data), 2)
            )
        )
    },
    {
        title: "Historical Mileage (km)",
        key: 'plat_hist_mileage',
        dataIndex: "plat_hist_mileage",
        render: (data) => (
            data
            &&
            !exportState ?
            (
                `${RoundUp(data, 2)} km`
            )
            :
            (
                RoundUp(data, 2)
            )
        )
    },
    {
        title: "Historical Engine Hours (hrs)",
        key: 'plat_hist_engine_hour',
        dataIndex: "plat_hist_engine_hour",
        render: (data) => (
            data
            &&
            !exportState ?
            (
                `${RoundUp(parseHours(data), 2)} hrs`
            )
            :
            (
                RoundUp(parseHours(data), 2)
            )
        )
    },
    {
        title: "Number of Trip",
        key: 'tripNumber',
        dataIndex: "tripNumber",
        render: (data) => data,
    },
    {
        title: "Total Moving Duration (hrs)",
        key: 'totalMovingDuration',
        dataIndex: "totalMovingDuration",
        render: (data) => (
            data
            &&
            !exportState ?
            (
                parseDuration(data)
            )
            :
            (
                RoundUp(parseHours(data), 2)
            )
        )
    },
    {
        title: "Total Idling Duration (hrs)",
        key: 'totalIdlingDuration',
        dataIndex: "totalIdlingDuration",
        render: (data) => (
            data
            &&
            !exportState ?
            (
                parseDuration(data)
            )
            :
            (
                RoundUp(parseHours(data), 2)
            )
        )
    },
    {
        title: "Accumlative Geofence In",
        key: "geoIN",
        dataIndex: "geoIN",
        render: (data) => 
            (
                data
                &&
                data.length 
                && 
                !exportState ? 
                (
                    data && data.map(geoid => <Tag color="blue" key={geoid}>{geoid && geofences[geoid].name}</Tag>)
                )
                :
                (
                    data && data.map(geoid => geoid && geofences[geoid].name).join(', ')
                )
            )
            ||
            '-'
    },
    {
        title: "Accumlative Geofence Out",
        key: "geoOUT",
        dataIndex: "geoOUT",
        render: (data) => 
            (
                data
                &&
                data.length 
                && 
                !exportState ? 
                (
                    data && data.map(geoid => <Tag color="blue" key={geoid}>{geoid && geofences[geoid].name}</Tag>)
                )
                :
                (
                    data && data.map(geoid => geoid && geofences[geoid].name).join(', ')
                )
            )
            ||
            '-'
    },
    {
        title: "Fuel Profile",
        key: "FP",
        dataIndex: "FP",
        render: (FP) => 
            (
                !exportState
                &&
                (
                    FP
                    &&
                    <Tag color="green" key={FP}>Have</Tag>
                    ||
                    <Tag color="red" key={FP}>Don't Have</Tag>
                )
                ||
                (
                    FP && 'Have' || `Don't Have`
                )
            )
    },
    {
        title: "Fuel Capacity",
        key: "FP_capacity",
        render: (data) => {
            const FP = data.FP
            return (
                FP
                &&
                data[`FP_0_capacity`]
                ||
                '-'
            )
        }
    },
    {
        title: "Estimate Fuel Cost Per Liter (RM)",
        key: "fuel_cost",
        render: () => 2.15
    },
    {
        title: "Total Fuel Consumption (L)",
        key: "FP_0_summary-fuel_consumption-total",
        render: (data) => {
            const FP = data.FP
            return (
                FP
                &&
                RoundUp(data[`FP_0_summary`].fuel_consumption.total, 3)
                ||
                '-'
            )
        }
    },
    {
        title: "Total Estimate Fuel Cost (RM)",
        key: "fuel_cost_total",
        render: (data) => {
            const FP = data.FP
            return (
                FP
                &&
                RoundUp(data[`FP_0_summary`].fuel_consumption.total*2.15, 3)
                ||
                '-'
            )
        }
    },
    {
        title: "Total Fuel Consumption Rate (L/km)",
        key: "FP_0_summary-fuel_consumption_rate-total",
        render: (data) => {
            const FP = data.FP
            return (
                FP
                &&
                RoundUp(data[`FP_0_summary`].fuel_consumption_rate.total, 3)
                ||
                '-'
            )
        }
    },
    {
        title: "Total Fuel Consumption Efficiency (km/L)",
        key: "FP_0_summary-fuel_efficiency-total",
        render: (data) => {
            const FP = data.FP
            return (
                FP
                &&
                RoundUp(data[`FP_0_summary`].fuel_efficiency.total, 3)
                ||
                '-'
            )
        }
    },
    {
        title: "Total Moving Fuel Consumption (L)",
        key: "FP_0_summary-fuel_consumption-moving",
        render: (data) => {
            const FP = data.FP
            return (
                FP
                &&
                RoundUp(data[`FP_0_summary`].fuel_consumption.moving, 3)
                ||
                '-'
            )
        }
    },
    {
        title: "Total Idling Fuel Consumption (L)",
        key: "FP_0_summary-fuel_consumption-idling",
        render: (data) => {
            const FP = data.FP
            return (
                FP
                &&
                RoundUp(data[`FP_0_summary`].fuel_consumption.idling, 3)
                ||
                '-'
            )
        }
    },
    {
        title: "Total Parking Fuel Consumption (L)",
        key: "FP_0_summary-fuel_consumption-parking",
        render: (data) => {
            const FP = data.FP
            return (
                FP
                &&
                RoundUp(data[`FP_0_summary`].fuel_consumption.parking, 3)
                ||
                '-'
            )
        }
    }
]