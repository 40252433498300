import * as API_CONSTANT from '../constants'

export const getVehicleReportDailySummaryTimeFrameSync = (vid, startTime, endTime) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vehicle/report/dailysummary/get/timeframe/sync`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vid,
            startTime,
            endTime
        })
    })
    .then(res => res.json())
)

export const getVehicleReportFuelAnalysisTimeFrameSync = (vid, startTime, endTime) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vehicle/report/fa/get/timeframe/sync`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vid,
            startTime,
            endTime
        })
    })
    .then(res => res.json())
)
