import * as ActionTypes from '../action-types'

const userRoles_DefaultState = {
    byID: {},
    allIDs: [],
    currentUROLEID: ''
}

export const userRoles = (state = userRoles_DefaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.USER_ROLE_GET: {
            
            const currentUROLEID = action.currentUROLEID
            const userRoles = action.userRoles
            
            newState.currentUROLEID = currentUROLEID

            if(userRoles && userRoles.length) {
                userRoles.map(ur => {
                    newState.byID[ur.uroleid] = ur

                    if(!newState.allIDs.includes(ur.uroleid)) newState.allIDs.push(ur.uroleid)

                    return null
                })
            }

            return newState
        }
        default: {
            return newState;
        }
    }
}