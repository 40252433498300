import * as API_CONSTANT from '../constants'

export const getVehicleInfoByVID = (vid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vehicle/getInfo`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vid
        })
    })
    .then(res => res.json())
)

export const getVehicleInfoBatch = (vids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vehicle/batch/getInfo`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vids
        })
    })
    .then(res => res.json())
)

export const getVehicleGroupByVHCGID = (vhcgid) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vehicle/getGroup`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vhcgid
        })
    })
    .then(res => res.json())
)

export const getVehicleGroupBatch = (vhcgids) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vehicle/batch/getGroup`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vhcgids
        })
    })
    .then(res => res.json())
)

export const getVehicleLogByTimeFrameSync = (vid, startTime, endTime) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vehicle/log/timeframe/get/sync`, {
      method: 'POST',
      headers: API_CONSTANT.headers,
      body: JSON.stringify({
        vid, 
        startTime, 
        endTime
      })
    })
    .then(res => res.json())
  )

export const getVehicleDerivativeLogByTimeFrameSync = (vid, startTime, endTime) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vehicle/log/derivative/timeframe/get/sync`, {
      method: 'POST',
      headers: API_CONSTANT.headers,
      body: JSON.stringify({
        vid, 
        startTime, 
        endTime
      })
    })
    .then(res => res.json())
  )

export const getVehicleGeoStateLogByTimeFrameSync = (vid, startTime, endTime) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vehicle/log/geoState/timeframe/get/sync`, {
      method: 'POST',
      headers: API_CONSTANT.headers,
      body: JSON.stringify({
        vid, 
        startTime, 
        endTime
      })
    })
    .then(res => res.json())
  )

export const getVehicleEventLogByTimeFrameSync = (vid, startTime, endTime) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vehicle/log/event/timeframe/get/sync`, {
      method: 'POST',
      headers: API_CONSTANT.headers,
      body: JSON.stringify({
        vid, 
        startTime, 
        endTime
      })
    })
    .then(res => res.json())
  )

export const getVehicleECULogByTimeFrameSync = (vid, startTime, endTime) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/vehicle/log/ecu/timeframe/get/sync`, {
      method: 'POST',
      headers: API_CONSTANT.headers,
      body: JSON.stringify({
        vid, 
        startTime, 
        endTime
      })
    })
    .then(res => res.json())
  )