import React from "react";
import { 
  Route, 
  Routes
} from "react-router";

// Constant
import {
  ROUTES,
  COMMON_FEATURES,
  CONSTANT_ACTIONS
} from '../../../../constants'

// Components
import { asyncComponent } from '../../../../components'

const {
  MODULE_ROUTE
} = ROUTES

const AsyncNotFoundPage = asyncComponent(() => import("../../../../pages/NotFoundPage"));
const AsyncVehicleListSidebar = asyncComponent(() => import('../../../../modules/VehicleManagement/modules/VehicleListSidebar'))
const AsyncVehicleRoutePlayback = asyncComponent(() => import('../../../../modules/RoutePlayback'))
const AsyncUserListSidebar = asyncComponent(() => import('../../../../modules/UserManagement/modules/UserListSidebar'))
const AsyncGeofenceListSidebar = asyncComponent(() => import('../../../../modules/GeofenceManagement/modules/GeofenceListSidebar'))
const AsyncAddEditPOISidebar = asyncComponent(() => import('../../../../modules/GeofenceManagement/modules/AddEditPOISidebar'))
const AsyncAddEditZoneSidebar = asyncComponent(() => import('../../../../modules/GeofenceManagement/modules/AddEditZoneSidebar'))
const AsyncUserProfile = asyncComponent(() => import('../../../../modules/Profile'))

/**
 * Available Routes for the Secondary Sidebar
 */
const SideBarRoutes = () => {
  return (
    <Routes>
      <Route exact path = {MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.LANDING_PAGE]} element={<AsyncVehicleListSidebar />} />
      <Route exact path = {MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.VEHICLE_ROUTE_PLAYBACK]} element={<AsyncVehicleRoutePlayback />} />
      <Route exact path = {MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.USER_MANAGEMENT]} element={<AsyncUserListSidebar />} />
      <Route exact path = {MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.GEOFENCE_MANAGEMENT]} element={<AsyncGeofenceListSidebar />} />
      <Route exact path = {MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_CREATE]} element={<AsyncAddEditZoneSidebar routeAction={CONSTANT_ACTIONS.COMMON_ACTION_TITLE[CONSTANT_ACTIONS.COMMON_ACTION.CREATE]}/>} />
      <Route exact path = {MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_EDIT]} element={<AsyncAddEditZoneSidebar routeAction={CONSTANT_ACTIONS.COMMON_ACTION_TITLE[CONSTANT_ACTIONS.COMMON_ACTION.EDIT]}/>} />
      <Route exact path = {MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.GEOFENCE_MANAGEMENT_POI_CREATE]} element={<AsyncAddEditPOISidebar routeAction={CONSTANT_ACTIONS.COMMON_ACTION_TITLE[CONSTANT_ACTIONS.COMMON_ACTION.CREATE]}/>} />
      <Route exact path = {MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.GEOFENCE_MANAGEMENT_POT_EDIT]} element={<AsyncAddEditPOISidebar routeAction={CONSTANT_ACTIONS.COMMON_ACTION_TITLE[CONSTANT_ACTIONS.COMMON_ACTION.EDIT]}/>} />
      <Route exact path = {MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.USER_PROFILE]} element={<AsyncUserProfile />}/>
      <Route element={<AsyncNotFoundPage />} />
    </Routes>
  )
}

export default SideBarRoutes;